import React, {Component} from 'react';

import { formattedDate } from '../../utils/utils';

import classes from './PhotosToc.module.css';

class PhotosToc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAlbum: props.currentAlbum,
      tocIsOpen: true
    };  
  }

  tocEntryClicked = (album) => {
    this.setState({currentAlbum: album, tocIsOpen: false});
    this.props.clicked(album);
  };

  tocClasses = (album) => {
    let classNames = classes.TocEntry;
    if (this.state.currentAlbum.id === album.id) {
      classNames = [classes.TocEntry, classes.TocEntryActive].join(' ');
    }
    return classNames;
  }

  renderToc = (albums) => {
    console.log('rendering albums TOC...')
    // debugger;
    return albums.map((album) => {
      return (
        <div
          className={this.tocClasses(album)} key={album.id}
          onClick={() => {this.tocEntryClicked(album)}}
        >
          <p className={classes.TocTitle}>{album.name}</p>
          <p className={classes.TocEntryMeta}>
            by {album.creator} <br />
            on {formattedDate(album.created_at)}
          </p>
        </div>
      )
    })
  }
  
  render() {
    return (
      <div className={classes.Toc}>
        {this.renderToc(this.props.albums)}
      </div>
    )  
  }
}

export default PhotosToc;
