import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { logout } from '../../redux/actions/authActions';
import Spinner from '../Spinner/Spinner';

const Logout = (props) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  console.log('[Logout]: logging out...')
  if (auth.logged_in) {
    dispatch(logout({token: auth.token, csrf_token: auth.csrf_token}));
    return <Spinner />;
  } else {
    return <Redirect to="/" />;
  }
}

export default Logout;