import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  isBrowser
} from 'react-device-detect';

import { updateListMessage } from '../../redux/actions/emailListsActions';

import Spinner from '../Spinner/Spinner';

import MessageForm from './MessageForm';

import { setTitle } from '../../redux/actions/controlsActions';
import classes from './ListMessages.module.css';

/**
 * 
 * @param {listMessage} props 
 * @param {email_list_id}
 * @returns 
 */
const EditMessage = (props) => {
  const auth = useSelector(state => state.auth);
  const emailLists = useSelector(state => state.emailLists);
  const dispatch = useDispatch();

  let listMessageDefaults = {
    id: props.listMessage.id,
    subject: props.listMessage.subject,
    body: props.listMessage.body,
    is_draft: props.listMessage.is_draft,
    user_id: props.listMessage.user_id,
    email_list_id: props.email_list_id
  };
  
  useEffect(() => {
    dispatch(setTitle("Edit Site Message"));
  }, []);

  useEffect(() => {
    // debugger
    listMessageDefaults = {...props.listMessage, id: props.email_list_id};
  }, [props.listMessage])

  // handleFormSubmit receives the values from the form in a JS object
  // the postCallback to let the form know we're done processing.
  const handleFormSubmit = (values) => {
    console.log('[EditMessage]: ***** handleFormSubmit *********')
    dispatch(updateListMessage(values))
    if (props.onSubmit) {
      props.onSubmit();
    }
  }

  const renderContent = () => {
    // debugger
    if (emailLists.updatingList) {
      return <Spinner />;
    } else {
      //TODO: factor in a callback from props passed in so the calling component can clear anything related...
      //  example: clear the new message form on create
      return <MessageForm edit={true} listMessage={listMessageDefaults} auth={auth} formSubmit={handleFormSubmit} />
    }
  }

  return (
    <Row>
      {isBrowser ? <div className={classes.Title}>Edit List Message</div> : null}
      <Col md={12}>
        {renderContent()}
      </Col>
    </Row>
  );
}

export default EditMessage;
