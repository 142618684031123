import React, {useEffect, useState} from 'react';

import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Row';

import moment from 'moment'

import { Calendar, momentLocalizer, Views  } from 'react-big-calendar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useSelector, useDispatch } from 'react-redux';

import CalendarEventDetailView from './CalendarEventDetailView';
import CalendarEventControls from './CalendarEventControls';

import { getCalendarEvents, getEventMetadata, getCalendarEventsFromTillCurrentStart } from '../../redux/actions/calendarEventActions';
import { setTitle } from '../../redux/actions/controlsActions';

import classes from './CalendarEvent/CalendarEvent.module.css';

const CalendarEventView = (props) => {
  const dispatch = useDispatch();
  const calendarEvents = useSelector(state => state.calendarEvents);
  const auth = useSelector(state => state.auth);
  const [eventToShow, setEventToShow] = useState(null);
  const [activityTypeFilter, setActivityTypeFilter] = useState(null);
  const [currentCalendaView, setCurrentCalendaView] = useState(Views.MONTH);
  const localizer = momentLocalizer(moment)

  useEffect(() => {
    // debugger
    dispatch(setTitle("Calendar Event List"))
    dispatch(getEventMetadata())
    const startDate = new Date()
    startDate.setDate(1)
    dispatch(getCalendarEvents(startDate));
    setEventToShow(null);
  }, [calendarEvents.updatedEvent])

  const viewChanged = (currentView) => {
    setCurrentCalendaView(currentView);
  }

  const dateRangeChanged = (dates) => {
    // If dates is an array (Week View) then use the first value.
    if (Array.isArray(dates)) {
      // debugger
      dispatch(getCalendarEventsFromTillCurrentStart(dates[0]))
      return;
    }

    dispatch(getCalendarEventsFromTillCurrentStart(dates.start))
  }

  const renderCloneButton = (calendarEvent) => {
    // if (auth.app_admin) {
      return <FontAwesomeIcon 
              id={"cloneSM"}
              onClick={(e) => {
                e.stopPropagation()
                let cloneEvent = window.confirm(`Do you want to clone this calendar event?`);
                if (cloneEvent) {          
                  this.cloneCalendarEvent(calendarEvent)
                }
              }}
              size="1x" className={classes.CloneIcon}
              // style={{float: 'right', marginRight: '5px'}}
              title='Clone Event'
              icon={faClone} />
    // }
    // return null;
  }

  const renderEventDetail = (event) => {
    setEventToShow(event)
    // props.setCurrentEvent(event);
    // alert(`renderEventDetail: Selected Event: ${JSON.stringify(event)}`)
  }

  const renderCalendarEvents = () => {
    if (calendarEvents.calendarEvents.length === 0) {
      return []
    }
    // Only show calendar events that match the activity type filter if set
    if (activityTypeFilter) {
      const calEvents = calendarEvents.calendarEvents.map(ce => {
        if (ce.activity_id === activityTypeFilter) {
          return ce;
        } else {
          return null;
        }
      })
      return calEvents;
    }
    return calendarEvents.calendarEvents;
  }

  const activityTypeChanged = (selectEvent) => {
    // alert(`activityTypeChanged in CalendarEventView ${selectEvent.target.value}`)
    setActivityTypeFilter(Number(selectEvent.target.value))
  }

  const MyCustomAgendaEventComponent = (props, renderCloneButton, renderEventDetails) => {
    // debugger
    return (
      <div>
      <div className={classes.Agenda} onClick={() => renderEventDetails(props.event)}>
        {/* Event details displayed here */}
          {props.event.title} 
        </div>
        <span style={{float: 'right'}}>{renderCloneButton(props.event)}</span>
        {/* <p>{props.event.description}</p> */}
      </div>
    )
  };

  return (
    <React.Fragment>
    <Container fluid>
      <div style={{height: '50px'}}>
        <CalendarEventControls
          onSelect={(event) => {setEventToShow(event)}}
          onActivityTypeChange={activityTypeChanged}
          calendarViewNext={'calendar_events'}
          // onRenderBack={(monthsBack) => refetchEvents(monthsBack)}
          // beginMonthBias={initialMonthsBack}
          rowStyle={{position: 'fixed', paddingLeft: '10px', _paddingTop: '4px', left: '0px', right: '0px', height: '50px', width: 'inherit', zIndex: '1000', backgroundColor: 'white'}}
        />
      </div>
      <div style={{height: '60vh'}}>
        <Calendar
          localizer={localizer}
          events={ renderCalendarEvents() }
          views={[Views.MONTH, Views.WEEK, Views.AGENDA, Views.DAY]}
          onView={(viewType) => {viewChanged(viewType)}}
          view={currentCalendaView}
          // startAccessor="start"
          // endAccessor="end"
          onNavigate={()=> {}}
          // date={currentDate}  DONT NEED THIS.
          onRangeChange={(dates) => dateRangeChanged(dates)}
          // style={{ height: 500 }}
          onSelectEvent={(event) => {setEventToShow(event)}}
          selectable={true}
          popup={true}
          onSelectSlot={(slotInfo) => {alert(`onSelectSlot: ${JSON.stringify(slotInfo)}`)}}

          components={{ agenda: { event: (props) => MyCustomAgendaEventComponent(props, renderCloneButton, renderEventDetail) } }}
          myCustomAttr='this is my custom attr'
          onSelectAgendaEvent={() => {alert('CLICKED')}} // Callback function
        
        />
      </div>
    </Container>
    <CalendarEventDetailView
      event={eventToShow}
      onChange={(event) => {setEventToShow(event)}}
      onClose={(event) => {setEventToShow(null)} }
    />
    </React.Fragment>
  );
}

export default CalendarEventView;