import React, {useState} from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useHistory } from 'react-router-dom';

import Input from '../UI/Input';

import classes from './ListMessages.module.css';

const schema = Yup.object({
  name: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(80, 'Must be 32 characters or less')
    .required('Required'),
  description: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(512, 'Must be 32 characters or less')
    .required('Required'),
});

const EmailListForm = (props) => {
  const history = useHistory();

  const inputStyle = {
    fontSize: '1.1em',
    marginTop: '10px',
    marginBottom: '10px'
  }

  const formik = useFormik({
    initialValues: {
      name: props.emailList.name,
      description: props.emailList.description,
      site_definition_id: props.auth.site_definition_id,
      user_id: props.emailList.user_id /*props.auth.uid*/ //TODO: figure out if I want to keep user_id == to creator
    },
    validationSchema: schema,
    onSubmit: (values) => {
      props.formSubmit(props.emailList.id, values);
    },
  });

  const renderSubmitButton = () => {
    if (props.edit) {
      return "Update List";
    } else {
      return "Create List"
    }
  }

  const cancelEdit = (event) => {
    event.preventDefault();
    if (props.onCancel) {
      props.onCancel();
    } else {
      history.goBack();
    }
  }

  const renderContent = () => {
    return(
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={12}>
            <Input
              style={inputStyle}
              placeholder="Enter List Name"
              noPrepend
              disabled={formik.isSubmitting}
              id='name'
              required
              label="Name"
              value={formik.values.name}
              name='name'
              changed={formik.handleChange}
              blur={formik.handleBlur}
              errors={formik.errors.name}
              touched={formik.touched.name}
              showValid
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Input
              style={inputStyle}
              as="textarea"
              placeholder="Enter List Description"
              noPrepend
              disabled={formik.isSubmitting}
              id='description'
              required
              label="description"
              value={formik.values.description}
              name='description'
              changed={formik.handleChange}
              blur={formik.handleBlur}
              errors={formik.errors.description}
              touched={formik.touched.description}
              showValid
            />
          </Col>
        </Row>

        {/* <input type='hidden' name='id' value={formik.values.id} /> */}
        <input type="hidden" name="site_definition_id" value={formik.values.site_definition_id} />
        <input type="hidden" name="user_id" value={formik.values.user_id} />
        <InputGroup className={classes.SubmitButton}>
          <Button size="sm" type="submit" disabled={formik.isSubmitting}>{renderSubmitButton()}</Button>
          &nbsp;&nbsp;
          <Button size="sm" type="cancel" onClick={(e) => {cancelEdit(e)}} disabled={formik.isSubmitting}>Cancel</Button>
        </InputGroup>
      </Form>
    );
  }

  // Render the form
  return (
    <div className={classes.FormContainer}>
      {renderContent()}
    </div>
  );
}

export default EmailListForm;
