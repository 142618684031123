import React from 'react';

import classes from './DrawerToggleButton.module.css';

const drawerToggleButton = (props) => {

  const renderRightBtn = () => {
    let btnText;
    if (props.btnText) {
      btnText = props.btnText;
    } else {
      return null;
    }
    
    return (<div style={{color: 'white', marginRight: '10px'}}>{btnText}</div>);
  }
  let btnClasses = classes.DrawerToggleButton;
  if (props.inverseColor) {
    btnClasses = [classes.DrawerToggleButton, classes.DrawerToggleButtonInverse].join(' ');
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      {renderRightBtn()}
      <div className={btnClasses} onClick={props.clicked}>
        <div></div>
        <div></div>
        <div></div>
      </div>
     </div>
  );
}

export default drawerToggleButton;
