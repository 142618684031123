import { WHE_BASE_URL, AUTH_INFO } from '../../utils/constants';

import axios from '../../utils/axios-whe';

import { startKeepAliveTimer, stopKeepAliveTimer, reloadAppIfNewerAvailable } from '../../utils/keepAlive';
// import { useParams } from 'react-router';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

export const LOGIN_WHE_START = 'login_whe_start';
export const LOGIN_WHE_SUCCESS = 'login_whe_success';
export const LOGIN_WHE_FAILED = 'login_whe_failed';
export const LOGOUT_WHE_START = 'logout_whe_start';
export const LOGOUT_WHE_SUCCESS = 'logout_whe_success';
export const LOGOUT_WHE_FAILED = 'logout_whe_failed';
export const CLEAR_LOGGED_IN_CREDENTIALS = 'clear_logged_in_credentials';
export const SET_REDIRECT_PATH = 'set_redirect_path';
export const UNAUTHORIZED_RESPONSE = 'unauthorized_response';
export const KEEP_ALIVE = 'keep_alive';

export const RESTORE_LOGIN_START = 'restore_login_start';
export const TRY_RESTORE_LOGIN = 'try_restore_login';

export const FORGOT_PASSWORD_START = 'forgot_password_start';
export const FORGOT_PASSWORD_FINISHED = 'forgot_password_finished';
export const SET_PASSWORD_SUCCESS = 'set_password_success';
export const SET_PASSWORD_FAILED = 'set_password_failed';

export const GET_USERS_START = 'get_users_start';
export const GET_USERS_SUCCESS = 'get_users_success';
export const GET_USERS_FAILED = 'get_users_failed';

export const REGISTER_USER_START = 'register_user_start';
export const REGISTER_USER_SUCCESS = 'register_user_success';
export const REGISTER_USER_FAILED = 'register__user_failed';

export const UPDATING_PASSWORD = "updating_password";
export const UPDATE_PASSWORD_SUCCESS = "update_password_success";
export const UPDATE_PASSWORD_FAILED = "update_password_failed";
export const CLEAR_UPDATING = "clear_updating";

export const PENDING_MEMBERS_START = "pending_members_start";
export const PENDING_MEMBERS_SUCCESS = "pending_members_success";
export const PENDING_MEMBERS_FAILED = "pending_members_failed";

export const APPROVE_MEMBER_START = "approve_member_start";
export const APPROVE_MEMBER_SUCCESS = "approve_member_success";
export const APPROVE_MEMBER_FAILED = "approve_member_failed";

export const DECLINE_MEMBER_START = "decline_member_start";
export const DECLINE_MEMBER_SUCCESS = "decline_member_success";
export const DECLINE_MEMBER_FAILED = "decline_member_failed";

export const CANCEL_USER_START = "cancel_user_start";
export const CANCEL_USER_SUCCESS = "cancel_user_success";
export const CANCEL_USER_FAILED = "cancel_user_failed";

export const ACK_POLICY = "ack_policy";

const errorText = (error) => {
  let errText = error.message;
  if (error.response?.status === 404 && error.response?.data?.error) {
    errText = error.response.data.error;
  }
  return errText;
}

export const login = ({ email, password }) => {
  // console.log(`Logging in ${email} with password ${password}`);
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_WHE_START, payload: {email: email, password: password} });

    axios.post(`${WHE_BASE_URL}/api/v1/login.json`, {}, {
      // method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        username: email,
        password: password
      }
    })
    .then(response => {
      // console.log(response);
      // console.log(response.data.signin.token);

      // Save in localStorage
      localStorage.setItem(AUTH_INFO, JSON.stringify(response.data.signin));
      reloadAppIfNewerAvailable(response.data.signin);
      startKeepAliveTimer();
      return dispatch({ type: LOGIN_WHE_SUCCESS, payload: response.data.signin });
    })
    .catch(error => {
      console.log(`Error logging in.  ${error}`);
      dispatch({ type: LOGIN_WHE_FAILED, payload: { email, password, error }});
    });
  }
}

export const tryLoginFromStorage = (path) => {
  let authInfo = localStorage.getItem(AUTH_INFO);
  // console.log('tryLoginFromStorage', authInfo, path)
  return (dispatch, getState) => {
    if (authInfo) {
      try {
        authInfo = JSON.parse( authInfo );
      } catch (e) {
        // Error trying to parse authInfo.  Clear and set redirect;
        localStorage.removeItem(AUTH_INFO);
        return dispatch({ type: LOGIN_WHE_FAILED, payload: { error: `Invalid login.  Sign in again.  ${e.message}` } });
      }
    }
    
    dispatch({ type: RESTORE_LOGIN_START });
    // OK, could be valid, try a keep_alive ping.  IF ok, allow.
    axios.get(`/api/v1/keep_alive.json`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${authInfo.token}`,
        'X-CSRF-Token': authInfo.csrf_token
      }
    })
    .then(response => {
      // console.log('=======> KeepAlive Ping test...')
      // console.log(`SiteDefinition: count: ${this.state.count}`)
      // debugger;
      if (response !== undefined) {
        // console.log("Keep alive data: ", response.data);
        // XXX: kick off next timeout and save the timer id
        localStorage.setItem(AUTH_INFO, JSON.stringify(response.data.signin));
        reloadAppIfNewerAvailable(response.data.signin);
        dispatch({ type: SET_REDIRECT_PATH, payload: {path: path} });
        const result = dispatch({ type: TRY_RESTORE_LOGIN, payload: response.data.signin });
        startKeepAliveTimer();
        return result;
      }
    })
    .catch(error => {
      // dispatch(fetchIngredientsFailed());
      console.log("ERROR fetching doKeepAlive...", error)
      return dispatch({ type: LOGIN_WHE_FAILED, payload: { error: `Login expired.  Must login again.  ${error.message}` } });
    });
  }
};

export const logout = ({ token, csrf_token }) => {
  // console.log( `Logging out with token: ${token}, CSRF_TOKEN: ${csrf_token}`);
  return (dispatch, getState) => {
    // console.log("LOGOUT: STATE: ", getState());
    axios.delete(`${WHE_BASE_URL}/api/v1/logout.json`, {
      // method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Token ${token}`,
        'X-CSRF-Token': csrf_token
      }
    })
    .then(response => {
      //console.log(JSON.stringify(response));
      // console.log(response);
      stopKeepAliveTimer();
      dispatch({ type: LOGOUT_WHE_SUCCESS, payload: response.data });
      dispatch({ type: SET_REDIRECT_PATH, payload: {path: '/'} });
      console.log('Dispatched LOGOUT_WHE_SUCCESS');
      localStorage.removeItem(AUTH_INFO);
    })
    .catch(error => {
      console.error(`ERROR logging out of ${error}`);
      dispatch({ type: LOGOUT_WHE_FAILED, payload: { error } });
    });
  }
}

export const forgotPassword = (email) => {
  return (dispatch) => {
    console.log('authActions: forgotPassword: email', email)
    dispatch({ type: FORGOT_PASSWORD_START, payload: {email: email} });
    axios.post(`${WHE_BASE_URL}/api_v1/password_resets.json`, {email: email}, {
      // method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        email: email
      }
    })
    .then(response => {
      // console.log(response);
      return dispatch({ type: FORGOT_PASSWORD_FINISHED, payload: response.data });
    })
    .catch(error => {
      console.log(`Error logging in.  ${error}`);
      dispatch({ type: LOGIN_WHE_FAILED, payload: { email: email, error: error }});
    });
  }
}

export const signinWithPerishableToken = (token, password, passwordConfirm) => {
  return (dispatch) => {
    const formJson = {
      user: {
        password: password,
        password_confirmation: passwordConfirm
      }
    };
    axios.patch(`/api_v1/password_resets/${token}`, formJson, {
      headers: {
        _method: 'patch',
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    .then(response => {
      // console.log(response);
      // console.log(response.data.signin.token);

      // Save in localStorage
      localStorage.setItem(AUTH_INFO, JSON.stringify(response.data.signin));
      reloadAppIfNewerAvailable(response.data.signin);
      startKeepAliveTimer();
      return dispatch({ type: SET_PASSWORD_SUCCESS, payload: response.data.signin });
    })
    .catch(error => {
      console.log(`Error logging in.  ${error}`);
      dispatch({ type: SET_PASSWORD_FAILED, payload: { token: token, password: password, error: error }});
    });
  }
}

export const getSiteUsers = () => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({type: GET_USERS_START});
    axios.get(`/api_v1/neighborhood_users.json`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      if (response.status === 200) {
        dispatch( {type: GET_USERS_SUCCESS, payload: {site_users: [...response.data]}} );
      } else {
        // ERROR: log it.  axios response Interceptor should handle it
        console.error("Error retrieving property user data");
        dispatch({type: GET_USERS_FAILED, payload: {error: 'Failed to get site users.'}})
      }
    })
    .catch(error => {
      console.error(`Error retrieving prop user data:  ${error}`);
      dispatch({type: GET_USERS_FAILED, payload: {error: errorText(error)}})
    })

  }
}

export const registerNewUser = (userParams) => {
  return (dispatch) => {
    dispatch({ type: REGISTER_USER_START, payload: userParams });

    axios.post(`${WHE_BASE_URL}/api_v1/account.json`, {user: userParams}, {
      // method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    .then(response => {
      // console.log(response);
      // debugger;
      if (response.data.signup.result === true) {
        return dispatch({ type: REGISTER_USER_SUCCESS, payload: response.data });
      } else {
        return dispatch({ type: REGISTER_USER_FAILED, payload: { userParams, error: response.data.signup.message } });
      }
    })
    .catch(error => {
      console.log(`Error logging in.  ${error}`);
      dispatch({ type: REGISTER_USER_FAILED, payload: { userParams, error }});
    });
  }
};

export const activateWithPerishableToken = (token, password, passwordConfirm) => {
  return (dispatch) => {
    const formJson = {
      user: {
        password: password,
        password_confirmation: passwordConfirm
      }
    };

    //XXX:WDS: Add activate with perishable_token method...
    //   The PasswordResets form can be replicated and we'll set
    //   the perishable_token as a hidden var OR pass on the URL...
    axios.post(`/api_v1/activate/${token}`, formJson, {
      headers: {
        _method: 'create',
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    .then(response => {
      // console.log(response);
      // console.log(response.data.signin.token);

      // Save in localStorage
      localStorage.setItem(AUTH_INFO, JSON.stringify(response.data.signin));
      reloadAppIfNewerAvailable(response.data.signin);
      startKeepAliveTimer();
      return dispatch({ type: SET_PASSWORD_SUCCESS, payload: response.data.signin });
    })
    .catch(error => {
      console.log(`Error logging in.  ${error}`);
      dispatch({ type: SET_PASSWORD_FAILED, payload: { token: token, password: password, error: error }});
    });
  }
}

export const updatePassword = (token, currentPassword, password, passwordConfirm) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    const formJson = {
      cur_pwd: currentPassword,
      user: {
        password: password,
        password_confirmation: passwordConfirm
      }
    };

    dispatch({type: UPDATING_PASSWORD});
    axios.put('/api_v1/account.json', formJson, {
      headers: {
        _method: 'put',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // console.log(response);
      return dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: response.data });
    })
    .catch(error => {
      console.log(`Error logging in.  ${error}`);
      dispatch({ type: UPDATE_PASSWORD_FAILED, payload: { error: error }});
    });
  }
}


export const setRedirectPath = (path) => {
  return (dispatch) => {
    dispatch({ type: SET_REDIRECT_PATH, payload: {path: path} });
  }
}

export const clearLoginCredentials = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_LOGGED_IN_CREDENTIALS })
  }
}

export const reinitAppState = () => {
  stopKeepAliveTimer();
  return (dispatch) => {
    dispatch({ type: REINIT_ALL_STATE });
  }
}

export const getPendingMembers = () => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    dispatch({ type: PENDING_MEMBERS_START });
    // debugger
    axios.get(`${WHE_BASE_URL}/api_v1/pending_site_members`, {
      // method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // console.log(response);
      dispatch({ type: PENDING_MEMBERS_SUCCESS, payload: {members: response.data }});
    })
    .catch(error => {
      console.log(`Error fetching pending site members.  ${error}`);
      dispatch({ type: PENDING_MEMBERS_FAILED, payload: { error: error }});
    });
  }
}

export const approveMember = (userId, propertyId) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    dispatch({ type: APPROVE_MEMBER_START });
    // debugger
    axios.post(`${WHE_BASE_URL}/api_v1/approve_member/${userId}/${propertyId}`, {}, {
      // method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // console.log(response);
      dispatch({ type: APPROVE_MEMBER_SUCCESS, payload: {members: response.data }});
    })
    .catch(error => {
      console.log(`Error approving site member.  ${error}`);
      dispatch({ type: APPROVE_MEMBER_FAILED, payload: { error: error }});
    });
  }
}

export const declineMember = (userId, propertyId) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    dispatch({ type: DECLINE_MEMBER_START });
    // debugger
    axios.post(`${WHE_BASE_URL}/api_v1/decline_member/${userId}/${propertyId}`, {}, {
      // method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // console.log(response);
      dispatch({ type: DECLINE_MEMBER_SUCCESS, payload: {members: response.data }});
    })
    .catch(error => {
      console.log(`Error declining site member.  ${error}`);
      dispatch({ type: DECLINE_MEMBER_FAILED, payload: { error: error }});
    });
  }
}

export const cancelUser = (userId) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    dispatch({ type: CANCEL_USER_START });
    // debugger
    axios.patch(`${WHE_BASE_URL}/api_v1/cancel_user/${userId}`, {}, {
      // method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // console.log(response);
      dispatch({ type: CANCEL_USER_SUCCESS, payload: {members: response.data }});
    })
    .catch(error => {
      console.log(`Error declining site member.  ${error}`);
      dispatch({ type: CANCEL_USER_FAILED, payload: { error: error }});
    });
  }
}

export const ackSitePolicy = () => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    axios.patch(`${WHE_BASE_URL}/api_v1/ack_conduct/${auth.uid}`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger
      dispatch({type: ACK_POLICY})
    })
    .catch(error => {
      debugger
      console.log(`Error acking site conduct.  ${error}`);
      // dispatch({ type: CANCEL_USER_FAILED, payload: { error: error }});
    });
  }
}