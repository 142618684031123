import React, {useState, useEffect} from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { withRouter } from 'react-router';

import Input from '../../UI/Input';

import { POST_EDITOR } from '../../../utils/constants';
import TinyMceEditor from '../PostEditor/TinyMceEditor';

import Spinner from '../../Spinner/Spinner';

import Modal from '../../UI/Modal/Modal';
import YesNoModal from '../../UI/YesNoModal';

import "./NewPost.css";
import classes from './PostForm.module.css';

const schema = Yup.object({
  name: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(80, 'Must be 32 characters or less')
    .required('Required'),
  // content: Yup.string()
  //   .test(
  //     'has-content',
  //     'Must have something to say or this will not be saved / published',
  //     (value, context) => {
  //       if (value === undefined || value.length === 0) {
  //         // alert("NO")
  //         return false;
  //       } else {
  //         return true
  //       }
  //     })
});

// WDS: Stopped using clear_attrs when I changed to TinyMCE
// const clear_attrs = (str,attrs) => {
//   var reg2 = /\s*(\w+)="[^"]+"/gm;
//   var reg = /<\s*(\w+).*?>/gm;
//   str = str.replace(reg,function(match, i) {
//     var r_ = match.replace(reg2,function(match_, i) {
//       var reg2_ = /\s*(\w+)="[^"]+"/gm;
//       var m = reg2_.exec(match_);
//       if(m!=null){
//         if(attrs.indexOf(m[1])>=0){
//           return match_;
//         }
//       }
//       return '';
//     });        
//     return r_;
//   });
//   return str;
// }

const msWordWarning = `Detected content pasted with some incompatible web formatting.  This has been cleaned up so it will display
                       properly on the website and in email.  Please review your message before trying to save it and/or publish it 
                       to make sure this is what you wanted the note to say and how you want it to look.  You might need to reformat 
                       slightly by hand.`;

const PostForm = (props) => {
  const postsState = useSelector(state => state.posts);
  const [initialData, setInitialData] = useState({name: props.post.name, content: props.post.content});
  // const [resizedImage, setResizedImage] = useState(undefined);
  // React.Component.whyDidYouRender = true;

  useEffect(() => {
    setInitialData({name: props.post.name, content: props.post.content})
  }, [])

  const getPostButtonText = () => {
    if (props.edit) {
      if (props.post.published) {
        return "Update and Send";
      } else {
        return "Update";
      }
    } else {
      return "Save";
    }
  }
  
  const [formButton, setFormButton] = useState(getPostButtonText());
  const [showModal, setShowModal] = useState();
  const [showTestMessage, setShowTestMessage] = useState();
  const [sendingTestMessage, setSendingTestMessage] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: props.post.id,
      name: props.post.name,
      content: props.post.content,
      published: props.post.published,
      neighborhood_id: props.auth.neighborhood_id,
      site_definition_id: props.auth.site_definition_id,
      user_id: props.auth.uid,
      allow_comments: props.post.allow_comments,
      post_type_id: 1,
      send_test_message: false
    },
    validationSchema: schema,
    onSubmit: (values) => {
      // debugger
      // const cleanedContent = clear_attrs(values.content, ["src", "href", "target"]);
      const cleanedContent = values.content; // WDS: HACK around the cleaning since MS tags should be out
      if (cleanedContent !== values.content) {
        // alert("Detected content pasted from MS Word/Excel.  This has been updated so it will display properly on the website and in email.  Please review before trying to submit again to make sure this is what you wanted the note to say.  You might need to reformat slightly by hand.");
        setShowModal(msWordWarning);
        formik.setFieldValue("content", cleanedContent);
        formik.setSubmitting(false)
      } else {
        if (values.content.length === 0) {
          alert("No content specified, not saving or posting.")
          formik.setSubmitting(false)
        } else {
          props.formSubmit(values);
        }
      }
    },
  });
  
  const handlePublishedRadioChange = (event) => {
    // alert(`handlePublishedRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
        let btnText = (props.edit ? "Update" : "Save");
        if (event.target.name === "published") {
          btnText = `${btnText} and Send`
        }
        setFormButton(btnText);
      } else {
        formik.setFieldValue(event.target.name, false);
        if (props.edit) {
          setFormButton("Update");
        } else {
          setFormButton("Save");
        }
      }
    }
  }
  
  const blurEditorContent = (content) => {
    debugger
    updateEditorContent(content)
  }
  const updateEditorContent = (content) => {
    // console.log(`[PostForm]: updatePostContent: contentIN: ${content}`);
    formik.setFieldValue("content", content);
  }
  
  const yesCallback = (post) => {
    setShowTestMessage(false);
    formik.handleSubmit(null);
  }

  const noCallback = (post) => {
    formik.setFieldValue("published", false, false);
    setShowTestMessage(false)
    if (props.edit) {
      setFormButton("Update");
    } else {
      setFormButton("Save");
    }
  }


  const sendTestMessage = () => {
    alert(`Saving as draft and sending a test email message to ${props.auth.user.email}`)
    formik.setFieldValue("published", false, false);
    formik.setFieldValue("send_test_message", true, false);

    setSendingTestMessage(true);
    formik.handleSubmit(null); // let save / update form submit run and publish the test message
  }

  const renderTestMessageModal = (post) => {
    // debugger
    if (formik.values.name !== initialData.name || formik.values.content !== initialData.content) {
      // alert('form changed')
    }

    // New message and didn't choose Published
    if (!props.edit && !formik.values.published) {
      return null;
    }

    let messageQuery = "Would you like to send a test message?";
    let yesLabel = "Post & send notifications";
    let noLabel = "No, don't post yet";

    // new message and chose to publish immediately
    if (formik.values.published) {
      // TODO: make uery message appropriate for this case
      messageQuery = `You're post will be live on the site and email messages will be sent.  Is this what you want to do?`;
    }

    return (
      <YesNoModal 
        show={showTestMessage}
        yesCallback={(cbArg) => {yesCallback(cbArg)}}
        callbackArg={post}
        noCallback={(cbArg) => noCallback(cbArg)}
        yesLabel={yesLabel}
        noLabel={noLabel}
        cancel={() => {setShowTestMessage(false)}}
      >
        <div style={{marginBottom: '10px'}}>
          {messageQuery}
        </div>
      </YesNoModal>
    );
  }

  const renderContent = () => {
    if (showModal) {
      return (
        <Modal show={showModal} modalClosed={() => {setShowModal(undefined)}}>
          <div class={classes.ModalBody}>
            {showModal}
          </div>
          <div>
            <Button onClick={() => {setShowModal(undefined)}}>Review</Button>
          </div>
        </Modal>
      )
    }

    const postFormHandleSubmit = (e) => {
      // debugger;
      if (formik.values.published && formik.values.content.length > 10) {
        e.preventDefault();
        // Force modal for test message
        setShowTestMessage(true);
      } else {
        formik.handleSubmit(e)
      }
    }

    const cancelPost = (e) => {
      e.preventDefault();
      props.history.replace((props.post.id !== null ? "/myposts":"/posts"));
    }

    return(
      <React.Fragment>
        <Form onSubmit={postFormHandleSubmit}>
          <Input
            style={{fontSize: '18px'}}
            placeholder="Enter Post Subject"
            noPrepend
            disabled={formik.isSubmitting}
            id='name'
            required
            label="Name"
            value={formik.values.name}
            name='name'
            changed={formik.handleChange}
            blur={formik.handleBlur}
            errors={formik.errors.name}
            touched={formik.touched.name}
            showValid
          />
            
          <InputGroup>
            <div className={classes.Published}>Publish ? </div>
            <Form.Label className={classes.Radio} htmlFor='published_true'>YES</Form.Label>
            <Form.Check
              disabled={formik.isSubmitting}
              type='radio'
              as='input'
              id="published_true"
              className={classes.Radio}
              checked={formik.values.published === true}
              value={true}
              name='published'
              onChange={handlePublishedRadioChange}
              isInvalid={formik.errors['published']}
              isValid={!formik.errors['published']}
            />
            <Form.Label className={classes.Radio} htmlFor='published_false'>NO</Form.Label>
            <Form.Check
              disabled={formik.isSubmitting}
              styles={[{marginLeft: 20}]}
              type='radio'
              as='input'
              id="published_false"
              className={classes.Radio}
              checked={formik.values.published === false}
              value={false}
              name='published'
              onChange={handlePublishedRadioChange}
              isInvalid={formik.errors['published']}
              isValid={!formik.errors['published']}
            />
          </InputGroup>

          <InputGroup>
            <div className={classes.Published}>Allow Comments ? </div>
            <Form.Label className={classes.Radio} htmlFor='allow_comments_true'>YES</Form.Label>
            <Form.Check
              disabled={formik.isSubmitting}
              type='radio'
              as='input'
              id="allow_comments_true"
              className={classes.Radio}
              checked={formik.values.allow_comments === true}
              value={true}
              name='allow_comments'
              onChange={handlePublishedRadioChange}
              isInvalid={formik.errors['allow_comments']}
              isValid={!formik.errors['allow_comments']}
            />
            <Form.Label className={classes.Radio} htmlFor='allow_comments_false'>NO</Form.Label>
            <Form.Check
              disabled={formik.isSubmitting}
              styles={[{marginLeft: 20}]}
              type='radio'
              as='input'
              id="allow_comments_false"
              className={classes.Radio}
              checked={formik.values.allow_comments === false}
              value={false}
              name='allow_comments'
              onChange={handlePublishedRadioChange}
              isInvalid={formik.errors['allow_comments']}
              isValid={!formik.errors['allow_comments']}
            />
          </InputGroup>
          <InputGroup>
          {(formik.errors['content'] ? <div style={{color: 'red'}}>(Need to add some content before saving.)</div>:null )}
          </InputGroup>
          <TinyMceEditor
            value={formik.values.content} 
            auth={props.auth}
            //TODO: Currently mapped to onChange but maybe should be mapped to onBlur in TinyMce...
            changed={updateEditorContent}
            blur={blurEditorContent}
            editorType={POST_EDITOR}
            placeholder={"Enter your post"}
            // testCallback={setResizedImage}
          />
          {/* <input type='hidden' name='id' value={formik.values.id} /> */}
          <input type="hidden" name="neighborhood_id" value={formik.values.neighborhood_id} />
          <input type="hidden" name="content" value={formik.values.content} />
          <input type="hidden" name="site_definition_id" value={formik.values.site_definition_id} />
          <input type="hidden" name="user_id" value={formik.values.user_id} />
          <input type="hidden" name="post_type_id" value={formik.values.post_type_id} />
          <InputGroup className={classes.SubmitButton}>
            <Button size="sm" type="submit" onClick={(e) => {sendTestMessage()}}>Save and Send Test Message</Button>
          </InputGroup>
          <InputGroup className={classes.SubmitButton}>
            <Button size="sm" type="submit" disabled={formik.isSubmitting}>{formButton} Post</Button>
            <Button size="sm" style={{marginLeft:'40px'}} type="submit" onClick={(e) => {cancelPost(e)}}>Cancel</Button>
          </InputGroup>
        </Form>
        {/* <img src={resizedImage} /> */}
        {renderTestMessageModal(props.post)}
      </React.Fragment>
    );
  }

  if (postsState.working) {
    return <Spinner title="Sending test message..." />
  }

  if (showTestMessage && sendingTestMessage && !postsState.working) {
    // alert("Tesst message sent.  Check your email to see how it looks.")
    setShowTestMessage(false);
    setSendingTestMessage(false)
  }

  // Render the form
  return renderContent();
}

export default withRouter(PostForm);