import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  UPLOAD_FILE_START,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILED,

  UPLOAD_RESET_INITIAL_STATE,
  UPLOAD_GET_DOCS_START,
  UPLOAD_GET_DOCS_SUCCESS,
  UPLOAD_GET_DOCS_FAILED,
  UPLOAD_SET_ENABLE_EDIT,

  UPLOAD_FILE_EDIT_START,
  UPLOAD_FILE_EDIT_SUCCESS,
  UPLOAD_FILE_EDIT_FAILED,

  UPLOAD_FILE_UPDATE_START,
  UPLOAD_FILE_UPDATE_SUCCESS,
  UPLOAD_FILE_UPDATE_FAILED,

  UPLOAD_FILE_DELETE_SUCCESS,
  UPLOAD_FILE_DELETE_START,
  UPLOAD_FILE_DELETE_FAILED,

  UPLOAD_FILE_CLEAR_ERROR
} from '../actions/fileUploadActions';
import { updateObject } from '../../utils/utils';

const INITIAL_STATE = {
  currentDoc: {
    id: null,
    name: '',
    description: '',
    creator_id: 1,
    active: false,
    external_url: '',
    doc: undefined,
    doc_file_name: '',
    is_external: false
  },
  error: '',
  working: false,
  uploading: false,
  upload_error: false,
  siteDocs: [],
  docTypes: [],
  trustees: [],
  editDocs: false
}

const fileUploadReducers = (state = INITIAL_STATE, action) => {
  let updObj;
  console.log('fileUploadReducers: ', action)
  switch (action.type) {
    case UPLOAD_FILE_START:
      updObj = updateObject(state, {working: true, uploading: true, upload_error: false, error: '', currentDoc: action.payload.doc})
      return updObj;

    case UPLOAD_FILE_SUCCESS:
      updObj = updateObject(state, {working: false, uploading: false, currentDoc: {...state.currentDoc, id: action.payload.doc_id}})
      return updObj;
      
    case UPLOAD_FILE_FAILED:
      updObj = updateObject(state, {working: false, uploading: false, upload_error: true, error: action.payload.error})
      return updObj;

    case UPLOAD_FILE_CLEAR_ERROR:
      // debugger;
      updObj = updateObject(state, {error: ''})
      return updObj;

    case UPLOAD_RESET_INITIAL_STATE:
      // return INITIAL_STATE;
      return updateObject(state, {working: false, error: '', currentDoc: {...INITIAL_STATE.currentDoc}})
    
    case UPLOAD_GET_DOCS_START:
      let uploadError = {error: ''};
      if (state.upload_error && state.error) {
        uploadError = {error: state.error}
      }
      return updateObject(state, {working: true, siteDocs: [], docTypes: [], ...uploadError})

    case UPLOAD_GET_DOCS_SUCCESS:
      return updateObject(state, {working: false, siteDocs: [...action.payload.site_docs.documents], docTypes: [...action.payload.site_docs.site_document_types], trustees: [...action.payload.trustees]})

    case UPLOAD_GET_DOCS_FAILED:
      return updateObject(state, {working: false, error: action.payload.error});

    case UPLOAD_SET_ENABLE_EDIT:
      return updateObject(state, {editDocs: action.payload.enable_edit})

    case UPLOAD_FILE_EDIT_START:
      return updateObject(state, {working: true, error: '', currentDoc: {...INITIAL_STATE.currentDoc}})

    case UPLOAD_FILE_EDIT_SUCCESS:
      // debugger
      return updateObject(state, {working: false, currentDoc: {...action.payload.site_doc}})

    case UPLOAD_FILE_EDIT_FAILED:
      return updateObject(state, {working: false, error: action.payload.error});

    case UPLOAD_FILE_UPDATE_START:
      return updateObject(state, {working: true, error: ''})

    case UPLOAD_FILE_UPDATE_SUCCESS:
      // Find the updated doc and replace it with this one
      // TODO: Need to filter based on active or re-fetch all docs
      const updSiteDocs = state.siteDocs.map((doc, ix) => {
        if (doc.id === action.payload.doc.id) {
          return {...action.payload.doc};
        } else {
          return {...doc}
        }
      });
      return updateObject(state, {working: false, siteDocs: updSiteDocs});

    case UPLOAD_FILE_UPDATE_FAILED:
      return updateObject(state, {working: false, error: action.payload.error});
  
    case UPLOAD_FILE_DELETE_START:
      return updateObject(state, {working: true});

    case UPLOAD_FILE_DELETE_SUCCESS:
      // TODO: delete the doc from the list
      const delUpdDocs = [];
      state.siteDocs.forEach((doc, ix) => {
        if (doc.id !== action.payload.doc_id) {
          delUpdDocs.push({...doc});
        }
      })
      // debugger
      return updateObject(state, {working: false, siteDocs: delUpdDocs});

    case UPLOAD_FILE_DELETE_FAILED:
      return updateObject(state, {working: false, error: action.payload.error});
  
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default fileUploadReducers;
