import React, { useState } from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import classes from './SearchButton.module.css';

// Inputs:
//    valueChanged - method to call when search value changes.  Calls back with the value
//
const SearchButton = (props) => {
  const [addrSearch, setAddrSearch] = useState('');

  const updateSearchValue = (value) => {
    setAddrSearch(value);
    props.valueChanged(value);
  }
  const updateSearch = (event) => {
    // debugger;
    const sval = event.target.value;
    console.log(`'SearchButton: updateSearch: ${sval}`)
    updateSearchValue(sval);
  }

  return (
    <InputGroup>
      <InputGroup.Prepend className="search-prepend">
        <InputGroup.Text>
          <FontAwesomeIcon onClick={() => {}} size="1x" className={classes.SearchIcon} icon={faSearch} />
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl 
        // className={classes.SearchFilter}
        placeholder={props.placeholder ? props.placeholder : "Search"}
        value={addrSearch}
        onChange={updateSearch}
        // onKeyDown={handleKeyDown}
        style={{fontSize: '1.2em'}}
      />
      {addrSearch.length === 0 ? null :
      <InputGroup.Append onClick={() => {updateSearchValue("")}}>
        <InputGroup.Text>
          <FontAwesomeIcon size="1x" className={classes.SearchIcon} icon={faTimesCircle} />
        </InputGroup.Text>
      </InputGroup.Append>}
    </InputGroup>
  )
}

export default SearchButton;
