import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import parse from 'html-react-parser';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimesCircle, faPlus, faMinus, faArrowLeft, faEye, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';

import { formattedDate } from '../../utils/utils';

import { updateEmailList, deleteListMessage, sendTestMessage } from '../../redux/actions/emailListsActions';
import { TEST_MESSAGE_SENDING } from '../../redux/reducers/emailListsReducer';

import Spinner from '../Spinner/Spinner';

import MessageListMembers from './MessageListMembers';
import EmailListForm from './EmailListForm';

import NewMessage from './NewMessage';
import EditMessage from './EditMessage';

import MessageModal from '../UI/MessageModal';
import YesNoModal from '../UI/YesNoModal';

import classes from './ListMessages.module.css';

const deleteConfirmMessage = "Are you sure you want to delete this message?";
const sendTestMessageConfrm = "Are you sure you want to send a test message to yourself?";

const EmailList = (props) => {
  // debugger
  const history = useHistory();
  const [addRemoveMembers, setAddRemoveMembers] = useState(false);
  const [editListDetails, setEditListDetails] = useState(false);
  const [currentMessage, setCurrentMessage] = useState();
  const [currentEmailList, setCurrentEmailList] = useState();
  const [editCurrentMessage, setEditCurrentMessage] = useState();
  const [areYouSureModal, setAreYouSureModal] = useState();

  const dispatch = useDispatch();
  const emailLists = useSelector(state => state.emailLists);
  let { listId } = useParams();
  listId = parseInt(listId);
  const auth = useSelector(state => state.auth);

  const getEmailListFromStore = React.useCallback((listId) => {
    if (!listId) {
      return undefined;
    }
    const ix = emailLists.email_lists.findIndex(el => el.id === listId);
    if (ix >= 0) {
      return emailLists.email_lists[ix];
    } else {
      return undefined;
    }
  }, [emailLists.email_lists])

  const emailListRef = useRef(getEmailListFromStore(listId));
  const [showMessageModal, setShowMessageModal] = useState();

  useEffect(() => {
    // debugger;
    emailListRef.current = getEmailListFromStore(listId);
    if (emailListRef.current && emailListRef.current.messages.length > 0) {
      setCurrentMessage(emailListRef.current.messages[0]);
    }
  }, [emailListRef, listId, getEmailListFromStore])

  // useEffect(() => {
  //   console.log('CurrentMessage...')
  // }, [editCurrentMessage])

  if (!emailListRef.current || !auth) {
    console.log('EmailList: invalid or not logged in.  Redirecting')
    history.replace('/emaillists');
    return null;
  }

  // emailList = getEmailListFromStore(emailList.id);
  const updateEmailListFormSubmit = (listId, emailList) => {
    setEditListDetails(false);

    dispatch(updateEmailList(listId, emailList));
  }

  const enableEditListDetails = () => {
    // Disable member edit if enabled (toggle)
    if (addRemoveMembers) {
      setAddRemoveMembers(false);
    }
    showFirstMessageIfExists(emailListRef.current)
    setEditListDetails(!editListDetails);
  }
  const genEditListDetails = () => {
    if (auth.app_admin) {
      return (
        <div className={classes.Icon} onClick={ (e) => {enableEditListDetails()} }>
          <FontAwesomeIcon
            icon={(editListDetails ? faTimesCircle : faEdit)}
            size="1x"
            className={classes.Icon}
          />
        </div>
      )
    }
    return null;
  }

  const renderOwnerName = () => {
    if (!auth.site_admin) {
      return null;
    }
    return (
      <Row key={`${emailListRef.current.id}-${emailListRef.current.name}`}>
        <Col className={classes.ListDetailDescriptor} xs={4} md={4}>List Owner</Col>
        <Col xs={8} md={8}>
          {emailListRef.current.owner_name}
        </Col>
      </Row>
    )
  }

  const enableAddRemoveMembers = () => {
    if (editListDetails) {
      setEditListDetails(false);
    }
    showFirstMessageIfExists(emailListRef.current);
    setAddRemoveMembers(!addRemoveMembers);
  }
  const genEditMembersButton = () => {
    if (auth.app_admin) {
      return (
        <div className={classes.Icon} onClick={ (e) => {enableAddRemoveMembers()} }>
          <FontAwesomeIcon
            icon={faPlus}
            size="1x"
            className={classes.Icon}
          />
          /
          <FontAwesomeIcon
            icon={faMinus}
            size="1x"
            className={classes.Icon}
          />
        </div>
      )
    }
    return null;
  }

  const renderListMembers = () => {
    let members = emailListRef.current.members.map(member => {
      return (
        <Row key={`mem-${member.id}`}>
          <Col key={member.id}>{member.last_name}, {member.first_name}</Col>
        </Row>
      );
    });

    if (emailListRef.current.members.length === 0) {
      members = <div>[No members.  Add some]</div>
    }
    return (
      <React.Fragment>
        <Row>
          <Col md={9} className={classes.ListHeader}>Current List Members</Col>
          <Col md={3} style={{textAlign: 'right'}}>{genEditMembersButton()}</Col>
        </Row>
        {members}
      </React.Fragment>
    )
  }

  const formatRecipients = (message) => {
    let recipView = message.recipients.map(recip => {
      return (
        <Row key={`recip-${recip.id}`}>
          <Col>{recip.last_name}, {recip.first_name}</Col>
        </Row>
      );
    });
    if (message.recipients.length === 0) {
      recipView = (
        <Row><Col>Message was queued to send.  Check back later.</Col></Row>
      )
    }
    return (
      <React.Fragment>
      <Row>
        <Col style={{fontWeight: 'bold', fontSize: '1.2em'}}>Message Recipient List</Col>
      </Row>
      <Row>
        <Col style={{fontSize: '0.9em'}}>Sent At: {formattedDate(message.time_sent)}</Col>
      </Row>
      {recipView}
      </React.Fragment>
    )
  }
  const showRecipients = (message) => {
    // debugger;
    setShowMessageModal({
      title: "Message Recipients",
      displayArg: message,
      displayCallback: formatRecipients
    })
  }

  const renderNumRecipients = (message) => {
    if (message.recipients.length === 0) {
      return "(queued for sending... check back)";
    }
    return `${message.recipients.length} recipient${message.recipients.length === 1 ? "":"s"}`;
  }
  const formatSentDetails = (message) => {
    const ctxMsg = message;
    if (ctxMsg.is_draft) {
      return ( //"DRAFT";
        <React.Fragment>
          <Row>
            <Col xs={4} className={classes.ListDetailDescriptor} md={4}>Last Updated</Col>
            <Col xs={6} md={6}>
              {formattedDate(message.updated_at)}
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4} className={classes.ListDetailDescriptor}>Is Draft?</Col>
            <Col xs={4} md={8}>YES</Col>
          </Row>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Row>
            <Col xs={4} md={4} className={classes.ListDetailDescriptor}>Sent At</Col>
            <Col xs={8} md={8}>{formattedDate(ctxMsg.time_sent)}</Col>
          </Row>
          <Row>
            <Col xs={4} md={4} className={classes.ListDetailDescriptor}>Sent To</Col>
            <Col className={classes.Icon} onClick={(message) => {showRecipients(ctxMsg)}} xs={8} md={8}>
              <FontAwesomeIcon
                icon={faEye}
                size="1x"
              />&nbsp;
              {renderNumRecipients(message)}
            </Col>
          </Row>
        </React.Fragment>
      )
    }
  }

  const enableAreYouSureModal = (modalFunc, modalArg, modalString) => {
    setAreYouSureModal({
      modalFunc: modalFunc,
      modalArg: modalArg,
      modalString: modalString
    });
  }
  const renderDeleteMessage = (message) => {
    if (message.is_draft) {
      return (
        <Col
          xs={1} md={1} 
          onClick={() => {enableAreYouSureModal(deleteCurrentlySelectedMessage, message, deleteConfirmMessage)}}
        >
          <FontAwesomeIcon
            icon={faTrash}
            size="1x"
            className={classes.Icon}
          />
        </Col>
      )
    }
    return null;
  }

  const invokeSendTestMessage = (message) => {
    dispatch(sendTestMessage(message));
    setAreYouSureModal(undefined);
  }

  const renderSendTestMessage = (message) => {
    // GET /api_v1/list_messages/:id/test_message
    if (message.is_draft) {
      return (
        <Button
          size="sm" 
          onClick={() => enableAreYouSureModal(invokeSendTestMessage, message, sendTestMessageConfrm)}
        >
          Send test message to myself
        </Button>
      )
    }
    return null;
  }

  const getMessageSummaryClass = (message) => {
    if (currentMessage && (message.id === currentMessage.id)) {
      return classes.MessageSelected;
    }
    return classes.MessageNotSelected;
  }

  const genNewMessage = (emailList) => {
    setCurrentMessage(undefined); // clear currently viewed message if any
    setCurrentEmailList(emailList);
    setEditCurrentMessage(undefined);
    setEditListDetails(undefined);
  }

  const invokeEditMessage = (message) => {
    setCurrentMessage(undefined);
    setEditCurrentMessage(message);
    setEditListDetails(undefined);
  }
  const genEditMessage = (message) => {
    if (message.is_draft) {
      return (
        <Col xs={1} md={1} onClick={() => {invokeEditMessage(message)}}>
          <FontAwesomeIcon
            icon={faEdit}
            size="1x"
            className={classes.Icon}
          />
        </Col>
      )
    }
    return (
      <Col xs={1} md={1}>
        &nbsp;
      </Col>
    );
  }

  const showFirstMessageIfExists = (emailList) => {
    if (emailList.messages?.length > 0) {
      showCurrentlySelectedMessage(emailList.messages[0]);
    } else {
      // Just close the NewMessage form
      setCurrentEmailList(undefined);
    }
  }

  const genAddMessageButton = (emailList) => {
    if (!currentEmailList) {
      return (
        <div className={classes.Icon} onClick={ (e) => {genNewMessage(emailList)} }>
          <FontAwesomeIcon
            icon={faPlus}
            size="1x"
            className={classes.Icon}
            style={{marginRight: '2px'}}
          />
          <FontAwesomeIcon
            icon={faEnvelope}
            size="1x"
            className={classes.Icon}
          />
        </div>
      )
    }

    return (
      <FontAwesomeIcon
        icon={faTimesCircle}
        size="1x"
        className={classes.Icon}
        style={{marginRight: '2px'}}
        onClick={() => {showFirstMessageIfExists(emailList)}}
      />
    )
  }

  const deleteCurrentlySelectedMessage = (message) => {
    if (currentMessage && (message.id === currentMessage.id)) {
      setCurrentMessage(undefined);
    }
    dispatch(deleteListMessage(message))
    setAreYouSureModal(undefined);
  }

  const invokeModalOperation = () => {
    if (areYouSureModal) {
      const modalOpFunc = areYouSureModal.modalFunc;
      const modalOpArg = areYouSureModal.modalArg;
      modalOpFunc(modalOpArg);
    }
  }
  const renderAreYouSureModal = () => {
    return (
      <YesNoModal
        show={(areYouSureModal !== undefined)}
        yesCallback={() => {invokeModalOperation()}}
        noCallback={() => {setAreYouSureModal(undefined)}}
      >
        <div style={{marginBottom: '10px'}}>
          {(areYouSureModal ? areYouSureModal.modalString:"")}
        </div>
      </YesNoModal>
    )
  }

  const renderMessageModal = () => {
    if (!showMessageModal) {
      return null;
    }
    let msgModalContent = showMessageModal.displayCallback(showMessageModal.displayArg);
    return (
      <MessageModal
        show={showMessageModal}
        closeCallback={() => { setShowMessageModal() }}
      >
        {msgModalContent}
      </MessageModal>
    )
  }

  const showCurrentlySelectedMessage = (message) => {
    setCurrentMessage(message);
    setCurrentEmailList(undefined);
    setEditCurrentMessage(undefined);
  }

  const renderEmailMessages = () => {
    if (emailListRef.current.messages.length === 0) {
      return (
        <Row>
          <Col xs={9} md={10} className={classes.ListInfoMessage}>No messages created yet.</Col>
          <Col style={{textAlign: 'right'}} xs={3} md={2}>
            {genAddMessageButton(emailListRef.current)}
          </Col>
        </Row>
      )
    }

    const messages = emailListRef.current.messages.map(message => {
      return (
        <div key={`lm-${message.id}`} className={getMessageSummaryClass(message)}>
          <hr />
          <Row>
            <Col xs={4} className={classes.ListDetailDescriptor} md={4}>Subject</Col>
            <Col xs={5} md={5}>{message.subject}</Col>
            {genEditMessage(message)}
            <Col xs={1} md={1} onClick={() => {showCurrentlySelectedMessage(message)}}>
              <FontAwesomeIcon
                icon={faEye}
                size="1x"
                className={classes.Icon}
              />
            </Col>
            {renderDeleteMessage(message)}
          </Row>
          {formatSentDetails(message)}
          <Row>
            <Col xs={4} md={4}>&nbsp;</Col>
            <Col xs={8} md={8}>{renderSendTestMessage(message)}</Col>
          </Row>
        </div>
      )
    })
    return (
      <React.Fragment>
        <Row>
          <Col xs={9} md={10} className={classes.ListHeader}>Email Messages</Col>
          <Col style={{textAlign: 'right'}} xs={3} md={2}>
              {genAddMessageButton(emailListRef.current)}
          </Col>
        </Row>
        {messages}
      </React.Fragment>
    )
  }

  const renderListDetails = () => {
    if (editListDetails) {
      return (
        <div style={{marginTop: '5px', marginBottom: '5px'}}>
          <Row>
            <Col xs={10} md={11}>
              {emailListRef.current.name}
            </Col>
            <Col xs={2} md={1}>
              {genEditListDetails()}
            </Col>
          </Row>
          <EmailListForm
            emailList={emailListRef.current}
            auth={auth}
            formSubmit={updateEmailListFormSubmit}
            onCancel={() => {setEditListDetails(false)}}
            edit
          />
        </div>
      )
    } else {
      return (
        <React.Fragment>
          <Row>
            <Col className={classes.ListHeader} xs={10} md={11}>
              Email List Description
            </Col>
            <Col xs={2} md={1}>
              {genEditListDetails()}
            </Col>
          </Row>
          {renderOwnerName()}
          <Row>
            <Col className={classes.ListDetailDescriptor} xs={4} md={4}>Description: </Col>
            <Col xs={8} md={8}>{emailListRef.current.description}</Col>
          </Row>
        </React.Fragment>
      );
    }
  }

  const renderMessageListForm = () => {
    if (addRemoveMembers) {
      return (
        <React.Fragment>
          <Row>
            <Col md={9} className={classes.ListHeader}>Current and Non List Members</Col>
            <Col md={3} style={{textAlign: 'right'}}>{genEditMembersButton()}</Col>
          </Row>
          <MessageListMembers
            emailList={emailListRef.current}
            auth={auth}
          />
        </React.Fragment>
      )
    } else {
      return null;
    }
  }

  const renderMembersListOrForm = () => {
    if (addRemoveMembers) {
      return (
        renderMessageListForm()
      );
    } else {
      return (
        renderListMembers()
      )
    }
  }

  const renderCurrentlySelectedMessage = () => {
    if (currentMessage) {
      return (
        <Col className={classes.ListDetailContainer} md={12}>
          <Row>
            <Col className={classes.ListHeader}>
              Currently Selected Message Details
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={2}>Created At</Col>
            <Col xs={8} md={10}>{formattedDate(currentMessage.created_at)}</Col>
          </Row>
          <Row>
            <Col xs={4} md={2}>Subject: </Col>
            <Col xs={8} md={10}>{currentMessage.subject}</Col>
          </Row>
          <Row>
            <Col className={classes.Desktop} md={2}>Body</Col>
            <Col xs={12} md={10}>{parse(currentMessage.body)}</Col>
          </Row>
        </Col>
      )
    }
  }

  const newMessageSubmitted = () => {
    // alert('close form')
    setCurrentEmailList(undefined);
  }

  const currentMessageSubmitted = () => {
    //TODO: Get current message by id.  SHOULD be updated
    // debugger;
    setEditCurrentMessage(undefined)
  }
  const renderNewOrCurrentlySelectedMessage = () => {
    // debugger
    if (currentEmailList && !editCurrentMessage) {
      return (
        <NewMessage email_list_id={currentEmailList.id} onSubmit={newMessageSubmitted} />
      )
    } else if (editCurrentMessage) {
      return (
        <EditMessage email_list_id={emailListRef.current.id} listMessage={editCurrentMessage} onSubmit={currentMessageSubmitted} />
        // <DummyEdtMessage email_list_id={emailList.id} listMessage={editCurrentMessage} onSubmit={newMessageSubmitted} />
      )
    } else {
      return renderCurrentlySelectedMessage();
    }
  }

  if (emailLists.error) {
    //TODO: show error form
  }
  if (emailLists.updatingList || emailLists.testMessageState === TEST_MESSAGE_SENDING) {
    return <Spinner />;
  }

  return (
    <div>
      <Row>
        <Col style={{marginLeft: '10px'}} md={2}>
          <Button size="sm" onClick={() => {history.goBack()}}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              size="1x"
              style={{marginRight: '5px'}}
              // className={classes.Icon}
            />
            Go Back
          </Button>
        </Col>
        <Col md={7} className={classes.Title}>
          Email List: "{emailListRef.current.name}"
        </Col>
      </Row>
      <Row className={classes.EmailListContainer}>
        <Col className={classes.ListDetailContainer} md={4}>
          {renderListDetails()}
        </Col>
        <Col className={classes.ListDetailContainer} md={8}>
          {renderMembersListOrForm()}
        </Col>
      </Row>
      <Row className={classes.EmailListContainer}>
        <Col className={classes.ListDetailContainer} md={4}>
          {renderEmailMessages()}
        </Col>
        <Col className={classes.ListDetailContainer} md={8}>
        {renderNewOrCurrentlySelectedMessage()}
        </Col>
      </Row>
      {renderAreYouSureModal()}
      {renderMessageModal()}
    </div>
  )
};

export default EmailList;
