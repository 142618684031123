import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const MessageModal = (props) => {

  const buildCloseLabel = () => {
    if (props.closeLabel) {
      return props.closeLabel;
    }
    return "Close";
  }

  const closeCallback = () => {
    if (props.closeCallback) {
      props.closeCallback();
    }
  }
  return (
    <Modal
      show={props.show !== undefined || props.show === true}
      onHide={() => {props.closeCallback()}}
    >
      <Modal.Body>
        <div>
          {props.children}
          <Button style={{marginTop: '10px'}} size="sm" onClick={() => {closeCallback()}}>{buildCloseLabel()}</Button>
        </div>
        
      </Modal.Body>
    </Modal>
  );
}

export default MessageModal;