import React from 'react';

import PhotosCommon from './PhotosCommon';

const MyPhotos = (props) => {
  return (
    <PhotosCommon title="My Album" mine />
  )
}

export default MyPhotos;