import axios from '../../utils/axios-whe';

import { WHE_BASE_URL } from '../../utils/constants';

export const GET_EMAIL_LISTS_START = "get_email_lists_start";
export const GET_EMAIL_LISTS_SUCCESS = "get_email_lists_success";
export const GET_EMAIL_LISTS_FAILED = "get_email_lists_failed";

export const GET_NEIGHBORHOOD_USERS_START = "get_neighborhood_users_start";
export const GET_NEIGHBORHOOD_USERS_SUCCESS = "get_neighborhood_users_success";
export const GET_NEIGHBORHOOD_USERS_FAILED = "get_neighborhood_users_failed";

export const CHANGE_MEMBERS_START = "change_members_start";
export const CHANGE_MEMBERS_SUCCESS = "change_members_success";
export const CHANGE_MEMBERS_FAILED = "change_members_failed";
export const REMOVE_MEMBERS = 'remove';
export const ADD_MEMBERS = 'add';

export const CREATE_EMAIL_LIST_START = "create_email_list_start";
export const CREATE_EMAIL_LIST_SUCCESS = "create_email_list_success";
export const CREATE_EMAIL_LIST_FAILED = "create_email_list_failed";

export const CLEAR_CREATING_FLAG = "clear_creating_flag";

export const UPDATE_EMAIL_LIST_START = "update_email_list_start";
export const UPDATE_EMAIL_LIST_SUCCESS = "update_email_list_success";
export const UPDATE_EMAIL_LIST_FAILED = "update_email_list_failed";

export const CREATE_LIST_MESSAGE_START = "create_list_message_start";
export const CREATE_LIST_MESSAGE_SUCCESS = "create_list_message_success";
export const CREATE_LIST_MESSAGE_FAILED = "create_list_message_failed";

export const UPDATE_LIST_MESSAGE_START = "update_list_message_start";
export const UPDATE_LIST_MESSAGE_SUCCESS = "update_list_message_success";
export const UPDATE_LIST_MESSAGE_FAILED = "update_list_message_failed";

export const DELETE_LIST_MESSAGE_START = "delete_list_message_start";
export const DELETE_LIST_MESSAGE_SUCCESS = "delete_list_message_success";
export const DELETE_LIST_MESSAGE_FAILED = "delete_list_message_failed";

export const SEND_TEST_MESSAGE_START = "send_test_message_start";
export const SEND_TEST_MESSAGE_SUCCESS = "send_test_message_success";
export const SEND_TEST_MESSAGE_FAILED = "send_test_message_start";

export const DELETE_EMAIL_LIST_START = "delete_email_list_start";
export const DELETE_EMAIL_LIST_SUCCESS = "delete_email_list_success";
export const DELETE_EMAIL_LIST_FAILED = "delete_email_list_failed";

// Get email lists from the store

export const getEmailLists = () => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    dispatch({ type: GET_EMAIL_LISTS_START });
    axios.get(`${WHE_BASE_URL}/api_v1/email_lists.json`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger
      if (response.status === 200) {
        const lists = [...response.data];

        dispatch({ type: GET_EMAIL_LISTS_SUCCESS, payload: {email_lists: lists} })
      } else {
        // ERROR: log it.  axios response Interceptor should handle it
        console.error("Error retrieving email lists");
        dispatch({ type: GET_EMAIL_LISTS_FAILED, payload: {error: `Get Email Lists failed.  Code: ${response.status}`} });
      }
    })
    .catch(error => {
      // debugger;
      // this.props.history.push('/auth', 
      //                 {state: { from: '/neighbors', error: `Unauthorized.  Must sign in to access this feature.` }}
      // );
      console.error(`Error retrieving prop user data:  ${error}`);
      dispatch({ type: GET_EMAIL_LISTS_FAILED, payload: {error: `Get Email Lists failed.  Error: ${error}`} });
    })
  }
}

export const getNeighborhoodUsers = () => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    dispatch({ type: GET_NEIGHBORHOOD_USERS_START });
    axios.get(`/api_v1/neighborhood_users.json`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log('[EmailList]: axios.get success]', response.data);

      // debugger;
      if (response !== undefined) {
        dispatch({ type: GET_NEIGHBORHOOD_USERS_SUCCESS, payload: {neighborhood_users: response.data} })
        // TODO: user needs to filter out full list
        // setupUsers(response.data);
      }
    })
    .catch(error => {
      // debugger;
      // dispatch(fetchIngredientsFailed());
      console.log("ERROR fetching Users...", error)
      // this.setState({error: error});
      // setError(`Error setting up neighborhood users.`);
      dispatch({ type: GET_NEIGHBORHOOD_USERS_FAILED, payload: {error: `ERROR fetching Users...  ${error}`} });
    });
  }
}

export const clearCreating = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CREATING_FLAG });
  }
}
export const createEmailList = (values) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    const formJson = {
      "email_list": {...values }
    };
    const url = `/api_v1/email_lists`;
    console.log(`[emailListActions: createEmailList]: Creating email list with URL of ${url}  FORM JSON:`, formJson)

    dispatch({ type: CREATE_EMAIL_LIST_START });
    axios.post(url, formJson,
    {
      _method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`Create Email List Result: ${JSON.stringify(response.data)}`);
      // debugger;

      dispatch({ type: CREATE_EMAIL_LIST_SUCCESS, payload: {email_list: response.data} })
    })
    .catch(error => {
      dispatch({ type: CREATE_EMAIL_LIST_FAILED, payload: {error: `ERROR creating EmailList...  ${error}`} });
    })

  }
}


export const updateEmailList = (listId, values) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    const formJson = {
      "email_list": {...values }
    };
    const url = `/api_v1/email_lists/${listId}`;
    console.log(`[emailListActions: updateEmailList]: Patching email list with URL of ${url}  FORM JSON:`, formJson)

    dispatch({ type: UPDATE_EMAIL_LIST_START });
    axios.patch(url, formJson,
    {
      _method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`Post create or update`, response.data);
      // debugger;

      dispatch({ type: UPDATE_EMAIL_LIST_SUCCESS, payload: {email_list: response.data} })
    })
    .catch(error => {
      dispatch({ type: UPDATE_EMAIL_LIST_FAILED, payload: {error: `ERROR updating EmailList...  ${error}`} });
    })

  }
}

const updateMembers = (action, emailListId, members) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    const formJson = {
      "email_list": {
        "ids": members
      }
    };
    const url = `/api_v1/email_lists/${emailListId}/${action}`;
    console.log(`[emailListActions]: Patching email list with URL of ${url}  FORM JSON:`, formJson)
    // updating = true;
    // setTimeoutId(startTimer());
    dispatch({ type: CHANGE_MEMBERS_START });
    axios.post(url, formJson, {
      _method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`Post create or update`, response.data);
      dispatch({ type: CHANGE_MEMBERS_SUCCESS, payload: {email_list: response.data, action: action} })
    })
    .catch(error => {
      console.log(`ERROR updating address info.  ${error}`)
      dispatch({ type: CHANGE_MEMBERS_FAILED, payload: {error: error}});
    })
  }
}

export const addMembers = (emailListId, members) => {
  return updateMembers(ADD_MEMBERS, emailListId, members);
}

export const removeMembers = (emailListId, members) => {
  return updateMembers(REMOVE_MEMBERS, emailListId, members);
}

export const createListMessage = (listMessageValues) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    const formJson = {
      "list_message": {...listMessageValues }
    };
    // debugger;
    const url = `${WHE_BASE_URL}/api_v1/list_messages.json`;

    console.log(`[createListMessage]: Creating new list_message with URL of ${url}  FORM JSON:`, formJson)
    // debugger;
    dispatch({ type: UPDATE_LIST_MESSAGE_START });

    axios.post(url, formJson, {
      _method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`Post create or update`, response.data);
      dispatch({ type: UPDATE_LIST_MESSAGE_SUCCESS, payload: {email_list: response.data} })
    })
    .catch(error => {
      console.error(`ERROR updating POST info.  ${error}`)
      dispatch({ type: UPDATE_LIST_MESSAGE_FAILED, payload: {error: error}});
    })
  }
}

export const updateListMessage = (listMessageValues) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    const formJson = {
      "list_message": {...listMessageValues }
    };
    // debugger;
    const url = `${WHE_BASE_URL}/api_v1/list_messages/${listMessageValues.id}`;

    console.log(`[updateListMessage]: Creating new list_message with URL of ${url}  FORM JSON:`, formJson)
    // debugger;
    dispatch({ type: UPDATE_LIST_MESSAGE_START });

    axios.patch(url, formJson, {
      _method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`Post create or update`, response.data);
      dispatch({ type: UPDATE_LIST_MESSAGE_SUCCESS, payload: {email_list: response.data} })
    })
    .catch(error => {
      console.error(`ERROR updating POST info.  ${error}`)
      dispatch({ type: UPDATE_LIST_MESSAGE_FAILED, payload: {error: error}});
    })
  }
}

export const deleteEmailList = ( emailList ) => {
  console.log(`emailLisActions: deleteEmailList: ID: ${emailList.id}`)
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: DELETE_EMAIL_LIST_START });
    axios.delete(`/api_v1/email_lists/${emailList.id}`, {
      // method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      dispatch({ type: DELETE_EMAIL_LIST_SUCCESS, payload: {email_list_id: emailList.id} })
    })
    .catch(error => {
      console.error(`ERROR deleting email list  ${emailList.id}, ERROR: ${error}`);
      dispatch({ type: DELETE_EMAIL_LIST_FAILED, payload: {error: error}});
    });
  }
}

export const deleteListMessage = ( listMessage ) => {
  console.log(`emailLisActions: deleteListMessage: ID: ${listMessage.id}`)
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: DELETE_LIST_MESSAGE_START });
    axios.delete(`/api_v1/list_messages/${listMessage.id}`, {
      // method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      dispatch({ type: DELETE_LIST_MESSAGE_SUCCESS, payload: {email_list: response.data} })
    })
    .catch(error => {
      console.error(`ERROR deleting list message ${listMessage.id}, ERROR: ${error}`);
      dispatch({ type: DELETE_LIST_MESSAGE_FAILED, payload: {error: error}});
    });
  }
}

export const sendTestMessage = (listMessage) => {
  return (dispatch, getState) => {
    // debugger;
    const auth = getState().auth;
    dispatch({ type: SEND_TEST_MESSAGE_START });
    axios.get(`/api_v1/list_messages/${listMessage.id}/test_message.json`, {
      // method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      dispatch({ type: SEND_TEST_MESSAGE_SUCCESS })
    })
    .catch(error => {
      console.error(`ERROR sending test message: ${error}`);
      dispatch({ type: SEND_TEST_MESSAGE_FAILED, payload: {error: error}});
    });
  }
}