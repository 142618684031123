import React, { Component } from 'react';

import { BrowserRouter as Router} from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from './components/Layout/Layout';

import { history } from './utils/utils';

// import logo from './logo.svg';
// import './App.css';

class MainApp extends Component {
  render() {
    return (
      <Router history={history}>
        <Layout />
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls
  }
}

export default connect(mapStateToProps, null)(MainApp);
