import { updateObject } from '../../utils/utils';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  GET_SITE_MESSAGES_START,
  GET_SITE_MESSAGES_SUCCESS,
  GET_SITE_MESSAGES_FAILED,
  SET_CURRENT_SITE_MESSAGE,
  INIT_SITE_MESSAGE_STATE,
  DELETE_SITE_MESSAGE_START,
  DELETE_SITE_MESSAGE_FAILED,
  DELETE_SITE_MESSAGE_SUCCESS,

  CREATE_SITE_MESSAGE_START,
  CREATE_SITE_MESSAGE_SUCCESS,
  CREATE_SITE_MESSAGE_FAILED,
  UPDATE_SITE_MESSAGE_START,
  UPDATE_SITE_MESSAGE_SUCCESS,
  UPDATE_SITE_MESSAGE_FAILED,

  CLEAR_OP_STATUS
} from '../actions/siteMessageActions';

const INITIAL_STATE = {
  currentMessage: {
    id: null,
    subject: '',
    body: '',
    is_draft: true,
    user_id: null,
    site_definition_id: 2,
  },
  messages: [],
  error: '',
  working: false,
  opComplete: false
};

const reducer = (state = INITIAL_STATE, action) => {
  // console.log(`[postReducers]: ${action.type}`, action.payload);
  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    case GET_SITE_MESSAGES_START:
      return updateObject(state, { working: true, opComplete: false });
    
    case GET_SITE_MESSAGES_SUCCESS:
      return updateObject(state, {working: false, /*opComplete: true,*/ messages: action.payload.messages, currentMessage: action.payload.currentMessage})

    case GET_SITE_MESSAGES_FAILED:
      return updateObject(state, {working: false, /*opComplete: true,*/ error: action.payload.error});

    case SET_CURRENT_SITE_MESSAGE:
      // debugger;
      return updateObject(state, {currentMessage: action.payload.message});

    case CREATE_SITE_MESSAGE_START:
    case UPDATE_SITE_MESSAGE_START:
    case DELETE_SITE_MESSAGE_START:
      return updateObject(state, {working: true, opComplete: false});

    case DELETE_SITE_MESSAGE_SUCCESS:
      // Remove the message from messages and replace the currentMessage
      let updMsgs = [...state.messages];
      let msgIx = updMsgs.findIndex((m) => m.id === action.payload.message.id);
      // debugger;
      updMsgs.splice(msgIx, 1);
  
      let curMsg = (updMsgs.length > 0 ? updMsgs[0] : {...INITIAL_STATE.currentMessage})    
      return updateObject(state, {working: false, /*opComplete: true,*/ messages: updMsgs, currentMessage: curMsg});

    case DELETE_SITE_MESSAGE_FAILED:
    case CREATE_SITE_MESSAGE_FAILED:
    case UPDATE_SITE_MESSAGE_FAILED:
      return updateObject(state, {working: false, opComplete: false, error: action.payload.error});

    case CREATE_SITE_MESSAGE_SUCCESS:
      // Update the list of site messages.
      return updateObject(state, {working: false, opComplete: true, 
                                  messages: [action.payload.site_message, ...state.messages], 
                                  currentMessage: action.payload.site_message});

    case UPDATE_SITE_MESSAGE_SUCCESS:
      // Update the list of site messages.
      let msgs = [...state.messages];
      let ix = state.messages.findIndex((m) => m.id === action.payload.site_message.id);

      msgs[ix] = {...action.payload.site_message};
      return updateObject(state, {working: false, opComplete: true, 
                                  messages: [...msgs, action.payload.site_message], 
                                  currentMessage: action.payload.site_message});

    case INIT_SITE_MESSAGE_STATE:
      return INITIAL_STATE;

    case CLEAR_OP_STATUS:
      return updateObject(state, {working: false, opComplete: false});

    default:
      return state;
  }
}

export default reducer;
