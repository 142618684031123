import React, { Component } from 'react';

import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { setUploadFile, uploadFile } from '../../../redux/uploadFile/uploadFile.actions';

import UploadProgress from '../../Uploader/UploadProgress/UploadProgress';
import PhotoUploadButton from '../PhotoUploader/PhotoUploadButton';

let uploadImageCount = 0;
class PhotoUploader extends Component {
  constructor(props) {
    super(props);
    this.uploadCompleteHandler = () => {alert("[PhotoUploader]: uploadCompleteHandler")};
    this.state = { showModal: false, disableModalClose: true}
  }

  renderUploadModal = () => {
    return (
      <Modal show={this.state.showModal} backdrop="static">
        <ModalBody>
          <UploadProgress uploadComplete={this.enableModalClose} album={this.props.currentAlbum} />
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.disableModalClose} color="secondary" onClick={this.closeModalAndCleanup}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  };

  displayModal = () => {
    if (!this.state.showModal) {
      this.setState((prevState, prevProps) => ({
        showModal: true
      }))
    }
  }
  hideModal = () => {
    if (this.state.showModal) {
      this.setState({ showModal: false, disableModalClose: true });
    }
  }
  enableModalClose = () => {
    setTimeout(() => {
      this.setState({ disableModalClose: false });
    })
  }

  closeModalAndCleanup = () => {
    this.hideModal();
    if (this.uploadCompleteHandler !== null) {
      this.uploadCompleteHandler();
      this.myUploadComplete();
      this.uploadCompleteHandler = () => {alert("[PhotoUploader]: uploadCompleteHandler closeAndCleanup")};
    }
  }

  myUploadComplete = () => {
    this.props.uploadComplete();
    uploadImageCount = 0;
  }
  uploadImages = (images, uploadComplete) => {
    uploadImageCount += 1;
    if (uploadImageCount > 1) {
      debugger;
    }
    this.uploadCompleteHandler = uploadComplete;
    this.props.setUploadFile(images);
    // debugger;
    this.displayModal();
    // XXX: uploadComplete will be called to allow cleanup.
    //uploadComplete();
  }

  render() {
    return (
      <div style={{display: 'inherit'}}>
        <PhotoUploadButton 
          title={this.props.title}
          uploadImages={ (images, uploadComplete) => {this.uploadImages(images, uploadComplete)} }
        />
        {this.renderUploadModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls,
    photos: state.photos,
    fileProgress: state.uploader.fileProgress
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    setUploadFile: (files) => { dispatch(setUploadFile(files)) },
    uploadFile: (photoJson, files) => { dispatch(uploadFile(photoJson, files)) }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoUploader);