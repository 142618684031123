import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Redirect, useParams } from 'react-router-dom';

import Spinner from '../../Spinner/Spinner';
import DocumentForm from './DocumentForm';
import { editDoc, updateFile } from '../../../redux/actions/fileUploadActions';
import { setTitle } from '../../../redux/actions/controlsActions';

const EditDocument = (props) => {
  const auth = useSelector(state => state.auth);
  const fileUpload = useSelector(state => state.fileUpload);
  const dispatch = useDispatch();
  let { docId } = useParams();
  const [postingForm, setPostingForm] = useState(false);
  const [fetchingDoc, setFetchingDoc] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const defaultDoc = {
    id: null,
    name: '',
    description: '',
    creator_id: auth.uid,
    active: true,
    external_url: '',
    doc: undefined,
    site_document_type_id: 0,
    doc_file_name: '',
    upload_file: true
  }

  const [currentDoc, setCurrentDoc] = useState(defaultDoc);

  if (docId) {
    docId = parseInt(docId);
  }

  useEffect(() => {
    console.log('EditDocument: useEffect begin')
    dispatch(setTitle("Edit Document"));

    if (currentDoc.id === null && docId !== null && !fetchingDoc) {
      setFetchingDoc(true);
      dispatch(editDoc(docId))
    } else {
      if (fetchingDoc && !fileUpload.working) {
        if (fileUpload.currentDoc?.id === docId) {
          setFetchingDoc(false);
          console.log(`EditDocument: ExtURL? : ${fileUpload.currentDoc.is_external}`)
          setCurrentDoc( {...fileUpload.currentDoc, upload_file: (fileUpload.currentDoc.is_external ? false:true)} )
        }
      }
    }

  })
  
  const startTimer = () => {
    return setTimeout(() => {
      if (fileUpload.working) {
        // setTimeoutId(startTimer());
      } else {
        // setTimeoutId(null);
        setPostingForm(false)
        if (fileUpload.error === '') {
          setRedirect('/documents')
        }
      }
    }, 1000)
  };

  const handleFormSubmit = (values) => {
    // Remove fields like doc or external_url if they haven't changed.
    let updatedValues = {...values};
    if (values.doc === undefined) {
      delete updatedValues['doc'];
    }
    if (values.external_url === undefined) {
      delete updatedValues['external_url'];
    }
    // debugger;

    setPostingForm(true);
    startTimer()
    dispatch(updateFile(updatedValues))
    // alert('Woulda dispatched the update form')
  }

  if (fetchingDoc || postingForm) {
    return <Spinner />;
  }
  if (redirect) {
    return <Redirect to={redirect} />
  }

  // debugger
  return (
    <DocumentForm
      site_document={currentDoc}
      form_submit={handleFormSubmit}
      edit
    />
  )
}

export default EditDocument;
