import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare } from '@fortawesome/free-solid-svg-icons';

import classes from './Photos.module.css';

import {
  BrowserView,
  MobileView
} from 'react-device-detect';

import { withRouter } from "react-router";

import ImageGallery from 'react-image-gallery';

import { connect } from 'react-redux';

import SideDrawerRightMobile from '../Navigation/SideDrawer/SideDrawerRightMobile';
import Backdrop from '../UI/Backdrop/Backdrop';

import Spinner from '../Spinner/Spinner';

import PhotosToc from './PhotosToc';

import { closeDrawer,
         toggleDrawer,
         showDrawerButton, 
         hideDrawerButton,
         setTitle } from '../../redux/actions/controlsActions';

import { getAllPhotoAlbums, getMyPhotoAlbums, setCurrentAlbum, getAlbum, likeAlbum, likePhoto } from '../../redux/actions/photoActions';
import { setUploadFile, uploadFile } from '../../redux/uploadFile/uploadFile.actions';

import ModalError from "../Errors/ModalError/ModalError";

import PhotoUploader from './PhotoUploader/PhotoUploader';

import Likes from '../Likes/Likes';
import LikesModal from '../Likes/LikesModal';
import { isMobile } from 'react-device-detect';

// const photoDefaults = {
//   album_id: -1,
//   'photo[description]': '',
//   'photo[site_definition_id]': 2,
//   'photo[neighborhood_id]': 2,
//   'photo[creator_id]': 1 // XXX: Must add in to post.
// };

/*
 * Composite inner Photos component.  Config Props:
 *
 * title - the title to display for the main component
 * mine - this boolean directs the component to get "my albums" or "all"
 * photoId - (param, if present, is the album to show if it exists)
 */
class PhotosCommon extends React.Component {

  constructor(props) {
    super(props)
    this.imgRef = React.createRef();
  }
  state = { usedParam: false, imageUploaderKey: 1, showLikesModal: null, currentSlideIndex: 0 };

  componentDidMount() {
    this.props.showButton();
    this.props.setTitle(this.props.title + 's');

    let defaultAlbumId = -1;
    if (this.props.match.params.albumId) {
      defaultAlbumId = parseInt(this.props.match.params.albumId);
      if (isNaN(defaultAlbumId) || defaultAlbumId < 1) {
        defaultAlbumId = -1;
      }
    }

    // Kick off "GET" action
    if (this.props.mine) {
      this.props.getMyPhotos(defaultAlbumId);
    } else {
      this.props.getAllPhotos(defaultAlbumId);
    }
  }

  componentWillUnmount() {
    this.props.hideButton();
  }

  toggleDrawer = () => {
    this.props.toggleDrawer();
  }

  sideDrawerClosedHandler = () => {
    this.props.closeDrawer();
  }

  albumSelected = (album) => {
    // debugger;
    this.setState({currentSlideIndex: 0})
    this.props.setCurrentAlbum(album);
    let newPath = this.props.location.pathname;
    if (newPath.includes(":albumId")) {
      newPath = newPath.replace(/:albumId/gi, `/${album.id}`);
    } else {
      newPath = newPath.replace(/\/[0-9]+/, `/${album.id}`);
    }
    this.props.history.replace(newPath);

    // debugger;
    this.props.closeDrawer();
  }

  newAlbum = () => {
    console.log(`there are ${this.props.photos.albums.length} albums...`, this.props)
    this.props.history.push('/newalbum')
  }

  _renderCustomControls = () => {
    if (this.imgRef.current === null) {
      return null;
    }

    console.log(`ImgIndex: ${this.imgRef.current.getCurrentIndex()}`)

    // Need to account for the case where the ImageGallery might not have caught up
    // and has the index of the picture from last album shown.  Make sure we don't
    // try and render with invalid data.
    const curIx = this.imgRef.current.getCurrentIndex();
    if (curIx >= this.props.photos.currentAlbum.photos.length) {
      return null;
    }
    const photo = this.props.photos.currentAlbum.photos[curIx];
    try {
      return (
        <Row style={{marginBottom: '0px'}}>
          <Col className={classes.PhotoLike}>
            <Likes
              likes={photo.likes} 
              likeClicked={() => {this.setState({currentSlideIndex: this.imgRef.current.getCurrentIndex()}); this.props.likePhoto(this.props.photos.currentAlbum, photo)}}
              onClick={(e) => {this.setState({showLikesModal: photo.likes})}}
            />
          </Col>
        </Row>
      )
    } catch (error) {
      // debugger;
      console.error(`ERROR rendering custom like controls.  ERR: ${error}`)
    }
  }
  renderGallery = () => {
    let photos = [];
    if (this.props.photos.currentAlbum && this.props.photos.currentAlbum.photos.length > 0) {
      photos = this.props.photos.currentAlbum.photos;
      return (
        <Row>
          <Col sm={12}>
            <BrowserView>
              <ImageGallery
                showBullets
                thumbnailPosition="bottom"
                key={this.props.photos.currentAlbum.photos.count} 
                items={photos}
                ref={this.imgRef}
                renderCustomControls={this._renderCustomControls.bind(this)}
                startIndex={this.state.currentSlideIndex}
              />
            </BrowserView>
            <MobileView>
              <ImageGallery 
                  showBullets
                  showThumbnails={false}
                  key={this.props.photos.currentAlbum.photos.count} 
                  items={photos}
                  ref={this.imgRef}
                  renderCustomControls={this._renderCustomControls.bind(this)}
                  startIndex={this.state.currentSlideIndex}
                />
            </MobileView>
          </Col>
        </Row>
      );
    } else {
      return <h5>[This album has no photos.  Add some.]</h5>;
    }
  }

  canAddPhotos = () => {
    const currentAlbum = this.props.photos.currentAlbum
    if ( (currentAlbum.published && currentAlbum.allow_all_to_contribute) ||
         (currentAlbum.creator_id === this.props.auth.uid) ||
         this.props.auth.site_admin) {
      return true;
    } else {
      return false;
    }
  }

  canEditAlbum = () => {
    const currentAlbum = this.props.photos.currentAlbum
    if ( (currentAlbum.creator_id === this.props.auth.uid) ||
         this.props.auth.site_admin) {
      return true;
    } else {
      return false;
    }
  }

  uploadComplete = () => {
    this.props.getAlbum(this.props.photos.currentAlbum.id);
  }

  editAlbum = () => {
    console.log("[PhotosCommon]: editAlbum: ", this.props);
    // this.props.setCurrentPost(post);
    // Clear the current post
    // this.props.clearCurrentPost();
    this.props.history.push(`/editalbum/${this.props.photos.currentAlbum.id}`, {album: this.props.photos.currentAlbum, mine: this.props.mine});
  }

  renderModalLikes = () => {
    if (this.state.showLikesModal === null) {
      return null;
    }

    return (
      <LikesModal
        show={this.state.showLikesModal.length > 0}
        onHide={() => {this.setState({showLikesModal: null})}}
        likes={this.state.showLikesModal}
      />
    )
  }

  renderPhotoUploader = () => {
    if (this.canAddPhotos()) {
      return (
        <PhotoUploader
          currentAlbum={this.props.photos.currentAlbum}
          // title="Add images to this album."
          // uploadImages={(images, uploadComplete) => {uploadImages(images, uploadComplete)}}
          uploadComplete={this.uploadComplete}
        />
      );
    } else {
      return null;
    }
  }
  renderEditButton = (album) => {
    if (this.canEditAlbum()) {
      return <FontAwesomeIcon onClick={() => {this.editAlbum()}} size="2x" className={classes.EditIcon} icon={faPenSquare} />

      // return <Button id={album.id} onClick={() => {this.editAlbum()}} className={classes.EditButton}>Edit Album</Button>;
    } else {
      return null;
    }
  }

  render() {
    if (this.props.photos.error) {
      const errMsg = `Error of "${this.props.photos.error}" while getting photos.`;
      return <ModalError redirect="/auth" error={errMsg} header="Error fetching photos." />
    }
    let backdrop;
    if (this.props.controls.drawerOpen) {
      console.log('*** PHOTOS *** backdrop SHOW')
      backdrop = <Backdrop show={this.props.controls.drawerOpen} click={this.sideDrawerClosedHandler} />;
    }

    // let photos = [];
    // if (this.props.photos.currentAlbum && this.props.photos.currentAlbum.photos.length > 0) {
    //   photos = this.props.photos.currentAlbum.photos;
    // }
  
    if (this.props.photos.working) {
      return <Spinner />;
    }

    if (this.props.photos.currentAlbum === undefined || this.props.photos.currentAlbum.id === null) {
      return (
        <Row>
          <Col sm={12} style={{textAlign: 'center', fontSize: '1.2em'}}>
            [You have not created any photo albums]
          </Col>
        </Row>
      );
    }
    // debugger;
    return (
      <div className={classes.PhotosContainer}>
        <Row>
          <Col sm={12}>
            <SideDrawerRightMobile
              contentPanel={<PhotosToc albums={this.props.photos.albums} clicked={this.albumSelected} currentAlbum={this.props.photos.currentAlbum} />
            } 
              open={this.props.controls.drawerOpen}
              closed={this.toggleDrawer}
            />
            {backdrop}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={9}>
            <Row style={{marginTop: '4px', marginBottom: '4px'}}>
              <Col xs={8} sm={9}>
                <h5 className={classes.AlbumTitle}>
                  {this.props.photos.currentAlbum.name}
                  <span className={classes.AlbumTitleLike}>
                  <Likes
                    likes={this.props.photos.currentAlbum.likes} 
                    likeClicked={() => {this.setState({currentSlideIndex: this.imgRef.current.getCurrentIndex()}); this.props.likeAlbum(this.props.photos.currentAlbum)}}
                    onClick={(e) => {this.setState({showLikesModal: this.props.photos.currentAlbum.likes})}}
                  />
                  </span>
                </h5>
              </Col>
              <Col xs={4} sm={3}>
                <div style={{display: 'inline-block'}}>
                  {this.renderPhotoUploader()}
                  {this.renderEditButton(this.props.photos.currentAlbum)}
                </div>
              </Col>
            </Row>
            {this.renderGallery()}
          </Col>
          {isMobile ? null:
          <Col md={3} className="d-none d-sm-block">
            <h5>{this.props.title}s</h5>
            <PhotosToc albums={this.props.photos.albums} clicked={this.albumSelected} currentAlbum={this.props.photos.albums[0]} />
          </Col>}
        </Row>
        <div>
        {this.renderModalLikes()}
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls,
    photos: state.photos,
    fileProgress: state.uploader.fileProgress
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    closeDrawer: () => dispatch(closeDrawer()),
    toggleDrawer: () => dispatch(toggleDrawer()),

    showButton: () => { console.log('dispatching showDrawerButton'); dispatch(showDrawerButton()) },
    hideButton: () => { console.log('dispatching hideDrawerButton'); dispatch(hideDrawerButton()) },
    setTitle: (title) => { dispatch(setTitle(title)) },

    getAllPhotos: (defaultAlbumId) => { dispatch(getAllPhotoAlbums(defaultAlbumId)) },
    getMyPhotos: (defaultAlbumId) => { dispatch(getMyPhotoAlbums(defaultAlbumId)) },
    getAlbum: (id) => { dispatch(getAlbum(id)) },
    setCurrentAlbum: (album) => { dispatch(setCurrentAlbum(album)) },

    setUploadFile: (files) => { dispatch(setUploadFile(files)) },
    uploadFile: (photoJson, files) => { dispatch(uploadFile(photoJson, files)) },

    likeAlbum: (album) => { dispatch(likeAlbum(album)) },
    likePhoto: (album, photo) => { dispatch(likePhoto(album, photo)) }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhotosCommon));
