import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import Spinner from '../../Spinner/Spinner';

import Input from '../../UI/Input';

import UploadButton from './UploadButton';

import Help from '../../Help/Help';

import classes from './DocumentForm.module.css';

const UPLOAD_FILE_SIZE = 10 * (1024*1024)
const DocumentForm = (props) => {
  const history = useHistory();
  let documentType = "Upload File or Create Link to external file";

  const auth = useSelector(state => state.auth);
  const fileUpload = useSelector(state => state.fileUpload);

  const helpBody =
    [
      {
        "name": "File Link Text",
        "desc": "This is the text that will show on the clickable link to the specified document."
      },
      {
        "name": "Description",
        "desc": "This is the text that will show to the right of the link to give more context about what the link is."
      },
      {
        "name": "Content Section",
        "desc": "This represents the section of the page the link will appear in on the doc page."
      },
      {
        "name": "File or URL",
        "desc": "(NOTE: This is only active when creating a new document) This switch will let you select whether you are uploading a PDF document or a URL to a document elsewhere that the link will point to."
      },
      {
        "name": "File",
        "desc": "This will show when 'File' is selected.  Click the image of a file to let you choose the PDF file to be uploaded."
      },
      {
        "name": "URL",
        "desc": "This will show when 'URL' is selected.  Enter a link to a file or internet resource you want to appear on the Forms and Info page."
      },
    ]
  
  const SUPPORTED_FORMATS = ['application/pdf'];

  const createSchema = Yup.object({
    name: Yup.string()
      .min(3, 'Must be 3 characters or more')
      .max(80, 'Must be 32 characters or less')
      .required('Required'),
    description: Yup.string(),
      // .min(3, 'Must be 3 characters or more')
      // .max(512, 'Must be 32 characters or less'),
    site_document_type_id: Yup.number()
      .min(1, "Must be a valid site document type id")
      .required('Must select type of document'),
    creator_id: Yup.number()
      .required('Required'),
    // active: Yup.boolean()
    //   .required('Must state if active or not'),
    external_url: Yup.string(),

    doc: Yup.mixed()
      .test(
        "fileSize",
        `The file is wrong or missing`,
        (file) => {
          // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
          // return sleep(100).then(() => {
          //   let retVal = true;
          //   // new upload
          //   if (!file) {
          //     return false;
          //   }
          //   console.log(`DocumentForm: createSchema: doc upload_file: ${formik.values.upload_file}  Value: ${file ? file: 'undefd'}`)
          //   if (formik.values.upload_file && file === undefined) {
          //     retVal = false;
          //   }
          //   console.log(`createSchema: RetVal: ${retVal}`)
          //   return retVal;
          // })
          let retVal = true;
          // new upload
          if (!file && formik.values.upload_file) {
            return false;
          }
          console.log(`DocumentForm: createSchema: doc upload_file: ${formik.values.upload_file}  Value: ${file ? file: 'undefd'}`)
          if (formik.values.upload_file && file === undefined) {
            retVal = false;
          }
          console.log(`createSchema: RetVal: ${retVal}`)
          return retVal;
        }
      ),
      // .default(undefined),
      // .notRequired(),
      // .required('File is required'),
    upload_file: Yup.boolean()
  });
  
  const editSchema = Yup.object({
    name: Yup.string()
      .min(3, 'Must be 3 characters or more')
      .max(80, 'Must be 32 characters or less')
      .required('Required'),
    description: Yup.string(),
      // .min(3, 'Must be 3 characters or more')
      // .max(512, 'Must be 32 characters or less'),
    site_document_type_id: Yup.number()
      .min(1, "Must be a valid site document type id")
      .required('Must select type of document'),
    creator_id: Yup.number()
      .required('Required'),
    // active: Yup.boolean()
    //   .required('Must state if active or not'),
    external_url: Yup.string(),

    doc: Yup.mixed()
      .test(
        "fileSize",
        `The file is wrong or missing`,
        (file) => {
          const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
          return sleep(100).then(() => {
            let retVal = true;
            if (file !== undefined && file.size > UPLOAD_FILE_SIZE) {
              retVal = false;
            }
  
            console.log(`editSchema: RetVal: ${retVal}`)
            return retVal;
          })
        }
      )
      .default(undefined),
      // .notRequired(),
      // .required('File is required'),
    upload_file: Yup.boolean()
  });
  
  const formik = useFormik({
    initialValues: {
      id: props.site_document.id,
      name: props.site_document.name,
      description: props.site_document.description,
      creator_id: props.site_document.creator_id,
      active: props.site_document.active,
      external_url: props.site_document.external_url,
      site_document_type_id: props.site_document.site_document_type_id,
      doc: props.site_document.doc,
      doc_file_name: props.site_document.doc_file_name,
      upload_file: props.site_document.upload_file // TODO: Need to pass this in with props
    },
    validateOnChange: true,
    // validateAfterSubmit: true,
    validationSchema: (props.edit ? editSchema : createSchema),
    onSubmit: (values) => {
      console.log(`DocumentForm: onSubmit`)
      props.form_submit(values)
      // alert("here I am Moe...")
      return false;// XXX: FIX / REMOVE
    },
  });
  
  useEffect(() => {
    console.log(`DocumentForm: useEffect running...`)
    if (formik.values.doc) {
      // formik.validateField('doc')
      console.log(`DocumentForm: useEffect WANT TO VALIDATE DOC HERE.`)
    }
  }, [formik.values.doc])

  const handleMediaTypeChange = (event) => {
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
        formik.setFieldValue('external_url', '');
      } else {
        formik.setFieldValue(event.target.name, false);
        // TODO: IF this logic ends up in an EditDocument component make sure this isn't cleared on load and that the radio button isn't allowed
        formik.setFieldValue('doc', '');
      }
    }
  }

  const fileChanged = (file) => {
    // TODO: mark when a doc was added???
    formik.setFieldValue('doc_file_name', file.name)
    formik.setFieldValue('doc', file)
  }

  const renderFileOrUrl = () => {
    console.log(`ExternalURL: ${formik.values.external_url}  Upload file? ${formik.values.upload_file}`)
    if (formik.values.upload_file) {
      return (
        <React.Fragment>
          {/* <InputGroup className={classes.InputGroup}> */}
            {/* <InputGroup.Prepend >
              <InputGroup.Text>
                File to Upload
              </InputGroup.Text>
            </InputGroup.Prepend> */}
            <UploadButton
              single
              onChange={fileChanged}
              currentFile={formik.values.doc}
              currentFileName={formik.values.doc_file_name}
              // uploadFiles={ (file) => {handleSelectedFile(file[0])} }
              // TODO: Maybe want to mod UploadButton to take validation args like blur,errors, touched?
              errors={formik.errors.doc || fileUpload.error}
              isValid={!formik.errors.doc}
            />
            {/* TODO: ADD ERROR MESSAGE.  MAYBE add errors like Input to the UploadButton??? */}
          {/* </InputGroup> */}
        </React.Fragment>
      )      
    }
    return (
      <React.Fragment>
        {/* <InputGroup className={classes.InputGroup}> */}
          <Input
            id='external_url'
            type="text"
            noPrepend
            noInputGroup
            placeholder="Enter URL to External File"
            required
            label="URL Link"
            value={formik.values.external_url}
            name='external_url'
            changed={formik.handleChange}
            blur={formik.handleBlur}
            errors={formik.errors.external_url}
            touched={formik.touched.external_url}
          />
        {/* </InputGroup> */}
      </React.Fragment>
    )
  }

  const renderFileOrUrlRadioButtons = (props) => {
    if (props.edit) {
      let uploadType = "External URL"
      if (props.site_document.upload_file) {
        uploadType = "Upload File"
      }
      return (
        <InputGroup.Text id="upload_file_">
          {uploadType}
        </InputGroup.Text>
      )
    } else {
      return (
        <InputGroup.Text className={classes.RadioLabel}>
          <Form.Label htmlFor='upload_file_true'>File</Form.Label>
          <Form.Check
            disabled={formik.isSubmitting}
            type='radio'
            as='input'
            id="upload_file_true"
            className={classes.Radio}
            checked={formik.values.upload_file === true}
            value={true}
            name='upload_file'
            onChange={handleMediaTypeChange}
            isInvalid={formik.errors['upload_file']}
            isValid={!formik.errors['upload_file']}
          />
          <Form.Label htmlFor='upload_file_false'>URL</Form.Label>
          <Form.Check
            disabled={formik.isSubmitting}
            styles={[{marginLeft: 20}]}
            type='radio'
            as='input'
            id="upload_file_false"
            className={classes.Radio}
            checked={formik.values.upload_file === false}
            value={false}
            name='upload_file'
            onChange={handleMediaTypeChange}
            isInvalid={formik.errors['upload_file']}
            isValid={!formik.errors['upload_file']}
          />
       
        </InputGroup.Text>
      )
    }
  }

  const renderSubmitButtonName = () => {
    let docType = (formik.values.upload_file ?  "File" : "Link");
    let btnName = `Upload ${docType}`;
    if (props.edit) {
      btnName =`Update ${docType}`;
    }
    return btnName;
  }

  const renderSiteDocTypeOptions = () => {
    const docTypeOptions = fileUpload.docTypes.map((docType) => {
      if ( (auth.site_admin && docType.admin_only) ||
           (docType.admin_only === false)) {
        return (
          <React.Fragment key={`${docType.name}.${docType.id}`}>
            <option value={docType.id}>{docType.name}</option>
          </React.Fragment>
        );
      } else {
        return null;
      }
    })
    // debugger
    return docTypeOptions;
  }

  const buildForm = (props) => {
    console.log('buildForm', props)
    return (
      <Row>
        <Col sm={12} className={classes.UploadCol}>
          <div className={classes.NameField}>
            <div className={classes.HelpIconContainer}>
              <h5 className={classes.FormTitle}>{documentType}</h5>
              <Help
                title={documentType}
                body={helpBody}
              />
            </div>

            {/* // TODO: Create reducer with error field we can use */}
            {/* <h5 style={{color: 'red', textAlign: 'center'}}>{auth.error}</h5>
            {props.location && props.location.state && props.location.state.error ? <p className={classes.FormError}>{props.location.state.error}</p>:null} */}
            <Form
              onSubmit={formik.handleSubmit}
              className={classes.Form}
            >
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='Name'
                  type="text"
                  placeholder="Enter Text to for the file link"
                  required
                  label="File Link Text"
                  value={formik.values.name}
                  name='name'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.name}
                  touched={formik.touched.name}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='Description'
                  type="text"
                  placeholder="(optional) Description of what is in this file."
                  // required
                  label="File Description"
                  value={formik.values.description}
                  name='description'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.description}
                  touched={formik.touched.description}
                />
              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="site_document_type_id">Content Section</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="site_document_type_id" name="site_document_type_id"
                  as="select" 
                  value={formik.values.site_document_type_id}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.site_document_type_id}
                >
                  <option value="0">[Select]</option>
                  {renderSiteDocTypeOptions()}
                  {/* <option value="Forms">Forms</option>
                  <option value="Misc.">Misc.</option>
                  <option value="Informational Documents">Informational Documents</option>
                  <option value="Informational Links">Informational Links</option> */}
                  {/* {renderSiteAdminOptions()} */}
                </Form.Control>
                <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
                  {formik.errors.site_document_type_id}
                </Form.Control.Feedback>
              </InputGroup>

              {/* <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="active">Activate?</InputGroup.Text>
                </InputGroup.Prepend>
                <ButtonGroup style={{padding: '5px', height: '37px', marginTop:'0px', marginLeft: '10px', marginRight: '-30px'}}>
                  <Form.Label  style={{marginBottom: '0'}} htmlFor='active_true'>Display</Form.Label>
                  <Form.Check
                    disabled={formik.isSubmitting}
                    type='radio'
                    as='input'
                    id="active_true"
                    className={classes.Radio}
                    checked={formik.values.active === true}
                    value={true}
                    name='active'
                    onChange={handleRadioChange}
                    isInvalid={formik.errors['active']}
                    isValid={!formik.errors['active']}
                  />
                  <Form.Label style={{marginBottom: '0'}} htmlFor='upload_file_false'>Hide</Form.Label>
                  <Form.Check
                    disabled={formik.isSubmitting}
                    styles={[{marginLeft: 20}]}
                    type='radio'
                    as='input'
                    id="active_false"
                    className={classes.Radio}
                    checked={formik.values.active === false}
                    value={false}
                    name='active'
                    onChange={handleRadioChange}
                    isInvalid={formik.errors['active']}
                    isValid={!formik.errors['active']}
                  />
                </ButtonGroup>
              </InputGroup> */}

              <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup}>
                  {renderFileOrUrlRadioButtons(props)}
                </InputGroup.Prepend>
                {renderFileOrUrl()}
              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend>
                </InputGroup.Prepend>
                <Button size="sm" type="submit" style={{marginRight:'10px'}} >{renderSubmitButtonName()}</Button>
                <Button size="sm" type="submit" onClick={() => {history.replace('/documents')}}>Cancel</Button>
              </InputGroup>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }

  if (fileUpload.working) {
    return <Spinner title="Uploading Document..." />
  }
  console.log('DocumentForm: rendering...')
  return buildForm(props)
}

export default DocumentForm;
