import React, { Component } from 'react';

import SiteMessagesCommon from './SiteMessagesCommon';

class MySiteMessages extends Component {
  // 

  render() {
    return (
      <SiteMessagesCommon 
        allowEdit
        title="My Draft Messages" 
        // url="/api_v1/site_messages_controller/my_site_messages.json"
        myDrafts
        okToEdit
      />
    );
  }
}
export default MySiteMessages;