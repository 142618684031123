import React, {useState, useEffect, useMemo} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { closeDrawer } from '../../redux/actions/controlsActions';
import { formattedDate } from '../../utils/utils';
import Post from '../Posts/Post';
import SideDrawerRightMobile from '../Navigation/SideDrawer/SideDrawerRightMobile';
import Backdrop from '../UI/Backdrop/Backdrop';

import SearchBox from './SearchBox';

import classes from './Search.module.css';


const postListRefs = new Map();

let effectCount = 0;

const SearchResultsContent = (props) => {
  const [selectedPost, setSelectedPost] = useState(null);
  const search = useSelector(state => state.search);
  const controls = useSelector(state => state.controls);
  const dispatch = useDispatch();

  const memoSelectedPost = useMemo( () => {
    return selectedPost;
  }, [selectedPost]);
  
  useEffect(() => {
    console.log(`SearchResultsContent: ${++effectCount}`)
    if (memoSelectedPost === null && search.posts.length > 0) {
      // debugger;
      setSelectedPost(search.posts[0])
    }
  }, [memoSelectedPost, search.posts])

  const closeNavDrawer = () => {
    dispatch(closeDrawer())
  }

  const updateSelectedPost = (post) => {
    setSelectedPost(post);
    closeNavDrawer();
  }

  const updateSearchList = () => {
    return;
  }

  const renderPost = (post) => {
    if (post === null) {
      return <p style={{textAlign: 'center'}}>{search.searched ? `[No search results for ${search.lastSearch}]`:null}</p>;
    }
    return (
      <React.Fragment>
        <Post key={post.id} post={post} updated={updateSearchList} />
      </React.Fragment>
    );
  }

  const isActive = (postId) => {
    return (postId === selectedPost?.id ? classes.TocEntryActive : '');
  }

  const renderResultsList = () => {
    let resultList = search.posts.map((post) => {
      return (
        <div
          key={post.id}
          ref={(element) => {postListRefs.set(post.id, element)}}
          className={[classes.TocEntry, isActive(post.id)].join(' ')} 
          onClick={() => {updateSelectedPost(post)}}>
            <p>{post.name}</p>
            <p className={classes.TocEntryMeta}>{formattedDate(post.post_date)}</p>
        </div>
      );
    })
    if (resultList.length === 0) {
      return null;
    }
    return (
      <div className={classes.Toc} style={{backgroundColor: 'white'}}>
        <div>
          <p className={classes.SearchListTitle}>Posts Search Results</p>
        </div>
        {resultList}
      </div>
    );
  }

  let backdrop;
  if (controls.drawerOpen) {
    backdrop = <Backdrop show={controls.drawerOpen} click={closeNavDrawer} />;
  }

  // Render the content
  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          <SideDrawerRightMobile
            contentPanel={renderResultsList()}
            open={controls.drawerOpen}
            closed={closeNavDrawer}
          />
          {backdrop}
          <Backdrop />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
        <Row>
            <Col sm={12} md={12}>
              <SearchBox placeholder="Search Posts..." searchProc={() => {}} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} className={classes.SearchContainer}>
              {renderPost(selectedPost)}
            </Col>
          </Row>
        </Col>
        <Col sm={4} className={[classes.SearchContainer, classes.TocDesktop].join(' ')}>
          {renderResultsList()}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SearchResultsContent;