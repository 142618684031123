import './utils/wdyr';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
// import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
// import thunk from 'redux-thunk';

import ReactGA from 'react-ga4'

import 'bootstrap/dist/css/bootstrap.min.css';
// import './css/sb-admin-2.css';
import 'react-image-gallery/styles/css/image-gallery.css';
// import './css/test-classes.css';
import './css/GeneralSite.css';

import store from './utils/store';

// import './index.css';
// import App from './App';
import MainApp from './MainApp';

import { GA_TRACKING_ID }  from './utils/constants';

// console.log(`Process NODE_ENV === ${process.env.NODE_ENV} DEV EXT: ${window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__}`)
// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
// const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
// const rootReducer = combineReducers({
//   auth: authReducer
// });

// const store = createStore(rootReducer, composeEnhancers(
//   applyMiddleware(thunk)
// ));

ReactGA.initialize(GA_TRACKING_ID);

ReactDOM.render(
  <Provider store={store}>
    <MainApp />
  </Provider>,
  document.getElementById('root'));
