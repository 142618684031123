import React from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Collapse from 'react-bootstrap/Collapse';

const Marquis = (props) => {
  console.log(`Open: ${props.open}`)
  let collapseClasses = "Close";
  if (props.open) {
    collapseClasses = "Open";
  }
  return (
    <Collapse in={props.open} className={collapseClasses}>
      <div style={{margin: '0', padding:'0'}}>
      <Jumbotron fluid>
        <Container className={collapseClasses} style={{marginLeft: '0px'}}>
          <h3 style={{textAlign:'left'}}>{props.title}</h3>
        </Container>
      </Jumbotron>
      </div>
    </Collapse>
  )
}

export default Marquis;
