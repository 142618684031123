import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';

import fileDownload from 'js-file-download';

import { setTitle } from '../../redux/actions/controlsActions';
import { setCenter, setSelectedAddr } from '../../redux/actions/mapActions';
import { getProperties, sortProperties } from '../../redux/actions/propertyActions';
import { getSiteUsers } from '../../redux/actions/authActions';

import axios from '../../utils/axios-whe';

import WhAddressHdr from './WhAddressHdr';

import WhAddressFunc from './WhAddressFunc';

import Spinner from '../Spinner/Spinner';

import NeighborhoodMap from '../Map/NeighborhoodMap';

import { WHE_BASE_URL } from '../../utils/constants';

import excelIcon from '../../images/excel-icon.png';

import classes from './Neighbors.module.css';

import '../Search/SearchResultOverride.css';

const baseGeocoderUrl = "https://maps.googleapis.com/maps/api/geocode/json";
const myGoogleApiKey = "AIzaSyAv7i3LzED6tY7l5RDOZO799GZeNxii3fU";

// const location = {
//   address: '7 Sassafras Ln.',
//   lat: 41.637306482436095,
//   lng: -70.6366415369223,
// }
class Neighbors extends Component {
  constructor(props) {
    super(props);
    this.geocoded = [];
    this.mapDiv = undefined;
    this.whAddrRef = new Map();
    this.state = {
      addrs: [],
      loading: false,
      addrSearch: "",
      sortAsc: true,
      sortCol: "last_name",
      selectedAddrId: -1
    };
    // alert(`Env == ${config.url.WHE_BASE_URL}`)
  }
  componentDidMount() {
    this.props.setTitle("Properties");
    this.props.getProperties();
    this.props.getSiteUsers();
  }

  addressUpdated = (addrIn) => {
    console.log("[Neighbors]: addressUpdated: ", addrIn)
    let addresses = [...this.state.addrs];
    let addrIx = addresses.findIndex(a => a.id === addrIn.id);
    addresses[addrIx] = {...addresses[addrIx], ...addrIn};
    console.log('[Neighbors]: calling setState');
    // debugger;
    console.log(`********* AddressUpdated:`, addresses[addrIx])
  };

  buildStreetAddr = (addr) => {
    let sa = "";
    if (addr.pri_street_addr && addr.pri_street_addr.length > 0) {
      sa += addr.pri_street_addr + ' ';
    }
    if (addr.pri_town && addr.pri_town.length > 0) {
      sa += addr.pri_town + ' ';
    }
    if (addr.pri_state && addr.pri_state.length > 0) {
      sa += addr.pri_street_addr + ' ';
    }
    if (addr.wh_house_num && addr.wh_house_num.length > 0) {
      sa += addr.wh_house_num + ' ';
    }
    if (addr.wh_street && addr.wh_street.length > 0) {
      sa += addr.wh_street;
    }
    return sa;    
  }
  buildSearchString = (addr) => {
   let searchString = addr.lot_num + " " + addr.last_name;
   if (addr.first_name1 && addr.first_name1.length > 0) {
     searchString += ' ' + addr.first_name1
   }
   if (addr.first_name2 && addr.first_name2.length > 0) {
     searchString += ' ' + addr.first_name2
   }
   searchString += this.buildStreetAddr(addr);
   return searchString;
  }
  filterAddress = (addr) => {
    const searchString = this.buildSearchString(addr);   
    // debugger;
    // if (new RegExp(searchString.split(' ').join("|")).test(this.state.addrSearch.toLowerCase())) {
    //   // At least one match
    //   return true;
    // } else {
    //   return false;
    // }

    if (searchString.toLowerCase().includes(this.state.addrSearch.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  sortByWhAddr = (addrs, sortDir) => {
    // Build Street map first
    let streets = {};
    let streetNames = [];
    addrs.forEach(a => {
      if (streets[a.wh_street] === undefined) {
        streets[a.wh_street] = [];
        streetNames.push(a.wh_street)
      }
      streets[a.wh_street].push(a);
    })
    
    // Sort street names
    streetNames.sort((a, b) => {
      if (sortDir) {
        return (a < b ? -1 : 1) 
      } else {
        return (a < b ? 1 : -1) 
      }
    })
    // Sort houses on a street
    streetNames.forEach(street => {
      streets[street].sort((a, b) => {
        let numA = parseInt(a.wh_house_num);
        let numB = parseInt(b.wh_house_num);
        if (sortDir) {
          if (numA === undefined || numB === undefined) {
            return(numB === undefined ? -1 : 1);
          }
          return (numA < numB ? -1 : 1)
        } else {
          if (numA === undefined || numB === undefined) {
            return(numB === undefined ? 1 : -1);
          }
          return (numA < numB ? 1 : -1)
        }
      })
    })
    // debugger
    let retAddrs = [];
    streetNames.forEach(street => {
      retAddrs = [...retAddrs, ...streets[street]];
    })
    return retAddrs;
  }

  centerMap = (addr) => {
    // debugger;
    this.setState({selectedAddrId: (addr ? addr.id: null)});
    if (this.mapDiv && this.mapDiv.scrollIntoView) {
      // alert('scrolling')
      // debugger;
      setTimeout(() => {
        this.props.setCenter(addr.latitude, addr.longitude);
        this.mapDiv.scrollIntoView({
          behavior: 'smooth',
          // WDS: block not supported in some mobile browsers.  this would let
          //     me center or scroll the entry to the end.
          // block: 'start'
          // block: 'center'
          // alignToTop: false
          block: "end", inline: "nearest"
        });
      }, 1000)
    }
  }

  addrClicked = (addr) => {
    //TODO: set selected
    this.props.setSelectedAddr(addr);
    this.centerMap(addr);
  };

  genWhAddrRef = (addr) => {
    let ref = React.createRef();
    this.whAddrRef.set(`${addr.id}`, ref);
    return ref;
  }
  renderAddrs = () => {
    // let { addrs } = this.state;
    let { addrs } = this.props.properties;
    return addrs.map((addr, index) => {
      if (this.filterAddress(addr)) {
        return <WhAddressFunc
                 ref={this.genWhAddrRef(addr)}
                 isSelected={(this.state.selectedAddrId === addr.id)}
                 key={index} addr={addr} clicked={this.addrClicked}
                 updated={this.addressUpdated}
               />
      } else {
        return null;
      }
    });
  }

  updateSearch = (event) => {
    // debugger;
    const sval = event.target.value;
    this.setState((prevState, prevProps) => ({
       addrSearch: sval
    }));
  }

  updateAddressCoords = (addr, coords) => {
    const formJson = {
      "address_list": {
        "id": addr.id,
        "latitude": coords.lat,
        "longitude": coords.lng
      }
    };
    const url = `${WHE_BASE_URL}/site_content/update_addr/${addr.id}`;
    console.log(`Patching WHAddress with URL of ${url}`)
    axios.patch(url, formJson,
    {
      _method: 'patch',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${this.props.auth.token}`,
        'X-CSRF-Token': this.props.auth.csrf_token
      }
    })
    .then(response => {
      console.log(`updateAddressCoords`, response.data);

    })
    .catch(error => {
      alert(`ERROR updating address info.  ${error}`)
    })

  }
  geocodeAddress = (addrIx, address) => {
    // Build url
    const lookupUrl = `${baseGeocoderUrl}?key=${myGoogleApiKey}&address=${address}`;
    axios.get(lookupUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => {
      // debugger;
      // alert(`lat: ${response.data.results[0].geometry.location.lat}  lng: ${response.data.results[0].geometry.location.lng}`  )
      console.log(JSON.stringify(response.data))
      // Save the address
      // this.geocoded.push({
      //   id: this.state.addrs[addrIx].id,
      //   address: address,
      //   lat: response.data.results[0].geometry.location.lat,
      //   lng: response.data.results[0].geometry.location.lng
      // })
      this.updateAddressCoords(this.state.addrs[addrIx], response.data.results[0].geometry.location)
      this.startGeocodeAddress(addrIx+1);
    })
    .catch(error => {
      alert("ERROR: " + error)
    })
  }

  startGeocodeAddress = (addrIx) => {
    setTimeout(() => {
      if (addrIx < this.state.addrs.length) {
        let addr = `${this.state.addrs[addrIx].wh_street}%20${this.state.addrs[addrIx].wh_house_num}%2002556`;
        this.geocodeAddress(addrIx, addr)
      } else {
        alert(JSON.stringify("Finished updateding addrs"))
      }
    }, 1000)
  }

  geocodeAddresses = () => {
    this.startGeocodeAddress(0);
  }

  renderDownloadIcon = () => {
    if (!this.props.auth.app_admin) {
      return null;
    }
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {/* {renderRightBtn(props)} */}
        <div title="Download for Excel or Numbers" className={classes.ExcelIcon} onClick={(e) => {this.downloadNeighbors(e)}}>
          <img className="" src={excelIcon} alt="Download Neighbors" />
        </div>
       </div>
    );  
  }
  downloadNeighbors = (e) => {
    e.preventDefault();
    // alert('would download neighbors excel ss')
    if (!window.confirm("Download property info file for Excel or Numbers?")) {
      return;
    }
    // debugger
    axios.get(`${WHE_BASE_URL}/api_v1/neighbors.json?f=csv`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/csv',
        'Authorization': `Token ${this.props.auth.token}`,
        'X-CSRF-Token': this.props.auth.csrf_token
      }
    })
    .then(response => {
      if (response.status === 200) {
        // debugger
        fileDownload(response.data, "whe_neighbors.csv")
      } else {
        // ERROR: log it.  axios response Interceptor should handle it
        console.error("Error retrieving Neighbor data");
      }
    })
    .catch(error => {
      // debugger;
      // this.props.history.push('/auth', 
      //                 {state: { from: '/neighbors', error: `Unauthorized.  Must sign in to access this feature.` }}
      // );
      console.error(`Error retrieving Neighbor data:  ${error}`);
    })

  }

  mapMarkerClicked = (addr) => {
    // debugger
    // if (this.whAddrDiv && this.whAddrDiv.scrollIntoView) {
    //   alert('scrolling')
    //   this.whAddrDiv.scrollIntoView();
    // }
    let ref = this.whAddrRef.get(`${addr.id}`);
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: "center", inline: "nearest"
      });
    }
    this.setState({selectedAddrId: (addr ? addr.id: null)});
  }

  renderNeighborList = () => {
    if (this.props.properties.working) {
      return (
        <div>
          <Row>
            <Col><Spinner title="Loading Property Info..." /></Col>
          </Row>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Row className={[classes.NeighborList, ".hidden-xl-down"]}>
          <Col xs={9} md={10}>
          {/* <h5 className={classes.Heading}>Properties</h5> */}
            <InputGroup>
              <InputGroup.Prepend className="search-prepend">
                <InputGroup.Text>
                  <FontAwesomeIcon onClick={() => {}} size="1x" className={classes.SearchIcon} icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl 
                // className={classes.SearchFilter}
                placeholder="Search Properties"
                value={this.state.addrSearch}
                onChange={this.updateSearch}
                // onKeyDown={handleKeyDown}
                style={{fontSize: '1.2em'}}
              />
              {this.state.addrSearch.length === 0 ? null :
              <InputGroup.Append onClick={() => {this.setState({addrSearch: ""})}}>
                <InputGroup.Text>
                  <FontAwesomeIcon size="1x" className={classes.SearchIcon} icon={faTimesCircle} />
                </InputGroup.Text>
              </InputGroup.Append>}
            </InputGroup>

          {/* <input className={classes.SearchFilter} type="search" onChange={this.updateSearch} placeholder="Search" />
          <button className={classes.CloseSearchBox}>x</button> */}
          {/* {this.props.auth.app_admin && <button onClick={() => {this.geocodeAddresses()}}>GEO</button>} */}
          </Col>
          <Col xs={2} md={1}>
            {/* <Link onClick={(e) => {this.downloadNeighbors(e)}} target="_blank" _download>Download</Link> */}
            {this.renderDownloadIcon()}
          </Col>
        </Row>
        <WhAddressHdr sortByClick={this.props.sortProperties} sortCol={this.props.properties.sortCol} sortAsc={this.props.properties.sortAsc} />
        <div style={{height: 'calc(45vh - 60px)', overflow: 'auto'}}>
        {this.renderAddrs()}
        </div>
      </React.Fragment>
    )
  }
  render() {
    return (
      <Container className={classes.NeighborsContainer}>
        <Row ref={(element) => {this.mapDiv = element}}>
          <Col sm={12} md={12} hidden={false}>
            <NeighborhoodMap addrs={this.props.properties.addrs} mmClicked={this.mapMarkerClicked} selectedAddrId={this.state.selectedAddrId} />
          </Col>
        </Row>
        {this.renderNeighborList()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    properties: state.properties
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    setTitle: (title) => {dispatch(setTitle(title))},
    setCenter: (lat, lng) => {dispatch(setCenter(lat, lng))},
    setSelectedAddr: (addr) => {dispatch(setSelectedAddr(addr))},
    getProperties: () => {dispatch(getProperties())},
    sortProperties: (sortCol) => {dispatch(sortProperties(sortCol))},
    getSiteUsers: () => {dispatch(getSiteUsers())}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Neighbors);
