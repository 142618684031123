import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Spinner from '../../Spinner/Spinner';
import Input from '../../UI/Input';

import { activateWithPerishableToken } from '../../../redux/actions/authActions';

import classes from '../../Auth/Authenticate.module.css';

const SetPassword = (props) => {
  const perishableToken = props.match.params.perishable_token;
  // console.log(`[SetPassword]: perishableToken: ${perishableToken}`)
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const schema = Yup.object({
    password: Yup.string()
      .min(8, 'Must be 8 characters or more')
      .max(32, 'Must be 32 characters or less')
      .required('Required'),
    password_confirmation: Yup.string()
      .min(8, 'Must be 8 characters or more')
      .max(32, 'Must be 32 characters or less')
      .required('Required')
    
  });
  
  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: ''
    },
    // validateOnChange: true,
    validationSchema: schema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  
  const handleFormSubmit = (values) => {
    // const keys = Object.keys(values);
    console.log("[SetPassword]:", values);
    dispatch(activateWithPerishableToken(perishableToken, values.password, values.password_confirmation));
  }

  const buildForm = () => {
    console.log('buildForm', props)
    return (
      <Row>
        <Col sm={12} className={classes.AuthCol}>
          <div className={classes.SigninBox}>
            <h4 className={classes.FormTitle}>Setup Your Password</h4>
            <h5 style={{color: 'red', textAlign: 'center'}}>{auth.error}</h5>
            {props.location && props.location.state && props.location.state.error ? <p className={classes.FormError}>{props.location.state.error}</p>:null}
            <Form
              onSubmit={formik.handleSubmit}
              className={classes.Form}
            >
              <InputGroup className={classes.InputGroup}>
              <Input
                id='password'
                type="password"
                required
                label="Password"
                value={formik.values.password}
                name='password'
                changed={formik.handleChange}
                blur={formik.handleBlur}
                errors={formik.errors.password}
                touched={formik.touched.password}
              />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
              <Input
                id='password_confirmation'
                type="password"
                required
                label="Password Confirm"
                value={formik.values.password_confirmation}
                name='password_confirmation'
                changed={formik.handleChange}
                blur={formik.handleBlur}
                errors={formik.errors.password_confirmation}
                touched={formik.touched.password_confirmation}
              />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend>
                </InputGroup.Prepend>
                <Button type="submit">Set Password</Button>
              </InputGroup>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }

  // console.log(`SetPassword: ${auth.logged_in}`)
  // setComponentRenderCount(componentRenderCount + 1)
  if (auth.loading) {
    return <Spinner />;
  } else {
    if (auth.logged_in) {
      // WDS: Change to use a previous URL.  Add reducer/action
      // console.log(`[SetPassword]: authRedirectPath: ${auth.authRedirectPath}`)
      return <Redirect to={auth.authRedirectPath} />;
    } else {
      return buildForm();
    }
  }
}

export default SetPassword;