import React, {useEffect} from 'react';

import Comment from './Comment';
import classes from './Comments.module.css';

const Comments = (props) => {
  useEffect(() => {
  }, [props.comments]);

  if (props.comments.length === 0) {
    return null;
  }

  const comments = props.comments.map((comment) => {
    return (
      <div key={comment.id}>
        <Comment comment={comment} likesClicked={() => {props.likesClicked(comment.likes)}} />
      </div>
    );
  });

  return (
    <div className={classes.Comments}>
      <h5><u>Comments</u></h5>
      {comments}
    </div>
  );
};

export default Comments;
