import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import EmailListForm from './EmailListForm';

import { createEmailList, clearCreating } from '../../redux/actions/emailListsActions';

import Spinner from '../Spinner/Spinner';
import classes from './ListMessages.module.css';

const NewEmailList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const emailLists = useSelector(state => state.emailLists);
  const auth = useSelector(state => state.auth);

  const emailListDefaults = {
    id: null,
    name: '',
    description: '',
    user_id: auth.uid
  }  

  const createEmailListFormSubmit = (listId, emailList) => {
    // debugger;
    dispatch(createEmailList(emailList));
  }

  if (emailLists.updatingList) {
    return (
      <Spinner title="Creating new email list..." />
    )
  }

  if (emailLists.finishedCreating) {
    dispatch(clearCreating());
    history.replace("/emaillists");
    return null;
  }

  return (
    <Row>
      <Col md={2} />
      <Col md={8}>
        <Row>
          <Col className={classes.ListTitle}>Create New Email List</Col>
        </Row>
        <EmailListForm
          emailList={emailListDefaults}
          auth={auth}
          formSubmit={createEmailListFormSubmit}
          onCancel={() => {history.goBack()}}
        />
      </Col>
    </Row>
  );
}

export default NewEmailList;
