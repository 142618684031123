import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class News extends Component {

  constructor(props) {
    super(props);
    const search = this.props.location.search;
    let pid = -1;
    if (search && search.includes('pid=')) {
      const ix = search.indexOf('pid=');
      pid = parseInt(search.substring(ix+4));
    }
    this.state = {postId: pid};
  }
  render() {
    console.log('[News]: render')
    const redirUrl = `/posts/${this.state.postId}`;
    return (
      <Redirect to={redirUrl} />
    );
  }
}
export default News;