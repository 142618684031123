import React, { useEffect, useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import fileDownload from 'js-file-download';

import axios from '../../utils/axios-whe';
import { WHE_BASE_URL } from '../../utils/constants';

import excelIcon from '../../images/excel-icon.png';

import SearchButton from '../SearchButton/SearchButton';
import Spinner from '../Spinner/Spinner';

import classes from './Properties.module.css';

const Properties = (props) => {
  const [properties, setProperties] = useState();
  const [propertySearch, setPropertySearch] = useState('');

  const auth = useSelector(state => state.auth);
  let { regd } = useParams();
  if (!regd) {
    regd = 0;
  }
  useEffect(() => {
    setProperties(); // Clear properties to activate Spinner
    axios.get(`${WHE_BASE_URL}/api_v1/property_report.json?u=${regd}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger
      if (response.status === 200) {
        const newAddrs = [...response.data];
        // console.log(`Addrs`, newAddrs);
        setProperties(newAddrs);
      } else {
        // ERROR: log it.  axios response Interceptor should handle it
        console.error("Error retrieving Neighbor data");
      }
    })
    .catch(error => {
      // debugger;
      // this.props.history.push('/auth', 
      //                 {state: { from: '/neighbors', error: `Unauthorized.  Must sign in to access this feature.` }}
      // );
      console.error(`Error retrieving Neighbor data:  ${error}`);
    })
  }, [regd, auth])

  const downloadProperties = (e) => {
    e.preventDefault();
    // alert('would download neighbors excel ss')
    if (!window.confirm("Download property info file for Excel or Numbers?")) {
      return;
    }
    // debugger
    axios.get(`${WHE_BASE_URL}/api_v1/property_report.csv?u=${regd}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/csv',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      if (response.status === 200) {
        // debugger
        fileDownload(response.data, "whe_neighbors.csv")
      } else {
        // ERROR: log it.  axios response Interceptor should handle it
        console.error("Error retrieving Neighbor data");
      }
    })
    .catch(error => {
      // debugger;
      // this.props.history.push('/auth', 
      //                 {state: { from: '/neighbors', error: `Unauthorized.  Must sign in to access this feature.` }}
      // );
      console.error(`Error retrieving Neighbor data:  ${error}`);
    })

  }

  const renderDownloadIcon = () => {
    // if (!this.props.auth.app_admin) {
    //   return null;
    // }
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {/* {renderRightBtn(props)} */}
        <div title="Download for Excel or Numbers" className={classes.ExcelIcon} onClick={(e) => {downloadProperties(e)}}>
          <img className="" src={excelIcon} alt="Download Properties" />
        </div>
       </div>
    );  
  }

  const updateSearchValue = (value) => {
    setPropertySearch(value);
  }

  const buildSearchString = (addr) => {
    let searchString = (addr.lot_num ? addr.lot_num + ' ' : '');
    searchString += (addr.registered_users ? addr.registered_users + ' ' : '');
    searchString += (addr.wh_street_address ? addr.wh_street_address + ' ' : '');
    searchString += (addr.full_name ? addr.full_name + ' ' : '');
    searchString += (addr.is_built ? addr.is_built + ' ' : '');
    return searchString.toLowerCase();
  }

  const filterAddress = (addr) => {
    const searchString = buildSearchString(addr);   
    // debugger;
    if (searchString.toLowerCase().includes(propertySearch.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }
  const renderProperties = () => {
    if (!properties) {
      return null;
    }

    return properties.map((addr, ix) => {
      if (filterAddress(addr)) {
        return (
          <Row key={addr.lot_num} className={classes.Property}>
            <Col className={classes.DesktopOnly} style={{width: '40px'}} xs={1} md={1}>{ix+1}</Col>
            <Col xs={5} className={[classes.MobileOnly, classes.Header].join(' ')}>Lot Number</Col>
            <Col xs={6} md={1}>{addr.lot_num}</Col>

            <Col xs={5} className={[classes.MobileOnly, classes.Header].join(' ')}>Registered Users</Col>
            <Col xs={6} md={3}>{addr.registered_users}</Col>

            <Col xs={5} className={[classes.MobileOnly, classes.Header].join(' ')}>WH Street</Col>
            <Col xs={6} md={2}>{addr.wh_street_address}</Col>

            <Col xs={5} className={[classes.MobileOnly, classes.Header].join(' ')}>Owner Name</Col>
            <Col xs={6} md={2}>{addr.full_name}</Col>

            <Col xs={5} className={[classes.MobileOnly, classes.Header].join(' ')}>Is Built?</Col>
            <Col xs={6} md={1}>{addr.is_built}</Col>
          </Row>
        );
      } else {
        return null;
      }
    })
  }

  if (!properties) {
    return <Spinner />
  }
  return (
    <React.Fragment>
      <Row>
        <Col className={classes.Title} xs={9} md={6}>
          { (regd === '0' ? 'Registered':'UNRegistered')} Properties
        </Col>
        <Col xs={2} md={1}>
          {renderDownloadIcon()}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <SearchButton valueChanged={updateSearchValue} placeholder="Search Users / Properties" />
        </Col>
      </Row>
      <div className={classes.PropertiesContainer}>
        <Row className={[classes.Header, classes.DesktopOnly].join(' ')}>
          <Col className={classes.DesktopOnly} style={{width: '40px'}} xs={1} md={1}>&nbsp;</Col>
          <Col xs={2} md={1}>Lot#</Col>
          <Col md={3} className={classes.DesktopOnly}>Property Users</Col>
          <Col xs={4} md={2}>WH Street</Col>
          <Col xs={6} md={2}>Owner</Col>
          <Col className={classes.DesktopOnly}>Is Built?</Col>
        </Row>
        <div style={{height: '65vh', overflowY: 'auto'}}>
        {renderProperties()}
        </div>
      </div>
    </React.Fragment>
  );

};

export default Properties;
