import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { setTitle } from '../../redux/actions/controlsActions';

import classes from './Administrative.module.css';
import { WHE_BASE_URL } from '../../utils/constants';

const Administrative = props => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if (auth.logged_in && auth.app_admin) {
      dispatch(setTitle("Administrative"));
    }
  });

  const renderSiteAdmin = () => {
    if (auth.site_admin === false) {
      return null;
    }
    return (
      <div className={classes.AdministrativeContainer}>
        <div className={classes.AdministrativeContainerHeader}>
          Bill's Administrative Stuff
        </div>
        <div>
          {/* <a href={`${WHE_BASE_URL}/admin/whe_users`} target="_oldsite">Wild Harbour Site Users (old site interface)</a> */}
          <NavLink to={`/whe_users`}>Wild Harbour Site Users</NavLink>
        </div>
        <div>
          <NavLink to={'/mailgun_events'}>Mailgun Events</NavLink>
        </div>
        <div>
          <NavLink to={'/mailgun_event_counts'}>Mailgun Event Counts</NavLink>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={classes.AdministrativeContainer}>
        <div className={classes.AdministrativeContainerHeader}>
          <h5>Administrative Functions</h5>
        </div>
        <div>
          <a href={`${WHE_BASE_URL}/email_lists`} target="_oldsite">CustomEmail List Messaging (old site interface)</a>
        </div>
        <div>
          <NavLink to={`/property_list/0`}>Registered Properties</NavLink>
        </div>
        <div>
        <NavLink to={`/property_list/1`}>UNRegistered Properties</NavLink>
        </div>
      </div>
      {renderSiteAdmin()}
    </div>
  );
};

export default Administrative;