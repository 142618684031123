import React, { Component } from 'react';
import parse from 'html-react-parser';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import addPost from '../../assets/images/add.png';

import Spinner from '../Spinner/Spinner';

import { formattedDate, filterHtmlTags } from '../../utils/utils';

import classes from './Post.module.css';
import Comments from './Comments/Comments';
import CommentForm from './Comments/CommentForm';

import Likes from '../Likes/Likes';
import LikesModal from '../Likes/LikesModal';

import placeholderImage from '../../assets/images/missing.png'

import { setCurrentPost, deletePost, clearCurrentPost, likePost } from '../../redux/actions/postActions';

class Post extends Component {
  state = { count: 0, showModal: false, showConfirmDeleteModal: false, lgImg: "", showLikesModal: null };

  cardTextClicked = (e) => {
    this.setState((prevState, { count }) => ({
      count: prevState.count + 1
    }));

    if (e.target.tagName === "IMG" || e.target.tagName === "img") {
      // console.log(`Clicked on an image: ${e.target.src}`);
      let newUrl = e.target.src;
      newUrl = newUrl.replace(/medium/gi, "original")
      // console.log(`NEW URL: ${newUrl}`);
      this.setState({ showModal: true, lgImg: newUrl});
    }
  };

  toggle = () => {
    this.setState((prevState, { modal }) => ({
      showModal: !this.state.showModal
    }));
  };

  toggleDeleteModal = () => {
    this.setState((prevState, { modal }) => ({
      showConfirmDeleteModal: !this.state.showConfirmDeleteModal
    }));
  };

  renderCommentForm = (post) => {
    if (post.allow_comments) {
      return <CommentForm post={post} updated={this.props.updated} />
    }
    return null;
  }
  renderComments = (post) => {
    if (post.published) {
      return (
        <React.Fragment>
          {this.renderCommentForm(post)}
          <Comments comments={post.comments} likesClicked={(likes) => {this.setState({showLikesModal: likes})}} />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  editPost = (post) => {
    console.log("Post: editPost: ", this.props);
    // this.props.setCurrentPost(post);
    // Clear the current post
    this.props.clearCurrentPost();
    this.props.history.push(`/editpost/${post.id}`, {post: post});
  }

  deleteThisPost = (post) => {
    console.log("DELETING POST")
    this.props.deletePost(post);
    this.props.history.push('/posts');
  }

  renderTooltip = (props) => {
    return (
      <Tooltip id="newpost-tooltip" {...props}>
        New Post
      </Tooltip>
    );
  }

  renderNewPostButton = () => {
    if (this.props.showNewPostButton) {
      return (
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={this.renderTooltip.bind(this)}
        >
          <div id="newpost-tooltip" title="New Post" onClick={() => {this.props.history.push('/newpost')}} className={classes.EditIcon} data-toggle="tooltip" placement="bottom">
            {/* <FontAwesomeIcon icon={faPlus} size="1x" />
            <FontAwesomeIcon icon={faNewspaper} size="1x" /> */}
            <Image src={addPost} height="32" width="32" thumbnail style={{backgroundColor: 'lightsalmon'}} />
          </div>
        </OverlayTrigger>
      )
    }
    return null;
  }
  renderEditButton = (post) => {
    if (post.published === true) {
      return null;
    } else {
      return <FontAwesomeIcon id={post.id} onClick={() => {this.editPost(post)}} size="2x" className={classes.EditIcon} icon={faPenSquare} />
    }
  }

  // XXX: Make this show for the post owner???  Just admin???
  renderDeleteButton = (post) => {
    if (post.published === true) {
      return null;
    } else {
      return  <FontAwesomeIcon id={post.id} onClick={() => {this.toggleDeleteModal(post)}} size="2x" className={classes.DeleteIcon} icon={faTrash} />
    }
  }

  renderPostHeader = (post) => {
    // avatars/thumb/missing.png
    // console.log(post);
    let thumbnailImage = post.poster_thumbnail;
    if (post.poster_thumbnail && post.poster_thumbnail.includes('missing.png')) {
      thumbnailImage = placeholderImage;
    }
    // debugger
    if (post && post.poster_thumbnail && false && post.poster_thumbnail.includes('missing.png')) {
      return (
        <React.Fragment>
          <Row>
            <Col sm={12} md={9}>{post.name}</Col>
            <Col sm={0} md={1} />
            <Col sm={2} md={2}>
              <div className={classes.EditIconContainer}>
                {this.renderNewPostButton()}
                {this.renderEditButton(post)} 
                {this.renderDeleteButton(post)}
              </div>
            </Col>
          </Row>
          <Row className={classes.PostHeaderAttributed}>
            <Col>by {post.poster} on {formattedDate(post.post_date)}</Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <Row>
          <Col sm={1} xs={2} style={{paddingRight: '0px'}}>
            <Image className={classes.PostHeaderImage} src={thumbnailImage} thumbnail />
          </Col>
          <Col sm={11} xs={10} style={{paddingLeft: '4px'}}>
            <Row>
              <Col xs={9} sm={10}>{post.name}</Col>
              <Col xs={3} sm={2}>
                <div className={classes.EditIconContainer}>
                  {this.renderNewPostButton()}
                  {this.renderEditButton(post)} 
                  {this.renderDeleteButton(post)}
                </div>
              </Col>
            </Row>
            <Row className={classes.PostHeaderAttributed}>
              <Col>by {post.poster} on {formattedDate(post.post_date)}</Col>
            </Row>
          </Col>
        </Row>
      );
    }
  }

  renderModalLikes = () => {
    if (this.state.showLikesModal === null /*|| post.likes.lenth === 0*/) {
      return null;
    }

    return (
      <LikesModal
        show={this.state.showLikesModal.length > 0} 
        onHide={() => {this.setState({showLikesModal: null})}}
        likes={this.state.showLikesModal}
      />
    )
  }
  renderLikes = (post) => {
    if (post.published === false) {
      return null;
    }

    return (
      <Row>
        <Col>
          <Likes 
            likes={post.likes} 
            likeClicked={() => {this.props.likePost(post)}}
            onClick={(e) => {this.setState({showLikesModal: post.likes})}}
          />
        </Col>
      </Row>
    )
  }
  renderPost(post) {
    let postBody = filterHtmlTags(post.content);

    // console.log("[Comments]", post.comments);
    return (
      <div className={classes.CardContainer}>
        <Card key={post.id} xs={6} className={[classes.Card, 'post-card border']} >
          <Card.Header className={classes.PostHeader}>
            {this.renderPostHeader(post)}
          </Card.Header>

          <Card.Text
            as="div"
            onClick={this.cardTextClicked}
            className={[classes.PostBody, classes.PostContainer]}
          >
            <Row>
              <Col>
                { parse(postBody) }
              </Col>
            </Row>
            {this.renderLikes(post)}
          </Card.Text>

          { this.renderComments(post)}
        </Card>

        <Modal 
          show={this.state.showModal}
          onHide={this.toggle}
          dialogClassName="modal-xl"
        >
          <Modal.Body>
            <Image className={["img-fluid"].join(' ')} src={this.state.lgImg} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-warning" className="btn-primary" onClick={this.toggle.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showConfirmDeleteModal}
          onHide={this.toggleDeleteModal}
        >
          <Modal.Body>
            <div>
              Are you sure you want to delete this post?&nbsp;&nbsp;
              <Button size="sm"
                onClick={() => {this.deleteThisPost(post)}}>YES</Button>&nbsp;&nbsp;
              <Button size="sm" onClick={this.toggleDeleteModal.bind(this)}>NO</Button>
            </div>
            
          </Modal.Body>
        </Modal>

        {this.renderModalLikes()}
      </div>
    );
  }

  render() {
    const { post } = this.props;

    if (this.props.posts.fetchingPost) {
      return <Spinner />;
    }
    return this.renderPost(post)
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.posts
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPost: (post) => dispatch(setCurrentPost(post)),
    clearCurrentPost: () => dispatch(clearCurrentPost()),
    deletePost: (post) => dispatch(deletePost(post)),
    likePost: (post) => dispatch(likePost(post))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Post));