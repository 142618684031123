import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import { setTitle } from '../../redux/actions/controlsActions';
import { getPendingMembers, cancelUser, approveMember, declineMember } from '../../redux/actions/authActions';

import Spinner from '../Spinner/Spinner';

import classes from './Administrative.module.css';

export const PendingSiteMembers = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [showCollapse, setShowCollapse] = useState(0)

  useEffect(() => {
    if (auth.logged_in && auth.app_admin) {
      dispatch(setTitle("Administrative"));

      if (auth.fetching === false && auth.fetched_members === false ) {
        dispatch(getPendingMembers());
      }
    }
  }, [auth.fetched_members, auth.api_op_finished, auth.app_admin, auth.fetching, auth.logged_in, dispatch]);

  const cancelUserAccount = (user) => {
    let cancelAccount = window.confirm(`Are you sure you want to cancel the acount for ${user.name}?`);
    if (cancelAccount) {
      dispatch(cancelUser(user.id))
    }
  }
  const renderStatus = (user) => {
    if (user.approved === null) {
      // Render approved button
      return renderApproveDeclineButtons(user);
    } else {
      // Render cancel account
      return <FontAwesomeIcon className={classes.Icon} icon={faBan} onClick={() => {cancelUserAccount(user)}} />
    }
  }

  const renderApproveDeclineButtons = (user) => {
    return (
      <div>
       <span className={classes.Icon} onClick={() => {dispatch(approveMember(user.id, user.address_list_id))}}>Approve <FontAwesomeIcon icon={faThumbsUp} title={"Approve"} /></span>
       <span className={classes.Icon} onClick={() => {dispatch(declineMember(user.id, user.address_list_id))}}>Decline <FontAwesomeIcon icon={faThumbsDown} title={"Decline"} /></span>
      </div>
    )
  }

  const renderProcessed = (user) => {
    if (user.approved_date !== null) {
      return  (
        <span>
          <FontAwesomeIcon className={classes.IconSimple} icon={(user.approved ? faThumbsUp:faThumbsDown)} />
          {user.approved_date}
        </span>
      )
    } else {
      return <span>"Pending"</span>
    }
  }

  const showHideDetails = (user) => {
    if (showCollapse === user.id) {
      setShowCollapse(0);
    } else {
      setShowCollapse(user.id);
    }
  }

  const shouldShowInner = (user) => {
    if (showCollapse === user.id) {
      return true;
    } else {
      return false;
    }
  }
  const renderPendingUsers = () => {
    /*
  json.id     user.id
  json.name   user.full_name
  json.street_address   user.street_address.strip
  json.home_owner   (user.address_list.length == 0 ? nil: user.address_list[0].full_name)
  json.approved     (@approverActions[user.id.to_s] == nil ? nil : @approverActions[user.id.to_s].approved)
  json.approved_date (@approverActions[user.id.to_s] == nil ? nil : @approverActions[user.id.to_s].updated_at.strftime("%m-%d-%Y"))
  json.approved_by  (@approverActions[user.id.to_s] != nil && @approverActions[user.id.to_s].approver_id != nil ? @approverActions[user.id.to_s].approver.user.full_name : nil)

    */
    const pendingUsers = auth.pending_site_members.map(u => {
      console.log("Should Show Inner? ", shouldShowInner(u))
      return (
        <Row key={`${u.id}-${u.name}`} className={classes.ListLine} onClick={() => {showHideDetails(u)}}>
          <Col xs={6} md={2}>{u.name}</Col>
          <Col xs={6} md={2}>{u.street_address}</Col>
          <Col xs={6} md={2}>{u.home_owner ? u.home_owner : "[Not set yet]"}</Col>
          <Col xs={6} md={2}>{renderStatus(u)}</Col>
          <Col xs={6} md={2}>{renderProcessed(u)}</Col>
          <Col xs={6} md={2}>{u.approved_by !== null ? u.approved_by : "Pending"}</Col>
          <Collapse key={`pending${u.id}`} in={shouldShowInner(u)}>
            <div style={{paddingLeft: '40px'}}>
              Context: {u.context_note}
            </div>
          </Collapse>
        </Row>
      )
    })

    return (
      <React.Fragment>
        <div className={classes.ListHeader}>
          <Row>
            <Col xs={6} md={2}>Name</Col>
            <Col xs={6} md={2}>Address</Col>
            <Col xs={6} md={2}>Owner</Col>
            <Col xs={6} md={2}>Status</Col>
            <Col xs={6} md={2}>Date Processed</Col>
            <Col xs={6} md={2}>Processed By</Col>
          </Row>
        </div>
        <div className={classes.ListBody}>
          {pendingUsers}
        </div>
      </React.Fragment>
    )
  }
  if ( (auth.pending_site_members.length === 0 && auth.fetching) || auth.api_op_inprog ) {
    return <Spinner title="Working on pending site members" />;
  }
  return (
    // <div>[Pending Members scaffolf  Found: {auth.pending_site_members.length} pending and/or inactive accounts]</div>
    <div>
      {renderPendingUsers()}
    </div>
  )
}