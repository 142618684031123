import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Input from '../UI/Input';

import { login, setRedirectPath } from '../../redux/actions/authActions';
import Spinner from '../Spinner/Spinner';

import classes from './Authenticate.module.css';

const Authenticate = (props) => {

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const schema = Yup.object({
    username: Yup.string()
      .min(3, 'Must be 3 characters or more')
      .max(64, 'Must be 64 characters or less')
      // WDS: IF I change to require email as login, uncomment
      // .email("Valid email address is required")
      .required('Required'),
    password: Yup.string()
      .min(3, 'Must be 3 characters or more')
      .max(32, 'Must be 32 characters or less')
      .required('Required')
  });
  
  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    // validateOnChange: true,
    validationSchema: schema,
    onSubmit: (values) => {
      // event.preventDefault();
      //setOrigAddrState(addrState);
      // alert('FUNC: submitting form with values: ' + JSON.stringify(formik.values))
      handleFormSubmit(values);
    },
  });
  
  const handleFormSubmit = (values) => {
    // const keys = Object.keys(values);
    // console.log(values);
    if (props.location && props.location.state) {
      dispatch(setRedirectPath(props.location.state.from));
    }
    dispatch(login({email: values.username, password: values.password}));
  }

  const buildForm = () => {
    // console.log('buildForm', props)
    return (
      <Row>
        <Col sm={12} className={classes.AuthCol}>
          <div className={classes.SigninBox}>
            <h4 className={classes.FormTitle}>Sign In</h4>
            <h5 style={{color: 'red', textAlign: 'center'}}>{auth.error}</h5>
            {props.location && props.location.state && props.location.state.error ? <p className={classes.FormError}>{props.location.state.error}</p>:null}
            <Form
              onSubmit={formik.handleSubmit}
              className={classes.Form}
            >
              <InputGroup className={classes.InputGroup}>
              <Input
                id='username'
                placeholder="Enter Email Address"
                type="email"
                required
                label="Email Address"
                value={formik.values.username}
                name='username'
                changed={formik.handleChange}
                blur={formik.handleBlur}
                errors={formik.errors.username}
                touched={formik.touched.username}
              />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
              <Input
                id='password'
                type="password"
                required
                label="Password"
                value={formik.values.password}
                name='password'
                changed={formik.handleChange}
                blur={formik.handleBlur}
                errors={formik.errors.password}
                touched={formik.touched.password}
              />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend>
                </InputGroup.Prepend>
                <Button type="submit">Sign In</Button>
              </InputGroup>
            </Form>
              <br />
              <NavLink to="/forgotpassword">
                Forgot Password?
              </NavLink>
          </div>
        </Col>
      </Row>
    );
  }

  // setComponentRenderCount(componentRenderCount + 1)
  if (auth.loggingIn) {
    console.log('Authenticate]: loggingIn.  not rendering...')
    return null;
  }

  if (auth.loading) {
    return <Spinner />;
  } else {
    if (auth.logged_in) {
      // WDS: Change to use a previous URL.  Add reducer/action
      console.log(`[Authenticate]: authRedirectPath: ${auth.authRedirectPath}`)
      return <Redirect to={auth.authRedirectPath} />;
    } else {
      return buildForm();
    }
  }
}

export default Authenticate;
