import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import classes from './NavigationItems.module.css';
import NavigationItem from '../NavigationItems/NavigationItem/NavigationItem';
import NestedDropdownButton from '../NestedNavigationItem/NestedDropdownButton';
import YesNoModal from '../../UI/YesNoModal';
import { ackSitePolicy, logout } from '../../../redux/actions/authActions';

import './OverrideStyles.css';

import Content from "./Content";

import {codeOfConduct} from '../../../utils/constants';

const NavigationItems = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const fileUpload = useSelector(state => state.fileUpload);

  const renderEditCancelDocs = () => {
    if (auth.app_admin) {
      return (
        <div href="/editdocs" divider>{(fileUpload.editDocs ? "Cancel " : "")}Edit Site Docs & Forms</div>
      )
    }
    return null;
  }

  const renderSiteAdmin = (props) => {
    if (auth.site_admin) {
      return (
        <div href='/administrative' >Administrative Stuff</div>
      )
    }
    return null;
  }
  const renderPendingMembers = (props) => {
    if (auth.site_admin) {
      return (
        <div href="/pending_site_members">Pending Site Members</div>
      )
    }
  }
  const authAdminNavItems = (props) => {
    if (auth.app_admin || auth.site_admin) {
      return (
        <NestedDropdownButton key={11} title="Admin Items" openIfSideSelected={true} closed={props.clicked}>
          {/* <div href='/siteDefs'>Site Defs</div> */}
          {/* <div href='/administrative' >Administrative Stuff</div> */}
          <div href='/mysitemessages'>My Draft Messages</div>
          <div href="/newsitemessage">NEW Site Message</div>
          {renderEditCancelDocs()}

          <div href={`/property_list/0`}>Registered Properties</div>
          <div href={`/property_list/1`}>UNRegistered Properties</div>
          <div href='/emaillists'>Custom Email Lists</div>
          <div href={`/whe_users`}>Wild Harbour Site Users</div>
          {renderSiteAdmin(props)}
          {renderPendingMembers(props)}
        </NestedDropdownButton>
      );
    } else {
      return null;
    }
  }

  const setSitePolicyAck = () => {
    dispatch(ackSitePolicy())
  }

  const issueLogout = () => {
    alert("You will be signed out of the site.  When you sign in again you can choose to accept the site conduct policy and be admitted or you won't be able to sign in.")
    dispatch(logout({token: auth.token, csrf_token: auth.csrf_token}))
  }

  const buildConductStatement = () => {
    return (
      <React.Fragment>
        <p><b><i>(Scroll to the bottom and click "Accept" or "Do not accept")</i></b></p>
        <Content json={codeOfConduct} />
      </React.Fragment>
    )
  }

  /*  */
  const authedNavItems = (props) => {
    return (
      <React.Fragment>
        <YesNoModal
          show={auth.user.has_ackd_policy === false}
          yesCallback={() => {setSitePolicyAck()}}
          yesLabel={"Accept"}
          noCallback={() => {issueLogout()}}
          noLabel={"Do not accept"}
        >
          {buildConductStatement()}
        </YesNoModal>
        <NavigationItem key={-123} link="/" activeClassName={props.activeClassName} clicked={props.clicked} exact>HOME</NavigationItem>
        <NavigationItem key={'abc123'} link='/calendar_events' activeClassName={props.activeClassName} clicked={props.clicked} exact>Calendar</NavigationItem>
        <NestedDropdownButton key={0} title="Posts!" openIfSideSelected={true} closed={props.clicked}>
          <div href="/posts">Posts</div>
          <div href="/myposts/">My Posts</div>
          <div href="/newpost" divider>New Post</div>
          <div href="/sitemessages/-1">Site Messages</div>
        </NestedDropdownButton>

        <NestedDropdownButton key={30} title="Neighborhood" openIfSideSelected={true} closed={props.clicked}>
          <div href="/neighbors">Properties</div>
          <div href="/directory">People</div>
          <div href="/assetcalendar">Asset Calendars</div>
        </NestedDropdownButton>

        {/* <NavigationItem key={2} link='/neighbors' activeClassName={props.activeClassName} clicked={props.clicked} exact>Neighbors</NavigationItem> */}

        <NestedDropdownButton key={1} title="Photos!" openIfSideSelected={true} closed={props.clicked}>
          <div href="/photos">Albums</div>
          <div href="/myphotos">My Albums</div>
          <div href="/newalbum">New Album</div>
        </NestedDropdownButton>

        <NavigationItem key={3} link='/documents' activeClassName={props.activeClassName} clicked={props.clicked} exact>Forms & Info</NavigationItem>
        {/* <NavigationItem key={4} link='/groups' activeClassName={props.activeClassName} clicked={props.clicked} exact>Groups</NavigationItem>
        <NavigationItem key={5} link='/search' activeClassName={props.activeClassName} clicked={props.clicked} exact>Search</NavigationItem> */}
        {authAdminNavItems(props)}
        <NavigationItem key={20} link="/profile" activeClassName={props.activeClassName} clicked={props.clicked}>Profile</NavigationItem>
        <NavigationItem key={6} link="/logout" activeClassName={props.activeClassName} clicked={props.clicked}>Logout</NavigationItem>
      </React.Fragment>
    );
  }
  
  const unauthedNavItems = (props) => {
    return (
      <React.Fragment>
        <NavigationItem key={9} link="/" activeClassName={props.activeClassName} clicked={props.clicked} exact>HOME</NavigationItem>
        <NavigationItem key={7} link="/forgotpassword" activeClassName={props.activeClassName} clicked={props.clicked}>Forgot Password</NavigationItem>
        <NavigationItem key={8} link="/register" activeClassName={props.activeClassName} clicked={props.clicked}>Register</NavigationItem>
        <NavigationItem key={10} link="/auth" activeClassName={props.activeClassName} clicked={props.clicked}>Sign In</NavigationItem>
      </React.Fragment>
    )
  }
  /*  */
  // const closeIfOpen = () => {
  //   if (location.pathname === props.link) {
  //     if (props.closed) {
  //       props.closed();
  //     }
  //   }
  // }

  // const buildCollapseDropdown = (props) => {
  //   let colClasses = [classes.NavigationItems, nestedNavClasses.MainLink, nestedNavClasses.MainLink_active];
  //   if (ddOpen) {
  //     colClasses.push(nestedNavClasses.MainLink_active_up);
  //   } else {
  //     colClasses.push(nestedNavClasses.MainLink_active_dn);
  //   }

  //   return (
  //     <div>
  //       <div onClick={() => {closeIfOpen()}} className={colClasses.join(' ')} data-toggle="collapse" aria-expanded="false" onClick={() => {setDdOpen(!ddOpen)}}>COL-{props.title}</div>
  //       <Collapse in={ddOpen}>
  //         <ul>
  //           <NavigationItem link="/posts" activeClassName={props.activeClassName} clicked={props.clicked}>Posts</NavigationItem>
  //           <NavigationItem link="/newpost" activeClassName={props.activeClassName} clicked={props.clicked}>New Post</NavigationItem>
  //           <NavigationItem link="/myposts" activeClassName={props.activeClassName} clicked={props.clicked}>My Posts</NavigationItem>
  //         </ul>
  //       </Collapse>
  //     </div>
  //   );
  // };
    
  return (
      <ul className={classes.NavigationItems}>
        {props.isAuthenticated ? authedNavItems(props) : unauthedNavItems(props)}
      </ul>
  );
};

export default NavigationItems;

