import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import Container from 'react-bootstrap/Container';
import Truncate from 'react-truncate-html';

import Spinner from '../Spinner/Spinner';

import axios from '../../utils/axios-whe';

import { setTitle, showMarquis } from '../../redux/actions/controlsActions';
import { getNews, clearNews } from '../../redux/actions/newsActions';

import { filterHtmlTags, formattedDate } from '../../utils/utils'


// import classes from '../components/Posts/PostToc.module.css';
import classes from './Home.module.css';


class Home extends Component {
  
  loadLoggedInHome = () => {
    if (this.props.auth.logged_in && !this.props.news.working && !this.props.news.fetched) {
      this.props.getNews();
    }
  }

  loadLoggedInHomeORIG = () => {
    if (this.props.auth.logged_in && !this.state.loading) {
      console.log('[Home] loadLoggedInHome: logged_in')
      // Get news JSON
      this.setState({loading: true});
      axios.get(`/api_v1/news.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${this.props.auth.token}`,
          'X-CSRF-Token': this.props.auth.csrf_token
        }
      })
      .then(response => {
        // debugger;
        const siteNews = response.data;
        console.log(`SiteNews`, siteNews);
        this.setState({ news: siteNews.news, loading: false });
      })
      .catch(error => {
        this.setState({loading: false});
        this.props.history.push('/auth', 
                        {state: { from: '/', error: `Unauthorized.  Must sign in to access this feature.` }}
        );
      })
    } else {
      console.log('[Home] loadLoggedInHome: NOT logged_in')
    }
  }

  componentDidMount() {
    console.log('[Home] componentDidMount')

    this.props.setTitle("Home");
    this.props.showMarquis(true);
    this.loadLoggedInHome();
  }

  componentWillUnmount() {
    this.props.clearNews();
  }

  renderError = () => {
    if (this.props.location && this.props.location.state && this.props.location.state.error) {
      return (
      <h4 style={{color: 'red'}}>{this.props.location.state.error}</h4>
      )
    }
  }

  renderMessageSnippet = (content) => {
    return (
      <div className={classes.Snippet}>
        <Truncate
          lines={3}
          breakWord={true}
          dangerouslySetInnerHTML={{
          __html: filterHtmlTags(content)
          }}
        />
      </div>
    );
  }
  renderMessageSnippet2 = (content) => {
    return (
      <div className={classes.Snippet}
            dangerouslySetInnerHTML={{__html: content}} />
    );
  }
  renderMessageSnippet3 = (content) => {
    return (
      <div className={[classes.Snippet, classes.Overflow].join(' ')}
      dangerouslySetInnerHTML={{__html: content}}
      />
    );
  }
  renderSiteMessageTruncated = (siteMessage) => {
    return (
      <div key={siteMessage.id} className={classes.MessageBlock}>
        <div className={classes.PostHeader}>
          <NavLink
            to={`/sitemessages/${siteMessage.id}`}
            exact={this.props.exact}
            className={classes.SiteMessageLink}
          >
            {siteMessage.subject}
          </NavLink>
        </div>
        <div>
          {/* {this.renderMessageSnippet2(filterHtmlTags(siteMessage.body))} */}
          {this.renderMessageSnippet2(siteMessage.body)}
          <div>
            <NavLink
              to={`/sitemessages/${siteMessage.id}`}
              exact={this.props.exact}
              className={classes.SiteMessageLink}
              // activeClassName={this.props.activeClassName ? this.props.activeClassName : classes.active}
            >
              (See message...)
            </NavLink>
          </div>
          {/* {this.renderMessageSnippet3(filterHtmlTags(siteMessage.body))} */}
        </div>
      </div>
    );
  }
  rendersiteMessages = () => {
    const site_messages = this.props.news.site_messages;

    if (site_messages.length === 0) {
      return null;
    }

    return site_messages.slice(0,5).map(sm => {
      return this.renderSiteMessageTruncated(sm);
    })
  }

  renderPostSnippet3 = (content) => {
    return (
      <div className={[classes.Snippet, classes.Overflow].join(' ')}
      dangerouslySetInnerHTML={{__html: content}}
      />
    );
  }
  renderSitePostTruncated = (post) => {
    return (
      <div key={post.id} className={classes.MessageBlock}>
        <div className={classes.PostHeader}>
          <NavLink
            to={`/posts/${post.id}`}
            exact={this.props.exact}
            className={classes.SiteMessageLink}
          >
            {post.name}
          </NavLink>
        </div>
        <div className={classes.PostHeaderAttributed}>by: {post.poster} on {formattedDate(post.post_date)}</div>
        <div>
          {this.renderMessageSnippet2(post.content)}
          <div>
            <NavLink
              to={`/posts/${post.id}`}
              exact={this.props.exact}
              className={classes.SiteMessageLink}
              // activeClassName={this.props.activeClassName ? this.props.activeClassName : classes.active}
            >
              (See Post...)
            </NavLink>
          </div>
          {/* {this.renderMessageSnippet3(filterHtmlTags(post.content))} */}
        </div>
      </div>
    );
  }

  renderSitePosts = () => {
    if (this.props.news.posts.length === 0) {
      return null;
    }
    return this.props.news.posts.slice(0,5).map(post => {
      return this.renderSitePostTruncated(post);
    })
  }

  renderLoggedIn = () => {
    // return <div>[LOGGED IN HOME]</div>
    return (
      <Row>
        <Col md={6} >
          <div className={classes.RightColHeader}>
            <div className={classes.TrusteeHeader}>Recent Site Posts</div>
            <div className={classes.SiteMessageLinkContainer}>
            <NavLink
              to="/posts"
              exact={this.props.exact}
              className={classes.SiteMessageLink}
              // activeClassName={this.props.activeClassName ? this.props.activeClassName : classes.active}
            >
              (All Posts)
            </NavLink>
            </div>

          </div>
          {this.renderSitePosts()}
        </Col>
        <Col md={6} >
          <div className={classes.LeftColHeader}>
            <div className={classes.TrusteeHeader}>Latest Messages</div>
            <div className={classes.SiteMessageLinkContainer}>
            <NavLink
              to="/sitemessages"
              exact={this.props.exact}
              className={classes.SiteMessageLink}
              // activeClassName={this.props.activeClassName ? this.props.activeClassName : classes.active}
            >
              (All Messages)
            </NavLink>
            </div>
          </div>
          {this.rendersiteMessages()}
        </Col>
      </Row>
    )
  }

  renderNotLoggedIn = () => {
    return (
      <Row>
        <Col md={6} className={classes.About}>
          {/* <h5 className={classes.LeftColHeader}>About</h5> */}
          <p>
             <b>Wild Harbour Estates</b> is a community located in beautiful North Falmouth, Ma. It boasts one of the nicest private 
             beaches on the Cape as well as a salt marsh that is great for kayaking and disappearing into another world.
          </p>
          <p>
            Before Wild Harbour Estates was developed in the mid 1970s, these vast acres were the setting for Camp
            Cowassett, a summer haven for young women. The community's wide, southwest-facing, sandy beaches are sheltered
            from, yet easily accessible by boat to Buzzards Bay, which connects with the Cape Cod Canal, Cape Cod Bay, Vineyard
            Sound, Nantucket Sound and the Atlantic.
          </p>
        </Col>
        <Col md={6}>
          <Carousel indicators={false} className={classes.Carousel}>
          <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://s3.amazonaws.com/whe_devel_images/photos/images/49/original/IMG_6927.jpg?1313925636"
                alt="First slide"
              />
              <Carousel.Caption>
                {/* <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://s3.amazonaws.com/whe_devel_images/photos/images/611/original/IMG_1632.JPG?1500231631"
                alt="Third slide"
              />

              <Carousel.Caption>
                {/* <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://s3.amazonaws.com/whe_devel_images/photos/images/648/original/IMG_7949.jpg?1532011990"
                alt="Third slide"
              />

              <Carousel.Caption>
                {/* <h3>Third slide label</h3>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
              </Carousel.Caption>
            </Carousel.Item>            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://s3.amazonaws.com/whe_devel_images/photos/images/49/original/IMG_6927.jpg?1313925636"
                alt="First slide"
              />
              <Carousel.Caption>
                {/* <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://s3.amazonaws.com/whe_devel_images/photos/images/611/original/IMG_1632.JPG?1500231631"
                alt="Third slide"
              />

              <Carousel.Caption>
                {/* <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://s3.amazonaws.com/whe_devel_images/photos/images/648/original/IMG_7949.jpg?1532011990"
                alt="Third slide"
              />

              <Carousel.Caption>
                {/* <h3>Third slide label</h3>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    )
  }

  componentWillUpdate() {
    console.log('[Home] componentWillUpdate')
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("[Home] componentDidUpdate");
    if (prevProps.auth.logged_in && this.props.auth.logged_in && !this.props.news.fetched) {
      // shoulda loaded last time thru
      console.log('[Home] componentDidUpdate not reloading...')
      return;
    }
    this.loadLoggedInHome();
  }

  render() {
    console.log('.....Rendering.....HOME')
    console.log("HOME props", this.props);

    if (this.props.auth.loggingIn || (this.props.auth.logged_in && !this.props.news.fetched)) {
      return (
        <div style={{textAlign: 'center'}}>
          {/* <Spinner style={{width: '200px', height: '200px'}} size="lg" animation="grow" /> */}
          <Spinner />
        </div>
      );
    }

    let content = this.renderNotLoggedIn();
    if (this.props.auth.logged_in) {
      content = this.renderLoggedIn();
    }
    return (
      <Container fluid>
        {content}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  }
}
const mapDispatchToProps= (dispatch) => {
  return {
    setTitle: (title) => {dispatch(setTitle(title))},
    showMarquis: (show) => {dispatch(showMarquis(show))},
    getNews: () => {dispatch(getNews())},
    clearNews: () => {dispatch(clearNews())}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
