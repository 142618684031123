import React, { useState, useEffect } from "react";

import { toArray } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Input from '../../UI/Input';

import classes from './UploadButton.module.css';

const UploadButton = (props) => {
  const [currentFile, setCurrentFile] = useState(null)
  const [filenameText, setFilenameText] = useState((props.currentFileName ? props.currentFileName : "(Select PDF File)"));

  useEffect(() => {
    if (props.currentFile) {
      setCurrentFile(props.currentFile)
      // setFilenameText(currentFile.name);
    }      
    if (props.currentFileName) {
      // setFilenameText(props.currentFileName)
    }
    }, [currentFile]);

  const handleChange = e => {
    if (e.target.files.length) {
      // debugger
      setCurrentFile(e.target.files[0]);
      setFilenameText(e.target.files[0].name);
      // if (props.uploadFiles) {
      //   const imgArray = [...toArray(e.target.files)];
      //   props.uploadFiles(imgArray)
      // }
      if (props.onChange) {
        props.onChange(e.target.files[0])
      }
    }
  };

  const renderErrorMessage = (props) => {
    if (props.errors) {
      return (
        <div style={{color: 'red'}}>
          {props.errors}
        </div>
      )
    } else {
      return null;
    }
  }

  // debugger;
  console.log('UploadButton: rendering...')
  return (
    <div className={classes.UploadContainer}>
      <label htmlFor="upload-button">
        <div className={classes.UploadIconContainer}>
          <FontAwesomeIcon
            icon={faFile}
            size="2x"
            className={classes.Icon}
          />
        </div>
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        multiple={props.single ? false:true}
        onChange={handleChange}
        accept="application/pdf, application/json"
      />
      {/* // XXX: Little hack but I wanted to get the filename displayed before upload. */}
      <div className={classes.FilenameText}>{filenameText}</div>
      {renderErrorMessage(props)}
    </div>
  );
}

export default UploadButton;
