import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// import Accordion from 'react-bootstrap/Accordion';

import { connect } from 'react-redux';

import classes from './Posts.module.css';
import axios from '../../utils/axios-whe';
import Post from './Post';

import PostTocSelect from './PostTocSelect';
// import PostToc from './PostToc';
// import PostTocUl from './PostTocUl';
// import PostTocMoc from './PostTocMoc';
import Spinner from '../Spinner/Spinner';
import ModalError from '../Errors/ModalError/ModalError';

import SideDrawerRightMobile from '../Navigation/SideDrawer/SideDrawerRightMobile';
import Backdrop from '../UI/Backdrop/Backdrop';

// import TocDrawerToggleButton from '../Navigation/SideDrawer/DrawerToggle/TocDrawerToggleButton';

import { closeDrawer, toggleDrawer, showDrawerButton, hideDrawerButton, setTitle } from '../../redux/actions/controlsActions';

import SearchBox from '../Search/SearchBox';

class Posts extends Component {
  state = { posts: [], currentPost: null, loading: true, error: null };

  constructor(props) {
    super(props);
    this.postToc = {};
    // const [open, setOpen] = useState(false);
    // debugger;
  }

  handleScroll = (event) => {
    // console.log('[Posts]: handleScroll', event)
    event.preventDefault();
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    let defaultPostId = -1;
    if (this.props.match.params.postId) {
      defaultPostId = parseInt(this.props.match.params.postId);
    }

    this.props.closeDrawer();
    this.props.showButton();
    this.props.setTitle("Posts");
    // console.log('[Posts]: componentDidMount', this.props.auth);
    // window.addEventListener('scroll', this.handleScroll, true);

    axios.get(`/api_v1/posts.json`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${this.props.auth.token}`,
        'X-CSRF-Token': this.props.auth.csrf_token
      }
    })
      .then(response => {
        // console.log(response)
        const postIx = response.data.findIndex(p => p.id === defaultPostId);
        let currentPost = response.data[0];
        if (postIx >= 0) {
          currentPost = response.data[postIx];
        }
        this.setState({posts: response.data, currentPost: currentPost, loading: false});
      })
      .catch(error => {
        console.log("ERROR: " + error);
        this.setState((prevState, prevProps) => ({
          error: error,
          loading: false
        }));
      });
  }

  componentWillUpdate() {
    console.log("componentWillUpdate")
  }

  componentWillUnmount() {
    this.props.hideButton();
  }

  tocEntryClicked = (post) => {
    console.log('[Posts][tocEntryClicked]')
    let postIx = this.state.posts.findIndex(p => p.id === post.id);
    if (postIx !== -1 && post.id !== this.state.currentPost.id) {
      this.setState({currentPost: this.state.posts[postIx], loading: false});   
      this.props.closeDrawer();
    }
  }

  toggleDrawer = () => {
    this.props.toggleDrawer();
  }
  closeDrawer = () => {
    console.log('closeDrawer')
    this.props.closeDrawer();
  }

  updatePost = (post) => {
    // Find post in state.
    let postIx = this.state.posts.findIndex(p => p.id === post.id);
    if (postIx !== -1) {
      // Update the posts.
      let updatedPosts = [...this.state.posts];
      console.log('Posts: updatePost: current and updated', postIx, updatedPosts[postIx], post);
      updatedPosts[postIx] = {...post};
      this.setState({currentPost: updatedPosts[postIx], posts: updatedPosts});
    }
  }

  renderPost() {
    console.log('***** renderPost *****')
    return (
    <div>
      <Post key={this.state.currentPost.id} post={this.state.currentPost} updated={this.updatePost} showNewPostButton />
    </div>
    );
  }
  renderPostContent() {
    let { posts } = this.state;
    // debugger;

    this.postToc = {};
    if (posts.length === 0) {
      return null;
    }
    let curYear = null;
    let curYearToc = [];
    posts.forEach((post) => {
      if (curYear === null || curYear !== new Date(post.post_date).getFullYear()) {
        curYear = new Date(post.post_date).getFullYear();
        curYearToc = [];
        this.postToc[curYear] = curYearToc;
      }
      curYearToc.push(post);
    });

    // console.log('PostTOC Object:', this.postToc);
    let postContent = (
      <React.Fragment>
        <SearchBox />
        <Row>
          <Col sm={12} md={9} className={classes.Posts}>
            {/* {postContentBody} */}
            {this.state.currentPost === null ? 
              null:
              (this.state.loading ? <Spinner title="Loading Posts..." /> :
              this.renderPost())
            }
          </Col>
          <Col sm={3} className={[classes.Toc, classes.TocDesktop]}>
            {this.state.posts.length > 0 ? <PostTocSelect title="Posts" clicked={this.tocEntryClicked} postToc={this.postToc} currentPost={this.state.currentPost} /> : console.log("**** NOT READY ***")}
          </Col>
        </Row>
      </React.Fragment>
    );
    return postContent;
  }

  renderPostToc() {
    // console.log(`[Posts]: renderPostToc ******`, this.postToc)
    return (
      <Row>
        <Col className={classes.Toc}>
          {this.state.posts.length > 0 ? <PostTocSelect title="Posts" clicked={this.tocEntryClicked} postToc={this.postToc} currentPost={this.state.currentPost} /> : console.log("**** NOT READY ***")}
        </Col>
      </Row>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.loading && nextState.posts === this.state.posts) {
      return false;
    } else {
      return true;
    }
  }
  
  render() {
    // console.log('[Posts]: rendering posts')
    if (this.state.error) {
      const errMsg = `Error of "${this.state.error.message}" while getting posts.`;
      return <ModalError logoutUser redirect="/auth" error={errMsg} header="Error fetching posts." />
    }

    if (this.state.loading) {
      return <Spinner title="Loading Posts..." />;
    }
    let backdrop;
    if (this.props.controls.drawerOpen) {
      console.log('*** POSTS *** backdrop SHOW')
      backdrop = <Backdrop show={this.props.controls.drawerOpen} click={this.closeDrawer} />;
    } else {
      // console.log('Backdrop not shown')
    }
  
    const postContent = this.renderPostContent();
    return (
      <Container fluid>
        <Row>
          <Col md={12}>
            <SideDrawerRightMobile
              contentPanel={this.renderPostToc()}
              open={this.props.controls.drawerOpen}
              closed={this.toggleDrawer}
            />
            {backdrop}
            <Backdrop />
          </Col>
        </Row>

        { postContent }
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    closeDrawer: () => dispatch(closeDrawer()),
    toggleDrawer: () => dispatch(toggleDrawer()),

    showButton: () => { dispatch(showDrawerButton()) },
    hideButton: () => { dispatch(hideDrawerButton()) },
    setTitle: (title) => {dispatch(setTitle(title))}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
