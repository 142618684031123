// export const WHE_BASE_URL = 'http://192.168.1.19:3004';
// export const WHE_BASE_URL = 'http://142.93.58.129:3000';
// export const WHE_BASE_URL = 'http://wildharbourestates.net:3000';
// export const WHE_BASE_URL = 'http://wildharbourestates.net';
// export const WHE_BASE_URL = 'http://127.0.0.1:3004';
// export const WHE_BASE_URL = "http://snappafal.homeip.net:3000";

// Editor types.  LARGE is for posts, small for comments/chat
export const POST_EDITOR = "post_editor";
export const COMMENT_EDITOR = "comment_editor";

export const codeOfConduct = [
  {"title": "Website Code of Conduct"},
  {"paragraph": [
    "Thank you for being part of the Wild Harbour Estates website. To ensure the best possible experience for all users, we have established some basic guidelines for participation.",
    "By joining and using this community website, you agree that you have read and will follow these guidelines.",
    `If you have questions, comments, suggestions, or concerns regarding the site or any of its content, feel free to email <a href='mailto:admin@wildharbourestates.org?subject=WHE Site Question'>admin@wildharbourestates.org</a> \
     and someone will get back to you within 24 hours.  The main goal of this site is to provide a platform that encourages civil and helpful dialogue. \
    The site administrators reserve the right to suspend or terminate membership on this website for anyone who violates these guidelines.`
    ]},
  {"unordered_list": [
    "Be respectful of others. This means treating everyone with courtesy and kindness, even if you disagree with them.",
    "Keep comments to the subject matter of the post on which you are commenting",
    "Don't defame others",
    "Avoid using offensive or inflammatory language.",
    "Don't post or share copyrighted material without permission.",
    "Don't share personal information that you don't want to be public.",
    "Be responsible for your own actions. Don't blame others for your mistakes.",
    "We reserve the right to remove any content or user that violates these guidelines.",
    "Do not use posts or comments as a form of attack against anyone."
  ]}

];

export const privacyStatement = [
  {"title": "Website Privacy Statement"},
  {"paragraph": [
  `Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to \
  understand how we collect, use, communicate, disclose and make use of personal information. The \
  following outlines our privacy policy.`
  ]},
  {"unordered_list": [
  `At no time will your personal information be shared with any party \
   outside of this site.  We will not be farming this information out \
   to marketers or any form of external commerce.`,
   `Before or at the time of collecting personal information, we will \
   identify the purposes for which information is being collected.",
   "We will collect and use personal information solely with the \
   objective of fulfilling functions on this website.`,
   "We will only retain personal information as long as necessary for the fulfillment of those purposes.",
   `Personal data should be relevant to the purposes for which it is to be used, and, to the \
   extent necessary for those purposes, should be accurate, complete, and up-to-date. ",
   "We will protect personal information by reasonable security safeguards against loss or theft, \
   as well as unauthorized access, disclosure, copying, use or modification.`
  ]},
  {"paragraph": [
  `We are committed to conducting our business in accordance with these principles in order \
  to ensure that the confidentiality of personal information is protected and maintained. `
  ]}
];

export const GA_TRACKING_ID = 'G-RP5WW9QMQH';

export const WHE_STATIC_CONTENT_URL = '//wildharbourestates.org/static/docs';
export const AUTH_INFO = 'auth_info';

export const MAX_IMAGE_SIZE = "8mb";

export const INSTRUMENT_RENDER = false;

// Version that UI is.  Once new UI is deployed its version will be bumped.  The
// backend keepAlive will return the current UI version.  If it's version is
// greater than this one the system will force a GUI reload to make sure the proper
// GUI code is in the client's browser.
// XXX: This comes from the signin.json.jbuilder file in the backend...
export const CURRENT_GUI_VERSION = 122;

const prod = {
  url: {
    WHE_STATIC_CONTENT_URL: '//wildharbourestates.org/static/docs',
    WHE_BASE_URL: '//api.wildharbourestates.org'
  }
};
const dev = {
  url: {
    WHE_STATIC_CONTENT_URL: '//192.168.1.19:3004/static/docs',
    WHE_BASE_URL: 'http://192.168.1.19:3004'
  }
};

export const PUBLIC_URL = process.env.NODE_ENV === 'development' ? 'http://192.168.1.19:3004' : '//wildharbourestates.org';
export const WHE_BASE_URL = process.env.NODE_ENV === 'development' ? 'http://192.168.1.19:3004' : '//api.wildharbourestates.org';
// export const WHE_BASE_URL = process.env.NODE_ENV === 'development' ? 'http://192.168.1.139:3004' : 'http://api.wildharbourestates.net';
export const config = process.env.NODE_ENV === 'development' ? dev : prod;
