import React, { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { InputGroup } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';

import SearchButton from '../SearchButton/SearchButton';
import { getProperties, moveUserToProperty } from '../../redux/actions/propertyActions';
import { capitalizeString } from '../../utils/utils';

import Spinner from '../Spinner/Spinner';

const PropertyUserForm = (props) => {
  const dispatch = useDispatch();
  const properties = useSelector(state => state.properties);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (properties.addrs.length === 0) {
      dispatch(getProperties());
    }
  }, []);

  const updateSearchValue = value => {
    setSearchValue(value)
  }

  const selectPropertyAddress = (e) => {
    const ix = properties.addrs.findIndex(addr => {
      if (Number(e.target.options[e.target.selectedIndex].value) === addr.id) {
        return true;
      } else {
        return false;
      }
    });
    // debugger
    // alert(`Found Prop with ix: ${ix}.  ${properties.addrs[ix].first_name1} ${properties.addrs[ix].last_name}`);

    let moveAddress = window.confirm(`Are you sure you want to move ${props.wheUser.full_name} to ${properties.addrs[ix].wh_house_num} ${properties.addrs[ix].wh_street}?`);
    if (moveAddress) {
      // dispatch(cancelUser(user.id))
      // alert('woulda moved...')
      dispatch(moveUserToProperty(props.wheUser.id, properties.addrs[ix].id));
      props.onSubmit();
    } else {
      props.cancel();
    }

  }

  const buildSearchString = (addr) => {
    return `${addr.lot_num} ${addr.wh_house_num} ${addr.wh_street.toLowerCase()} `;
  }

  const renderPropertyAddrInfo = () => {
    // debugger
    if (!properties.addrs || properties.addrs.length === 0) {
      return null;
    }

    const propItems = properties.addrs.map(addr => {
      if (buildSearchString(addr).toLowerCase().includes(searchValue.toLowerCase())) {
        return (
          <option key={addr.id} value={addr.id}> {addr.lot_num} - {addr.wh_house_num} {capitalizeString(addr.wh_street)}</option>
        );
      } else {
        return null;
      }
    });
    return propItems;
  }

  const buildPropertySelector = () => {
    return (
      <InputGroup style={{marginBottom: '10px'}}>
        <SearchButton valueChanged={updateSearchValue} placeholder="Property Address Helper" />
        <Form.Control
          id="prop_addr"
          name="prop_addr"
          label="Property Addr"
          as="select"
          rows={1}
          multiple={true}
          onChange={(e) => selectPropertyAddress(e)}
        >
            {renderPropertyAddrInfo()}
        </Form.Control>
      </InputGroup>
    )
  }

  if (properties.addrs.length === 0) {
    return <Spinner title='Fetching properties...' />
  }
  if (properties.whe_users.length === 0) {
    return <Spinner title="Updating property info..." />
  }
  return (
    <Row>
      <Col sm={12}>
        <Form>
          {buildPropertySelector()}
        </Form>
      </Col>
    </Row>  
  );
}

export default PropertyUserForm;