import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Spinner from '../Spinner/Spinner';

import { getNeighborhoodUsers, addMembers, removeMembers } from '../../redux/actions/emailListsActions';

import ModalError from '../Errors/ModalError/ModalError';
import SearchButton from '../SearchButton/SearchButton';
import Input from '../UI/Input';

import classes from './ListMessages.module.css';

const MessageListMembers = (props) => {
  const [users, setUsers] = useState([]); // TODO: move to form
  const [error, setError] = useState();
  const [nonListSearch, setNonListSearch] = useState('');
  const [spinnerTitle, setSpinnerTitle] = useState("Loading Member Info...");
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  // const neighbors = useSelector(state => state.emailLists.neighborhood_users);
  const emailLists = useSelector(state => state.emailLists);
  const history = useHistory();

  const [membersToBeRemoved, setMembersToBeRemoved] = useState([]);
  const [membersToBeAdded, setMembersToBeAdded] = useState([]);

  const selectStyle = {
    fontSize: '1.1em',
    // marginTop: '10px',
    marginBottom: '4px',
    // color: '#ff00ff',
    width: '50%',
    minHeight: '30vh'
  }

  const formik = useFormik({
    initialValues: {
      name: props.emailList.name,
      description: props.emailList.description,
      site_definition_id: props.auth.site_definition_id,
      user_id: props.auth.uid,
      members: props.emailList.members
    },
    onSubmit: (values) => {
      props.formSubmit(values);
    },
  });

  useEffect(() => {
    // debugger;
    dispatch(getNeighborhoodUsers());
  }, [])

  useEffect(() => {
    // debugger;
    setupUsers(emailLists.neighborhood_users);
  }, [emailLists.neighborhood_users]);

  useEffect(() => {
    // debugger;
    // If the email_lists were updated then we need to trigger the reload of NeighborhoodUsers.
    dispatch(getNeighborhoodUsers());
  }, [emailLists.email_lists])

  const getEmailListFromStore = (listId) => {
    const ix = emailLists.email_lists.findIndex(el => el.id === listId);
    if (ix >= 0) {
      return emailLists.email_lists[ix];
    } else {
      return undefined;
    }
  }
  let emailListCpy = getEmailListFromStore(props.emailList.id);

  const setupUsers = (users) => {
    // Create a list of ALL users not in the current member list.
    const userList = [];

    users.forEach(user => {
      if (emailListCpy.members.findIndex((e) => {
        if (e.id === user.id) {
          return true;
        } else {
          return false;
        }
      }) === -1) {
        userList.push(user);
      }
    })

    setUsers(userList);
  }

  const initAddRemoveButtonState = () => {
    setMembersToBeRemoved([]);
    setMembersToBeAdded([]);
  }

  const handleAddMembers = () => {
    // debugger
    setSpinnerTitle("Updating Member Info...");
    dispatch(addMembers(emailListCpy.id, membersToBeAdded));
    initAddRemoveButtonState();
  }

  const handleMemberChange = (e) => {
    // debugger
    let selIds = []
    for (var i = 0; i < e.target.options.length; i++) {
      if (e.target.options[i].selected) {
        selIds.push(e.target.options[i].value)
      }
    }
    console.log(`SelIds: ${JSON.stringify(selIds)}`);
    setMembersToBeRemoved([...selIds])
  }

  const getSelectedOptions = (e) => {
    // debugger

    let selIds = []
    for (var i = 0; i < e.target.options.length; i++) {
      if (e.target.options[i].selected) {
        selIds.push(e.target.options[i].value)
      }
    }
    console.log(`SelIds: ${JSON.stringify(selIds)}`);
    setMembersToBeAdded([...selIds]);
  }

  const removeMembersLocal = (event) => {
    setSpinnerTitle("Updating Member Info...");
    dispatch(removeMembers(emailListCpy.id, membersToBeRemoved));
    initAddRemoveButtonState();
  }

  const renderListUsers = (emailList) => {
    // Iterate locations
    // debugger;
    if (emailList.members.length === 0) {
      return <option key={33333} disabled>[No members. Add some]</option>;
    }

    const members = emailList.members.map(user => {
      return (
        <option key={user.id} value={user.id}> {user.last_name},  {user.first_name}</option>
      );
    });
    return members;
  }

  const buildSearchString = (user) => {
    return `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()} `;
  }

  const renderNonListMembers = () => {
    // Iterate locations
    // debugger;
    if (!users || users.length === 0) {
      return null;
    }

    const userItems = users.map(user => {
      if (buildSearchString(user).includes(nonListSearch)) {
        return (
          <option key={user.id} value={user.id}> {user.last_name},  {user.first_name}</option>
        );
      } else {
        return null;
      }
    });
    return userItems;
  }

  const disableRemoveMembers = () => {
    if (membersToBeRemoved.length === 0) {
      return true;
    }
    return false;
  }
  const disableAddMembers = () => {
    if (membersToBeAdded.length === 0) {
      return true;
    }
    return false;
  }

  const searchValueUpdate = (value) => {
    setNonListSearch(value.toLowerCase());
  }
  const renderForm = () => {
    return(
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={6}>
            <div className={classes.MemberList}>
              <div className={classes.ListHeader}>Current List Members</div>
              <Input
                id="members"
                name="members"
                label="List Members"
                as="select"
                style={selectStyle}
                noPrepend
                multiple
                changed={handleMemberChange}
                errors={formik.errors.members}
                disabled={formik.isSubmitting}
              >
              {renderListUsers(emailListCpy)}
            </Input>
            <Button type="button" onClick={(e) => {removeMembersLocal(e)}} disabled={disableRemoveMembers()}>{(disableRemoveMembers() ? "Select 1 or more to remove":"Remove Selected Names")}</Button>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.MemberList}>
              <div className={classes.ListHeader}>Non List Members</div>
              <Row>
                <Col xs={12} md={12}>
                  <SearchButton valueChanged={(value) => {searchValueUpdate(value)}} placeholder="Filter User List" />
                </Col>
              </Row>

              <Input
                id="non_members"
                name="non_members"
                label="List non_members"
                as="select"
                style={selectStyle}
                noPrepend
                multiple
                changed={(e) => getSelectedOptions(e)}
              >
              {renderNonListMembers()}
            </Input>
            <Button type="button" onClick={(e) => {handleAddMembers(e)}} disabled={disableAddMembers()}>{(disableAddMembers() ? "Select 1 or more to add":"Add Selected Names")}</Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }

  if (error) {
    return (
      <ModalError redirect="/emaillists" error={error} header="Error fetching users." />
    )
  }

  if (!users || (emailLists.fetchingUsers || emailLists.fetchingLists)) {
    return <Spinner title={spinnerTitle} />;
  }
  // debugger
  return (
    renderForm()
  )
};

export default MessageListMembers;
