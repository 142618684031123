import axios from '../../utils/axios-whe';

export const BEGIN_NEWS_FETCH = 'begin_news_fetch';
export const END_NEWS_FETCH = 'end_news_fetch';
export const SET_NEWS_ERROR = 'news_error';
export const CLEAR_NEWS = 'clear_news';

export const getNews = () => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: BEGIN_NEWS_FETCH })
    axios.get(`/api_v1/news.json`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      const siteNews = response.data;
      console.log(`SiteNews`, siteNews);
      // this.setState({ news: siteNews.news, loading: false });
      const news = {...siteNews.news}
      dispatch({ type: END_NEWS_FETCH, payload: news })
      // const posts = [news.posts[0], news.posts[2], news.posts[3], news.posts[4]];
      // dispatch({ type: END_NEWS_FETCH, payload:  {posts: posts, site_messages: news.site_messages} })
    })
    .catch(error => {
      dispatch({ type: SET_NEWS_ERROR, payload: error });
      // this.setState({loading: false});
      // this.props.history.push('/auth', 
      //                 {state: { from: '/', error: `Unauthorized.  Must sign in to access this feature.` }}
      // );
    })
  }
}

export const clearNews = () => {
  return (dispatch, getState) => {
    dispatch({type: CLEAR_NEWS});
  }
}