import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { clearLoginCredentials } from '../../../redux/actions/authActions';

class ModalError extends Component {
  state = {
    show: true
  }

  toggle = () => {
    this.setState((prevState, prevProps) => ({
      show: !prevState.show
    }));
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  buildModal = () => {
    let hdrText = "Error";
    if (this.props.header) {
      hdrText = this.props.header;
    }
    if (this.state.show) {
      return (
        <Modal show={this.state.show}>
          <ModalHeader>{hdrText}</ModalHeader>
          <ModalBody style={{color: 'red'}}>{this.props.error}</ModalBody>
          <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>Close</Button>
            </ModalFooter>
        </Modal>
      );
    } else {
      if (this.props.logoutUser) {
        this.props.clearLogin();
      }
      if (this.props.redirect) {
        return <Redirect to={this.props.redirect} />;
      } else {
        return null;
      }
    }
  }

  render() {
    return this.buildModal();
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearLogin: (path) => dispatch(clearLoginCredentials(path))
  }
};
export default connect(null, mapDispatchToProps)(ModalError);