import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';

import moment from 'moment'

import axios from '../../utils/axios-whe';
import { WHE_BASE_URL } from '../../utils/constants'; 

import Spinner from '../Spinner/Spinner';

import classes from './MailgunEvents.module.css';

const MailgunEvents = (props) => {
  const [events, setEvents] = useState([])
  const auth = useSelector(state => state.auth);

  const [showCollapse, setShowCollapse] = useState({})

  useEffect(() => {
    setEvents([]); // Clear properties to activate Spinner
    // axios.get(`${WHE_BASE_URL}/api_v1/mailgun_events.json`, {
    axios.get(`https://api.wildharbourestates.org/api_v1/mailgun_events.json`, {
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger
      if (response.status === 200) {
        const mgEvents = [...response.data];
        // const md = moment(response.data[0].created_at)
        // debugger
        setEvents(mgEvents);
      } else {
        // ERROR: log it.  axios response Interceptor should handle it
        console.error("Error retrieving mailgun events");
      }
    })
    .catch(error => {
      console.error(`Error retrieving mailgun events:  ${error}`);
    })
  }, [])

  const toggleCollapse = (mailgunEvent) => {
    // debugger
    const scHash = {...showCollapse};
    if (scHash[mailgunEvent.id] === undefined) {
      scHash[mailgunEvent.id] = false;
    }
    scHash[mailgunEvent.id] = !scHash[mailgunEvent.id]
    setShowCollapse(scHash)
    return true
  }

  const renderMessage = (eventData) => {
    // const details = JSON.parse(mailgunEvent.body)
    return `${(eventData.reason ? eventData.reason : "NA")}, ${eventData.recipient}`
    // return `${mailgunEvent.body}`
  }
  const renderMessageHeaders = (eventData) => {
    return JSON.stringify(eventData.message.headers)
  }
  const renderEvents = () => {
    return events.map((me, ix) => {
      const eventData = JSON.parse(me.body)
      return (
        <React.Fragment key={`ev-ix-${ix}`}>
          <Row className={classes.ListLine} onClick={() => {toggleCollapse(me)}}>
            <Col md={2}>{ix+1}:{me.event_type.toUpperCase()}</Col>
            <Col md={2}>{me.user_name}</Col>
            <Col md={2}>{moment(me.created_at).format("MM-DD-YY  hh:mm:ss")}</Col>
            <Col md={4}>{renderMessage(eventData)}</Col>
          </Row>
          <Collapse key={`collapse-ev-data${me.id}`} in={showCollapse[me.id]}>
            <Row style={{marginLeft: '10px'}}>
            <Col md={6}>{renderMessageHeaders(eventData)}</Col>
            </Row>
          </Collapse>
        </React.Fragment>
    )
    })
  }

  if (events.length === 0) {
    return <Spinner />
  }
  return (
    <div style={{marginLeft: '10px'}}>
      <h5>Mailgun Events:  Found {events.length} events</h5>
      <Row>
        <Col md={2} _style={{border: '1px solid black'}}>Event Type</Col>
        <Col md={2}>User Name</Col>
        <Col md={2}>Event Date</Col>
        <Col md={4}>Event Data</Col>
      </Row>
      {renderEvents()}
    </div>
  );
}

export default MailgunEvents;
