import React, { useState, useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Spinner from '../../Spinner/Spinner';
import DocumentForm from './DocumentForm';
import { uploadFile } from '../../../redux/actions/fileUploadActions';

const NewDocument = (props) => {
  const [postingForm, setPostingForm] = useState({posting: false, posted: false});
  const auth = useSelector(state => state.auth);
  const fileUpload = useSelector(state => state.fileUpload);
  const dispatch = useDispatch();

  const defaultDoc = {
    id: null,
    name: '',
    description: '',
    creator_id: auth.uid,
    active: true,
    external_url: '',
    doc: undefined,
    doc_file_name: '',
    site_document_type_id: 0,
    upload_file: true
  }
  
  useEffect(() => {
    if (!postingForm.posting && postingForm.posted) {
      setPostingForm({posting: false, posted: false});
    }  
  }, [postingForm, fileUpload]);

  const startTimer = () => {
    return setTimeout(() => {
      if (fileUpload.working) {
        startTimer();
      } else {
        // setTimeoutId(null);
        setPostingForm({posting: false, posted: true})
      }
    }, 1000)
  };

  const handleFormSubmit = (values) => {
    // debugger;
    setPostingForm({posting: true, posted: false});
    startTimer()
    dispatch(uploadFile(values))
  }

  if (postingForm.posting) {
    return <Spinner />;
  }

  if (!postingForm.posting && postingForm.posted && !fileUpload.error) {
    console.log(`NewDocument: redirecting to /documents`)
    return <Redirect to="/documents" />;
  }

  return (
    <DocumentForm
      site_document={defaultDoc}
      form_submit={handleFormSubmit}
    />
  )
}

export default NewDocument;
