import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

import { withRouter } from "react-router";

import { connect } from 'react-redux';

import Modal from 'react-bootstrap/Modal';

// import Header from './Header';
import Routes from '../Routes/Routes';
import Toolbar from '../Navigation/Toolbar/Toolbar';

// import Sidebar from '../Navigation/Sidebar/Sidebar';
import SideDrawerLeftMobile from '../Navigation/SideDrawer/SideDrawerLeftMobile';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';

import Marquis from './Marquis';

import Backdrop from '../UI/Backdrop/Backdrop';
import { tryLoginFromStorage } from '../../redux/actions/authActions';
import { showNavDrawer, hideNavDrawer, toggleNavDrawer } from '../../redux/actions/controlsActions';

import { couldBeLoggedIn } from '../../utils/utils';

import hdrClasses from '../Layout/Header.module.css';
import contentClasses from './Layout.module.css';
import Content from '../Navigation/NavigationItems/Content';
import { codeOfConduct, privacyStatement } from '../../utils/constants';
import { Button } from 'react-bootstrap';

class Layout extends Component {
  state = {
    loggingIn: false,
    contentClasses: contentClasses.Content,
    modalStatement: null
  }

  componentWillMount() {
    // See if this was a refresh where there may still be a valid token saved.
    if (!this.props.auth.logged_in && couldBeLoggedIn()) {
      console.log('[Layout] trying login...');
      this.setState({loggingIn: true});
      let redirUrl = this.props.location.pathname;
      if (this.props.location.search) {
        redirUrl = `${redirUrl}${this.props.location.search}`;
      }
      this.props.tryLogin(redirUrl);
    }  
  }

  getContentClass = () => {
    let cc = contentClasses.ContentNoMarquis;
    if (this.props.controls.showMarquis) {
      cc = contentClasses.Content;
    }
    return cc;
  }

  componentDidUpdate() {
    if (this.state.loggingIn && this.props.auth.logged_in) {
      this.setState({loggingIn: false});
      const redirPath = (this.props.auth.authRedirectPath ? this.props.auth.authRedirectPath : '/');
      this.props.history.replace(redirPath);
    }
  }
  sideDrawerClosedHandler = (event) => {
    event.preventDefault();
    this.props.hideNavDrawer();
  }

  sideDrawerToggleHandler = () => {
    console.log('sideDrawerToggleHandler')
    this.props.toggleNavDrawer();
  };

  showModalStatement = () => {
    if (this.state.modalStatement === null) {
      return null;
    }
    return (
      <Modal show={this.state.modalStatement !== null} style={{width: '100%'}} onHide={() => {this.setState({modalStatement: null})}}>
        <Modal.Body>
          <Content json={this.state.modalStatement} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {this.setState({modalStatement: null})}}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  renderCodeOfConductLink = () => {
    if (this.props.auth.logged_in === false) {
      return false;
    }
    return (
      <a className={contentClasses.CodeOfConduct} href="#" onClick={() => {this.setState({modalStatement: codeOfConduct})}}>
        Code of Conduct
      </a>
    );
  }
  render() {
    let backdrop;
    if (this.props.controls.showNavDrawer) {
      console.log('*** Layout *** backdrop SHOW')
      backdrop = <Backdrop show={this.props.controls.showNavDrawer} click={this.props.hideNavDrawer} />;
    }

    const marqiusText = `${(this.props.auth.logged_in ? "" : "Welcome to ")} Wild Harbour Estates`;
    return (
      <Container fluid={true}  style={{padding: '0px'}}>
        {/* <Header /> */}
        <div className="fixed-top">
        <Marquis open={this.props.controls.showMarquis} title={marqiusText} />
        <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler} isAuth={this.props.auth.logged_in} />
        </div>
        <SideDrawerLeftMobile
          contentPanel={<NavigationItems clicked={this.props.hideNavDrawer} isAuthenticated={this.props.auth.logged_in} activeClassName={hdrClasses.NavLinkSelected} />} 
          open={this.props.controls.showNavDrawer}
          closed={this.props.hideNavDrawer}
        />
        {backdrop}
        <div className={this.getContentClass()}>
          <Routes />
          <div className={contentClasses.Footer}>
            <a className={contentClasses.Privacy} href="#" onClick={() => {this.setState({modalStatement: privacyStatement})}}>Privacy</a>
            {this.renderCodeOfConductLink()}
          </div>
        </div>
        {this.showModalStatement()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    tryLogin: (path) => { dispatch(tryLoginFromStorage(path)) },

    showNavDrawer: () => { dispatch(showNavDrawer())},
    hideNavDrawer: () => { dispatch(hideNavDrawer())},
    toggleNavDrawer: () => { dispatch(toggleNavDrawer())}
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
