import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faInfo, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import { useSelector, useDispatch } from 'react-redux';

import { getEmailLists, deleteEmailList } from '../../redux/actions/emailListsActions';

import Spinner from '../Spinner/Spinner';

import EmailList from './EmailList';

import YesNoModal from '../UI/YesNoModal';

import classes from './ListMessages.module.css';

const EmailLists = (props) => {
  const deleteConfirmMessage = "Are you sure you want to delete this email list?";
  // const [emailLists, setEmailLists] = useState();
  const [emailListModal, setEmailListModal] = useState(null);
  const [areYouSureModal, setAreYouSureModal] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const emailLists = useSelector(state => state.emailLists);

  useEffect(() => {
    dispatch(getEmailLists());
  }, []);

  // useEffect(() => {

  const deleteEmailListWrapper = (emailList) => {
    dispatch(deleteEmailList(emailList));
    setAreYouSureModal(undefined);
  }

  // }, [emailLists.email_lists])
  const renderDeleteEmailList = (emailList) => {
    if (emailList.members?.length === 0 && emailList.messages?.length === 0) {
      return (
        <FontAwesomeIcon
          icon={faTrash}
          size="1x"
          className={classes.Icon}
          style={{marginLeft: '10px'}}
          onClick={() => {enableAreYouSureModal(deleteEmailListWrapper, emailList, deleteConfirmMessage)}}
        />
      );
    }
    return null;
  }

  const genEditInfoButton = (listId) => {
    if (auth.app_admin) {
      return (
        <div className={classes.Icon} onClick={() => {history.push(`/emaillist/${listId}`)}}>
          <FontAwesomeIcon
            icon={faInfo}
            size="1x"
          />/
          <FontAwesomeIcon
            icon={faEdit}
            size="1x"
          />
        </div>
      )
    }
    return null;
  }
  const renderEmailLists = () => {
    return emailLists.email_lists.map((emailList) => {
      return (
        <Row key={`${emailList.id}-${emailList.name}`} className={classes.ListEntry}>
          {auth.site_admin ? <Col className={classes.Desktop} md={2}>{emailList.owner_name}</Col> : null}
          <Col xs={5} md={3}>{emailList.name}</Col>
          <Col xs={5} md={4}>{emailList.description}</Col>
          {/* <Col md={1} onClick={() => {showEmailList(emailList)}}> */}
          <Col xs={2} md={2}>
            {genEditInfoButton(emailList.id)}
            {renderDeleteEmailList(emailList)}
          </Col>
          {/* <Col md={1}>{genEditButton(emailList.id)}</Col> */}
        </Row>
      );
    })
  }

  const enableAreYouSureModal = (modalFunc, modalArg, modalString) => {
    setAreYouSureModal({
      modalFunc: modalFunc,
      modalArg: modalArg,
      modalString: modalString
    });
  }
  const invokeModalOperation = () => {
    if (areYouSureModal) {
      const modalOpFunc = areYouSureModal.modalFunc;
      const modalOpArg = areYouSureModal.modalArg;
      modalOpFunc(modalOpArg);
    }
  }
  const renderAreYouSureModal = () => {
    return (
      <YesNoModal
        show={areYouSureModal}
        yesCallback={() => {invokeModalOperation()}}
        noCallback={() => {setAreYouSureModal(undefined)}}
        cancel={() => {setAreYouSureModal(undefined)}}
      >
        {(areYouSureModal ? areYouSureModal.modalString:"")}&nbsp;&nbsp;
      </YesNoModal>
    );
  }

  const renderEmailModal = () => {
    let showModal = (emailListModal !== null ? true : false);

    if (!showModal) {
      // debugger;
      return null;
    }
    return (
      <Modal show={showModal} onHide={() => {setEmailListModal(null)}} backdrop={true}>
        <Modal.Header style={{fontSize: '1.2em'}} closeButton>
          Email List
        </Modal.Header>
        <Modal.Body>
          <EmailList emailList={emailListModal} auth={auth} />
        </Modal.Body>
      </Modal>
    );
  }

  if (emailLists.fetchingLists) {
    return <Spinner />
  }

  return (
    <div className={classes.ListContainer}>
      <Row>
        <Col xs={6} md={2} className={classes.ListTitle}>Email Lists</Col>
        <Col xs={6} md={2}>
          <Button style={{marginLeft: '10px'}} size="sm" onClick={() => history.push('/newemaillist')}>
            <FontAwesomeIcon
              icon={faPlus}
              size="1x"
              style={{marginRight: '5px'}}
              // className={classes.Icon}
            />
            New List
          </Button>
        </Col>
      </Row>
      <Row className={classes.ListHeader}>
        {auth.site_admin ? <Col className={classes.Desktop} xs={0} md={2}>Owner Name</Col> : null}
        <Col xs={5} md={3}>List Name</Col>
        <Col xs={5} md={4}>description</Col>
        <Col md={2}>&nbsp;</Col>
        {/* <Col md={1}>Edit</Col> */}
      </Row>
      {renderEmailLists()}
      {renderEmailModal()}
      {renderAreYouSureModal()}
    </div>
  );
}

export default EmailLists;
