import jwt_decode from 'jwt-decode';

import { updateObject } from '../../utils/utils';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  LOGIN_WHE_START,
  LOGIN_WHE_FAILED,
  LOGIN_WHE_SUCCESS,
  LOGOUT_WHE_START,
  LOGOUT_WHE_SUCCESS,
  LOGOUT_WHE_FAILED,
  CLEAR_LOGGED_IN_CREDENTIALS,
  UNAUTHORIZED_RESPONSE,
  SET_REDIRECT_PATH,
  KEEP_ALIVE,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_FINISHED,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILED,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  RESTORE_LOGIN_START,
  TRY_RESTORE_LOGIN,

  UPDATING_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  CLEAR_UPDATING,

  PENDING_MEMBERS_START,
  PENDING_MEMBERS_SUCCESS,
  PENDING_MEMBERS_FAILED,

  CANCEL_USER_START,
  CANCEL_USER_SUCCESS,
  CANCEL_USER_FAILED,

  APPROVE_MEMBER_START,
  APPROVE_MEMBER_SUCCESS,
  APPROVE_MEMBER_FAILED,

  DECLINE_MEMBER_START,
  DECLINE_MEMBER_SUCCESS,
  DECLINE_MEMBER_FAILED,

  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,

  ACK_POLICY
} from '../actions/authActions';

const INITIAL_STATE = {
  logged_in: false,
  loggingIn: false,
  token: '',
  csrf_token: '',
  loading: false,
  app_admin: false,
  site_admin: false,
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  error: '',
  uid: -1,
  authRedirectPath: '/',
  keepAliveTimer: null,
  lastKeepAlivePing: null,
  neighboorhood_id: null,
  site_definition_id: null,
  registered: false,
  userParams: {},
  user: null,
  updating: false,
  didUpdate: false,
  fetching: false,
  fetched_members: false,
  api_op_inprog: false,
  api_op_finished: false,
  pending_site_members: [],
  site_users: []
};

const reducer = (state = INITIAL_STATE, action) => {
  let tokenPayload;
  
  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    case LOGIN_WHE_START:
      return updateObject(state, { loggingIn: true, email: action.payload.email, password: action.payload.password, error: '' })

    case RESTORE_LOGIN_START:
      return updateObject(state, { loggingIn: true });

    case LOGIN_WHE_SUCCESS:
      console.log('LOGIN SUCCESS');
      // console.log(action);
      // Parse Token payload.
      // let payload = jwt_decode("eyJhbGciOiJIUzI1NiJ9.eyJzIjoiVzk4V1I2emt6dzg3MFVyS0VjZFZ2RUFlSFQzdjNxbUQxbW9pejVkRVBZbyIsInNpZCI6ImFiNDBkOGE4Yjg0ZTM2NjAiLCJzaXRlX2FkbWluIjp0cnVlLCJmaXJzdF9uYW1lIjoiQmlsbCIsImxhc3RfbmFtZSI6IlNuYXBwZXIifQ.O_DkVN8swzLXlFj3bKuVVKQNPKQHSgut9m_FQ1tOq7k")
      tokenPayload = jwt_decode(action.payload.token);
      // console.log(LOGIN_WHE_SUCCESS + '  token payload: ', tokenPayload);
      return updateObject(state, { loggingIn: false, loading: false, uid: action.payload.uid, 
                                   app_admin: tokenPayload.app_admin, site_admin: tokenPayload.site_admin,
                                   logged_in: action.payload.result, 
                                   first_name: tokenPayload.first_name, last_name: tokenPayload.last_name,
                                   token: action.payload.token, csrf_token: action.payload.csrf_token,
                                   neighboorhood_id: action.payload.neighboorhood_id, 
                                   site_definition_id: action.payload.site_definition_id,
                                   user: action.payload.user});

    case TRY_RESTORE_LOGIN:
      tokenPayload = jwt_decode(action.payload.token);
      // console.log(LOGIN_WHE_SUCCESS + '  token payload: ', tokenPayload);
      return updateObject(state, { loggingIn: false, loading: false, uid: action.payload.uid, 
                                   app_admin: tokenPayload.app_admin, site_admin: tokenPayload.site_admin, 
                                   logged_in: action.payload.result, 
                                   first_name: tokenPayload.first_name, last_name: tokenPayload.last_name,
                                   token: action.payload.token, csrf_token: action.payload.csrf_token,
                                   neighboorhood_id: action.payload.neighboorhood_id,
                                   site_definition_id: action.payload.site_definition_id,
                                   user: action.payload.user });

    case SET_PASSWORD_SUCCESS:
      let spTokenPayload = jwt_decode(action.payload.token);
      // console.log(SET_PASSWORD_SUCCESS + '  token payload: ', spTokenPayload);
      return updateObject(state, { error: '', loading: false, uid: action.payload.uid, 
                                   app_admin: spTokenPayload.app_admin, site_admin: spTokenPayload.site_admin,
                                   logged_in: action.payload.result, 
                                   first_name: spTokenPayload.first_name, last_name: spTokenPayload.last_name,
                                   token: action.payload.token, csrf_token: action.payload.csrf_token,
                                   neighboorhood_id: action.payload.neighboorhood_id,
                                   site_definition_id: action.payload.site_definition_id,
                                   user: action.payload.user });

    case SET_PASSWORD_FAILED:
      // console.log(`SET_PASSWORD_FAILED Failure: ${action.payload}`);
      return updateObject(state, { loading: false, error: `Failed to set password, ${action.payload.error.message}.  Try again.` });

    case LOGIN_WHE_FAILED:
      // console.log(`LOGIN Failure: ${action.payload}`);
      let errMsg = action.payload.error.message;
      if (action.payload.error &&
          action.payload.error.response &&
          action.payload.error.response.data &&
          action.payload.error.response.data.errors &&
          action.payload.error.response.data.errors['invalid_credentials']) {
        errMsg = action.payload.error.response.data.errors['invalid_credentials']
      }
      return updateObject(state, { loggingIn: false, loading: false, error: errMsg });

    case LOGOUT_WHE_START:
      console.log('LOGOUT START...');
      return updateObject(state, { error: '' });
  
    case LOGOUT_WHE_SUCCESS:
      console.log('LOGOUT SUCCESS...');
      return INITIAL_STATE;

    case FORGOT_PASSWORD_START:
      console.log('FORGOT PASSWORD START');
      return updateObject(state, { loading: true });

    case FORGOT_PASSWORD_FINISHED:
      console.log('FORGOT PASSWORD FINISHED');
      let err = '';
      if (action.payload.reset_password && action.payload.reset_password.error) {
        err = action.payload.reset_password.error;
      }
      return updateObject(state, { loading: false, error: err });

    case LOGOUT_WHE_FAILED:
      // return updateObject(INITIAL_STATE, { error: `Failure logging out.  Error: ${action.payload.error.message}`})
      return updateObject(INITIAL_STATE, { error: ''})

    case CLEAR_LOGGED_IN_CREDENTIALS:
      // return updateObject(INITIAL_STATE, { });
      return INITIAL_STATE;

    case UNAUTHORIZED_RESPONSE:
      return updateObject(state, { logged_in: false, error: 'Inactivity timeout.  Must sign in again.' });

    case SET_REDIRECT_PATH:
      return updateObject(state, { authRedirectPath: action.payload.path })

    case KEEP_ALIVE:
      return updateObject(state, { keepAliveTimer: action.payload.timerId, lastKeepAlivePing: new Date() });

    case GET_USERS_START:
      return updateObject(state, { api_op_inprog: true, api_op_finished: false, site_users: [] })

    case GET_USERS_SUCCESS:
      return updateObject(state, { api_op_inprog: false, api_op_finished: true, site_users: action.payload.site_users })

    case GET_USERS_FAILED:
      return updateObject(state, { api_op_inprog: false, api_op_finished: true, error: action.payload.error });

    case REGISTER_USER_START:
      console.log(REGISTER_USER_START)
      return updateObject(state, { loading: true, userParams: action.payload })
    case REGISTER_USER_SUCCESS:
      return updateObject(state, { loading: false, registered: true, error: action.payload.signup.message })
    case REGISTER_USER_FAILED:
      return updateObject(state, { loading: false, error: action.payload.error, userParams: action.payload })

    case UPDATING_PASSWORD:
      return updateObject(state, { updating: true, didUpdate: false });

    case UPDATE_PASSWORD_SUCCESS:
      return updateObject(state, { updating: false, didUpdate: true, user: {...action.payload.user} })

    case UPDATE_PASSWORD_FAILED:
      return updateObject(state, { updating: false, error: `Failed to update password, ${action.payload.error.message}.  Try again.` })

    case CLEAR_UPDATING:
      return updateObject(state, { updating: false, didUpdate: false });

    case PENDING_MEMBERS_START:
      return updateObject(state, { fetching: true, fetched_members: false, pending_site_members: [] });

    case PENDING_MEMBERS_SUCCESS:
      return updateObject(state, { fetching: false, fetched_members: true, pending_site_members: [...action.payload.members]});

    case PENDING_MEMBERS_FAILED:
      return updateObject(state, { fetching: false, fetched_members: true, error: action.payload.error });

    case CANCEL_USER_START:
      return updateObject(state, { api_op_inprog: true, api_op_finished: false });

    case CANCEL_USER_SUCCESS:
      // Set fetched_members: false to force a refetch
      // debugger
      return updateObject(state, { api_op_inprog: false, api_op_finished: true, fetched_members: false});

    case CANCEL_USER_FAILED:
      return updateObject(state, { api_op_inprog: false, api_op_finished: true, error: action.payload.error });

    case APPROVE_MEMBER_START:
    case DECLINE_MEMBER_START:
      return updateObject(state, { api_op_inprog: true, api_op_finished: false });

    case APPROVE_MEMBER_SUCCESS:
    case DECLINE_MEMBER_SUCCESS:
      return updateObject(state, { api_op_inprog: false, api_op_finished: true, fetched_members: false});
    
    case APPROVE_MEMBER_FAILED:
    case DECLINE_MEMBER_FAILED:
      return updateObject(state, { api_op_inprog: false, api_op_finished: true, error: action.payload.error });
  
    case ACK_POLICY:
      return updateObject(state, { user: {...state.user, has_ackd_policy: true } });

    default:
      return state;

  }
};

export default reducer;
