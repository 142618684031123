import { updateObject } from '../../utils/utils';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import { 
  SHOW_DRAWER,
  HIDE_DRAWER,
  OPEN_DRAWER,
  CLOSE_DRAWER,
  TOGGLE_DRAWER,
  SET_TITLE,
  SHOW_NAV_DRAWER,
  HIDE_NAV_DRAWER,
  TOGGLE_NAV_DRAWER,
  SHOW_MARQUIS
} from "../actions/controlsActions";

const INITIAL_STATE = {
  showDrawerButton: false,
  drawerOpen: false,
  title: '',
  showNavDrawer: false,
  showMarquis: true
};

const reducer = (state = INITIAL_STATE, action) => {
  console.log(`[controlsReducers]: ${action.type}`);
  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    case SET_TITLE:
      return updateObject(state, { title: action.payload });

    case SHOW_DRAWER:
      return updateObject(state, { showDrawerButton: true });
      
    case HIDE_DRAWER:
      return updateObject(state, { showDrawerButton: false });
      
      case OPEN_DRAWER:
      return updateObject(state, { drawerOpen: true });

    case CLOSE_DRAWER:
      return updateObject(state, { drawerOpen: false });
    
    case TOGGLE_DRAWER:
      return updateObject(state, { drawerOpen: !state.drawerOpen });

      case SHOW_NAV_DRAWER:
        return updateObject(state, { showNavDrawer: true });
  
      case HIDE_NAV_DRAWER:
        return updateObject(state, { showNavDrawer: false });
  
      case TOGGLE_NAV_DRAWER:
        return updateObject(state, { showNavDrawer: !state.showNavDrawer });
    
      case SHOW_MARQUIS:
        // debugger;
        return updateObject(state, { showMarquis: action.payload.showMarquis });

      default:
        return state;
  }
}

export default reducer;
