import React, { Component } from 'react';
import { connect } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare } from '@fortawesome/free-solid-svg-icons';

import Spinner from '../Spinner/Spinner';

import axios from '../../utils/axios-whe';

import UserForm from './UserForm';

import { setTitle } from '../../redux/actions/controlsActions';
import classes from './User.module.css';
import placeholderImage from '../../assets/images/missing.png'

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: false, user: null, error: null, editUser: false, showModal: false, modalUrl: null};
    this.props.setTitle("My Profile")
  }
  componentWillUnmount() {
    this.setState({loading: false, user: null, editUser: false});
  }
  componentDidMount() {
    if (this.state.loading === false && this.state.user === null) {
      axios.get(`/api_v1/account.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${this.props.auth.token}`,
          'X-CSRF-Token': this.props.auth.csrf_token
        }
      })
      .then(response => {
        console.log('[User]: axios.get success]', response.data);
        // debugger;
        if (response !== undefined) {
          this.setState({loading: false, user: {...response.data.user}});
        }
      })
      .catch(error => {
        this.setState({loading: false});
        // debugger;
        // dispatch(fetchIngredientsFailed());
        console.log("ERROR fetching User profile...", error)
        // this.setState({error: error});
        this.setState((prevState, prevProps) => ({
          error: error
        }));
    
      });
    }
  }

  showImageModal = (user) => {
    if (user.avatar_orig.includes("missing.png")) {
      return;
    }
    this.setState({showModal: true, modalUrl: user.avatar_orig});
  }
  closeModal = () => {
    this.setState({showModal: false, modalUrl: null});
  }

  renderImageModal = () => {
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.closeModal}
      >
        <Modal.Body>
          <Image src={this.state.modalUrl}  width="300" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-warning" className="btn-primary" onClick={this.closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderAvatarImg = (user) => {
    if (user.avatar_thumbnail.includes('missing.png')) {
      return (
        <Image 
          src={placeholderImage} 
          height="75" width="75" thumbnail
        />
      );
    } else {
      return (
        <Image 
          onClick={() => {this.showImageModal(user)}}
          src={user.avatar_thumbnail} 
          height="75" width="75" thumbnail
        />
      );      
    }
  }

  showProfile = (user) => {
    if (this.state.editUser) {
      return null;
    }

    const mnfDescIx = user.message_notification_frequencies.findIndex(mnf => {
      if (mnf.id === user.message_notification_frequency_id) {
        return true;
      } else {
        return false;
      }
    })
    let mnfDesc = user.message_notification_frequencies[0].description;
    if (mnfDescIx >= 0) {
      mnfDesc = user.message_notification_frequencies[mnfDescIx].description;
    }

    return (
      <Row className="justify-content-sm-center">
        <Col xs={12} sm={6}>
          {this.renderAvatarImg(user)}
          {this.renderImageModal()}
        </Col>
        <Col sm={6}>
          <Row className={classes.UserInfo}>
            <Col sm={12}className={classes.Name}>{user.first_name} {user.last_name}</Col>
          </Row>
          <Row className={classes.UserInfo}>
            <Col sm={12}>{user.email}</Col>
          </Row>
          <Row className={classes.UserInfo}>
            <Col sm={12}>{user.phone}</Col>
          </Row>
          <Row className={classes.UserInfo}>
            <Col sm={12}>{user.house_number} {user.street}</Col>
          </Row>
          <Row className={classes.UserInfo}>
            <Col sm={12}>Allow Email Notifications - {user.wants_notifications ? "YES":"NO"}</Col>
          </Row>
          <Row className={classes.UserInfo}>
            <Col sm={12}>Message Frequency - {mnfDesc}</Col>
          </Row>
          <Row className={classes.UserInfo}>
            <Col sm={12}>Share Email - {user.share_email ? "YES":"NO"}</Col>
          </Row>
          <Row className={classes.UserInfo}>
            <Col sm={12}>Share Phone - {user.share_phone ? "YES":"NO"}</Col>
          </Row>
          <Row className={classes.UserInfo}>
            <Col sm={12}>
              <Button onClick={() => {this.editProfile()}} className={classes.EditButton}>
                <FontAwesomeIcon size="1x" className={classes.EditIcon} icon={faPenSquare} />
                Edit Profile
              </Button>
            </Col>
          </Row>
          <Row className={classes.UserInfo}>
            <Col sm={12}>
              <Button onClick={() => {this.props.history.push("/update_password")}} className={classes.EditButton}>
                <FontAwesomeIcon size="1x" className={classes.EditIcon} icon={faPenSquare} />
                Change Password
              </Button>
            </Col>
          </Row>

        </Col>
      </Row>
    );
  }
  editProfile = (user) => {
    this.setState({ editUser: true })
    return null;
  }

  formPostComplete = (user) => {
    // alert("[User]")
    this.setState({editUser: false, user: {...user}});
  }
  cancelEdit = (user) => {
    this.setState({ editUser: false })
  }
  showEditForm = (user) => {
    if (this.state.editUser) {
      return (
        <UserForm
          user={user}
          updated={this.formPostComplete}
          cancel={() => this.cancelEdit(user)}
        />
      );
    }
    return null;
  }
  
  render() {
    const {user} = this.state;
    // debugger;
    if (this.state.loading || this.state.user === null) {
      return <Spinner title="Loading my profile..." />
    }
    // debugger
    if (this.state.user === null) {
      return <div>NADA</div>
    }
    return (
      // <Container fluid className={classes.UserBox}>
      <div className={classes.UserBox}>
        {this.showProfile(user)}
        {this.showEditForm(user)}
      </div>
      // </Container>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);