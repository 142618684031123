import React, { Component } from 'react';
import { connect } from 'react-redux';

import { parsePhoneNumber } from 'libphonenumber-js';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Spinner from '../Spinner/Spinner';

import SearchButton from '../SearchButton/SearchButton';

import placeholderImage from '../../assets/images/missing.png'

import { setTitle } from '../../redux/actions/controlsActions';
import { getSiteUsers } from '../../redux/actions/authActions';

import { capitalizeString } from '../../utils/utils';

import classes from './User.module.css';

let userCount = 0;
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, modalUrl: null, error: null, userSearch: '' }
  }

  componentDidMount() {
    this.props.setTitle("Neighbors");
    this.props.getSiteUsers();
  }

  buildSearchString = (user) => {
    let searchString = (user.first_name ? user.first_name + ' ' : '');
    searchString += (user.last_name ? user.last_name + ' ' : '');
    searchString += (user.house_number ? user.house_number + ' ' : '');
    searchString += (user.street ? user.street : '');
    return searchString.toLowerCase();
  }
  filterAddress = (user) => {
    const searchString = this.buildSearchString(user);   
    // debugger;
    if (searchString.toLowerCase().includes(this.state.userSearch.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  renderEmail = (user) => {
    if (user.share_email || this.props.auth.app_admin) {
      return (
        <Row className={classes.UserInfo}>
          <Col md={12}>
            <a href={`mailto:${user.email}?subject=[${this.props.auth.first_name} ${this.props.auth.last_name} from WHE]`}>{user.email}</a> {(user.share_email ? "":"(private)")}
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  renderPhone = (user) => {
    if ( (this.props.auth.app_admin || user.share_phone) && user.phone) {

      let phoneNumber = user.phone;
      try {
        phoneNumber = parsePhoneNumber(user.phone, "US")
        // debugger;
        phoneNumber = phoneNumber.formatNational()
      } catch (e) {
        // console.error(`EXC parsing phone.  ${user.phone}`)
        phoneNumber = user.phone;
      }
      // debugger;
      return (
        <Row className={classes.UserInfo}>
          <Col md={12}>
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>  {(user.share_phone ? "":"(private)")}
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }
  
  showImageModal = (user) => {
    if (user.avatar_orig.includes("missing.png")) {
      return;
    }
    this.setState({showModal: true, modalUrl: user.avatar_orig});
  }
  closeModal = () => {
    this.setState({showModal: false, modalUrl: null});
  }
  renderImageModal = () => {
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.closeModal}
      >
        <Modal.Body>
          <Image src={this.state.modalUrl}  width="300" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-warning" className="btn-primary" onClick={this.closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderAvatarImg = (user) => {
    if (user.avatar_thumbnail.includes('missing.png')) {
      return (
        <Image 
          src={placeholderImage} 
          height="75" width="75" thumbnail
        />
      );
    } else {
      return (
        <Image 
          onClick={() => {this.showImageModal(user)}}
          src={user.avatar_thumbnail} 
          height="75" width="75" thumbnail
        />
      );      
    }
  }
  renderUser = (user) => {
    userCount += 1;
    return (
      <Row key={user.email} className={[classes.UserRow, "justify-content-sm-center"].join(' ')}>
        <Col xs={3} md={4}>
          {this.renderAvatarImg(user)}
        </Col>
        <Col xs={8} md={8}>
          <Row className={classes.UserInfo}>
            <Col md={12}className={classes.Name}>{capitalizeString(user.first_name)} {capitalizeString(user.last_name)}</Col>
          </Row>
          {this.renderEmail(user)}
          {this.renderPhone(user)}
          <Row className={classes.UserInfo}>
            <Col md={12}>{user.house_number} {user.street}</Col>
          </Row>
        </Col>
      </Row>
    );
  }

  renderUsers = () => {
    const users = this.props.auth.site_users.map(user => {
      if (this.filterAddress(user)) {
        return (
          <Row key={user.email + ' ' + userCount}>
            {/* <Col xs={0} md={3} /> */}
            <Col xs={12} md={12}>
              {this.renderUser(user)}
            </Col>
          </Row>
        );
      } else {
        return null;
      }
    });
    return (
      <Row>
        <Col xs={0} md={3} />
        <Col xs={12} md={6}>
          {users}
        </Col>
      </Row>
    )
  }

  updateSearchValue = (value) => {
    this.setState((prevState, prevProps) => ({
      userSearch: value
    }));
  }

  render() {
    if (this.props.auth.api_op_inprog) {
      return <Spinner />
    }
    
    return (
      <Container fluid>
        <Row style={{marginBottom: '8px'}}>
          <Col md={3} />
          <Col md={6} className={classes.SearchColumn}>
            <SearchButton valueChanged={this.updateSearchValue} placeholder="Search People" />
          </Col>
        </Row>
        <Row>
          {/* <Col md={3} /> */}
          <Col style={{height: '65vh', overflowY: 'auto'}}>
            {this.renderUsers()}
          </Col>
        </Row>
        {this.renderImageModal()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
    getSiteUsers: () => dispatch(getSiteUsers())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
