import React, { Component } from 'react';
import parse from 'html-react-parser';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

import Spinner from '../../Spinner/Spinner';

import axios from "../../../utils/axios-whe";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faTrash, faClone } from '@fortawesome/free-solid-svg-icons';

import { formattedDate, filterHtmlTags } from '../../../utils/utils';

import classes from './SiteMessage.module.css';

// import { setCurrentPost, deleteSiteMessage, clearCurrentPost } from '../../redux/actions/postActions';
import { deleteSiteMessage } from '../../../redux/actions/siteMessageActions';

class SiteMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { count: 0, showModal: false, showConfirmDeleteModal: false, lgImg: "" };
  }
  cardTextClicked = (e) => {
    this.setState((prevState, { count }) => ({
      count: prevState.count + 1
    }));

    if (e.target.tagName === "IMG" || e.target.tagName === "img") {
      // console.log(`Clicked on an image: ${e.target.src}`);
      let newUrl = e.target.src;
      newUrl = newUrl.replace(/medium/gi, "original")
      // console.log(`NEW URL: ${newUrl}`);
      this.setState({ showModal: true, lgImg: newUrl});
    }
  };

  toggle = () => {
    this.setState((prevState, { modal }) => ({
      showModal: !this.state.showModal
    }));
  };

  showDeleteModal = () => {
    this.setState((prevState) => ({
      showConfirmDeleteModal: true
    }));
  }
  hideDeleteModal = () => {
    this.setState((prevState) => ({
      showConfirmDeleteModal: false
    }));
  }
  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      showConfirmDeleteModal: !this.state.showConfirmDeleteModal
    }));
  };

  editSiteMessage = (siteMessage) => {
    console.log("SiteMessage: editSiteMessage: ", this.props);
    // this.props.setCurrentPost(post);
    // Clear the current post
    this.props.history.push(`/editsitemessage/${siteMessage.id}`, {siteMessage: siteMessage});
  }

  deleteThisSiteMessage = (siteMessage) => {
    console.log("DELETING SITE MESSAGE")
    this.props.deleteSiteMessage(siteMessage);
    setTimeout(() => {
      this.props.history.push('/mysitemessages');
    }, 100)
  }

  renderEditButton = (siteMessage) => {
    return <FontAwesomeIcon 
             id={siteMessage.id}
             onClick={() => {this.editSiteMessage(siteMessage)}}
             size="2x" className={classes.EditIcon} 
             icon={faPenSquare} />

    // return <Button id={siteMessage.id} onClick={() => {}} className={classes.EditButton}>Edit Message</Button>;
  }

  // XXX: Make this show for the post owner???  Just admin???
  renderDeleteButton = (siteMessage) => {
    if (this.props.okToEdit && siteMessage.is_draft === true) {
      return <FontAwesomeIcon 
             id={siteMessage.id}
             onClick={this.showDeleteModal}
             size="2x" className={classes.DeleteIcon} 
             icon={faTrash} />
    } else {
      return null;
    }
  }

  cloneSiteMessageLocal = async (messageId) => {
    try {
      const result = await axios.post(`/api_v1/site_messages/${messageId}/clone_message`, {},
                    {
                      _method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Token ${this.props.auth.token}`,
                        'X-CSRF-Token': this.props.auth.csrf_token
                      }
                    })
      // console.log(`Cloned SiteMessage: new msg: `, result.data)
      this.props.history.replace('/mysitemessages')
    } catch (e) {
      alert(`Error trying to clone the message.  Try again.  If this persists contact the site administrator.`)
    }
  }
  renderCloneButton = (siteMessage) => {
    if (this.props.auth.app_admin && !siteMessage.is_draft) {
      return <FontAwesomeIcon 
              id={"cloneSM"}
              onClick={() => {
                let cloneMessage = window.confirm(`Do you want to clone this message?`);
                if (cloneMessage) {          
                  this.cloneSiteMessageLocal(siteMessage.id)
                }
              }}
              size="2x" className={classes.EditIcon}
              style={{float: 'right', marginRight: '5px'}}
              title='Clone Post'
              icon={faClone} />
    }
    return null;
  }

  okToEdit = (siteMessage) => {
    if (this.props.okToEdit && siteMessage.is_draft) {
      return true;
    } else {
      return false;
    }
  }

  renderDeleteSpinner = () => {
    if (this.props.siteMessages.working) {
      return <Spinner />;
    }
    // if (this.waitingForOp) {
    //   this.waitingForOp = false;
    //   this.props.history.push('/mysitemessages');
    // }
    return null;
  }
  renderSiteMessage(siteMessage) {
    let siteMessageBody = filterHtmlTags(siteMessage.body);

    // console.log("[Comments]", post.comments);
    return (
      <div>

        <Card key={siteMessage.id} xs={6} className={[classes.Card, 'post-card border'].join(' ')} >
          <Card.Header className={classes.SiteMessageHeader}>
            <Row>
              <Col xs={9} md={10}>{siteMessage.subject}</Col>
              <Col xs={3} md={2}>
                <div style={{display: 'inline-block', float: 'right', marginRight: '5px'}}>
                  {this.okToEdit(siteMessage) ? this.renderEditButton(siteMessage) : null} 
                  <div className={classes.DeleteIconContainer}>
                    {this.okToEdit(siteMessage) ? this.renderDeleteButton(siteMessage) : null}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className={classes.SiteMessageHeaderAttributed}>
              <Col xs={10} sm={10}>by {siteMessage.creator} on {formattedDate(siteMessage.updated_at)}</Col>
              <Col xs={2} sm={2}>
                {this.renderCloneButton(siteMessage)}
              </Col>
            </Row>
          </Card.Header>

          <Card.Text
            onClick={this.cardTextClicked}
            className={[classes.SiteMessageBody, classes.SiteMessageContainer].join(' ')}
          >
            { parse(siteMessageBody) }
          </Card.Text>
        </Card>

        <Modal 
          show={this.state.showModal}
          onHide={this.toggle}
        >
          <Modal.Body>
            <Image src={this.state.lgImg} className="img-fluid" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-warning" className="btn-primary" onClick={this.toggle.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showConfirmDeleteModal}
          // onHide={this.state.showConfirmDeleteModal}
        >
          <Modal.Body>
            <div>Are you sure you want to delete this site message?&nbsp;&nbsp;<br />
            <Button size="sm"
              onClick={() => {this.deleteThisSiteMessage(siteMessage)}}>YES</Button>&nbsp;&nbsp;
            <Button size="sm" onClick={this.hideDeleteModal}>NO</Button>
            </div>
            {this.renderDeleteSpinner()}
          </Modal.Body>
        </Modal>

      </div>
    );
  }

  render() {
    const { siteMessage } = this.props;
    return this.renderSiteMessage(siteMessage)
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls,
    siteMessages: state.siteMessages
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // deleteSiteMessage: (siteMessage) => dispatch(deleteSiteMessage(siteMessage))
    deleteSiteMessage: (siteMessage) => dispatch(deleteSiteMessage(siteMessage))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteMessage));