import React from 'react';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import classes from './Input.module.css';

const Input = (props) => {
  const { id, name } = props;

  const buildPrepend = (props) => {
    if (props.noPrepend) {
      return null;
    } else {
      return (
        <InputGroup.Prepend>
          <InputGroup.Text id={id}>{props.required ? '* ': ''}{props.label}</InputGroup.Text>
        </InputGroup.Prepend>  
      );
    };
  }

  const inputWithInputGroup = (props) => {
    return (
      <InputGroup>
        {buildPrepend(props)}
        <Form.Control
          placeholder={props.placeholder}
          as={props.as ? props.as : 'input'}
          type={props.type ? props.type : 'text'}
          required={props.required}
          value={props.value}
          rows={props.rows ? props.rows : 1}
          name={name}
          multiple={(props.multiple ? props.multiple : false)}
          onClick={(props.onClick ? props.onClick : () => {})}
          onChange={props.changed}
          onBlur={props.blur}
          isInvalid={props.touched && props.errors}
          isValid={props.showValid && props.touched && !props.errors}
          disabled={props.disabled ? props.disabled : false}
          onKeyDown={props.onKeyDown ? props.onKeyDown : () => {}}
          style={(props.style ? props.style : {} )}
          className={(props.CN ? props.CN : {})}
          onKeyPress={props.onKeyPress ? props.onKeyPress : () => {}}
        >
          {props.children}
        </Form.Control>
        <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
          {props.errors}
        </Form.Control.Feedback>
      </InputGroup>
    )
  }
  const inputWithoutInputGroup = (props) => {
    return (
      <React.Fragment>
        {buildPrepend(props)}
        <Form.Control
          placeholder={props.placeholder}
          as={props.as ? props.as : 'input'}
          type={props.type ? props.type : 'text'}
          required={props.required}
          value={props.value}
          rows={props.rows ? props.rows : 1}
          name={name}
          onClick={(props.onClick ? props.onClick : () => {})}
          onChange={props.changed}
          onBlur={props.blur}
          isInvalid={props.touched && props.errors}
          isValid={props.showValid && props.touched && !props.errors}
          disabled={props.disabled ? props.disabled : false}
          onKeyDown={props.onKeyDown ? props.onKeyDown : () => {}}
          style={(props.style ? props.style : {} )}
          className={(props.CN ? props.CN : {})}
          onKeyPress={props.preventEnter ? props.preventEnter : () => {}}
        />
        <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
          {props.errors}
        </Form.Control.Feedback>
      </React.Fragment>
    )
  }
  let retComponent = inputWithInputGroup(props);
  if (props.noInputGroup) {
    retComponent = inputWithoutInputGroup(props);
  }
  return retComponent;
};

export default Input;
