import React, {useState} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faCalendarAlt, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

import { useSelector } from 'react-redux';

import moment from 'moment';

import classes from './CalendarEvent/CalendarEvent.module.css';

const CalendarEventControls = (props) => {
  const history = useHistory();
  const [currentActivity, setCurrentActivity] = useState(-1)
  const calendarEvents = useSelector(state => state.calendarEvents)

  // TODO: Add better filtering for asset types.  See AssetCalendarComponent to get assetTypes

  // TODO: MAYBE better to have an option to show assets or something?
  
  const activityTypeChanged = (event) => {
    const activityIx = calendarEvents.eventMetadata.activities.findIndex(activity => {
      if (activity.id === Number(event.target.value)) {
        return true;
      } else {
        return false;
      }
    })
    if (activityIx === -1) {
      // alert('chose select type...')
      setCurrentActivity(0)
      props.onSelect && props.onSelect(null);
    } else {
      setCurrentActivity(event.target.value)
      props.onSelect && props.onSelect(calendarEvents.eventMetadata.activities[activityIx])
    }
  }

  const renderActivities = () => {
    if (calendarEvents && !calendarEvents.eventMetadata) {
      return null;
    }

    const activities = calendarEvents.eventMetadata.activities.map(activity => {
      // debugger
      if (calendarEvents.filterEventTypeList.includes(activity.name)) {
        return (
          <option key={activity.id} value={activity.id}>
            {/* <div className={classes.ActivityIcon}>
              <Image src={activity.thumbnail} height="30" width="30" thumbnail />
            </div> */}
            {activity.name}
          </option>
        );
      } else {
        return null;
      }
    });
    activities.unshift(<option key={0} value={0}>[Show All Event Types]</option>)

    return (
      <Form.Control style={{fontSize: '1.2em', marginLeft: '4px'}} id="activitySelect" as="select"
        onChange={(props.onActivityTypeChange ? props.onActivityTypeChange : activityTypeChanged)} 
        defaultValue={currentActivity}
      >
        {activities}
      </Form.Control>
    )
    // return activities;
  }

  const renderReverseBias = () => { 
    if (!props.onRenderBack) {
      return null;
    }
    const now = new Date()
    const monthsBack = [
      {months: 0, label: `${moment(now).format("MM/DD")} forward`}, 
      {months: 6, label: "6 months back"},
      {months: 12, label: "1 year back"},
      {months: 24, label: "2 years back"},
      {months: 36, label: "3 years back"},
      {months: 48, label: "4 years back"}];

    let initVal = 0;
    const months = monthsBack.map((nm, ix) => {
      if (nm.months === Number(props.beginMonthBias)) {
        initVal = Number(props.beginMonthBias);
      }
      return (
        <option key={`month_${nm.months}`} value={nm.months}>{nm.label}</option>
      )
    })

    return (
      <Col xs={4} sm={2}>
        <Form.Control style={{fontSize: '1.2em', marginLeft: '4px'}} id="activitySelect" as="select" onChange={(event) => props.onRenderBack(event.target.value)} defaultValue={`${initVal}`}>
          {months}
        </Form.Control>
      </Col>
    )
  }

  //style={{...props.rowStyle}}
  return (
    <Row style={{...props.rowStyle}}>
      <Col xs={4} sm={3}>{renderActivities()}</Col>
      {renderReverseBias()}
      <Col xs={2} sm={1}>
        <FontAwesomeIcon 
          id={"eventCalendar"}
          onClick={(e) => {
            e.stopPropagation();
            history.push(props.calendarViewNext);
          }}
          size="2x" className={classes.NewCalendarEventIcon}
          // style={{float: 'right', marginRight: '5px'}}
          title={(props.calendarViewNext === 'calendar_view' ? 'Event Calendar':"Event List")}
          icon={(props.calendarViewNext === 'calendar_view' ? faCalendarAlt : faCalendarDay)}
        />
      </Col>
      <Col xs={2} sm={1}>
        <FontAwesomeIcon 
          id={"newEvent"}
          onClick={(e) => {
            e.stopPropagation();
            history.push('/newevent');
          }}
          size="2x" className={classes.NewCalendarEventIcon}
          // style={{float: 'right', marginRight: '5px'}}
          title='New Event'
          icon={faCalendarPlus}
        />
      </Col>
    </Row>
  );
}

export default CalendarEventControls;