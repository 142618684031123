import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  CREATE_ALBUM_START,
  CREATE_ALBUM_SUCCESS,
  CREATE_ALBUM_FAILED,

  UPDATE_ALBUM_START,
  UPDATE_ALBUM_SUCCESS,
  UPDATE_ALBUM_FAILED,

  ADD_PHOTOS_START,
  ADD_PHOTOS_SUCCESS,
  ADD_PHOTOS_FAILED,

  DELETE_PHOTO_START,
  DELETE_PHOTO_SUCCESS,
  DELETE_PHOTO_FAILED,

  GET_ALL_PHOTOS_START,
  GET_ALL_PHOTOS_SUCCESS,
  GET_ALL_PHOTOS_FAILED,
  SET_CURRENT_ALBUM,
  GET_ALBUM_SUCCESS,

  LIKE_ALBUM_START,
  LIKE_ALBUM_SUCCESS,
  LIKE_ALBUM_FAILED,

  LIKE_PHOTO_START,
  LIKE_PHOTO_SUCCESS,
  LIKE_PHOTO_FAILED
} from '../actions/photoActions';
import { updateObject } from '../../utils/utils';

const INITIAL_STATE = {
  currentAlbum: {
    // XXX: fill in attributes.
    id: null,
    name: '',
    description: '',
    published: false,
    allow_all_to_contribute: false,
    creator: '',
    creator_id: 1,
    created_at: '',
    neighborhood_id: 2,
    site_definition_id: 2,
    likes: [],
    photos: []
  },
  albums: [],
  error: '',
  working: false
}

const getAlbumIndex = (state, album) => {
  const aix = state.albums.findIndex((a) => {
    if (a.id === album.id) {
      return true;
    } else {
      return false;
    }
  })
  return aix;
}

const reducer = (state = INITIAL_STATE, action) => {
  console.log(`photoReducer: action: ${action.type}`);
  let ix = -1;
  let updAlbums = null;

  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    case CREATE_ALBUM_START:
      return updateObject(state, {working: true })

    case CREATE_ALBUM_SUCCESS:
      updAlbums = [...state.albums];
      updAlbums.push({...action.payload.album});
      let updatedObj = updateObject(state, {working: false, currentAlbum: {...action.payload.album}, albums: updAlbums})
      return updatedObj;

    case CREATE_ALBUM_FAILED:
      return updateObject(state, {working: false, error: `Error creating album.  Error: ${action.payload.error}`})

    case UPDATE_ALBUM_START:
      return updateObject(state, {working: true })

    case UPDATE_ALBUM_SUCCESS:
      return updateObject(state, {working: false, currentAlbum: action.payload.album})

    case UPDATE_ALBUM_FAILED:
      return updateObject(state, {working: false, error: `Error updating album.  Error: ${action.payload.error}`})

    case GET_ALBUM_SUCCESS:
      // XXX: Get dup the albums.
      // debugger;
      const album = action.payload.album;
      let updAlbums2 = [...state.albums];
      let albumIx = updAlbums2.findIndex(a => a.id === album.id)
      updAlbums2[albumIx] = {...album};
      return updateObject(state, {error: '', albums: updAlbums2, currentAlbum: album});

      case ADD_PHOTOS_START:
        break;
      case ADD_PHOTOS_SUCCESS:
        break;
      case ADD_PHOTOS_FAILED:
        break;
  
      case DELETE_PHOTO_START:
        return updateObject(state, {working: true })
      case DELETE_PHOTO_SUCCESS:
        return updateObject(state, {working: false})
      case DELETE_PHOTO_FAILED:
        return updateObject(state, { working: false, error: action.payload.error });
  
      case GET_ALL_PHOTOS_START:
        return updateObject(state, { working: true });

    case GET_ALL_PHOTOS_SUCCESS:
      const albums = [...action.payload.albums];
      let defaultAlbum = albums[0];
      if (action.payload.defaultAlbumId > 0) {
        albums.forEach(album => {
          if (album.id === action.payload.defaultAlbumId) {
            defaultAlbum = album;
          }
        })
      }
      return updateObject(state, { working: false, albums: albums, currentAlbum: defaultAlbum, error: '' });

    case GET_ALL_PHOTOS_FAILED:
      return updateObject(state, { working: false, error: action.payload.error });

    case SET_CURRENT_ALBUM:
      return updateObject(state, { currentAlbum: action.payload.currentAlbum })

    case LIKE_ALBUM_START:
    case LIKE_PHOTO_START:
      return state;

    case LIKE_ALBUM_SUCCESS:
      ix = getAlbumIndex(state, action.payload.album);
      updAlbums = [...state.albums];
      updAlbums[ix].likes = [...action.payload.likes];
      let updObj = updateObject(state, {currentAlbum:  {...state.currentAlbum, likes: [...action.payload.likes]}, albums: updAlbums})
      return updObj;

    case LIKE_PHOTO_SUCCESS:
      // Get the index of the updated photo.
      let photoIx = action.payload.album.photos.findIndex(photo => {
        if (photo.id === action.payload.photo.id) {
          return true;
        } else {
          return false;
        }
      });
      if (photoIx === -1) {
        // TODO: Error.  Should handle
        return state;
      }
      let photos = [...action.payload.album.photos];
      // update the specific photo's likes
      photos[photoIx].likes = [...action.payload.likes]

      // Update the album
      ix = getAlbumIndex(state, action.payload.album);
      updAlbums = [...state.albums];
      updAlbums[ix].photos = [...photos];

      return updateObject(state, {currentAlbum: {...state.currentAlbum, photos: photos}, albums: updAlbums})

    default:
      return state;
  }
}

export default reducer;
