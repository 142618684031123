import React from 'react';

import Help from '../../Help/Help';

const SiteMessageHelp = ({title}) => {

  const helpBody = [
    {
      "name": "Subject",
      "desc": "Short, 1 line description about your message."
    },
    {
      "name": "Publish?",
      "desc": `IF set to YES, the site message will be live on the site when saved or updated and email notifications will be sent.  IF set to NO, 
               the site message will be saved in your drafts so it can be worked on, completed, or deleted at a later date.`
    },
    {
      "name": "Message",
      "desc": `This will contain your site message.  IF you decide to paste content in from another application like MS Word, Excel, etc, the application 
              will detect this and cleanup the content so that it's formatted properly for the web and email. The application will warn you that 
              this was done and give you a chance to correct / reformat anything that might not look the way you wanted it to before it's posted
              or saved.`
    },
    {
      "name": "Save and Send Test Message",
      "desc": `This will save the curremt site message as a DRAFT and send a test email message to you and ONLY you so you can see how this message will
              appear in email`
    },
    {
      "name": "Save / Update...",
      "desc": `If the 'Save Message' or 'Update Message' button is visible the post will be saved to your drafts so you can work on this at a later
               date.  If the button says 'Save / Post Message' or 'Update / Post Message', the site message will be saved, posted live on the site, and
               email notifications will be sent out.  The setting of the 'Publish?' switch (See above) will dictate this behavior.`
    },
    {
      "name": "Cancel",
      "desc": "If you click the Cancel button any changes you made to the form will not be saved."
    }
  ];
  return (
    <Help 
      title={title}
      body={helpBody}
      iconStyle={{float: 'none', display: 'inline-block', marginLeft: '4px', marginTop: '4px', fontSize: '0.4em'}}
    />
  )
}

export default SiteMessageHelp;
