import { updateObject } from '../../utils/utils';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  GET_EMAIL_LISTS_START,
  GET_EMAIL_LISTS_SUCCESS,
  GET_EMAIL_LISTS_FAILED,

  GET_NEIGHBORHOOD_USERS_START,
  GET_NEIGHBORHOOD_USERS_SUCCESS,
  GET_NEIGHBORHOOD_USERS_FAILED,

  CHANGE_MEMBERS_START,
  CHANGE_MEMBERS_SUCCESS,
  CHANGE_MEMBERS_FAILED,

  CREATE_EMAIL_LIST_START,
  CREATE_EMAIL_LIST_SUCCESS,
  CREATE_EMAIL_LIST_FAILED,

  CLEAR_CREATING_FLAG,

  UPDATE_EMAIL_LIST_START,
  UPDATE_EMAIL_LIST_SUCCESS,
  UPDATE_EMAIL_LIST_FAILED,

  CREATE_LIST_MESSAGE_START,
  CREATE_LIST_MESSAGE_SUCCESS,
  CREATE_LIST_MESSAGE_FAILED,

  UPDATE_LIST_MESSAGE_START,
  UPDATE_LIST_MESSAGE_SUCCESS,
  UPDATE_LIST_MESSAGE_FAILED,
  
  DELETE_LIST_MESSAGE_START,
  DELETE_LIST_MESSAGE_SUCCESS,
  DELETE_LIST_MESSAGE_FAILED,

  SEND_TEST_MESSAGE_START,
  SEND_TEST_MESSAGE_SUCCESS,
  SEND_TEST_MESSAGE_FAILED,

  DELETE_EMAIL_LIST_START,
  DELETE_EMAIL_LIST_SUCCESS,
  DELETE_EMAIL_LIST_FAILED
} from '../actions/emailListsActions';

export const TEST_MESSAGE_INIT = 0;
export const TEST_MESSAGE_SENDING = 1;
export const TEST_MESSAGE_SENT = 2;
export const TEST_MESSAGE_ERROR = 3;

const INITIAL_STATE = {
  fetchingLists: false,
  updatingList: false,
  finishedCreating: false,
  testMessageState: TEST_MESSAGE_INIT,
  email_lists: [],
  fetchingUsers: false,
  neighborhood_users: [],
  error: ''
};

const updateEmailList = (state, action) => {
  // debugger;
  const emailList = action.payload.email_list;
  let updLists = [...state.email_lists];
  let emailListIx = updLists.findIndex(a => a.id === emailList.id)
  updLists[emailListIx] = {...emailList};

  return updateObject(state, { updatingList: false, email_lists: [...updLists]});
}

const reducer = (state = INITIAL_STATE, action) => {
  // console.log(`[postReducers]: ${action.type}`, action.payload);
  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    case GET_EMAIL_LISTS_START:
      return updateObject(state, { fetchingLists: true });

    case GET_EMAIL_LISTS_SUCCESS:
      return updateObject(state, { fetchingLists: false, email_lists: [...action.payload.email_lists]});

    case GET_EMAIL_LISTS_FAILED:
      return updateObject(state, { fetchingLists: false, error: action.payload.error});

    case GET_NEIGHBORHOOD_USERS_START:
      return updateObject(state, { fetchingUsers: true });

    case GET_NEIGHBORHOOD_USERS_SUCCESS:
      return updateObject(state, { fetchingUsers: false, neighborhood_users: [...action.payload.neighborhood_users]});

    case GET_NEIGHBORHOOD_USERS_FAILED:
      return updateObject(state, { fetchingUsers: false, error: action.payload.error});
    
    case CHANGE_MEMBERS_START:
    case CREATE_LIST_MESSAGE_START:
    case UPDATE_LIST_MESSAGE_START:
    case CREATE_EMAIL_LIST_START:
    case UPDATE_EMAIL_LIST_START:
    case DELETE_LIST_MESSAGE_START:
    case DELETE_EMAIL_LIST_START:
      return updateObject(state, { updatingList: true });

    case DELETE_EMAIL_LIST_SUCCESS:
      // TODO: remove email list frmo array
      let updEmailLists = [...state.email_lists];
      const listIx = updEmailLists.findIndex((el) => action.payload.email_list_id === el.id);
      if (listIx >= 0) {
        updEmailLists.splice(listIx, 1);
      }
      return updateObject(state, { updatingList: false, email_lists: updEmailLists });

    case CREATE_EMAIL_LIST_SUCCESS:
      // Prepend the new email list...
      return updateObject(state, {updatingList: false, finishedCreating: true, email_lists: [action.payload.email_list, state.email_lists]});

    case CLEAR_CREATING_FLAG:
      return updateObject(state, {finishedCreating: false});
    
    case CHANGE_MEMBERS_SUCCESS:
    case UPDATE_EMAIL_LIST_SUCCESS:
    case CREATE_LIST_MESSAGE_SUCCESS:
    case UPDATE_LIST_MESSAGE_SUCCESS:
    case DELETE_LIST_MESSAGE_SUCCESS:
      return updateEmailList(state, action);

    case CHANGE_MEMBERS_FAILED:
    case CREATE_EMAIL_LIST_FAILED:
    case UPDATE_EMAIL_LIST_FAILED:
    case CREATE_LIST_MESSAGE_FAILED:
    case UPDATE_LIST_MESSAGE_FAILED:
    case DELETE_LIST_MESSAGE_FAILED:
    case DELETE_EMAIL_LIST_FAILED:
      return updateObject(state, { updatingList: false, error: action.payload.error });

    case SEND_TEST_MESSAGE_START:
      return updateObject(state, { testMessageState: TEST_MESSAGE_SENDING });

    case SEND_TEST_MESSAGE_SUCCESS:
      return updateObject(state, { testMessageState: TEST_MESSAGE_INIT }); // Could use TEST_MESSAGE_SENT for messaging...
  
    case SEND_TEST_MESSAGE_FAILED:
      return updateObject(state, { testMessageState: TEST_MESSAGE_ERROR, error: action.payload.error });
      
    default:
      return state;
  }
}

export default reducer;
