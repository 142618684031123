import React, { useState } from "react";

import { toArray } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoVideo, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import classes from './PhotoUploadButton.module.css';

const PhotoUploadButton = (props) => {
  const [image, setImage] = useState({ preview: "", raw: [] });

  const handleChange = e => {
    if (e.target.files.length) {
      // alert('setting image')
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files
      });
      if (props.uploadImages) {
        const imgArray = [...toArray(e.target.files)];
        props.uploadImages(imgArray, () => {setImage({ preview: "", raw: [] })})
      }
    }
  };

  const removeFileFromUpload = file => {
    let updImages = [...image.raw];
    let imgIx = updImages.findIndex((f) => f.name === file.name);
    // debugger;
    updImages.splice(imgIx, 1);
    setImage({
      preview: image.preview,
      raw: updImages
    })
  }
  const clicked = (e) => {
    // XXX: Let the next handler take this.  That'll be the handleChange
    // e.preventDefault();
    // alert('clicked')
  }

  /*
   * XXX: This method was used to show files being uploaded. I changed this to
   * show the images in the upload modal.
   */
  const renderPreviewImages = (pictures) => {
    if (pictures.length === 0) {
      return null;
    }
    const imgArray = toArray(pictures);
    const thumbs = imgArray.map((file) => {
      const imgPreview = URL.createObjectURL(file);
      return (
        <Col xs={3} sm={2} key={file.name} className={classes.Thumbnail}>
          <FontAwesomeIcon onClick={() => {removeFileFromUpload(file)}} className={classes.DeleteIcon} icon={faTimesCircle} />
          <Image src={imgPreview} alt={file.name} width="100" height="100" thumbnail />
        </Col>
      )
    })
    return (
      <Row className={classes.ThumbnailRow}>
        {thumbs}
      </Row>
    );
  }
  return (
    <div className={classes.UploadContainer}>
      <label htmlFor="upload-button">
        <div className={classes.UploadIconContainer}>
          {props.title && image.raw.length === 0 ?
            <h5 className={[classes.UploadIconContainer, "text-center"].join(' ')}>{props.title}</h5>
            : null}
          <FontAwesomeIcon
            onClick={ (e) => {clicked(e)} }
            icon={faPhotoVideo}
            size="2x"
            className={classes.Icon}
          />
          {/* {image.raw.length > 0 ?
            <h5 className={[classes.UploadIconContainer, "text-center"].join(' ')}>The following images will be uploaded</h5>
            : null} */}
        </div>
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        multiple={props.single ? false:true}
        onChange={handleChange}
        accept="image/jpg, image/jpeg, image/gif, image/png"
      />
      {/* {renderPreviewImages(image.raw)} */}
    </div>
  );
}

export default PhotoUploadButton;
