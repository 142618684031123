import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { useSelector } from 'react-redux';

import Input from '../UI/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import SearchButton from '../SearchButton/SearchButton';
import classes from './PropertyContactForm.module.css';
import { capitalizeString } from '../../utils/utils';

const PropertyContactForm = (props) => {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const auth = useSelector(state => state.auth);
  const [userFilterString, setUserFilterString] = useState("");
  const [userAssocText, setUserAssocText] = useState("");
  /*
    # t.integer :address_list_id, null: false

    # t.string  :first_name
    # t.string  :last_name
    # t.boolean :is_primary, default: true
    # t.boolean :is_billing, default: true
    # t.string  :email
    # t.string  :phone
    # t.string  :note

    # t.boolean :whe_is_primary, default: false
    # t.string  :address_1
    # t.string  :address_2
    # t.string  :town
    # t.string  :state
    # t.string  :zipcode
  */
  const createSchema = Yup.object({
    address_list_id: Yup.number()
      .required("Must associate with an existing property address."),
    first_name: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(80, 'Must be 32 characters or less')
      .required('Required'),
    last_name: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .max(80, 'Must be 32 characters or less')
      .required('Required'),
    email: Yup.string()
      .email("Valid email address is required"),
      // .required('Required'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid'),
      // .required('Required'),
    note: Yup.string()
      .min(0, 'Must be 3 characters or more')
      .max(512, 'Must be 32 characters or less'),
    is_billing: Yup.boolean()
      .required('Must state if billing contact or not'),
    is_primary: Yup.boolean()
      .required('Must state if primary contact or not'),
    is_owner: Yup.boolean()
      .required('Specifies if contact is an owner or just a contact'),
    is_admin: Yup.boolean()
      .required('Specifies if contact is an administrator for this property info'),
    whe_is_primary: Yup.boolean()
      .required("Is WHE your primary address?"),
    address_1: Yup.string()
      .max(128, "Needs to be a valid street / apartment address")
      .required("Must specify home address"),
    address_2: Yup.string()
      .max(128, "Needs to be a valid street / apartment address"),
    town: Yup.string()
      .max(128, "Needs to be a valid town")
      .required("Must specify town"),
    state: Yup.string()
      .max(128, "Needs to be a valid state"),
    zipcode: Yup.string()
      .length(5)
      .matches(/^[0-9]{5}/)
      .required()  
    })

  const formik = useFormik({
    initialValues: {
      address_list_id: props.property_contact.address_list_id,
      id: props.property_contact.id,
      first_name: props.property_contact.first_name,
      last_name: props.property_contact.last_name,
      note: props.property_contact.note,
      is_primary: props.property_contact.is_primary,
      is_billing: props.property_contact.is_billing,
      is_owner: props.property_contact.is_owner,
      whe_is_primary: props.property_contact.whe_is_primary,
      email: props.property_contact.email,
      share_email: props.property_contact.share_email,
      phone: props.property_contact.phone,
      share_phone: props.property_contact.share_phone,
      address_1: props.property_contact.address_1,
      address_2: props.property_contact.address_2,
      town: props.property_contact.town,
      state: props.property_contact.state,
      zipcode: props.property_contact.zipcode,
      user_id: props.property_contact.user_id,
      is_admin: props.property_contact.is_admin
    },
    validateOnChange: true,
    // validateAfterSubmit: true,
    validationSchema: createSchema,
    onSubmit: (values) => {
      console.log(`PropertyContactForm: onSubmit`)
      const retVal = props.onSubmit(values)
      if (retVal === false) {
        alert("Did not save contact info.")
      }
      return retVal;
    },
  });

  useEffect(() => {
    setUserAssocText(buildCurrentUser())
  }, [])

  const handleRadioChange = (event) => {
    // alert(`handleRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
      } else {
        formik.setFieldValue(event.target.name, false);
      }
    }
  }

  const handleWheIsPrimary = (event) => {
    // TODO: need to reset value to AL address if value === false.
    if (event.target.value === 'true' || event.target.value === true) {
      const setWhAddr = window.confirm(`Do you want to set the mailing address to the Wild Harbour street address or leave as is (i.e. Po Box or other)?`);
      if (setWhAddr) {
        formik.setValues({...formik.values,
          address_1: props.al.wh_house_num + ' ' + props.al.wh_street,
          town: "North Falmouth",
          state: "MA",
          zipcode: '02556'
        })
      }
    } else {
      const setAlAddr = window.confirm(`Do you want to set the mailing address to the info set in the propery's info?`);
      if (setAlAddr) {
        formik.setValues({...formik.values,
          address_1: props.al.pri_street_addr,
          town: props.al.pri_town,
          state: props.pri_state,
          zipcode: props.pri_zipcode
        })
      }
    }
    handleRadioChange(event);
  }

  const handleIsAdmin = (event) => {
    if (event.target.value === 'true' || event.target.value === true) {
      const setAdmin = window.confirm(`If the contact is NOT associated with a Wild Harbour User account then the contact will not be able to administer the info. Do you want to keep this as admin?`);
      if (!setAdmin) {
        return false;
      }
    }
    handleRadioChange(event);
  }
  const renderTextBox = (fieldName, label, isReqd) => {
    return (
      <InputGroup className={classes.InputGroup}>
        <Input
          id={fieldName}
          type="text"
          placeholder={`Enter ${label}`}
          required={isReqd}
          label={label}
          value={formik.values[fieldName]}
          name={fieldName}
          changed={formik.handleChange}
          blur={formik.handleBlur}
          errors={formik.errors[fieldName]}
          touched={formik.touched[fieldName]}
        />
        {/* <InputGroup.Text>{label}</InputGroup.Text>
        <Form.Control
          as={'text'}
          id={fieldName}
          placeholder={`Enter ${label}`}
          label={label}
          value={formik.values[fieldName]}
          name={fieldName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors[fieldName]}
          touched={formik.touched[fieldName]}
        /> */}
      </InputGroup>
    )
  }

  const renderRadioButtons = (fieldName, label, changeHandler) => {
    const onChangeHandler = (changeHandler ? changeHandler : handleRadioChange );
    return (
      <InputGroup>
        {/* <div className={classes.RadioLabel}>{label}</div> */}
        <InputGroup.Prepend className={classes.InputGroup}>
          <InputGroup.Text id={`${fieldName}_lbl`}>{label}</InputGroup.Text>
        </InputGroup.Prepend>

        <Form.Label className={classes.RadioLabelYes} htmlFor={`${fieldName}_true`}>YES</Form.Label>
        <Form.Check
          disabled={formik.isSubmitting}
          type='radio'
          as='input'
          id={`${fieldName}_true`}
          className={classes.Radio}
          checked={formik.values[fieldName] === true}
          value={true}
          name={fieldName}
          onChange={onChangeHandler}
          isInvalid={formik.errors[fieldName]}
          isValid={!formik.errors[fieldName]}
        />
        <Form.Label className={classes.RadioLabelNo} htmlFor={`${fieldName}_false`}>NO</Form.Label>
        <Form.Check
          disabled={formik.isSubmitting}
          styles={[{marginLeft: 20}]}
          type='radio'
          as='input'
          id={`${fieldName}_false`}
          className={classes.Radio}
          checked={formik.values[fieldName] === false}
          value={false}
          name={fieldName}
          onChange={onChangeHandler}
          isInvalid={formik.errors[fieldName]}
          isValid={!formik.errors[fieldName]}
        />
      </InputGroup>
    )
  }

  const buildSearchString = (user) => {
    return `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()} `;
  }

  const updateSearchValue = (value) => {
    //TODO: do seatch and filter 
    setUserFilterString(value);
  }
  const selectEmailAddr = (e) => {
    // debugger
    // Find the user for the selected ID
    const ix = auth.site_users.findIndex(user => {
      if (Number(e.target.options[e.target.selectedIndex].value) === user.id) {
        return true;
      } else {
        return false;
      }
    });
    if (ix !== -1 || e.target.options[e.target.selectedIndex].value === "-1") {
      let u = null;
      if (ix !== -1) {
        u = auth.site_users[ix];
        setUserAssocText(<div className={classes.SiteUserMessage}>Associating {u.first_name} {u.last_name} with this property contact.</div>)
      } else {
        setUserAssocText(<div className={classes.SiteUserMessage}>No website user associated with this property contact.</div>)
      }
      // debugger
      let fValues = {...formik.values, user_id: (u ? u.id : null)};
      // TODO: Should I make it possible to populate all on edit?  I'm thinking that they can delete
      //      and recreate rather than complicate the UI.
      if (!props.edit) {
        fValues = {...fValues,
          email: (u ? u.email.toLowerCase() : ""), 
          phone: (u ? u.phone : ""),
          first_name: (u ? u.first_name : ""),
          last_name: (u ? u.last_name : "")
        }
      }
      formik.setValues(fValues)
    }
  }
  const renderEmailAddrInfo = () => {
    // debugger
    if (!auth.site_users || auth.site_users.length === 0) {
      return null;
    }

    const userItems = auth.site_users.map(user => {
      if (buildSearchString(user).toLowerCase().includes(userFilterString.toLowerCase())) {
        return (
          <option key={user.id} value={user.id}> {capitalizeString(user.last_name)}, {capitalizeString(user.first_name)} - {user.email}</option>
        );
      } else {
        return null;
      }
    });
    return [
      <option key={8675309} value={-1}>[No User]</option>,
      ...userItems
    ];
  }
  
  const buildCurrentUser = () => {
    if (props.property_contact.user_id === null) {
      return <div className={classes.SiteUserMessage}>[No website user currently associated with this contact]</div>
    }
    const ix = auth.site_users.findIndex(user => {
      if (props.property_contact.user_id === user.id) {
        return true;
      } else {
        return false;
      }
    });
    if (ix === -1) {
      return <div className={classes.SiteUserMessage}>[Invalid website user associated with this contact]</div>
    }
    return <div className={classes.SiteUserMessage}>{capitalizeString(auth.site_users[ix].first_name)} {capitalizeString(auth.site_users[ix].last_name)} associated with this site contact</div>;
  }
  const buildContactInfoHelper = () => {
    return (
      <InputGroup style={{marginBottom: '10px'}}>
        <SearchButton valueChanged={updateSearchValue} placeholder="Property Contact Helper" />
        <Form.Control
          id="email_addr"
          name="email_addr"
          label="User Email Addr"
          as="select"
          rows={1}
          multiple={true}
          // style={selectStyle}
          onChange={(e) => selectEmailAddr(e)}
        >
            {renderEmailAddrInfo()}
        </Form.Control>
      </InputGroup>
    )
  }

  const renderPropertyNoteField = () => {
    if (auth.app_admin) {
      return (
        <InputGroup>
          <InputGroup.Prepend className={classes.InputGroup}>
            <InputGroup.Text>Extra Info</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            id="note"
            as="textarea"
            placeholder={`Enter Note`}
            required={false}
            label="Note"
            value={formik.values['note']}
            name={'note'}
            onChange={formik.handleChange}
          />
        </InputGroup>
      )
    }
    return null;
  }
  return (
    <Row>
      <Col sm={12}>
        <Form
          onSubmit={formik.handleSubmit}
        >
          {userAssocText}
          {buildContactInfoHelper()}
          {renderTextBox("first_name", "First Name", true)}
          {renderTextBox("last_name", "Last Name", true)}
          {renderRadioButtons("is_primary", "Is Primary?", undefined)}
          {renderRadioButtons("is_billing", "Is Billing?", undefined)}
          {renderRadioButtons("is_owner", "Is Owner?", undefined)}
          {renderRadioButtons("is_admin", "Is Admin?", handleIsAdmin)}

          {renderTextBox("email", "Email", false)}
          {renderRadioButtons("share_email", "Share Email", undefined)}
          {renderTextBox("phone", "Phone", false)}
          {renderRadioButtons("share_phone", "Share Phone", undefined)}

          {renderRadioButtons("whe_is_primary", "Primary Address WH?", handleWheIsPrimary)}
          {renderTextBox("address_1", "Address 1", true)}
          {renderTextBox("address_2", "Address 2", false)}
          {renderTextBox("town", "Town", true)}
          {renderTextBox("state", "State", true)}
          {renderTextBox("zipcode", "Zipcode", true)}
          {renderPropertyNoteField()}
          <input type="hidden" name="user_id" value={(formik.values.user_id === null ? "" : formik.values.user_id)} />
          <InputGroup className={classes.InputGroup} style={{marginTop: '8px'}}>
            <InputGroup.Prepend>
            </InputGroup.Prepend>
            <Button size="sm" type="submit" style={{marginRight:'10px'}} >Save Contact</Button>
            <Button size="sm" type="submit" onClick={(e) => {props.cancel()}}>Cancel</Button>
          </InputGroup>

        </Form>
      </Col>
    </Row>
  )
};

export default PropertyContactForm;