import React, { useRef, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';

import Spinner from '../../Spinner/Spinner';

import PostForm from './PostForm';
import PostHelp from './PostHelp';

import MessageModal from '../../UI/MessageModal';

import { editPost, updatePost, clearPostError } from '../../../redux/actions/postActions';
import { setTitle } from '../../../redux/actions/controlsActions';

import classes from './PostForm.module.css';
import "./NewPost.css";

  let editPostCount = 0;

const EditPost = (props) => {
  const { postId } = useParams();
  const auth = useSelector(state => state.auth);
  const posts = useSelector(state => state.posts);
  const dispatch = useDispatch();
  const history = useHistory();
  const postToEdit = posts.currentPost; // Should be set after completes
  const updateInfo = useRef({});
  const showPostModal = useRef(false);

  useEffect(() => {
    // IF we got here with an invalid postId such as :postId redirect
    if (postId && isNaN(postId)) {
      console.log("Post id not valid...Redirecting to posts")
      history.replace('/posts')
    }

    dispatch(setTitle("Edit Post"));
    if (postId !== undefined && !isNaN(postId) && postToEdit.id === null) {
      console.log(`[EditPost] dispatching editPost.  count: ${++editPostCount}`);
      // Kickoff the edit to get the post form populated.
      dispatch(editPost(postId, auth));
    }

    return () => {
      console.log('[EditPost]: cleanup from useEffect')
    }
  }, [postToEdit, auth, dispatch, history, postId]);
  
  // Shoulda fetched post.
  // if (posts.working) {
  //   setFetchingPost(false);
  // }
  // const postToEdit = props.location.state.post;

  console.log(`[EditPost]: postId: ${postId}`)
  
  const handleFormSubmit = (values) => {
    if (values.send_test_message) {
      updateInfo.current = {
        opType: "update with test message",
        message: "Your post was updated and your test message was sent.  Check your inbox to verify it and see that it looks how you want it to before publishing it."
      }
    } else {
      updateInfo.current = {
        opType: "updated",
        message: "Your post has been updated and saved as a draft."
      }
    }
    if (values.published) {
      updateInfo.current = {
        opType: "post published",
        message: "Your post was published, messages are being sent, and it's live on the site"
      }
    }
    dispatch(updatePost(values))
  }

  const closePostMessageModal = (postId) => {
    showPostModal.current = false;
    dispatch(clearPostError()); // clears error and postsOpComplete state
    props.history.push(`/myposts/${postId}`)
  }

  const renderContent = () => {
    if (posts.working) {
      return <Spinner />;
    } else {
      if (posts.postsOpComplete) {
        showPostModal.current = true;
        // debugger;
        // TODO: post a modal showing either update complete or update / test message complete...
        // alert(updateInfo.current.message)
        return (
          <MessageModal
            closeCallback={() => { closePostMessageModal(postToEdit.id)}}
            show={showPostModal.current}
          >
            <div>
              {updateInfo.current.message}
            </div>
          </MessageModal>
        )
        // TODO: have the CLOSE action clear the postsOpComplete state.
        // return <Spinner title="Updating post..." />
      }

      return (
        <PostForm
          post={postToEdit}
          auth={auth}
          formSubmit={handleFormSubmit}
          edit
        />
      );
    }
  }

  const showTitleAndHelp = () => {
    if (showPostModal.current) {
      return (
        <React.Fragment>
          <div className={classes.Title}>
            Edit Post
          </div>
          <PostHelp title="Edit Post" />
        </React.Fragment>
      )
    }
    return null;
  }
  return (
    <div>
      <Col sm={10}>
        {showTitleAndHelp()}
      </Col>
      <Col sm={10}>
        {renderContent()}
      </Col>
    </div>
  );
}

export default EditPost;
