import axios from '../../utils/axios-whe';

import { SET_SELECTED_ADDR } from '../actions/mapActions'; 

export const GET_PROPERTIES_START = 'get_properties_start';
export const GET_PROPERTIES_SUCCESS = 'get_properties_success';
export const GET_PROPERTIES_FAILED = 'get_properties_failed';

export const SORT_PROPERTIES = 'sort_properties';

export const CREATE_PROPERTY_CONTACT_START = 'create_property_contact_start';
export const CREATE_PROPERTY_CONTACT_SUCCESS = 'create_property_contact_success';
export const CREATE_PROPERTY_CONTACT_FAILED = 'create_property_contact_failed';

export const UPDATE_PROPERTY_CONTACT_START = 'update_property_contact_start';
export const UPDATE_PROPERTY_CONTACT_SUCCESS = 'update_property_contact_success';
export const UPDATE_PROPERTY_CONTACT_FAILED = 'update_property_contact_failed';

export const DELETE_PROPERTY_CONTACT_START = 'delete_property_contact_start';
export const DELETE_PROPERTY_CONTACT_SUCCESS = 'delete_property_contact_success';
export const DELETE_PROPERTY_CONTACT_FAILED = 'delete_property_contact_failed';

export const UPDATE_PROPERTY_START = 'update_property_start';
export const UPDATE_PROPERTY_SUCCESS = 'update_property_succes';
export const UPDATE_PROPERTY_FAILED = 'update_property_failed';

export const GET_WHE_USERS_START = 'get_whe_users_start';
export const GET_WHE_USERS_SUCCESS = 'get_whe_users_success';
export const GET_WHE_USERS_FAILED = 'get_whe_users_failed';

export const MOVE_USER_START = 'move_user_start';
export const MOVE_USER_SUCCESS = 'move_user_success';
export const MOVE_USER_FAILED = 'move_user_failed';

const errorText = (error) => {
  let errText = error.message;
  if (error.response?.status === 404 && error.response?.data?.error) {
    errText = error.response.data.error;
  }
  return errText;
}

export const sortProperties = (sortCol) => {
  // Sory the list of properties / addresses based on the specified column
  return (dispatch) => {
    dispatch({type: SORT_PROPERTIES, payload: { sortCol: sortCol } });
  }
}

export const getProperties = () => {
  return async (dispatch, getState) => {
    const auth = getState().auth;

    dispatch({ type: GET_PROPERTIES_START });
    try {
      let response = await axios.get(`/api_v1/neighbors.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      if (response.status === 200) {
        const newAddrs = [];
        response.data.forEach(addr => {
          const num = parseInt(addr.wh_house_num)
          if (!isNaN(num) && num > 0) {
            newAddrs.push(addr);
          }
        });
        // console.log(`Addrs`, newAddrs);
        dispatch({ type: GET_PROPERTIES_SUCCESS, payload: {addrs: newAddrs} })
      } else {
        // ERROR: log it.  axios response Interceptor should handle it
        console.error("Error retrieving Neighbor data");
        dispatch({ type: GET_PROPERTIES_FAILED, payload: {error: "Error retrieving Neighbor data"}})
      }
    } catch (error) {
      console.error(`Error retrieving Neighbor data:  ${error}`);
      dispatch({ type: GET_PROPERTIES_FAILED, payload: {error: errorText(error)}})
    }
  }
}

export const createPropertyContact = ( propertyContact ) => {
  const formJson = {
    "property_contact": {...propertyContact }
  };

  console.log(`propertyContactActions: createPropertyContact}`)
  return async (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: CREATE_PROPERTY_CONTACT_START })
    try {
      let response = await axios.post(`/api_v1/property_contacts`, formJson, {
        _method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      console.log(response)
      let response2 = await axios.get(`/api_v1/property_contacts/${propertyContact.address_list_id}/get_address_list.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      dispatch({ type: CREATE_PROPERTY_CONTACT_SUCCESS, payload: response2.data })
      dispatch({type: SET_SELECTED_ADDR, payload: {addr: response2.data}})

    } catch(error) {
      console.log("ERROR: " + error);
      dispatch({ type: CREATE_PROPERTY_CONTACT_FAILED, payload: { payload: {error: errorText(error)} } });
    }
  }
}

export const updatePropertyContact = ( propertyContact ) => {
  const formJson = {
    "property_contact": {...propertyContact }
  };

  console.log(`propertyContactActions: updatePropertyContact}`)
  return async (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: UPDATE_PROPERTY_CONTACT_START })
    try {
      let response = await axios.patch(`/api_v1/property_contacts/${propertyContact.id}`, formJson, {
        _method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      response = await axios.get(`/api_v1/property_contacts/${propertyContact.address_list_id}/get_address_list.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      dispatch({ type: UPDATE_PROPERTY_CONTACT_SUCCESS, payload: response.data })
      dispatch({type: SET_SELECTED_ADDR, payload: {addr: response.data}})
    } catch (error) {
      console.log("ERROR: " + error);
      dispatch({ type: UPDATE_PROPERTY_CONTACT_FAILED, payload: { payload: {error: errorText(error)} } });
    }
  }
}

export const deletePropertyContact = ( propertyContact ) => {
  return async (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: DELETE_PROPERTY_CONTACT_START });
    try {
      let response = await axios.delete(`/api_v1/property_contacts/${propertyContact.id}`, {
        // method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      response = await axios.get(`/api_v1/property_contacts/${propertyContact.address_list_id}/get_address_list.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })

      dispatch({ type: DELETE_PROPERTY_CONTACT_SUCCESS, payload: response.data })
    } catch (error) {
      console.error(`ERROR deleting property contact ${propertyContact.id}, ERROR: ${error}`);
      dispatch({ type: DELETE_PROPERTY_CONTACT_FAILED, payload: {error: errorText(error)}});
    }
  }
}

export const updateProperty = (addr, values) => {
  const keys = Object.keys(values);
  let whAddr = {...addr};
  keys.map(key => whAddr[key] = values[key]);
  const formJson = {
    "address_list": {...values}
  };
  const url = `/api_v1/property_contacts/${whAddr.id}/update_address_list`;
  console.log(`updateProperty: Patching WHAddress with URL of ${url}`)
  return async (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({type: UPDATE_PROPERTY_START});
    try {
      let response = await axios.patch(url, formJson,
      {
        _method: 'patch',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      console.log(`updateProperty`, response.data);
      dispatch({type: UPDATE_PROPERTY_SUCCESS, payload: response.data})
    } catch (error) {
      console.error(`ERROR updating address info.  ${error}`)
      dispatch({ type: UPDATE_PROPERTY_FAILED, payload: { payload: {error: errorText(error)} } });
    }
  }
}

export const getWheUsers = () => {
  return async (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({type: GET_WHE_USERS_START});
    try {
      let response = await axios.get('/api_v1/whe_users.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      dispatch({ type: GET_WHE_USERS_SUCCESS, payload: {whe_users: response.data} })
    } catch (error) {
      dispatch({type: GET_WHE_USERS_FAILED, payload: errorText(error)})
    }
  }
}

export const moveUserToProperty = (userId, addrId) => {
  // 
  const url = `/api_v1/property_contacts/update_user_address/${userId}/${addrId}`;
  console.log(`moveUserToProperty: Moving user with URL of ${url}`)
  return async (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({type: MOVE_USER_START});
    try {
      let response = await axios.post(url, {},
      {
        _method: 'patch',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      console.log(`moveUserToProperty`, response.data);

      response = await axios.get('/api_v1/whe_users.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      dispatch({ type: MOVE_USER_SUCCESS, payload: {whe_users: response.data} })
// 
      // dispatch({type: MOVE_USER_SUCCESS, payload: response.data})
    } catch (error) {
      console.error(`ERROR updating address info.  ${error}`)
      dispatch({ type: MOVE_USER_FAILED, payload: { payload: {error: errorText(error)} } });
    }
  }
}