import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';

import axios from '../../utils/axios-whe';

import Spinner from '../Spinner/Spinner';

import classes from './MailgunEvents.module.css';
import { OPENED, FAILED, COMPLAINT } from './MailgunConstants';

const MailgunEventCounts = (props) => {
  const [events, setEvents] = useState([]);
  const [eventType, setEventType] = useState(FAILED);
  const [maxEvents, setMaxEvents] = useState(10)
  const [showCollapse, setShowCollapse] = useState(0)
  const [apiError, setApiError] = useState('');
  const auth = useSelector(state => state.auth);

  const eventTypes = [
    COMPLAINT,
    OPENED,
    FAILED
  ]

  // debugger
  useEffect(() => {
    setEvents([]); // Clear properties to activate Spinner
    setApiError('');
    // axios.get(`https://api.wildharbourestates.org/api_v1/mailgun_events.json`, {
    axios.get(`/api_v1/get_last_n_uniq_counts?event_type=opened&n=${maxEvents}&event_type=${eventType}`, {
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      if (response.status === 200) {
        const mgEvents = [...response.data];
        // const md = moment(response.data[0].created_at)
        // debugger
        setEvents(mgEvents);
      } else {
        // ERROR: log it.  axios response Interceptor should handle it
        console.error("Error retrieving mailgun event counts");
        setApiError("Error retrieving mailgun event counts")
      }
    })
    .catch(error => {
      // alert(`ERROR: ${error.message}`)
      // debugger
      console.error(`Error retrieving mailgun event counts:  ${error}`);
      setApiError(`Error retrieving mailgun event counts.  ERR: ${error.message}`)
    })
  }, [eventType, auth.csrf_token, auth.token, maxEvents])


  const showHideDetails = (me) => {
    console.log(`Showing event details for event`, me)
    if (showCollapse === me.event_content_type_id) {
      setShowCollapse(0);
    } else {
      setShowCollapse(me.event_content_type_id);
    }
  }

  const renderMailgunEventDetails = (mailgunEvent) => {
    if (showCollapse !== mailgunEvent.event_content_type_id) {
      return <div></div>;
    }
    console.log(`ShowCollapse: ${mailgunEvent.event_content_type_id}`, showCollapse === mailgunEvent.event_content_type_id)
    const renderedUsers = mailgunEvent.users.map(user => {
      return (
        <Row key={`user${user.id}`} className={classes.User}>
          <Col md={12}>{user.first_name} {user.last_name}, {user.email}</Col>
        </Row>
      )
    })
    // debugger
    // return <Row><Col>{mailgunEvent.event_content_type_id}</Col></Row>
    return (
      <div className={classes.UserList} style={{maxWidth: '50%', marginLeft: '10px'}}>
          {renderedUsers}
      </div>
    )
  }

  const shouldShowInner = (mailgunEvent) => {
    if (showCollapse === mailgunEvent.event_content_type_id) {
      console.log("SHOULD SHOW for ID: ", mailgunEvent.event_content_type_id)
      return true;
    } else {
      return false;
    }
  }
  const renderEvents = () => {
    return events.map((me, ix) => {
      return (
        <React.Fragment key={`ev-ix-${ix}`}>
          <Row className={classes.ListLine} onClick={() => showHideDetails(me)}>
            <Col xs={3} md={2}>{ix+1}: {me.event_type.toUpperCase()}</Col>
            <Col xs={4} md={3}>{me.name}</Col>
            <Col xs={2} md={2}>{me.count}</Col>
            <Col xs={3} md={2}>{me.event_content_type}</Col>
          </Row>
          <Collapse key={`dets${me.name}`} in={shouldShowInner(me)}>
            {renderMailgunEventDetails(me)}
            {/* <div>{me.event_content_type_id}</div> */}
          </Collapse>
        </React.Fragment>
      )
    })
  }

  const renderEventTypes = () => {
    return (
      <React.Fragment>
        <Form.Control style={{display: 'inline-block'}} id="eventType" as="select" onChange={(event) => {setEventType(event.target.value)}} value={eventType}>
          {eventTypes.map((ttype) => {
            return <option key={ttype} value={ttype}>{ttype.toUpperCase()}</option>
          })}
        </Form.Control>
      </React.Fragment>
    )
  }
  if (events.length === 0 && apiError === '') {
    return <Spinner />
  }

  const renderError = (error) => {
    return (
      <div style={{marginLeft: '10px'}}>
        <Row>
          <Col md={1} />
          <Col md={10}><h5>Error fetching Mailgun Event Counts</h5></Col>
          <Col md={1} />
        </Row>
        <Row>
          <Col md={1} />
          <Col md={10}><h6 style={{color: 'red'}}>{error}</h6></Col>
          <Col md={1} />
        </Row>
      </div>
    )
  }

  const renderMaxEventCount = () => {
    return (
      <React.Fragment>
        <Form.Control style={{display: 'inline-block'}} id="eventType" as="select" onChange={(event) => {setMaxEvents(event.target.value)}} value={maxEvents}>
          {Array(40).fill(0).map((_, i) => {
            return <option key={`max${i+1}`} value={i+1}>{i+1}</option>
          })}
        </Form.Control>
      </React.Fragment>
    )
  }

  if (apiError !== '') {
    return renderError(apiError)
  }

  return (
    <div style={{marginLeft: '10px'}}>
      <Row>
        <Col md={3}><h5>Mailgun Events:  Found {events.length} events</h5></Col>
        <Col md={1}>{renderMaxEventCount()}</Col>
        <Col md={3}>{renderEventTypes()}</Col>
      </Row>
      <Row>
        <Col xs={3} md={2} _style={{border: '1px solid black'}}>Event Type</Col>
        <Col xs={4} md={3}>Post Name</Col>
        <Col xs={2} md={2}>Count</Col>
        <Col xs={3} md={2}>Type</Col>
      </Row>
      {renderEvents()}
    </div>
  );
}

export default MailgunEventCounts;
