import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';

import Spinner from '../../Spinner/Spinner';

import { setTitle } from '../../../redux/actions/controlsActions';
import { createEvent } from '../../../redux/actions/calendarEventActions';

import classes from './EventForm.module.css';
import "./EventForm.css";
import EventForm from './EventForm';

  let eventDefaults = {
    id: null,
    title: '',
    description: '',
    created_by: -1,
    begin_date: new Date(),
    activity_id: -1,
    location_id: -1,
    canceled: false,
    site_definition_id: null,
    send_email: false,
    neighborhood_id: null,
    event_duration: 60,
    groups: []
  };

const NewEvent = (props) => {
  let waitingForCreateEvent = false;
  const auth = useSelector(state => state.auth);
  const calendarEvents = useSelector(state => state.calendarEvents);
  const history = useHistory();
  const dispatch = useDispatch();

  if (calendarEvents && !calendarEvents.eventMetadata) {
    history.replace('/calendar_events');
  }
  useEffect(() => {
    dispatch(setTitle("New Event"));
  }, [calendarEvents]);

  let [timeoutId, setTimeoutId] = useState(null);
  const startTimer = () => {
    return setTimeout(() => {
      if (calendarEvents.updatingEvents === false) {
        waitingForCreateEvent = false;
      }
      if (waitingForCreateEvent) {
        setTimeoutId(startTimer());
      } else {
        setTimeoutId(null);
        history.replace('/calendar_events')
      }
    }, 1000)
  };

  // handleFormSubmit receives the values from the form in a JS object
  // the postCallback to let the form know we're done processing.
  const handleFormSubmit = (values, postCallback) => {
    waitingForCreateEvent = true;
    // TODO: Make sure end_date has been added
    // debugger
    dispatch(createEvent(values));
    setTimeoutId(startTimer());
  }

  const renderContent = () => {
    if (timeoutId !== null || calendarEvents.updatingEvents) {
      return <Spinner title="CREATING EVENT..." />;
    } else {
      return (
        <EventForm 
          event={{...eventDefaults, groups: auth.user.groups, group_ids: [auth.user.groups[0].id]}}
          auth={auth}
          edit={false}
          sendNotifications={false}
          onSubmit={handleFormSubmit}
          title="New Event"
          cancel={() => {history.replace('/calendar_events')}}
        />
      );
    }
  }

  return (
    <div>
      <div className={classes.Title}>Create New Event</div>
      <Col sm={10} style={{paddingLeft: '0', marginLeft: '20px', marginRight: '0'}}>
        {renderContent()}
      </Col>
    </div>
  );
}

export default NewEvent;
