import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';


import { useFormik } from 'formik';
import * as Yup from 'yup';

import Input from '../../UI/Input';

import { registerNewUser } from '../../../redux/actions/authActions';
import Spinner from '../../Spinner/Spinner';

import classes from './Register.module.css';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object({
  email: Yup.string()
    .email("Valid email address is required")
    .required('Required'),
  first_name: Yup.string()
    .min(1, 'Must be 1 character or more')
    .max(32, 'Must be 32 characters or less')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Must be 2 character or more')
    .max(32, 'Must be 32 characters or less')
    .required('Required'),
  house_number: Yup.number()
    .min(1, "House number must be valid")
    .required('House number required.'),
  // street: Yup.string()
  //   .min(3, 'Must be 1 character or more')
  //   .max(64, 'Must be 32 characters or less')
  //   .required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  street_name: Yup.string()
    .test("len", "Must select a street.", val => {
      console.log("val ", val);
      return val !== "0";
    }),
  context_note: Yup.string()
    .test("len", "Must select relationship.", val => {
      console.log("val ", val);
      return val !== "0";
    })
});

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  house_number: '',
  street_name: '',
  phone: '',
  context_note: '0',
  town: "N. Falmouth",
  state: "MA",
  zipcode: "02556",
  password: "nsudnsud",
  password_confirmation: "nsudnsud"
};

/*
      <%= hidden_field_tag :nid, -1 %>
      <%= f.hidden_field :town, :value => "N. Falmouth" %>
      <%= f.hidden_field :state, :value => "MA" %>
      <%= f.hidden_field :zipcode, :value => "02556" %>

*/

const Register = (props) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  let updating = false;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    }
  });

  const handleFormSubmit = (values) => {
    let regValues = {...values, login: values.email, street: `${values.house_number} ${values.street_name}`};
    delete regValues.house_number;
    delete regValues.street_name;
    // alert(JSON.stringify(regValues))
    dispatch(registerNewUser(regValues));
  };

  const buildForm = () => {
    return (
      <Row>
        <Col sm={12} className={classes.AuthCol}>
          <div className={classes.SignupBox}>
            <h4 className={classes.FormTitle}>Signup</h4>
            <h5 style={{color: 'red', textAlign: 'center'}}>{auth.error}</h5>
            {props.location && props.location.state && props.location.state.error ? <p className={classes.FormError}>{props.location.state.error}</p>:null}

            <Form
              onSubmit={formik.handleSubmit}
              className={classes.Form}
            >
            <InputGroup className={classes.InputGroup}>
                <Input
                  id='email'
                  type="email"
                  placeholder="Your email address"
                  required
                  label="Email"
                  value={formik.values.email}
                  name='email'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.email}
                  touched={formik.touched.email}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='first_name'
                  type="text"
                  placeholder="Your first name"
                  required
                  label="First Name"
                  value={formik.values.first_name}
                  name='first_name'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.first_name}
                  touched={formik.touched.first_name}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='last_name'
                  type="text"
                  placeholder="Your last name"
                  required
                  label="Last Name"
                  value={formik.values.last_name}
                  name='last_name'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.last_name}
                  touched={formik.touched.last_name}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='phone'
                  type="text"
                  placeholder="Your phone number"
                  required
                  label="Phone"
                  value={formik.values.phone}
                  name='phone'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.phone}
                  touched={formik.touched.phone}
                  showValid
                />
              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <Input
                  id='house_number'
                  placeholder="Enter House Number"
                  type="number"
                  // required
                  label="House Number"
                  value={formik.values.house_number}
                  name='house_number'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.house_number}
                  touched={formik.touched.house_number}
                  showValid
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="context_note">Street</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="street_name"
                  name="street_name"
                  as="select" 
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.street_name}
                >
                  <option value="0">[Select]</option>
                  <option value="Alder Ln">Alder Ln</option>
                  <option value="Aspen Ln">Aspen Ln</option>
                  <option value="Birch Ln">Birch Ln</option>
                  <option value="Buckthorn Ln">Buckthorn Ln</option>
                  <option value="Inkberry Ln">Inkberry Ln</option>
                  <option value="Ironwood Ln">Ironwood Ln</option>
                  <option value="Redbud Ln">Redbud Ln</option>
                  <option value="Sassafras Ln">Sassafras Ln</option>
                </Form.Control>
              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="context_note">Relationship to owner?</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="context_note" name="context_note"
                  as="select" 
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.context_note}
                >
                  <option value="0">[Select]</option>
                  <option value="Owner">Owner</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Parent">Parent</option>
                  <option value="Child">Child</option>
                  <option value="Son/Daughter In-Law">Son/Daughter In-Law</option>
                  <option value="Friend">Friend</option>
                  <option value="Renter">Renter</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
                  {formik.errors.context_note}
                </Form.Control.Feedback>
              </InputGroup>

              <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup} />
                <Button type="submit" disabled={updating}>Sign Up!</Button>
              </InputGroup>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }

  if (auth.loading) {
    return <Spinner />;
  } else {
    // XXX: Check error and either redirect or show form again
    if (auth.registered) {
      return (
        <div className="Placeholder">
          You've registered with the email address {auth.userParams.email}.  A site approver
          will approve you within 24 hours or reject your registration.  Notification will
          be sent to this email address and will contain a link to complete the signup process
          if you're approved.
        </div>
      );
    } else {
      return buildForm();
    }
  }

}

export default Register;