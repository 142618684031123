import { updateObject } from '../../utils/utils';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  SET_CURRENT_POST,
  SET_POST_TOC,
  CLEAR_CURRENT_POST,
  SET_POSTS,
  EDIT_POST,
  SET_WORKING,
  SET_POST_ERROR,
  CLR_FETCHING_POST,
  CLEAR_POST_ERROR,

  GET_POST_START,
  GET_POST_SUCCESS,
  GET_POST_FAILED,

  CREATE_POST_START,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILED,

  UPDATE_POST_START,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_FAILED,

  SEND_TEST_MESSAGE_START,
  SEND_TEST_MESSAGE_SUCCESS,
  SEND_TEST_MESSAGE_FAILED,

  LIKE_POST_START,
  LIKE_POST_SUCCESS,
  LIKE_POST_FAILED,

  LIKE_COMMENT_START,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAILED
} from '../actions/postActions';

const INITIAL_STATE = {
  currentPost: {
    id: null,
    name: '',
    content: '',
    published: false,
    likes: [],
    comments: []
  },
  posts: [],
  postToc: [],
  error: '',
  working: false,
  postsOpComplete: false,
  fetchingPost: false
};

const reducer = (state = INITIAL_STATE, action) => {
  // console.log(`[postReducers]: ${action.type}`, action.payload);
  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    case GET_POST_START:
      return updateObject(state, { fetchingPost: true, postsOpComplete: false });

    case GET_POST_SUCCESS:
      return updateObject(state, { fetchingPost: false, postsOpComplete: true })
    // case SET_FETCHING_POST:
    //   return updateObject(state, { fetchingPost: true });

    case CLR_FETCHING_POST:
      return updateObject(state, { fetchingPost: false });

    case SET_POST_TOC:
      return updateObject(state, { working: false, currentPost: {...action.payload.post}, postToc: [...action.payload.toc] })

    case SET_CURRENT_POST:
      return updateObject(state, { currentPost: {...action.payload}});

    case SET_WORKING:
      return updateObject(state, { working: action.payload });

    case SET_POSTS:
      // return updateObject(state, { posts: [...action.payload.posts], working: action.payload.working, currentPost: {...action.payload.currentPost}} );
      return updateObject(state, { posts: [...action.payload.posts] } );

    case SET_POST_ERROR:
      return updateObject(state, { error: action.payload.error, working: false });
      
    case CLEAR_POST_ERROR:
      return updateObject(state, { error: '', postsOpComplete: false });

    case CLEAR_CURRENT_POST:
      return INITIAL_STATE;

    case EDIT_POST:
      return updateObject(state, { currentPost: 
                                   {id: action.payload.id, published: action.payload.published,
                                    name: action.payload.name, content: action.payload.content,
                                    comments: action.payload.comments},
                                    error: '' })

    case SEND_TEST_MESSAGE_START:
      return updateObject(state, { working: true, postsOpComplete: false });

    case SEND_TEST_MESSAGE_SUCCESS:
      return updateObject(state, { working: false, postsOpComplete: true });

    case SEND_TEST_MESSAGE_FAILED:
      return updateObject(state, { working: false, error: action.payload.error, postsOpComplete: true });

    case CREATE_POST_START:
    case UPDATE_POST_START:
      return updateObject(state, { working: true, postsOpComplete: false });

    case CREATE_POST_SUCCESS:
      // TODO: Should we update this into the set of posts or just re-fetch?
      return updateObject(state, { working: false, postsOpComplete: true });

    case CREATE_POST_FAILED:
    case UPDATE_POST_FAILED:
      return updateObject(state, { working: false, error: action.payload.error, postsOpComplete: true });

    case UPDATE_POST_SUCCESS:
      return updateObject(state, { working: false, postsOpComplete: true });

      case LIKE_POST_START:
      case LIKE_COMMENT_START:
        return state;

    case LIKE_POST_SUCCESS:
      let updObj = updateObject(state, {currentPost:  {...state.currentPost, likes: [...action.payload.likes]}})
      return updObj;

    case LIKE_COMMENT_SUCCESS:
      let commentIx = state.currentPost.comments.findIndex(comment => {
        if (comment.id === action.payload.comment.id) {
          return true;
        } else {
          return false;
        }
      });
      if (commentIx === -1) {
        // TODO: Error.  Should handle
        return state;
      }
      let comments = [...state.currentPost.comments];
      comments[commentIx] = {...comments[commentIx], likes: [...action.payload.likes]}
      return updateObject(state, {currentPost: {...state.currentPost, comments: comments}})
      
    default:
      return state;
  }
}

export default reducer;
