import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import {formattedDate} from '../../utils/utils';

import classes from './PostToc.module.css';

const postRefs = new Map();

const postTocYears = (postToc) => {
  const years = Object.keys(postToc);
  years.sort((a, b) => {
    return (a < b ? 1 : -1);
  });
  return years;
}

class PostTocSelect extends Component {

  constructor(props) {
    super(props);

    const years = postTocYears(props.postToc);
    // Compute current year.
    const d = new Date(props.currentPost.post_date);
    let yr = d.getFullYear();

    console.log(`*** PostTocSelect: CTOR: Years: ${years}  CURRENT YEAR: ${yr}`)
    this.state = {
      currentPost: props.currentPost,
      currentYear: yr,
      curTocId: this.makePostTocId(props.currentPost.id),
    };
  }

  getCurrentYear() {
    if (this.state.currentYear) {
      return this.state.currentYear;
    }
    if (this.props.postToc === undefined) {
      return null;
    }

    // WDS: Probably don't need this as the change to set currentYear might've helped.
    const years = postTocYears(this.props.postToc);
    let year;
    if (years.length > 0) {
      year = years[0];
    } else {
      year = null;
    }
    return year;
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.year === null) {
      return false;
    }

    return true;
  }

  isActive(postId) {
    return (postId === this.state.currentPost.id ? classes.TocEntryActive : '');
  }

  tocEntryClicked = (post) => {
    this.props.clicked(post);
    this.setState({currentPost: post, curTocId: this.makePostTocId(post.id)});
  };

  componentDidUpdate() {
    // console.log('componentDidUpdate')
    setTimeout(() => {
      this.setTocFocus();
    }, 500);
  }

  setTocFocus() {
    // console.log('setTocFocus')
    const node = postRefs.get(this.state.currentPost.id);
    if (node && node.scrollIntoView) {
      // console.log('scrollING into view..... I HOPE')
      var bodyElem = document.querySelector('body');
      const bodyAttrs = bodyElem.getBoundingClientRect();
      const nodeAttrs = node.getBoundingClientRect();
      // console.log('[PostTocSelect]: scrollIntoView for node...', bodyAttrs, nodeAttrs)
      if (nodeAttrs.y > bodyAttrs.height) {
        // console.log("!!!!!Elem is hidden!!!!!")
        node.scrollIntoView({
          behavior: 'smooth',
          // WDS: block not supported in some mobile browsers.  this would let
          //     me center or scroll the entry to the end.
          // block: 'end'
          // block: 'center
          // alignToTop: false
        });
      } else {
        // console.log("is not hidden")
      }

      // console.log('scrollED into view..... I HOPE')
    }
  }

  toggleClicked = (event) => {
    event.preventDefault();
    let year = parseInt(event.target.options[event.target.options.selectedIndex].value);
    console.log('toggleClicked', year);

    let updateProps = {currentYear: year};
    // WDS: Hack to see if I can force into vieww for hardcoded index
    // if (year === this.state.currentYear) {
    //   console.log('WTF???', postRefs);
    //   this.setTocFocus();
    // }
    this.setState(updateProps);
  };
  
  makePostTocId(postId) {
    return `PostToc${postId}`;
  }

  renderPostTocYear(postYear, ix) {
    if (postYear === undefined) {
      console.log("***** renderPostTocYear  EMPTY")
      return null;
    }
    // console.log('renderPostTocToYear')
    let theYearToc = postYear.map((post) => {
      return (
        // <Accordion.Collapse
        //   id={this.makePostTocId(post.id)}
        //   key={post.id}
        //   eventKey={ix.toString()}>
          <div
            key={post.id}
            ref={(element) => {postRefs.set(post.id, element)}}
            className={[classes.TocEntry, this.isActive(post.id)].join(' ')} 
            onClick={() => {this.tocEntryClicked(post)}}>
              <p>{post.name}</p>
              <p className={classes.TocEntryMeta}>{formattedDate(post.post_date)}</p>
          </div>
        // </Accordion.Collapse>
      );
    })
    if (ix === 0) {
      console.log(`**** renderPostTocYear  RETURNING ${theYearToc.length} items`)
      // this.setTocFocus();
    }
    this.setTocFocus();
    return theYearToc;
  }

  renderPostsToc(postToc) {
    // NEED TO SET STATE
    const curYear = this.getCurrentYear();
    if (curYear === null) {
      return null;
    }

    // console.log(postToc);
    const years = postTocYears(postToc);
    
    // console.log(`Found years: ${years}.  Current Year: ${this.state.currentYear}`)
    
    let theToc = years.map((year, ix) => {
      return (
        <option key={ix} value={year}>{this.props.title} - {year}</option>
      );
    });

    return (
      <React.Fragment>
        <div className="selectdiv">
          <Form.Control id="tocSelect" as="select" onChange={(event) => {this.toggleClicked(event)}} defaultValue={curYear}>
            {theToc}
          </Form.Control>
        </div>
        {/* <Card id={`myContainer${this.state.currentYear}`} key={this.state.currentYear}> */}
          {/* <Accordion.Toggle onClick={(event) => {this.toggleClicked(event, ix)}} as={Card.Header} eventKey={ix.toString()}>
            <FontAwesomeIcon style={{marginRight: '10px'}} icon={this.tocIcon(ix)} />
            {year} Posts
          </Accordion.Toggle> */}
          <Card
            className={classes.TocCard}
            id={`myContainer${this.state.currentYear}`}
            key={this.state.currentYear}
          >

            {this.renderPostTocYear(postToc[this.state.currentYear], this.state.currentYear)}

          </Card>
        {/* </Card> */}

      </React.Fragment>
    );
  }

  render() {
    // console.log('**** [PostToc] render ****')

    const renderedPostToc = this.renderPostsToc (this.props.postToc);
    return (
      // <Accordion defaultActiveKey="0">
        <Card>
        {renderedPostToc}
        </Card>
      // </Accordion>
    );
  }
}
export default PostTocSelect;