import React, { Component } from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setCurrentPath } from '../../utils/utils';
import {  showMarquis} from '../../redux/actions/controlsActions';

class PrivateRoute extends Component {
  showHideMarquis = (show) => {
    if (this.props.controls.showMarquis === show) {
      return;
    }

    setTimeout(() => {
      this.props.showMarquis(show);
    }, 1000);
  }
  render() {
    console.log('[PrivateRoute]')

    setCurrentPath(this.props.path);

    if (this.props.auth.logged_in) {
      if (this.props.hideMarquis) {
        this.showHideMarquis(false);
      } else {
        this.showHideMarquis(true);
      }
      return <Route {...this.props} />
    } else {
      this.showHideMarquis(true);
      return (
        <Route
          path={this.props.path}
          exact={this.props.exact ? this.props.exact : undefined}
          render={(routeProps) => {
            return <Redirect
              to={{
                pathname: '/auth',
                state: { from: this.props.path, error: `Unauthorized.  Must sign in to access this feature.` }
              }}
            />
          }}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    showMarquis: (show) => {dispatch(showMarquis(show))}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);