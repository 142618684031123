import axios from '../../utils/axios-whe';

import uploadFileTypes from './uploadFile.types'
import {  MAX_IMAGE_SIZE } from "../../utils/constants";

import { resizeFile } from '../../utils/utils';

export const setUploadFile = data => ({
  type: uploadFileTypes.SET_UPLOAD_FILE,
  payload: data,
})

export const setUploadProgress = (id, progress) => ({
  type: uploadFileTypes.SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
})

export const successUploadFile = (id, file) => ({
  type: uploadFileTypes.SUCCESS_UPLOAD_FILE,
  payload: {id: id, file: file}
})

export const failureUploadFile = (id, error) => {
  return {
    type: uploadFileTypes.FAILURE_UPLOAD_FILE,
    payload: {id: id, message: error}
  }
}

export const cleanupUploads = () => {
  console.log("uploadFile.actions: cleanupUploads")
  return ({ type: uploadFileTypes.CLEANUP_UPLOADS, message: 'Cleared upload data.' })
}

export const uploadFile = (photoJson, files) => {
  console.log('uploadFile.actions: uploadFile')
  // debugger;
  return (dispatch, getState) => {
    if (files.length) {
      let formData;
      let fileArray = [];
      files.forEach( file => {
        if (file.progress === 0) {
          fileArray.push(file);
          // XXX: WDS: Set file progress to prevent rogue uploadFile invocations
          //     due to a rerender before any progress has been logged.  All files
          //     to be uploaded should be in this state now.
          //
          // XXX: MIGHT want to look for duplicates elsewhere and prevent them
          //     in the GUI.
          dispatch(setUploadProgress(file.id, 1));
        }
      });
      const auth = getState().auth;
      fileArray.forEach(async file => {
        const smallerFile = await resizeFile(file.file, "file");

        if (smallerFile.size > (4 * (1024*1024))) {
          dispatch(failureUploadFile(file.id, `File is too large.  Must be less than ${MAX_IMAGE_SIZE}. Choose a smaller version or resize this and try again.`))
        } else {
          formData = new FormData()
          let keys = Object.keys(photoJson);

          keys.map(key => formData.append(`${key}`, photoJson[key]));
          // XXX: To be generic this key needs to be passed in or maybe we need to
          //   split this into the buildJSON and then the upload...
          // debugger;
          formData.append('photo[image]', smallerFile);
          try {
            let response = await axios.post('/api_v1/photos.json', formData, {
              _method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Token ${auth.token}`,
                'X-CSRF-Token': auth.csrf_token
              },
              onUploadProgress: progress => {
                const { loaded, total } = progress

                const percentageProgress = Math.floor((loaded / total) * 100)
                // debugger;
                dispatch(setUploadProgress(file.id, percentageProgress))
              },
            })
            // debugger;
            if (response.status === 201) {
              // Send in the resized file to apply it
              dispatch(successUploadFile(file.id, smallerFile))
            } else {
              dispatch(failureUploadFile(file.id, response.data.message))
            }
          } catch (error) {
            // debugger;
            dispatch(failureUploadFile(file.id, error.response.data.message))
          }
        }
      })
    }
  }
}
