import React, { Component } from 'react';

import { connect } from 'react-redux';

import { setTitle } from '../../../redux/actions/controlsActions';

class SiteNews extends Component {

  componentDidMount() {
    this.props.setTitle("Site News");
  }
  
  render() {
    return (
      <div className="Placeholder">[This is Site News]</div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    // closeDrawer: () => dispatch(closeDrawer()),
    // toggleDrawer: () => dispatch(toggleDrawer()),

    // showButton: () => { dispatch(showDrawerButton()) },
    // hideButton: () => { dispatch(hideDrawerButton()) },
    setTitle: (title) => {dispatch(setTitle(title))}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteNews);
