import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import PostsCommon from '../PostsCommon';

const MyPostsV2 = (props) => {
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (props.match.params.postId) {
      const postId = parseInt(params.postId);
      if (isNaN(postId) || postId < 1) {
        history.replace("/myposts")
      }
    }
  }, [props, params.postId, history])

  return (
    <PostsCommon title="My Posts" mine />
  )
}

export default MyPostsV2;
