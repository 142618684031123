import React, {useState} from 'react';

import { useHistory } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Input from '../../UI/Input';

import { POST_EDITOR } from '../../../utils/constants';
import TinyMceEditor from '../../Posts/PostEditor/TinyMceEditor';

import YesNoModal from '../../UI/YesNoModal';

import classes from './SiteMessageForm.module.css';

const schema = Yup.object({
  subject: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(80, 'Must be 32 characters or less')
    .required('Required'),
});

const SiteMessageForm = (props) => {
  const editor = React.createRef();
  const [showTestMessage, setShowTestMessage] = useState();
  const history = useHistory();

  const getPostButtonText = () => {
    if (props.edit) {
      if (props.siteMessage.is_draft) {
        return "Update";
      } else {
        return "Update/Post";
      }
    } else {
      return "Save";
    }
  }

  const [formButton, setFormButton] = useState(getPostButtonText());

  const formik = useFormik({
    initialValues: {
      id: props.siteMessage.id,
      subject: props.siteMessage.subject,
      body: props.siteMessage.body,
      is_draft: props.siteMessage.is_draft,
      site_definition_id: props.auth.site_definition_id,
      user_id: props.auth.uid
    },
    validationSchema: schema,
    onSubmit: (values) => {
      props.formSubmit(values);
    },
  });
  
  const handlePublishedRadioChange = (event) => {
    // console.log(`handlePublishedRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
        if (props.edit) {
          setFormButton("Update");
        } else {
          setFormButton("Save");
        }
      } else {
        formik.setFieldValue(event.target.name, false);
        if (props.edit) {
          setFormButton("Update / Post");
        } else {
          setFormButton("Save / Post");
        }
      }
    }
  }
  
  const updateEditorBody = (body) => {
    // console.log(`[NewPostJodit]: updatePostContent: contentIN: ${body}`);
    formik.setFieldValue("body", body);
  }

  const noCallback = (post) => {
    formik.setFieldValue("is_draft", true, false);
    setShowTestMessage(false)
    if (props.edit) {
      setFormButton("Update");
    } else {
      setFormButton("Save");
    }
  }
  
  const yesCallback = (post) => {
    /**
     * Cases
     * 
     * 1: New post where id === null.  Would you like to save as a draft and send a test message before publishing?
     *    IF YES, change published to FALSE and save post and send test message.
     * 
     * 2: Updated post where id > 0.  Would you like to save as a draft and send a test message before publishing?
     * 
     * 3: Answer to TEST Message === NO?  Both cases should indicate NO test and let user save / update message as they want.
     */
    // alert(`Would send a test message.  Should change published to False before saving and then check your email to see the result.  Post: ${JSON.stringify(post)}`)
    // Last false says to NOT validate the change.
    // formik.setFieldValue("is_draft", true, false);
    // formik.setFieldValue("send_test_message", true, false);

    // First tried the sentTestMethod action but decided to do this in the update / save methods.
    // dispatch(sendTestMessage(post))
    // setSendingTestMessage(true);
    // formik.handleSubmit(null); // let save / update form submit run and publish the test message
    // setShowTestMessage(false)
    setShowTestMessage(false);
    formik.handleSubmit(null);
  }

  const sendTestMessage = () => {
    alert(`Saving as draft and sending a test email message to ${props.auth.user.email}`)
    formik.setFieldValue("is_draft", true, false);
    formik.setFieldValue("send_test_message", true, false);

    formik.handleSubmit(null); // let save / update form submit run and publish the test message
    // setShowTestMessage(false);
    // formik.handleSubmit(null);
  }

  const renderTestMessageModal = (siteMessage) => {
    // debugger
    // New message and didn't choose to publish
    if (formik.values.is_draft) {
      return null;
    }

    let messageQuery = "Would you like to send a test message?";
    let yesLabel = "Post & send notifications?";
    let noLabel = "No, don't post yet";

    // new message and chose to publish immediately
    if (formik.values.is_draft === false) {
      messageQuery = `You're site message will be live on the site and email messages will be sent.  Is this what you want to do?`;
    }

    return (
      <YesNoModal 
        show={showTestMessage}
        yesCallback={(cbArg) => {yesCallback(cbArg)}}
        callbackArg={siteMessage}
        noCallback={(cbArg) => {noCallback(cbArg)}}
        yesLabel={yesLabel}
        noLabel={noLabel}
        cancel={() => {setShowTestMessage(false)}}
      >
        <div style={{marginBottom: '10px'}}>
          {messageQuery}
        </div>
      </YesNoModal>
    );
  }

  const postFormHandleSubmit = (e) => {
    // debugger;
    if (!formik.values.is_draft) {
      e.preventDefault();
      // Force modal for test message
      setShowTestMessage(true);
    } else {
      formik.handleSubmit(e)
    }
  }

  const cancelSiteMessage = (e) => {
    e.preventDefault();
    history.replace("/mysitemessages");
  }

  const renderContent = () => {
    return(
      <Form onSubmit={postFormHandleSubmit}>
        <Input
          style={{fontSize: '18px'}}
          placeholder="Enter Post Subject"
          noPrepend
          disabled={formik.isSubmitting}
          id='subject'
          required
          label="Subject"
          value={formik.values.subject}
          name='subject'
          changed={formik.handleChange}
          blur={formik.handleBlur}
          errors={formik.errors.subject}
          touched={formik.touched.subject}
          showValid
        />
          
        <InputGroup>
          <div className={classes.Published}>Publish? </div>
          <Form.Label className={classes.Radio} htmlFor='is_draft_false'>YES</Form.Label>
          <Form.Check
            disabled={formik.isSubmitting}
            styles={[{marginLeft: 20}]}
            type='radio'
            as='input'
            id="is_draft_false"
            className={classes.Radio}
            checked={formik.values.is_draft === false}
            value={false}
            name='is_draft'
            onChange={handlePublishedRadioChange}
            isInvalid={formik.errors['is_draft']}
            isValid={!formik.errors['is_draft']}
          />
          <Form.Label className={classes.Radio} htmlFor='is_draft_true'>NO</Form.Label>
          <Form.Check
            disabled={formik.isSubmitting}
            type='radio'
            as='input'
            id="is_draft_true"
            className={classes.Radio}
            checked={formik.values.is_draft === true}
            value={true}
            name='is_draft'
            onChange={handlePublishedRadioChange}
            isInvalid={formik.errors['is_draft']}
            isValid={!formik.errors['is_draft']}
          />
        </InputGroup>
        <TinyMceEditor 
          editor={editor}
          value={formik.values.body} 
          auth={props.auth}
          blur={updateEditorBody}
          changed={updateEditorBody}
          editorType={POST_EDITOR}
          placeholder={"Enter your message"}
        />
        {/* <input type='hidden' name='id' value={formik.values.id} /> */}
        <input type="hidden" name="body" value={formik.values.body} />
        <input type="hidden" name="site_definition_id" value={formik.values.site_definition_id} />
        <input type="hidden" name="user_id" value={formik.values.user_id} />
        <input type="hidden" name="id" value={formik.values.id} />

        <InputGroup className={classes.SubmitButton}>
          <Button size="sm" type="submit" onClick={(e) => {sendTestMessage()}}>Save and Send Test Message</Button>
        </InputGroup>
        <InputGroup className={classes.SubmitButton}>
          <Button size="sm" type="submit" disabled={formik.isSubmitting}>{formButton} Message</Button>
          <Button size="sm" style={{marginLeft:'20px'}} type="submit" onClick={(e) => {cancelSiteMessage(e)}}>Cancel</Button>
        </InputGroup>
      </Form>
    );
  }

  // Render the form
  return (
    <div className={classes.FormContainer}>
      {renderContent()}
      {renderTestMessageModal()}
    </div>
  );
}

export default SiteMessageForm;
