import React from 'react';
import Moment from 'react-moment';
import { createBrowserHistory } from 'history';

import Resizer from 'react-image-file-resizer';

import heic2any from 'heic2any';


import {
  isMobile
} from 'react-device-detect';

import { AUTH_INFO } from './constants';

export const history = createBrowserHistory();

let _currentPath = '/';
export const setCurrentPath = (path) => {
  _currentPath = path;
}
export const getCurrentPath = () => {
  return _currentPath;
}

export const formattedDate = (dateStr) => {
  return (
    <Moment format="MM-DD-YYYY @ h:mm a">
      {dateStr}
    </Moment>
  );
}
export const formattedTime = (dateStr) => {
  return (
    <Moment format="h:mm a">
      {dateStr}
    </Moment>
  );
}

export const formattedDateSm = (dateStr) => {
  return (
    <Moment format="MM-DD-YYYY">
      {dateStr}
    </Moment>
  );
}

export const couldBeLoggedIn = () => {
  if (localStorage.getItem(AUTH_INFO) === null) {
    return false;
  } else {
    return true;
  }
}

export const addNewLineTags = (strIn) => {
  let strOut = strIn.replace(/(?:\r\n|\r|\n)/g, '<br />');
  return strOut;
}

export const filterHtmlTags = (htmlStrIn) => {
  let htmlStr = htmlStrIn.replace(/<\/p>/gi, "<br /><br />");
  htmlStr = htmlStr.replace(/<p>/gi, "");
  htmlStr = htmlStr.replace(/<div/gi, "<span");
  htmlStr = htmlStr.replace(/<\/div>/gi, "</span><br />");
  htmlStr = htmlStr.replace(/<blockquote/gi, "<span");
  htmlStr = htmlStr.replace(/<\/blockquote>/gi, "</span>");
  return htmlStr;
}

export const capitalizeString = (strIn) => {
  let strArr = strIn.trim().split(' ');
  let x = '';
  for (var i = 0;i < strArr.length; i++) {
    if (strArr[i].charAt(0).match(/[a-z]/i) && strArr[i] !== 'and') {
      x = x + strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1).toLowerCase() + ' ';
    } else {
      x = x + strArr[i] + ' ';
    }
  }
  return x.trim();
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const isLandscapeMode = () => {
  if (isMobile && (window.innerWidth > window.innerHeight)) {
    return true;
  } else {
    return false;
  }
}

export const isPortraitMode = () => {
  if (isMobile && (window.innerHeight > window.innerWidth)) {
    return true;
  } else {
    return false;
  }
}

export const resizeFile = async (file, outputType) => {
  const retUri = await 
    new Promise((resolve, reject) => {
      // debugger
      try {
        Resizer.imageFileResizer(
          file,
          800,
          800,
          "jpeg",
          100,
          0,
          (uri) => {
            // debugger
            resolve(uri);
          },
          // "base64"
          // "file"
          outputType
        );
      } catch (e) {
        // debugger
        // console.error(`ERROR resizing file: ERR: ${e.message}`)
        // debugger
        alert(`ERROR resizing file: ERR: ${e.message}`)
        reject(`ERROR resizing file: ERR: ${e.message}`)
      }
    });
  // debugger;
  return retUri
}
