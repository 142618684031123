import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import {
  isBrowser
} from 'react-device-detect';

import Spinner from '../../Spinner/Spinner';

import SiteMessageForm from './SiteMessageForm';
import SiteMessageHelp from './SiteMessageHelp';

import { setTitle } from '../../../redux/actions/controlsActions';
import { saveSiteMessage, clearOpStatus } from '../../../redux/actions/siteMessageActions';

import classes from './SiteMessageForm.module.css';

/*
#  id                 :integer          not null, primary key
#  site_definition_id :integer
#  subject            :string(255)
#  body               :text
#  user_id            :integer
#  created_at         :datetime
#  updated_at         :datetime
#  is_draft           :boolean          default(TRUE)
#  time_sent          :datetime
*/
  let siteMessageDefaults = {
    id: '',
    subject: '',
    body: '',
    is_draft: true,
    user_id: null,
    site_definition_id: 2,
  };

const NewSiteMessage = (props) => {
  const auth = useSelector(state => state.auth);
  const siteMessages = useSelector(state => state.siteMessages);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("New Site Message"));
  }, [dispatch]);

  useEffect(() => {
    console.log('NewSiteMessage: useEffect')
    if (siteMessages.opComplete) {
      // debugger
      dispatch(clearOpStatus());
      history.push('/mysitemessages');
    }
  }, [siteMessages.opComplete, dispatch, history])

  const handleFormSubmit = (values) => {
    console.log('[NewSiteMessage]: ***** handleFormSubmit *********')
    dispatch(saveSiteMessage(values));
  }

  const renderContent = () => {
    if (siteMessages.working) {
      return <Spinner />;
    } else {
      // if (siteMessages.opComplete && siteMessages.error === '') {
      //   history.push('/mysitemessages');
      // }
      // TODO: Get any error displayed.
      return <SiteMessageForm edit={false} siteMessage={siteMessageDefaults} auth={auth} formSubmit={handleFormSubmit} />
    }
  }

  const renderTitle = () => {
    return (
      <div className={classes.Title}>
        {isBrowser ? "Create New Site Message" : null}
        <SiteMessageHelp title="New Site Message" />
      </div>
    ) 
  }
// debugger;
  return (
    <div>
      {renderTitle()}
      <Col sm={10} style={{paddingLeft: '0', marginLeft: '20px', marginRight: '0'}}>
        {renderContent()}
      </Col>
    </div>
  );
}

export default NewSiteMessage;
