import axios from '../../utils/axios-whe';

export const SET_CURRENT_POST = 'set_current_post';
export const CLEAR_CURRENT_POST = 'clear_current_post';

export const SET_POSTS = "set_posts";
export const SET_POST_TOC = "set_post_toc";
export const EDIT_POST = 'edit_post';
export const SET_POST_ERROR = 'set_post_error';
export const SET_WORKING = 'set_working';
// export const SET_FETCHING_POST = 'set_fetching_post';
export const CLR_FETCHING_POST = 'clr_fetching_post';
export const CLEAR_POST_ERROR = 'clear_post_error';

export const GET_POST_START = "get_post_start";
export const GET_POST_SUCCESS = "get_post_success";
export const GET_POST_FAILED = "get_post_failed";

export const CREATE_POST_START = "create_post_start";
export const CREATE_POST_SUCCESS = "create_post_success";
export const CREATE_POST_FAILED = "create_post_failed";

export const UPDATE_POST_START = "update_post_start";
export const UPDATE_POST_SUCCESS = "update_post_success";
export const UPDATE_POST_FAILED = "update_post_failed";

export const SEND_TEST_MESSAGE_START = 'send_test_message_start';
export const SEND_TEST_MESSAGE_SUCCESS = "send_test_message_success";
export const SEND_TEST_MESSAGE_FAILED = "send_test_message_failed";

export const LIKE_POST_START = "like_post_start";
export const LIKE_POST_SUCCESS = "like_post_success";
export const LIKE_POST_FAILED = "like_post_failed";

export const LIKE_COMMENT_START = "like_comment_start";
export const LIKE_COMMENT_SUCCESS = "like_comment_success";
export const LIKE_COMMENT_FAILED = "like_comment_failed";

const errorText = (error) => {
  let errText = error.message;
  if (error.response?.status === 404 && error.response?.data?.error) {
    errText = error.response.data.error;
  }
  return errText;
}

export const getPost = ( postId ) => {
  console.log(`postActions: getPost: ID: ${postId}`)
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: GET_POST_START })
    axios.get(`/api_v1/posts/${postId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      dispatch({ type: SET_CURRENT_POST, payload: response.data.post })
      dispatch({ type: CLR_FETCHING_POST })
    })
    .catch(error => {
      console.log("ERROR: " + error);
      dispatch({ type: SET_POST_ERROR, payload: {error: errorText(error)} });
      dispatch({ type: CLR_FETCHING_POST })
    });
  }
}

export const editPost = ( postId, auth ) => {
  console.log(`postActions: getPost: ID: ${postId}`)
  return (dispatch, getState) => {
    dispatch({ type: SET_WORKING, payload: true })
    axios.get(`/api_v1/posts/${postId}/edit`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      dispatch({ type: SET_CURRENT_POST, payload: response.data.post })
      dispatch({ type: SET_WORKING, payload: false })
    })
    .catch(error => {
      console.log("ERROR: " + error);
      dispatch({ type: SET_POST_ERROR, payload: {error: errorText(error)} });
    });
  }
}

export const setCurrentPost = ( post ) => {
  console.log(`postActions: setCurrentPost: ID: ${post.id}`)
  return (dispatch, getState) => {
    return dispatch({type: SET_CURRENT_POST, payload: post});
  }
}

export const clearCurrentPost = () => {
  return (dispatch, getState) => {
    return dispatch({type: CLEAR_CURRENT_POST});
  }
}

// export const updatePostContent = (content) => {
//   return (dispatch) => {
//     return dispatch({ type: UPDATE_POST_CONTENT, payload: content })
//   }
// }

export const clearPostError = () => {
  return (dispatch) => {
    return dispatch({ type: CLEAR_POST_ERROR });
  }
}

export const deletePost = ( post ) => {
  console.log(`postActions: deletePost: ID: ${post.id}`)
  return (dispatch, getState) => {
    const auth = getState().auth;
    axios.delete(`/api_v1/posts/${post.id}`, {
      // method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      //console.log(JSON.stringify(response));
      console.log(response);
      // XXX: Do a REDIRECT to myposts
      // dispatch({ type: SET_REDIRECT_PATH, payload: {path: '/myposts'} });
    })
    .catch(error => {
      console.error(`ERROR logging out of ${error}`);
    });
    
    return dispatch({type: CLEAR_CURRENT_POST});
  }
}

export const getPostTocAndOnePost = (postId) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_WORKING, payload: true })
    const auth = getState().auth;
    let url = `/api_v1/posts_toc.json`;
    if (postId !== -1) {
      url = `/api_v1/posts_toc.json?pid=${postId}`;
    }
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      dispatch({ type: SET_POST_TOC, payload: response.data.post_toc });
      dispatch({ type: SET_CURRENT_POST, payload: response.data.post_toc.post })
    })
    .catch(error => {
      console.error("ERROR: " + error);
      dispatch({ type: SET_POST_ERROR, payload: {error: errorText(error)} });
    });
  }
}

export const addComment = (post, commentValues) =>{
  const formJson = {
    "comment": {...commentValues}
  };

  return (dispatch, getState) => {
    const auth = getState().auth;

    const url = `/api_v1/posts/${post.id}`;
    console.log(`postActions: addComment: Posting comment with URL of ${url}`, formJson)
    dispatch({ type: SET_WORKING, payload: true })

    axios.patch(url, formJson, {
      _method: 'patch',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`UpdatedAddr`, response.data);

      // setAddingComment(false);
      // formik.resetForm();
      // XXX: Re-fetch / re-issue the post paint with the updated comments...
      // XXX: OR...  Pass in a callback that will hand the caller the updated
      //    Post so it will repaint automatically when merged
      dispatch({ type: SET_CURRENT_POST, payload: response.data.post })
      dispatch({ type: SET_WORKING, payload: false })
    })
    .catch(error => {
      dispatch({ type: SET_POST_ERROR, payload: {error: errorText(error)} });
    })
  }
}

export const removeComment = (commentId) => {
  // /api_v1/posts/:id/remove_comment
  console.log(`postActions: removeComment: ID: ${commentId}`)
  return (dispatch, getState) => {
    const auth = getState().auth;
    axios.delete(`/api_v1/posts/${commentId}/remove_comment`, {
      // method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      //console.log(JSON.stringify(response));
      console.log(response);
      // XXX: Do a REDIRECT to myposts
      // dispatch({ type: SET_REDIRECT_PATH, payload: {path: '/myposts'} });
      dispatch({ type: SET_CURRENT_POST, payload: response.data.post })
    })
    .catch(error => {
      console.error(`ERROR logging out of ${error}`);
    });
    
    // return dispatch({type: CLEAR_CURRENT_POST});
  }
}

export const setPosts = (posts) => {
  console.log('postActions:  ', posts);
  // debugger;
  return (dispatch, getState) => {
    dispatch({ type: SET_POSTS, payload: posts })
  }
}

export const getPostsForCurrentUser = (postId) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_WORKING, payload: true })
    const auth = getState().auth;
    let url = "/api_v1/posts_controller/my_posts";
    if (postId >= 0) {
      url = `/api_v1/posts_controller/my_posts?pid=${postId}`;
    }
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      // dispatch({ type: SET_POSTS, payload: {posts: response.data, currentPost: response.data[0], working: false} });
      dispatch({ type: SET_POST_TOC, payload: response.data.post_toc });
      dispatch({ type: SET_CURRENT_POST, payload: response.data.post_toc.post })

      // dispatch({ type: SET_CURRENT_POST, payload: response.data[0] })
      // dispatch({ type: SET_WORKING, payload: false })
      // this.setState((prevState, prevProps) => ({
      //   posts: response.data, loading: false
      // }));
    })
    .catch(error => {
      console.log("ERROR: " + error);
      dispatch({ type: SET_POST_ERROR, payload: {error: errorText(error)} });
    });
  }
}

export const savePost = (post) => {
  const formJson = {
    "post": {...post }
  };

  return (dispatch, getState) => {
    const auth = getState().auth;

    dispatch({type: CREATE_POST_START});
    axios.post(`/api_v1/posts.json`, formJson,
    {
      _method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`postActions: savePost`, response.data);
      dispatch({type: CREATE_POST_SUCCESS}, response.data.post);
    })
    .catch(error => {
      alert(`ERROR updating address info.  ${error}`)
      dispatch({type: CREATE_POST_FAILED, payload: { error: errorText(error) }})
    })
  }
}

export const updatePost = (post) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    const url = `/api_v1/posts/${post.id}`
    const formJson = {
      "post": {...post }
    };
    dispatch({ type: UPDATE_POST_START });
    axios.patch(url, formJson,
    {
      _method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`postActions: updatePost: updated`, response.data);
      // props.updated(values);
      // XXX: Shuold we have a disable form / reenable toggle methdo invoked here?
      // clearCurrentPost();
      dispatch( {type: UPDATE_POST_SUCCESS, payload: {post: response.data}} )
    })
    .catch(error => {
      // alert(`ERROR updating address info.  ${error}`)
      dispatch( {type: UPDATE_POST_FAILED, payload: {error: error}});
    })
  }
}

export const sendTestMessage = (post) => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: SEND_TEST_MESSAGE_START});
    axios.post(`/api_v1/posts/${post.id}/test_message`, {},
    {
      _method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`Test message sent.  Status`, response.data);
      dispatch({type: SEND_TEST_MESSAGE_SUCCESS, payload: {result: response.data}})
    })
    .catch(error => {
      alert(`ERROR updating address info.  ${error}`)
      dispatch({type: SEND_TEST_MESSAGE_FAILED, payload: {error: error}});
    })
  }
}

export const likePost = (post) => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: LIKE_POST_START});
    axios.post(`/api_v1/likes/thumbs_up/Post/${post.id}`, {},
    {
      _method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger
      console.log(`Thumbs Up post.  Status`, response.data);
      dispatch({type: LIKE_POST_SUCCESS, payload: {likes: response.data.likes}})
    })
    .catch(error => {
      alert(`ERROR updating address info.  ${error}`)
      dispatch({type: LIKE_POST_FAILED, payload: {error: error}});
    })
  }
}

export const likeComment = (comment) => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: LIKE_COMMENT_START});
    axios.post(`/api_v1/likes/thumbs_up/Comment/${comment.id}`, {},
    {
      _method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger
      console.log(`Thumbs Up comment.  Status`, response.data);
      dispatch({type: LIKE_COMMENT_SUCCESS, payload: {comment: comment, likes: response.data.likes}})
    })
    .catch(error => {
      alert(`ERROR updating address info.  ${error}`)
      dispatch({type: LIKE_COMMENT_FAILED, payload: {error: error}});
    })
  }
}