import React, { Component } from 'react';

import { connect } from 'react-redux';

import { WHE_BASE_URL } from '../../../utils/constants';

import Col from 'react-bootstrap/Col';
import {
  isBrowser
} from 'react-device-detect';

import Spinner from '../../Spinner/Spinner';

import SiteMessageForm from './SiteMessageForm';
import SiteMessageHelp from './SiteMessageHelp';

import { setTitle } from '../../../redux/actions/controlsActions';
import { updateSiteMessage, clearOpStatus } from '../../../redux/actions/siteMessageActions';

import classes from './SiteMessageForm.module.css';

/*
#  id                 :integer          not null, primary key
#  site_definition_id :integer
#  subject            :string(255)
#  body               :text
#  user_id            :integer
#  created_at         :datetime
#  updated_at         :datetime
#  is_draft           :boolean          default(TRUE)
#  time_sent          :datetime
*/
  let siteMessageDefaults = {
    id: null,
    subject: '',
    body: '',
    is_draft: true,
    user_id: null,
    site_definition_id: 2,
  };

class EditSiteMessage extends Component {
  // const auth = useSelector(state => state.auth);
  // const history = useHistory();
  // const dispatch = useDispatch();
  // const [siteMessage, setSiteMessage] = useState({...siteMessageDefaults});

  constructor(props) {
    super(props);
    this.waitingForPost = false;
    let sm = {...siteMessageDefaults};
    if (this.props.location.state && this.props.location.state.siteMessage) {
      // debugger
      sm = {...this.props.location.state.siteMessage};
    }
    this.state = { timeoutId: null, siteMessage: {...sm} }
  }

  componentDidMount() {
    this.props.setTitle("Edit Site Message");
  }

  startTimer = () => {
    setTimeout(() => {
      if (this.waitingForPost) {
        this.setState({timeoutId: this.startTimer()});
      } else {
        this.setState({timeoutId: null});
      }
    }, 1000)
  };

  componentDidUpdate = () => {
    if (this.props.siteMessages.working === false && this.props.siteMessages.opComplete) {
      this.props.clearOpStatus();

      this.props.history.push('/mysitemessages');
    }
  }

  // handleFormSubmit receives the values from the form in a JS object
  handleFormSubmit = (values) => {
    console.log('[EditSiteMessage]: ***** handleFormSubmit *********')
    // debugger
    this.props.updateSiteMessage(values)
  };

  renderContent = () => {
    if (this.props.siteMessages.working && !this.props.siteMessages.opComplete) {
      return <Spinner />;
    } else {
      // debugger
      return <SiteMessageForm edit siteMessage={this.state.siteMessage} auth={this.props.auth} formSubmit={this.handleFormSubmit} />
    }
  }

  renderTitle = () => {
    return (
      <div className={classes.Title}>
        {isBrowser ? "Update Site Message" : null}
        <SiteMessageHelp title="Update Site Message" />
      </div>
    ) 
  }

  render() {
    return (
      <div>
        {this.renderTitle()}
        <Col sm={10} style={{paddingLeft: '0', marginLeft: '20px', marginRight: '0'}}>
          {this.renderContent()}
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls,
    siteMessages: state.siteMessages
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    setTitle: (title) => {dispatch(setTitle(title))},
    updateSiteMessage: (values) => {dispatch(updateSiteMessage(values))},
    clearOpStatus: () => {dispatch(clearOpStatus)}
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(EditSiteMessage);
