import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import classes from './SideDrawerLeft.module.css';

const sideDrawerLeftMobile = (props) => {
  let attachedClasses = [classes.SideDrawer, classes.Close];
  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }
  console.log('[SideDrawerLeftModule] open: ' + props.open)
  console.log('[SideDrawerLeftModule]: ', props.contentPanel)
  return (
    <div>
      {/* WDS: Don't want onClick here as that closes the whole panel.  DO however want the panel to close on clicking an entry... */}
      <div className={attachedClasses.join(' ')} >
        <div className={classes.CloseButtonContainer}>
          <FontAwesomeIcon onClick={() => {props.closed()}} className={classes.CloseIcon} icon={faTimes} />
        </div>
        <div>
          {props.contentPanel}
        </div>
      </div>
    </div>
  );
};

export default sideDrawerLeftMobile;
