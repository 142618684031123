import React from 'react';

import classes from './SideDrawerRight.module.css';

const sideDrawerRightMobile = (props) => {
  let attachedClasses = [classes.SideDrawer, classes.Close];
  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }
  console.log('[SideDrawerRightModule] open: ' + props.open)
  // console.log('[SideDrawerRightModule]: ', props.contentPanel)
  return (
    <div>
      {/* WDS: Don't want onClick here as that closes the whole panel.  DO however want the panel to close on clicking an entry... */}
      <div className={attachedClasses.join(' ')} >
        <div>
          {props.contentPanel}
        </div>
      </div>
    </div>
  );
};

export default sideDrawerRightMobile;
