import React from 'react'
import Styles from './UploadItem.module.css'

import Image from 'react-bootstrap/Image';

const UploadItem = props => {
  const { file, progress } = props.file

  if (file === undefined || file === null) {
    return null;
  }

  const renderError = (file) => {
    if (progress === 100 && props.file.status === 0) {
      return <div style={{color: 'red'}}>Error: {props.file.message}</div>
    } else {
      return null;
    }
  }
  const imgPreview = URL.createObjectURL(file);
  return (
    <div className={Styles.wrapperItem}>
      <div className={Styles.leftSide}>
        <Image className={Styles.thumbnail} src={imgPreview} alt={file.name} width="50" height="50" thumbnail />
        <div className={Styles.progressBar}>
          <div style={{ width: `${progress}%` }} />
        </div>
        <label>{file.name}</label>
        {renderError(file)}
      </div>
      <span className={Styles.percentage}>{progress}%</span>
    </div>
  )
}

export default UploadItem
