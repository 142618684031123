import React, {useState} from 'react';
import { useSelector } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import DatePicker from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import { useFormik } from 'formik';

import * as Yup from 'yup';

import Input from '../../UI/Input';

import {  } from '../../../redux/actions/calendarEventActions';
import Spinner from '../../Spinner/Spinner';

import './EventForm.css';
import classes from './EventForm.module.css';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object({
  title: Yup.string()
    .min(3, 'Event title must be 3 characters or more')
    .max(80, 'Event title must be 80 characters or less')
    .required('Required'),
  description: Yup.string(),
  begin_date: Yup.date()
    .min(new Date(), "Date can't be in the past")
    .required('Required', "MUST HAVE A DATE"),
  event_duration: Yup.number()
    .required("required"),
  location_id: Yup.number()
    .min(1, "Must choose a location from the list.")
    .required('Required'),
  activity_id: Yup.number()
    .min(1, "Must select an activity type from the list.")
    .required('Required')
});

const eventDurations = [
  {"30 min": 30},
  {"60 min": 60},
  {"90 min": 90},
  {"2 hours": 120},
  {"3 hours": 180},
  {"4 hours": 240}
];

const EventForm = (props) => {
  const auth = useSelector(state => state.auth);
  const calendarEvents = useSelector(state => state.calendarEvents);
  const [startDate, setStartDate] = useState(props.event.begin_date);

  let updating = false;

  let testEventDate = new Date();
  testEventDate.setMonth(testEventDate.getMonth()+1);
  // let startTime = testEventDate.getTime();
  // startTime = startTime / 1000;

  const buildGroupIds = (groups) => {
    const groupIds = groups.map(group => group.group_id);
    return groupIds;
  }
  const initialValues = {
    title: props.event.title,
    description: props.event.description, //Math.floor(startTime),
    begin_date: startDate,
    event_duration: props.event.event_duration,
    location_id: props.event.location_id,
    activity_id: props.event.activity_id,
    activity_type: props.event.activity_type,
    send_email: props.sendNotifications,
    groups: props.event.groups,
    created_by: auth.uid,
    site_definition_id: auth.site_definition_id,
    group_ids: props.event.group_ids
  };
    
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      // Build begin_date
      let beginDate = {};

      beginDate['begin_date(1i)'] = values.begin_date.getFullYear().toString();
      beginDate['begin_date(2i)'] = (values.begin_date.getMonth()+1).toString();
      beginDate['begin_date(3i)'] = values.begin_date.getDate().toString();
      beginDate['begin_date(4i)'] = values.begin_date.getHours().toString();
      beginDate['begin_date(5i)'] = values.begin_date.getUTCMinutes().toString();

      let endDate = moment(values.begin_date).add(values.event_duration, "m").toDate();
      let endDateFmt = {};
      endDateFmt['end_date(1i)'] = endDate.getFullYear().toString();
      endDateFmt['end_date(2i)'] = (endDate.getMonth()+1).toString();
      endDateFmt['end_date(3i)'] = endDate.getDate().toString();
      endDateFmt['end_date(4i)'] = endDate.getHours().toString();
      endDateFmt['end_date(5i)'] = endDate.getUTCMinutes().toString();

      const formValues = {...values, ...beginDate, ...endDateFmt};
      delete formValues.begin_date;
      delete formValues.event_duration;
      props.onSubmit(formValues);
    }
  });

  const updateGroups = (event) => {
    let groups = [];
    groups.push(parseInt(event.target.value));
    formik.setFieldValue(event.target.name, groups);
  }
  const handleRadioChange = (event) => {
    // alert(`handleRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
      } else {
        formik.setFieldValue(event.target.name, false);
      }
    }
  }

  const renderLocations = () => {
    // Iterate locations
    if (calendarEvents && !calendarEvents.eventMetadata) {
      return null;
    }

    const locs = calendarEvents.eventMetadata.locations.map(location => {
      return (
        <option key={location.id} value={location.id}>{location.name}</option>
      );
    });
    locs.unshift(<option key={0} value={0}>[Choose a location]</option>)
    return locs;
  }

  const renderGroupsDropdown = () => {
    const groups = auth.user.groups.map((group, index) => {
      return (
        <option key={group.id} value={group.id}>{group.name}</option>
      )
    })
    return groups;
  }

  const renderActivities = () => {
    if (calendarEvents && !calendarEvents.eventMetadata) {
      return null;
    }

    const activities = calendarEvents.eventMetadata.activities.map(activity => {
      if (calendarEvents.filterEventTypeList.includes(activity.name)) {
        return (
          <option key={activity.id} value={activity.id}>
            {/* <div className={classes.ActivityIcon}>
              <Image src={activity.thumbnail} height="30" width="30" thumbnail />
            </div> */}
            {activity.name}
          </option>
        );
      } else {
        return null;
      }
    });
    activities.unshift(<option key={0} value={0}>[Choose an activity]</option>)
    return activities;
  }

  const renderDurations = () => {
    return (
      <React.Fragment>
        {eventDurations.map((ttype) => {
          let typeKey = Object.keys(ttype)[0];
          let typeValue = ttype[typeKey];
          return <option key={typeKey} value={typeValue}>{typeKey}</option>
        })}
      </React.Fragment>
    )
  }

  const CustomDateTimeInput = React.forwardRef(({ value, onClick }, ref) => {
    // debugger;
    return (
      <Input
        onClick={onClick}
        id="begin_date"
        name="begin_date"
        noPrepend
        innerRef={ref}
        // type="text"
        placeholder="Start Date"
        required
        label="Start Date"
        value={value}
        changed={formik.handleChange}
        blur={formik.handleBlur}
        errors={formik.errors.begin_date}
        touched={formik.touched.begin_date}
        disabled={formik.isSubmitting}
      />
    );
  });

  const buildForm = () => {
    return (
      <Row>
        <Col sm={12} className={classes.AuthCol}>
          <div className={classes.SignupBox}>
            <h5 style={{color: 'red', textAlign: 'center'}}>{auth.error}</h5>
            {props.location && props.location.state && props.location.state.error ? <p className={classes.FormError}>{props.location.state.error}</p>:null}
            <Form
              onSubmit={formik.handleSubmit}
              className={classes.Form}
            >
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='title'
                  type="title"
                  placeholder="Event Title"
                  required
                  label="Title"
                  value={formik.values.title}
                  name='title'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.title}
                  touched={formik.touched.title}
                  disabled={formik.isSubmitting}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="description_">Description</InputGroup.Text>
                </InputGroup.Prepend>
                <textarea 
                  className='form-control'
                  style={{fontSize: '18px', minHeight: '90px', whiteSpace: 'pre-wrap'}}
                  placeholder="Enter Description"
                  disabled={formik.isSubmitting}
                  id='description'
                  required
                  label="Description"
                  value={formik.values.description}
                  name='description'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={formik.errors.description}
                  touched={formik.touched.description}
                >
                    {formik.values.description}
                </textarea>

              </InputGroup>

              {/* <DatePicker
                  customInput={<CustomDateTimeInput />}
                  name="begin_date"
                  id="begin_date"
                  // minDate={new Date()}
                  // minTime={new Date()}
                  // maxTime={new Date()}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                    formik.setFieldValue("begin_date", date);
                    formik.setFieldTouched("begin_date", true);
                    console.log(formik.errors.begin_date)
                  }}
                  showTimeSelect
                  timeFormat="hh:mm a"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                /> */}

              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="datetime_id_">* Date / Time</InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  customInput={<CustomDateTimeInput />}
                  // name="begin_date"
                  // id="begin_date"
                  minDate={new Date()}
                  // minTime={new Date()}
                  // maxTime={new Date()}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                    formik.setFieldValue("begin_date", date);
                    formik.setFieldTouched("begin_date", true);
                    console.log(formik.errors.begin_date)
                  }}
                  popperPlacement="left"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "500px, -400px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                        
                  showTimeSelect
                  timeFormat="hh:mm a"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
                  {formik.errors.begin_date}
                </Form.Control.Feedback>
              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="event_duration_">* Duration</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="event_duration"
                  name="event_duration"
                  as="select"
                  required
                  value={formik.values.event_duration}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.event_duration}
                  disabled={formik.isSubmitting}
                >
                  {renderDurations()}
                </Form.Control>
                <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
                  {formik.errors.event_duration}
                </Form.Control.Feedback>

              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="location_id_">* Location</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="location_id"
                  name="location_id"
                  as="select"
                  required
                  value={formik.values.location_id}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.location_id}
                  disabled={formik.isSubmitting}
                >
                  {renderLocations()}
                </Form.Control>
                <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
                  {formik.errors.location_id}
                </Form.Control.Feedback>

              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="activity_id_">* Activity Type</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="activity_id"
                  name="activity_id"
                  as="select"
                  required
                  value={formik.values.activity_id}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.activity_id}
                  disabled={formik.isSubmitting}
                >
                  {renderActivities()}
                </Form.Control>
                <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
                  {formik.errors.activity_id}
                </Form.Control.Feedback>

              </InputGroup>
              
              <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="send_email_">Send Notification?</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Label className={classes.Radio} htmlFor='send_email_true'>YES</Form.Label>
                <Form.Check
                  disabled={formik.isSubmitting}
                  type='radio'
                  as='input'
                  id="send_email_true"
                  className={classes.Radio}
                  checked={formik.values.send_email === true}
                  value={true}
                  name='send_email'
                  onChange={handleRadioChange}
                  isInvalid={formik.errors['send_email']}
                  isValid={!formik.errors['send_email']}
                />
                <Form.Label className={classes.Radio} htmlFor='send_email_false'>NO</Form.Label>
                <Form.Check
                  disabled={formik.isSubmitting}
                  styles={[{marginLeft: 20}]}
                  type='radio'
                  as='input'
                  id="send_email_false"
                  className={classes.Radio}
                  checked={formik.values.send_email === false}
                  value={false}
                  name='send_email'
                  onChange={handleRadioChange}
                  isInvalid={formik.errors['send_email']}
                  isValid={!formik.errors['send_email']}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="groups_">Groups</InputGroup.Text>
                </InputGroup.Prepend>
                {/* <div className={classes.CheckboxMainContainer}> */}
                <Form.Control
                  id="group_ids"
                  name="group_ids"
                  as="select"
                  required
                  value={formik.values.group_ids}
                  // onChange={formik.handleChange}
                  onChange={updateGroups}
                  isInvalid={formik.errors.group_ids}
                  disabled={formik.isSubmitting}
                  className={classes.Groups}
                >
                  {renderGroupsDropdown()}
                </Form.Control>
                {/* </div> */}
              </InputGroup>
              <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup} />
                  <Button type="submit" disabled={updating}>{(props.edit ? "Update Event" : "Create Event")}</Button>
                &nbsp;&nbsp;&nbsp;
                <Button type="cancel" onClick={props.cancel} disabled={updating}>Cancel</Button>
              </InputGroup>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }

  if (auth.loading) {
    return <Spinner />;
  } else {
    return buildForm();
  }
}

export default EventForm;