import axios from '../../utils/axios-whe';

export const SET_SEARCHING = 'search_set_searching';
export const SET_RESULTS = 'search_set_results';
export const SET_ERROR = 'search_set_error';
export const SHOW_SEARCH_BOX = 'show_search_box';
export const CLEAR_SEARCH_RESULTS = 'clear_search_results';

export const SEARCH_SITE_MESSAGES_START = 'search_site_messages_start';
export const SEARCH_SITE_MESSAGES_SUCCESS = 'search_site_messages_success';
export const SEARCH_SITE_MESSAGES_FAILED = 'search_site_messages_failed';

const errorText = (error) => {
  let errText = error.message;
  if (error.response?.status === 404 && error.response?.data?.error) {
    errText = error.response.data.error;
  }
  return errText;
}

export const searchPosts = ( searchString ) => {
  // console.log(`searchActions: searchPosts: ${searchString}`)
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: SET_SEARCHING, payload: {searching: true, searchString: searchString} })
    axios.get(`/api_v1/search_posts?s=${searchString}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      // console.log(response)
      dispatch({ type: SET_RESULTS, payload: {posts: response.data} })
    })
    .catch(error => {
      console.log("ERROR: " + error);
      dispatch({ type: SET_ERROR, payload: {error: errorText(error)} });
    });
  }
}

export const searchSiteMessages = ( searchString ) => {
  // console.log(`searchActions: searchSiteMessages: ${searchString}`)
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: SEARCH_SITE_MESSAGES_START, payload: {searchString: searchString} })
    axios.get(`/api_v1/search_site_messages?s=${searchString}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      // console.log(response)
      dispatch({ type: SEARCH_SITE_MESSAGES_SUCCESS, payload: {site_messages: response.data.site_messages} })
    })
    .catch(error => {
      console.log("ERROR: " + error);
      dispatch({ type: SEARCH_SITE_MESSAGES_FAILED, payload: {error: errorText(error)} });
    });
  }
}

export const showSearchBox = (show) => {
  return (dispatch) => {
    dispatch({ type: SHOW_SEARCH_BOX, payload: {show: show} })
  }
}

export const clearSearch = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SEARCH_RESULTS })
  }
}