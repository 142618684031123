import { updateObject } from '../../utils/utils';
import moment from 'moment';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  SET_FETCHING_EVENTS,
  SET_CALENDAR_EVENTS,
  SET_CURRENT_EVENT,
  JOIN_EVENT_START,
  JOIN_EVENT_END,
  LEAVE_EVENT_START,
  LEAVE_EVENT_END,
  SET_FETCHING_METADATA,
  SET_CALENDAR_METADATA,
  CREATE_EVENT_START,
  CREATE_EVENT_END,
  CANCEL_EVENT_START,
  CANCEL_EVENT_END,
  SET_CALENDAR_EVENT_ERROR,
  RESET_EVENT_STATE,
  EDIT_EVENT_START,
  EDIT_EVENT_END,

  UPDATE_EVENT_START,
  UPDATE_EVENT_END,

  RESERVE_ASSET_START,
  RESERVE_ASSET_FINISHED,
  RESERVE_ASSET_ERROR,
  CLEAR_ASSET_ERROR,

  BEGIN_FETCH_EARLIER,
  SUCCESS_FETCH_EARLIER,
  ERROR_FETCH_EARLIER
} from '../actions/calendarEventActions';

const INITIAL_STATE = {
  // currentEvent: {
  //   id: null,
  //   title: '',
  //   description: '',
  //   creator: '',
  //   begin_date: null,
  //   end_date: null,
  //   event_type: '',
  //   location: {
  //     id: 4,
  //     name: '',
  //     description: '',
  //     street: '',
  //     city: '',
  //     state: '',
  //     zipcode: '',
  //     latitude: -1,
  //     longitude: -1,
  //     url: ''
  //   },
  // },
  searchStartDate: new Date(), // start of search date range
  searchEndDate: null,   // end of search date range
  currentEvent: null,
  eventMetadata: null,
  fetchingMetadata: false,
  calendarEvents: [],
  error: '',
  fetchingEvents: false,
  updatingEvents: false,
  updatedEvent: false,
  updatingEvent: false,
  filterEventTypeList: [
    'Social Gathering', 'Bocce', 'Cocktails', 'Community Project',
    'Kayaking', 'Movies', 'Sailing', 'Meeting',
    'Tennis', 'Walking'
  ]
};

const transformEvent = (event) => {
  if (event.begin_date) {
    event.begin_date = new Date(event.begin_date);
    event.start = new Date(event.begin_date);
  }
  if (event.end_date) {
    event.end_date = new Date(event.end_date);
    event.end = new Date(event.end_date);
  }
}

const transformEvents = (events) => {
  // Transform dates in the events to JS Date objects
  let updEvents = [...events];
  updEvents.forEach(event => {
    transformEvent(event);
  })
  return updEvents;
}

const updateEvents = (state, event) => {
  const events = [...state.calendarEvents];
  const ix = events.findIndex(e => {
    if (e.id === event.id) {
      return true;
    } else {
      return false;
    }
  });
  // debugger
  if (ix >= 0) {
    events[ix] = {...event};
  }
  return events;
}

const reducer = (state = INITIAL_STATE, action) => {
  let calEvents;
  // console.log(`[postReducers]: ${action.type}`, action.payload);
  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    case SET_FETCHING_EVENTS:
      return updateObject(state, {calendarEvents: [], updatingEvents: false, updatedEvent: false, currentEvent: null, fetchingEvents: true, searchStartDate: action.payload.searchStartDate});

    case BEGIN_FETCH_EARLIER:
      return updateObject(state, {updatingEvents: false, updatedEvent: false, currentEvent: null, fetchingEvents: true, 
                                  searchStartDate: action.payload.searchStartDate, searchEndDate: action.payload.searchEndDate});
    
    case SUCCESS_FETCH_EARLIER:
      // TODO: Merge in fetched events
      const fetchedEvents = transformEvents(action.payload.calendarEvents);
      // const mergedEvents = [...earlierEvents, ...state.calendarEvents];
      return updateObject(state, {currentEvent: null, calendarEvents: [...fetchedEvents], fetchingEvents: false});

    case ERROR_FETCH_EARLIER:
      return updateObject(state, {updatedEvent: false, updatingEvents: false, error: action.payload})


    case SET_CALENDAR_EVENTS:
      // debugger;
      const events = transformEvents(action.payload.calendarEvents);
      return updateObject(state, {currentEvent: null, calendarEvents: events, fetchingEvents: false});

    case SET_CURRENT_EVENT:
      return updateObject(state, {currentEvent: action.payload.event});

    case JOIN_EVENT_START:
    case LEAVE_EVENT_START:
      return updateObject(state, {fetchingEvents: true});

    case LEAVE_EVENT_END:
    case JOIN_EVENT_END:
      transformEvent(action.payload.calendar_event);
      calEvents = updateEvents(state, action.payload.calendar_event);
      //XXX: Update event attendees.
      return updateObject(state, {fetchingEvents: false, currentEvent: {...action.payload.calendar_event}, calendarEvents: [...calEvents]});

    case SET_FETCHING_METADATA:
      return updateObject(state, {eventMetadata: null, calendarEvents: [], fetchingMetadata: action.payload});

    case SET_CALENDAR_METADATA:
      return updateObject(state, {fetchingMetadata: false, 
                                  eventMetadata: action.payload.eventMetadata});

    case CREATE_EVENT_START:
      return updateObject(state, {updatingEvents: true});

    case RESERVE_ASSET_START:
      return updateObject(state, {updatingEvents: true});

    case RESERVE_ASSET_FINISHED:
    transformEvent(action.payload.calendar_event);
    return updateObject(state, {updatingEvents: false, calendarEvents: [...state.calendarEvents, {...action.payload.calendar_event}]});

    // return updateObject(state, {updatingEvents: false});

    case RESERVE_ASSET_ERROR:
      return updateObject(state, {updatingEvents: false, error: action.payload.error})
    
    case CLEAR_ASSET_ERROR:
      return updateObject(state, {error: ''});
      
    case CANCEL_EVENT_START:
    case UPDATE_EVENT_START:
      return updateObject(state, {updatedEvent: false, updatingEvents: true});

    case EDIT_EVENT_START:
      // debugger;
      const evId = action.payload.eventId;
      const evIx = state.calendarEvents.findIndex(event => {
        if (event.id === evId) {
          return true;
        } else {
          return false;
        }
      })
      // debugger;
      return updateObject(state, { updatedEvent: false, currentEvent: state.calendarEvents[evIx] });

    case EDIT_EVENT_END:
      let eventToEdit = {...action.payload.calendar_event};
      // debugger;
      transformEvent(eventToEdit);
      return updateObject(state, {updatedEvent: true, currentEvent: eventToEdit, error: ''});
      
    case UPDATE_EVENT_END:
      // Find event
      const ix = state.calendarEvents.findIndex(event => {
        if (event.id === action.payload.calendar_event.id) {
          return true;
        } else {
          return false;
        }
      })
      let editEvents = [...state.calendarEvents];
      let editedEvent = {...action.payload.calendar_event};
      // debugger;
      transformEvent(editedEvent);

      if (ix >= 0) {
        editEvents[ix] = editedEvent
      }
      return updateObject(state, {updatedEvent: true, updatingEvents: false, currentEvent: editedEvent, calendarEvents: editEvents, error: ''});

    case CREATE_EVENT_END:
      return updateObject(state, {updatedEvent: true, updatingEvent: false, updatingEvents: false, error: ''})

    case CANCEL_EVENT_END:
      // Remove the event from the current list if it's there
      // let updatedEventList = [];
      // state.calendarEvents.forEach((ce) => {
      //   if (ce.id !== action.payload.calendar_event.id) {
      //     debugger
      //     updatedEventList.push({...ce})
      //   }
      // })
      return updateObject(state, {updatedEvent: true, updatingEvent: false, updatingEvents: false})
        
    case SET_CALENDAR_EVENT_ERROR:
        return updateObject(state, {updatedEvent: false, updatingEvents: false, error: action.payload})

    case RESET_EVENT_STATE:
        return updateObject(state, {error: '', updatedEvent: false, updatingEvents: false, fetchingEvents: false})

    default:
      return state;
  }
};

export default reducer;
