import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { WHE_BASE_URL } from '../../utils/constants';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  isBrowser
} from 'react-device-detect';

import { createListMessage } from '../../redux/actions/emailListsActions';

import Spinner from '../Spinner/Spinner';

import MessageForm from './MessageForm';

import { setTitle } from '../../redux/actions/controlsActions';
import classes from './ListMessages.module.css';

/*
#  id                 :integer          not null, primary key
#  subject            :string(255)
#  body               :text
#  user_id            :integer
#  created_at         :datetime
#  updated_at         :datetime
#  is_draft           :boolean          default(TRUE)
#  time_sent          :datetime
*/
// :body, :is_draft, :email_list_id, :subject, :time_sent, :user_id

const NewMessage = (props) => {
  let waitingForPost = false;
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  let listMessageDefaults = {
    id: null,
    subject: '',
    body: '',
    is_draft: true,
    user_id: null,
    email_list_id: props.email_list_id
  };
  
  useEffect(() => {
    dispatch(setTitle("New Site Message"));
  }, []);

  let [timeoutId, setTimeoutId] = useState(null);
  const startTimer = () => {
    setTimeout(() => {
      if (waitingForPost) {
        setTimeoutId(startTimer());
      } else {
        setTimeoutId(null);
      }
    }, 1000)
  };

  // handleFormSubmit receives the values from the form in a JS object
  // the postCallback to let the form know we're done processing.
  const handleFormSubmit = (values) => {
    // debugger;
    console.log('[NewMessage]: ***** handleFormSubmit *********')
    dispatch(createListMessage(values))
    if (props.onSubmit) {
      props.onSubmit();
    }
  }

  const renderContent = () => {
    if (timeoutId !== null) {
      return <Spinner />;
    } else {
      //TODO: factor in a callback from props passed in so the calling component can clear anything related...
      //  example: clear the new message form on create
      return <MessageForm edit={false} listMessage={listMessageDefaults} auth={auth} formSubmit={handleFormSubmit} />
    }
  }

  return (
    <Row>
      {isBrowser ? <div className={classes.Title}>Create New List Message</div> : null}
      <Col md={12}>
        {renderContent()}
      </Col>
    </Row>
  );
}

export default NewMessage;
