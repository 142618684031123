import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';

import Home from '../Home/Home';
import About from '../About';
import News from '../Posts/News';
import PostsV2 from '../Posts/PostsV2';
import NewPost from '../Posts/Edit/NewPost';
import EditPost from '../Posts/Edit/EditPost';

// import PhotosImageGallery from '../Photos/Photos';
import Photos from '../Photos/Photos';
import MyPhotos from '../Photos/MyPhotos';

import Neighbors from '../Neighbors/Neighbors';
import Administrative from '../Administrative/Administrative';
import Groups from '../Groups/Groups';
import Authenticate from '../Auth/Authenticate';
import ForgotPassword from '../Auth/ForgotPassword';
// import Register from '../Register/Register';
import Register from '../User/Registration/Register';

import Logout from '../Auth/Logout';

import SiteDefinition from '../SiteDefinition';

import PrivateRoute from './PrivateRoute.js';
import MyPostsV2 from '../Posts/MyPosts/MyPostsV2';
import SiteNews from '../Posts/SiteNews/SiteNews';
import PasswordResets from '../Auth/PasswordResets';
import SiteMessages from '../SiteMessages/SiteMessages';
import SetPassword from '../User/Registration/SetPassword';
import UpdatePassword from '../User/UpdatePassword';
import NewAlbum from '../Photos/Albums/NewAlbum';
import EditAlbum from '../Photos/Albums/EditAlbum';
import NewSiteMessage from '../SiteMessages/Edit/NewSiteMessage';
import MySiteMessages from '../SiteMessages/MySiteMessages';
import EditSiteMessage from '../SiteMessages/Edit/EditSiteMessage';
import User from '../User/User';
import Users from '../User/Users';
import CalendarEventList from '../CalendarEvents/CalendarEventList.js';
import NewEvent from '../CalendarEvents/Edit/NewEvent';
import EditEvent from '../CalendarEvents/Edit/EditEvent';
import CalendarEventView from '../CalendarEvents/CalendarEventView.js';

import SiteDocuments from '../Docs/SiteDocuments';
import EnableDisableEditDocs from '../Docs/Uploader/EnableDisableEditDocs';
import DocumentForm from '../Docs/Uploader/DocumentForm';
import NewDocument from '../Docs/Uploader/NewDocument';
import EditDocument from '../Docs/Uploader/EditDocument';
import AssetCalendarComponent from '../AssetScheduling/AssetCalendarComponent';
import Properties from '../Properties/Properties';
import WheUsers from '../Properties/WheUsers';

import EmailLists from '../ListMessages/EmailLists';
import EmailList from '../ListMessages/EmailList';
// import NewMessage from '../ListMessages/NewMessage';
import EditMessage from '../ListMessages/EditMessage';
import NewEmailList from '../ListMessages/NewEmailList';
// import EditEmailList from '../ListMessages/EditEmailList';

import { PendingSiteMembers } from '../Administrative/PendingSiteMembers';

import MailgunEvents from '../MailgunEvents/MailgunEvents';
import MailgunEventCounts from '../MailgunEvents/MailgunEventCounts.js';

// import ReserveAsset from '../AssetScheduling/Forms/ReserveAsset';

import {
  isMobile
} from 'react-device-detect';
import CloneEvent from '../CalendarEvents/Edit/CloneEvent.js';

class Routes extends Component {

  render() {
    return (
      <div style={{flex: 1}}>
        <Switch>
          <Route exact path="/about" component={About} />
          <PrivateRoute path="/news" component={News} />
          <PrivateRoute path="/calendar_view" hideMarquis={isMobile} component={CalendarEventView} />
          <PrivateRoute path="/calendar_events/:eventId?" hideMarquis={isMobile} component={CalendarEventList} />
          <PrivateRoute path="/calendar/:eventId?" hideMarquis={isMobile} component={CalendarEventList} />
          <PrivateRoute path="/newevent" component={NewEvent} />
          <PrivateRoute path="/editevent/:eventId?" hideMarquis={isMobile} component={EditEvent} />
          <PrivateRoute path="/cloneevent/:eventId?" hideMarquis={isMobile} component={CloneEvent} />

          <PrivateRoute path='/assetcalendar' hideMarquis={isMobile} component={AssetCalendarComponent} />
          {/* <PrivateRoute path='/reserveasset' hideMarquis={isMobile} component={ReserveAsset} /> */}

          <PrivateRoute path="/posts/:postId?" hideMarquis={isMobile} component={PostsV2} />
          <PrivateRoute path="/allposts/:postId?" hideMarquis={isMobile} component={PostsV2} />
          <PrivateRoute exact path="/newpost" hideMarquis={isMobile} component={NewPost} />
          <PrivateRoute exact path="/editpost/:postId" hideMarquis={isMobile} component={EditPost}/>
          {/* <PrivateRoute exact={false} path="/uploadimage" component={ImageUpload} /> */}
          <PrivateRoute path="/myposts/:postId?" hideMarquis={isMobile} component={MyPostsV2} />
          <PrivateRoute path="/profile" hideMarquis={isMobile} component={User} />
          <PrivateRoute path="/directory" component={Users} />
          <PrivateRoute path="/newsitemessage" hideMarquis={isMobile} component={NewSiteMessage} />
          { this.props.auth.app_admin ? <PrivateRoute path="/editsitemessage/:siteMessageId" hideMarquis={isMobile} component={EditSiteMessage} /> : null }
          { this.props.auth.app_admin ? <PrivateRoute path="/mysitemessages" hideMarquis={isMobile} component={MySiteMessages} /> : null }

          <PrivateRoute exact path="/sitenews" hideMarquis={isMobile} component={SiteNews} />
          <PrivateRoute exact path="/photos/:albumId?" hideMarquis component={Photos} />
          <PrivateRoute path="/myphotos/:albumId?" hideMarquis component={MyPhotos} />
          <PrivateRoute exact path="/newalbum" hideMarquis={isMobile} component={NewAlbum} />
          <PrivateRoute exact path="/editalbum/:albumId" hideMarquis={isMobile} component={EditAlbum}/>
          <PrivateRoute exact path="/neighbors" hideMarquis component={Neighbors} />
          { this.props.auth.site_admin ? <PrivateRoute exact path="/siteDefs" component={SiteDefinition} />:null }
          { this.props.auth.site_admin ? <PrivateRoute exact path="/pending_site_members" component={PendingSiteMembers} /> : null };
          
          { this.props.auth.site_admin ? <PrivateRoute exact path="/administrative" component={Administrative} />:null }
          { this.props.auth.app_admin ? <PrivateRoute exact path="/property_list/:regd?" component={Properties} />:null }
          { this.props.auth.app_admin ? <PrivateRoute exact path="/whe_users" component={WheUsers} />:null }
          { this.props.auth.site_admin ? <PrivateRoute exact path="/mailgun_events" component={MailgunEvents} />:null }
          { this.props.auth.site_admin ? <PrivateRoute exact path="/mailgun_event_counts" component={MailgunEventCounts} />:null }

          {/* XXX: /forms is for legacy site links */}
          <PrivateRoute exact path="/forms" component={SiteDocuments} />
          <PrivateRoute exact path="/documents" component={SiteDocuments} />
          <PrivateRoute exact path="/new_doc" component={NewDocument} />
          <PrivateRoute exact path="/edit_doc/:docId" component={EditDocument} />
          <PrivateRoute exact path="/sitedocs" component={SiteDocuments} />
          <PrivateRoute exact path="/editdocs" component={EnableDisableEditDocs} />
          <PrivateRoute exact path="/editdoc/:docId" component={ DocumentForm } />
          <PrivateRoute exact path="/groups" component={Groups} />
          <Route exact path="/" component={Home} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route path="/password_resets/:perishable_token" component={PasswordResets} />
          {/* SetPassword requires the perishable token and is used to complete the signup by
              setting the user's password */}
          <Route path="/register/:perishable_token" component={SetPassword} />
          <PrivateRoute exact path="/update_password" component={UpdatePassword} />
          <Route exact path="/auth" component={Authenticate} />
          <Route exact path="/logout" component={Logout} />

          <PrivateRoute exact path="/sitemessages/:messageId?" hideMarquis={isMobile} component={SiteMessages} />

          {/* Email List Support */}
          { this.props.auth.app_admin ? <PrivateRoute exact path='/emaillists' component={EmailLists} /> : null }
          { this.props.auth.app_admin ? <PrivateRoute exact path='/newemaillist' component={NewEmailList} /> : null }
          { this.props.auth.app_admin ? <PrivateRoute path='/emaillist/:listId?' component={EmailList} /> : null }
          {/* { this.props.auth.app_admin ? <PrivateRoute path="/editemaillist/:listId?" hideMarquis={isMobile} component={EditEmailList} /> : null } */}
          {/* { this.props.auth.app_admin ? <PrivateRoute exact path="/newlistmsg" hideMarquis={isMobile} component={NewMessage} /> : null } */}
          { this.props.auth.app_admin ? <PrivateRoute path="/editemailmsg/:msgId?" hideMarquis={isMobile} component={EditMessage} /> : null }

        </Switch>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}
export default connect(mapStateToProps)(Routes);
