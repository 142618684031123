import React from 'react';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { likeComment, removeComment } from '../../../redux/actions/postActions'; 

import Likes from '../../Likes/Likes';

import { formattedDate } from '../../../utils/utils';
import classes from './Comment.module.css';

const Comment = (props) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { comment } = props;

  const deleteComment = (commentId) => {
    let delComment = window.confirm(`Are you sure you want to delete the comment "${comment.body}"?`);
    if (!delComment) {
      return;
    }
    dispatch(removeComment(commentId))
  }

  const renderDeleteIcon = () => {
    if (auth.site_admin) {
      return (
        <FontAwesomeIcon icon={faTimesCircle} className={classes.Icon} onClick={(e) => {deleteComment(comment.id)}} />
      )
    }
    return null;
  }
  // debugger
  return (
    <div className={classes.Comment}>
      <div className={classes.CommentHdr}>
        {renderDeleteIcon()}
        Posted by: {comment.name} @ {formattedDate(comment.date)}
      </div>
      <div className={classes.CommentBody}>{parse(comment.body)}</div>
      <Likes 
        likes={comment.likes} /*onClick={(e) => {this.setState({showLikesModal: post.likes})}}*/ 
        onClick={(e) => {props.likesClicked()}}
        likeClicked={() => {dispatch(likeComment(comment))}}
      />
    </div>
  );
}

export default Comment;
