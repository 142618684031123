import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { useFormik } from 'formik';

import classes from './CancelEventForm.module.css';

const CancelEventForm = (props) => {
  const initialValues = {
    send_notifications: true,
    cancel_event: true
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      // TODO: ADD onSubmit to caller
      // debugger
      props.onSubmit(values);
    }
  });

  const handleRadioChange = (event) => {
    // alert(`handleRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
      } else {
        formik.setFieldValue(event.target.name, false);
      }
    }
  }

  return (
    <Form onSubmit={formik.handleSubmit}>

      <InputGroup>
        {/* <InputGroup.Prepend className={classes.InputGroup}>
          <InputGroup.Text id="send_notifications_">Send Notification?</InputGroup.Text>
        </InputGroup.Prepend> */}
        <p>Send Cancel Notifications?</p>
        <Form.Label className={classes.Radio} htmlFor='send_notifications_true'>YES</Form.Label>
        <Form.Check
          disabled={formik.isSubmitting}
          type='radio'
          as='input'
          id="send_notifications_true"
          className={classes.Radio}
          checked={formik.values.send_notifications === true}
          value={true}
          name='send_notifications'
          onChange={handleRadioChange}
          isInvalid={formik.errors['send_notifications']}
          isValid={!formik.errors['send_notifications']}
        />
        <Form.Label className={classes.Radio} htmlFor='send_notifications_false'>NO</Form.Label>
        <Form.Check
          disabled={formik.isSubmitting}
          styles={[{marginLeft: 20}]}
          type='radio'
          as='input'
          id="send_notifications_false"
          className={classes.Radio}
          checked={formik.values.send_notifications === false}
          value={false}
          name='send_notifications'
          onChange={handleRadioChange}
          isInvalid={formik.errors['send_notifications']}
          isValid={!formik.errors['send_notifications']}
        />
      </InputGroup>
      <InputGroup>
        {/* <InputGroup.Prepend> */}
          <p className={classes.CancelIt}>Cancel the event?</p>
        {/* </InputGroup.Prepend> */}
        <div className={classes.ButtonContainer}>
          <Button size="sm" color="secondary" className={classes.YesButton} type="submit">Yes</Button>
          <Button size="sm" color="secondary" type="cancel" onClick={props.onCancel}>No</Button>
        </div>
      </InputGroup>

    </Form>
  );
}

export default CancelEventForm;