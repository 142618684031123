import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Accordion from 'react-bootstrap/Accordion';

import { connect } from 'react-redux';
import { withRouter } from "react-router";

import classes from './SiteMessages.module.css';
import SiteMessage from './SiteMessage/SiteMessage';

import SiteMessageToc from './SiteMessageToc';

// import PostToc from './PostToc';
// import PostTocUl from './PostTocUl';
// import PostTocMoc from './PostTocMoc';
import Spinner from '../Spinner/Spinner';
import ModalError from '../Errors/ModalError/ModalError';

import SideDrawerRightMobile from '../Navigation/SideDrawer/SideDrawerRightMobile';
import Backdrop from '../UI/Backdrop/Backdrop';

import SiteMessageSearchResultsContent from '../Search/SiteMessageSearchResultsContent';

import SearchBox from '../Search/SearchBox';
import { closeDrawer, 
         toggleDrawer, 
         showDrawerButton, 
         hideDrawerButton, 
         setTitle } from '../../redux/actions/controlsActions';

import { getMySiteMessages,
         getPostedSiteMessages,
         setCurrentMessage,
         initSiteMessageState} from '../../redux/actions/siteMessageActions';

import { showSearchBox, searchSiteMessages, clearSearch } from '../../redux/actions/searchActions';

let defaultMessageId = -1;
class SiteMessagesCommon extends Component {
  constructor(props) {
    super(props);
    this.postToc = {};
    // const [open, setOpen] = useState(false);
    // debugger;

    if (this.props.match.params.messageId) {
      defaultMessageId = parseInt(this.props.match.params.messageId);
    }
    if (isNaN(defaultMessageId)) {
      props.history.replace("/mysitemessages"); // cover the case of re-login and the :messageId is passed on the URL
    }
  }

  componentDidMount() {
    this.props.closeDrawer();
    this.props.showButton();
    this.props.setTitle(this.props.title);
    console.log('[SiteMessagesCommon]: componentDidMount', this.props.auth);

    if (this.props.myDrafts) {
      console.log('[SiteMessagesCommon: getting ALL')
      this.props.getMySiteMessages(defaultMessageId);
      return;
    }

    if (this.props.allPosted) {
      console.log('[SiteMessagesCommon: getting MY drafts')
      this.props.getPostedSiteMessages(defaultMessageId);
      return;
    }
  }

  componentWillUnmount() {
    this.props.hideButton();
    // this.props.hideSearchBox();
    this.props.clearSearch();
  }

  tocEntryClicked = (post) => {
    console.log('[SiteMessagesCommon][tocEntryClicked]')
    let postIx = this.props.siteMessages.messages.findIndex(p => p.id === post.id);
    if (postIx !== -1 && post.id !== this.props.siteMessages.currentMessage.id) {
      this.props.setCurrentMessage(this.props.siteMessages.messages[postIx]);
      this.props.closeDrawer();
    }
  }

  toggleDrawer = () => {
    this.props.toggleDrawer();
  }
  closeDrawer = () => {
    console.log('closeDrawer')
    this.props.closeDrawer();
  }

  renderPost() {
    console.log('***** renderPost *****')

    const okToEdit = this.props.okToEdit;

    return (
      <div className={classes.SiteMessageContainer}>
        <SiteMessage
          key={this.props.siteMessages.currentMessage.id} 
          siteMessage={this.props.siteMessages.currentMessage} 
          okToEdit={okToEdit}
        />
      </div>
    );
  }
  renderPostContent() {
    const siteMessagesCommon = this.props.siteMessages.messages;
    // debugger;

    this.postToc = {};
    if (siteMessagesCommon.length === 0) {
      return (
        <Row>
          <Col sm={12} md={9} className={classes.SiteMessagesCommon}>
            <h4 style={{textAlign: 'center', paddingTop: '10px'}}>[You have no draft site messages.]</h4>
          </Col>
        </Row>
      );
    }
    let curYear = null;
    let curYearToc = [];
    siteMessagesCommon.forEach((post) => {
      if (curYear === null || curYear !== new Date(post.updated_at).getFullYear()) {
        curYear = new Date(post.updated_at).getFullYear();
        curYearToc = [];
        this.postToc[curYear] = curYearToc;
      }
      curYearToc.push(post);
    });

    // console.log('PostTOC Object:', this.postToc);
    const spinnerTitle = `Loading ${this.props.title}...`;
    let postContent = (
      <Row>
        <Col sm={12} md={9} className={classes.SiteMessagesCommon}>
          <SearchBox placeholder="Search Site Messages..." searchProc={this.searchSiteMessagessProc}  />
          {/* {postContentBody} */}
          {this.props.siteMessages.currentMessage.id === null ? 
            null:
            (this.props.siteMessages.working ? <Spinner title={spinnerTitle} /> :
            this.renderPost())
          }
          
        </Col>
        <Col sm={3} className={[classes.Toc, classes.TocDesktop]}>
          {this.props.siteMessages.messages.length > 0 ? <SiteMessageToc title={this.props.title} clicked={this.tocEntryClicked} postToc={this.postToc} currentPost={this.props.siteMessages.currentMessage} /> : console.log("**** SiteMessages 1 NOT READY ***")}
        </Col>
      </Row>
    );
    return postContent;
  }

  searchSiteMessagessProc = searchValue => {
    // do the search
    if (searchValue.length > 2) {
      this.props.searchSiteMessages(searchValue);
    }
  }

  renderPostToc() {
    // console.log(`[SiteMessagesCommon]: renderPostToc ******`, this.postToc)
    // debugger;
    return (
      <Row>
        <Col className={classes.Toc}>
          {this.props.siteMessages.messages.length > 0 ? <SiteMessageToc title={this.props.title} clicked={this.tocEntryClicked} postToc={this.postToc} currentPost={this.props.siteMessages.currentMessage} /> : console.log("**** SiteMessages 2 NOT READY ***")}
        </Col>
      </Row>
    );
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextState.loading && nextState.siteMessagesCommon === this.state.siteMessagesCommon) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
  clearError = () => {
    this.props.initSiteMessageState();
  }
  
  renderBackdrop = () => {
    let backdrop;
    if (this.props.controls.drawerOpen) {
      // console.log('*** MY POSTS *** backdrop SHOW')
      backdrop = <Backdrop show={this.props.controls.drawerOpen} click={this.closeDrawer} />;
    } else {
      // console.log('Backdrop not shown')
    }
    return backdrop;
  }

  renderSearchResultsContent = () => {
    return (
      <SiteMessageSearchResultsContent />
    )
  }
  renderSiteMessageContent = () => {
    const postContent = this.renderPostContent();
    return (
      <div className={classes.SiteMessagesContainer}>
        <Row>
          <Col sm={12} className={classes.MobileOnly}>
            <SideDrawerRightMobile
              contentPanel={this.renderPostToc()}
              open={this.props.controls.drawerOpen}
              closed={this.toggleDrawer}
            />
            {this.renderBackdrop()}
            <Backdrop />
          </Col>
        </Row>

        { postContent }
      </div>
    );
  }

  renderContent = () => {
    if (this.props.search.searched) {
      return this.renderSearchResultsContent();
    }
    return this.renderSiteMessageContent()
  }

  render() {
    // console.log('[SiteMessagesCommon]: rendering SiteMessagesCommon')
    if (this.props.siteMessages.error) {
      const errMsg = `Error of "${this.props.siteMessages.error}" while getting SiteMessagesCommon.`;
      return <ModalError
              logoutUser
              redirect="/auth"
              error={errMsg}
              header="Error fetching SiteMessagesCommon."
              onClose={this.clearError}
            />
    }

    const spinnerTitle = `Loading ${this.props.title}...`;
    if (this.props.siteMessages.working) {
      return <Spinner title={spinnerTitle} />;
    }
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    controls: state.controls,
    siteMessages: state.siteMessages,
    search: state.search
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    getMySiteMessages: (messageId) => dispatch(getMySiteMessages(messageId)), 
    getPostedSiteMessages: (messageId) => dispatch(getPostedSiteMessages(messageId)),
    setCurrentMessage: (siteMessage) => dispatch(setCurrentMessage(siteMessage)),
    initSiteMessageState: () => dispatch(initSiteMessageState()),

    closeDrawer: () => dispatch(closeDrawer()),
    toggleDrawer: () => dispatch(toggleDrawer()),

    showButton: () => { dispatch(showDrawerButton()) },
    hideButton: () => { dispatch(hideDrawerButton()) },
    setTitle: (title) => {dispatch(setTitle(title))},

    hideSearchBox: () => {dispatch(showSearchBox(false))},
    searchSiteMessages: (searchString) => {dispatch(searchSiteMessages(searchString))},
    clearSearch: () => {dispatch(clearSearch())}
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteMessagesCommon));
