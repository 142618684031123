import React, { Component } from 'react';

import { Circle } from 'rc-progress';

class SpinnerV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {percent: 0, color: '#F35D2d', count: 0}
    this.timerId = null;
  }

  startSpinner = () => {
    this.timerId = setTimeout(() => {
      this.setState({percent: (this.state.percent + 1) % 100, count: this.state.count + 1})

      // if (this.state.count === 200) {
      //   return;
      // }
      this.startSpinner();
    }, 10);  
  }

  componentDidMount() {
    this.startSpinner();
  }

  componentWillUnmount() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  render() {
    // const color = "#3FC7FA";
    const circleContainerStyle = {
      width: '150px',
      height: '150px',
      display: 'inline-block',
    };

    const title = (this.props.title ? this.props.title : "Loading...")
    return (
      <div style={{textAlign: 'center'}}>
        <h5>{title}</h5>
        <div style={circleContainerStyle}>
          <Circle
            percent={this.state.percent}
            strokeWidth="8" strokeLinecap="round"
            strokeColor={{
              "0%": '#EEAA7B',
              "100%": '#ff3c08'
            }}
          />
        </div>
      </div>
    );
  }
}
export default SpinnerV2;
