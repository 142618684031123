import uploadFileTypes from './uploadFile.types'
import { modifyFiles } from './uploadFile.utils'

const INITIAL_STATE = {
  fileProgress: {
    // format will be like below
    // 1: {
    //   id: 1,
    //   file,
    //   progress: 0,
    //   cancelSource: source,
    //   status: 0,
    //   message: ''
    // },
  },
  count: 0
}

const fileProgressReducer = (state = INITIAL_STATE, action) => {
  console.log('upload.fileReducer: ', action)
  switch (action.type) {
    case uploadFileTypes.SET_UPLOAD_FILE:
      // XXX: Should never be able to kickoff another upload while
      //   one is in progress
      if (state.count > 0) {
        debugger;
      }
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.payload),
        },
        count: action.payload.length
      }

    case uploadFileTypes.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          },
        },
      }

    case uploadFileTypes.SUCCESS_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload],
            status: 1,
            file: action.payload.file
          },
        },
        count: state.count - 1
      }

    case uploadFileTypes.FAILURE_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            status: 0,
            // progress: 0,
            // WDS: FAIL!  Force to 100 so it doesn't continue to try and upload
            progress: 100,
            message: action.payload.message
          },
        },
        count: state.count - 1
      }

    case uploadFileTypes.CLEANUP_UPLOADS:
      return INITIAL_STATE;

    default:
      return state
  }
}

export default fileProgressReducer
