export const SET_MAP = 'set_map';
export const SET_SELECTED_ADDR = 'set_selected_addr';

export const setMap = (map) => {
  return (dispatch) => {
    if (map) {
      dispatch({type: SET_MAP, payload: {map: map}});
    } else {
      debugger;
    }
  }
}

export const setCenter = (lat, lng) => {
  return (dispatch, getState) => {
    const map = getState().map;
    if (map && map.map) {
      // debugger
      map.map.setCenter({lat: lat, lng: lng});
      map.map.setZoom(20);
    } else {
      debugger;
    }
  }
}

export const setSelectedAddr = (addr) => {
  return (dispatch) => {
    dispatch({type: SET_SELECTED_ADDR, payload: {addr: addr}})
  }
}
