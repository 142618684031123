import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Spinner from '../Spinner/Spinner';
import Input from '../UI/Input';

import { updatePassword, CLEAR_UPDATING } from '../../redux/actions/authActions';

import classes from '../Auth/Authenticate.module.css';

const UpdatePassword = (props) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const schema = Yup.object({
    password: Yup.string()
      .min(8, 'Must be 8 characters or more')
      .max(32, 'Must be 32 characters or less')
      .required('Required'),
    password_confirmation: Yup.string()
      .min(8, 'Must be 8 characters or more')
      .max(32, 'Must be 32 characters or less')
      .required('Required'),
    current_password: Yup.string()
      .required('Required')
});
  
  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
      current_password: ''
    },
    // validateOnChange: true,
    validationSchema: schema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  
  const handleFormSubmit = (values) => {
    // const keys = Object.keys(values);
    console.log("[UpdatePassword]:", values);
    if (values.password !== values.password_confirmation) {
      alert("Password and Password Confirm must match.");
      return false;
    }
    dispatch(updatePassword(auth, values.current_password, values.password, values.password_confirmation));
  }

  const buildForm = () => {
    console.log('buildForm', props)
    return (
      <Row>
        <Col sm={12} className={classes.AuthCol}>
          <div className={classes.SigninBox}>
            <h4 className={classes.FormTitle}>Change Your Password</h4>
            <h5 style={{color: 'red', textAlign: 'center'}}>{auth.error}</h5>
            {props.location && props.location.state && props.location.state.error ? <p className={classes.FormError}>{props.location.state.error}</p>:null}
            <Form
              onSubmit={formik.handleSubmit}
              className={classes.Form}
            >
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='current_password'
                  type="password"
                  required
                  label="Current Password"
                  value={formik.values.current_password}
                  name='current_password'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.current_password}
                  touched={formik.touched.current_password}
                />
              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <Input
                  id='password'
                  type="password"
                  required
                  label="Password"
                  value={formik.values.password}
                  name='password'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.password}
                  touched={formik.touched.password}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='password_confirmation'
                  type="password"
                  required
                  label="Password Confirm"
                  value={formik.values.password_confirmation}
                  name='password_confirmation'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.password_confirmation}
                  touched={formik.touched.password_confirmation}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend>
                </InputGroup.Prepend>
                <Button type="submit">Update</Button>
                <Button style={{marginLeft:'40px'}} type="submit" onClick={() => {props.history.goBack()}}>Cancel</Button>
              </InputGroup>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }

  // console.log(`UpdatePassword: ${auth.logged_in}`)
  // setComponentRenderCount(componentRenderCount + 1)
  if (auth.updating) {
    return <Spinner />;
  } else {
    if (auth.didUpdate) {
      dispatch({type: CLEAR_UPDATING})
      return <Redirect to={"/"} />;
    }
    return buildForm();
  }
}

export default UpdatePassword;