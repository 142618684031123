import React, {useState} from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Input from '../UI/Input';

import { POST_EDITOR } from '../../utils/constants';
import TinyMceEditor from '../Posts/PostEditor/TinyMceEditor';

import classes from './ListMessages.module.css';

const schema = Yup.object({
  subject: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(80, 'Must be 32 characters or less')
    .required('Required'),
  // body: Yup.string()
  //   .min(3, 'Must be 3 characters or more')
  //   .max(80, 'Must be 32 characters or less')
  //   .required('Required')
});

const MessageForm = (props) => {
  const [bodyContent, setBodyContent] = useState(props.listMessage.body);

  const getPostButtonText = () => {
    if (props.edit) {
      if (props.listMessage.is_draft) {
        return "Update";
      } else {
        return "Update and Send";
      }
    } else {
      return "Save";
    }
  }

  const [formButton, setFormButton] = useState(getPostButtonText());

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      id: props.listMessage.id,
      subject: props.listMessage.subject,
      body: props.listMessage.body,
      is_draft: props.listMessage.is_draft,
      user_id: props.auth.uid,
      email_list_id: props.listMessage.email_list_id
    },
    validationSchema: schema,
    validateOnChange: true,
    // "validateOnBlur": true,
    onSubmit: (values) => {
      // debugger;
      console.log(`MessageForm: formik.onSubmit`)
      const updValues = {...values, body: bodyContent};
      // debugger
      props.formSubmit(updValues);
    },
  });
  
//  console.log(`MessageForm: ... Values: ${JSON.stringify(props.listMessage)}`)

  const handlePublishedRadioChange = (event) => {
    // console.log(`handlePublishedRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
        if (props.edit) {
          setFormButton("Update");
        } else {
          setFormButton("Save");
        }
      } else {
        formik.setFieldValue(event.target.name, false);
        if (props.edit) {
          setFormButton("Update and Send");
        } else {
          setFormButton("Save and Send");
        }
      }
    }
  }
  
  const updateEditorBody = (body) => {
    // console.log(`[MessageForm]: updatePostContent: contentIN: ${body}`);
    // formik.setFieldValue("body", body);
  }

  const onChangeBody = (body) => {
    // console.log(`onChangeBody: ${body}`)
    setBodyContent(body);
  }

  const handleSubmitWrapper = (e) => {
    // if (formik.errors.subject || formik.errors.body) {
    //   alert(`Formik Errors: ${JSON.stringify(formik.errors)}`)
    // } else {
    //   alert("NO Errors found.  invoking handleSubmit")
    // }
    formik.handleSubmit(e);
  }

  const renderContent = () => {
    return(
      <Form onSubmit={handleSubmitWrapper}>
        <Input
          style={{fontSize: '18px'}}
          placeholder="Enter Message Subject"
          noPrepend
          disabled={formik.isSubmitting}
          id='subject'
          required
          label="Subject"
          value={formik.values.subject}
          name='subject'
          changed={formik.handleChange}
          blur={formik.handleBlur}
          errors={formik.errors.subject}
          touched={formik.touched.subject}
          showValid
        />
          
        <InputGroup>
          <div className={classes.Published}>Is draft? </div>
          <Form.Label className={classes.Radio} htmlFor='is_draft_true'>YES</Form.Label>
          <Form.Check
            disabled={formik.isSubmitting}
            type='radio'
            as='input'
            id="is_draft_true"
            className={classes.Radio}
            checked={formik.values.is_draft === true}
            value={true}
            name='is_draft'
            onChange={handlePublishedRadioChange}
            isInvalid={formik.errors['is_draft']}
            isValid={!formik.errors['is_draft']}
          />
          <Form.Label className={classes.Radio} htmlFor='is_draft_false'>NO</Form.Label>
          <Form.Check
            disabled={formik.isSubmitting}
            styles={[{marginLeft: '20px'}]}
            type='radio'
            as='input'
            id="is_draft_false"
            className={classes.Radio}
            checked={formik.values.is_draft === false}
            value={false}
            name='is_draft'
            onChange={handlePublishedRadioChange}
            isInvalid={formik.errors['is_draft']}
            isValid={!formik.errors['is_draft']}
          />
        </InputGroup>
        <TinyMceEditor 
          value={formik.values.body} 
          auth={props.auth}
          blur={updateEditorBody}
          changed={onChangeBody}
          editorType={POST_EDITOR}
          placeholder={"Enter your message"}
        />
        {/* {renderErrors()} */}
        {/* <input type='hidden' name='id' value={formik.values.id} /> */}
        <input type="hidden" name="body" value={bodyContent} />
        <input type="hidden" name="user_id" value={formik.values.user_id} />
        <input type="hidden" name="email_list_id" value={formik.values.email_list_id} />
        <InputGroup className={classes.SubmitButton}>
          <Button
            size="sm"
            type="submit"
            disabled={formik.isSubmitting}
            // onClick={() => alert(`FormSubmit: Errors: ${JSON.stringify(formik.errors)}`)}
          >
            {formButton} Message
          </Button>
        </InputGroup>
      </Form>
    );
  }

  // debugger;
  // Render the form
//  console.log(`[MessageForm]: props.listMessage: ${JSON.stringify(props.listMessage)} formikValues: ${JSON.stringify(formik.values)}`)
  return (
    <div className={classes.FormContainer}>
      {renderContent()}
    </div>
  );
}

export default MessageForm;
MessageForm.whyDidYouRender = {
  logOnDifferentValues: true,
  trackAllPureComponents: true,
  trackHooks: true
}