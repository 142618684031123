import React from 'react';

import classes from './Toolbar.module.css';
// import Logo from '../../Logo/Logo';

import { useDispatch, useSelector } from 'react-redux';

import { toggleDrawer } from '../../../redux/actions/controlsActions';

import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggleButton from '../SideDrawer/DrawerToggle/DrawerToggleButton';
import TocDrawerToggleButton from '../../Navigation/SideDrawer/DrawerToggle/TocDrawerToggleButton';

const Toolbar = (props) => {
  const dispatch = useDispatch();
  const controls = useSelector(state => state.controls);

  const ourToggleDrawer = () => {
    console.log('drawerToggled...')
    if (props.isAuth) {
      dispatch(toggleDrawer());
    }
  };

  return (
    <header className={classes.Toolbar}>
      {/* <div className={[classes.LogoName, classes.DesktopOnly].join(' ')}>WHE</div> */}
      <div className={classes.ToolbarButtonToggle}>
        <DrawerToggleButton clicked={props.drawerToggleClicked} />
      </div>
      {/* <div className={classes.Logo}>
        <Logo />
      </div> */}
      <div className={classes.MobileTitle}>
        <span className={classes.LogoName}>WHE</span>
        {controls.title}
      </div>
      <div className={classes.ToolbarButtonToggle}>
        {props.isAuth ? <TocDrawerToggleButton clicked={ourToggleDrawer} /> : <div style={{width:'40px'}} />}
      </div>
      <nav className={classes.DesktopOnly}>
        <NavigationItems isAuthenticated={props.isAuth} />
      </nav>
    </header>
  
  );
}

export default Toolbar;
