import React, {useRef} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import classes from './Likes.module.css';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const Likes = (props) => {
  const auth = useSelector(state => state.auth);
  const target = useRef(null);

  const renderLikes = () => {
    let likes = "";

    if (props.likes === undefined) {
      return null;
    }
    if (props.likes.length === 0) {
      return null;
    }

    let you = false;
    props.likes.forEach(like => {
      if (like.user_id === auth.uid) {
        you = true;
      }
    });

    const likeType = props.likes[0].likable_type.toLowerCase();
    if (you) {
      if (props.likes.length > 1) {
        likes = `you and ${props.likes.length - 1} ${(props.likes.length > 2 ? "others":"other")} like this ${likeType}`;
      } else {
        likes = `you like this ${likeType}`;
      }
    } else {
      if (props.likes.length > 0) {
        likes = `${props.likes.length} like this ${likeType}`;
      }
    }
    return `(${likes})`;
  }

  return (
    <React.Fragment>
      <div ref={target}>
        <div className={classes.LikeIcon} onClick={() => {props.likeClicked()}}>
          <FontAwesomeIcon icon={faThumbsUp} />
          <span className={classes.Like}>Like</span>
        </div>
        <div className={classes.LikesInfo}>
          <div onClick={(e) => {props.onClick()}}>{renderLikes()}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Likes;
