import axios from '../../utils/axios-whe';
import moment from 'moment';

export const SET_FETCHING_EVENTS = 'set_fetching_events';
export const SET_CALENDAR_EVENTS = 'set_calendar_events';
export const SET_CURRENT_EVENT = 'set_current_event';
export const SET_CALENDAR_EVENT_ERROR = 'set_calendar_event_error';
export const JOIN_EVENT_START = 'join_event_start';
export const JOIN_EVENT_END = 'join_event_end';
export const LEAVE_EVENT_START = 'leave_event_start';
export const LEAVE_EVENT_END = 'leave_event_end';
export const CREATE_EVENT_START = 'create_event_start';
export const CREATE_EVENT_END = 'create_event_end';
export const UPDATE_EVENT_START = 'update_event_start';
export const UPDATE_EVENT_END = 'update_event_end';
export const EVENT_OP_ERROR = 'event_op_error';

export const SET_FETCHING_METADATA = 'set_fetching_metadata';
export const SET_CALENDAR_METADATA = 'set_calendar_metadata';
export const CANCEL_EVENT_START = 'cancel_event_start';
export const CANCEL_EVENT_END = 'cancel_event_end';
export const RESET_EVENT_STATE = 'reset_event_state';

export const EDIT_EVENT_START = 'edit_event_start';
export const EDIT_EVENT_END = 'edit_event_end';

export const RESERVE_ASSET_START = 'reserve_asset_start';
export const RESERVE_ASSET_FINISHED = 'reserve_asset_finished';
export const RESERVE_ASSET_ERROR = 'reserve_asset_error';
export const CLEAR_ASSET_ERROR = 'clear_asset_error';

export const BEGIN_FETCH_EARLIER = "begin_fetch_earlier";
export const SUCCESS_FETCH_EARLIER = "success_fetch_earlier";
export const ERROR_FETCH_EARLIER = "error_fetch_earlier";

export const getEventMetadata = () => {
  return (dispatch, getState) => {
    dispatch({ type: SET_FETCHING_METADATA, payload: true })
    const auth = getState().auth;
    axios.get('/api_v1/event_metadata', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      dispatch({ type: SET_CALENDAR_METADATA, payload: {eventMetadata: response.data.metadata} })
    })
    .catch(error => {
      // debugger;
      console.error("ERROR: " + error);
      dispatch({ type: SET_CALENDAR_EVENT_ERROR, payload: error });
      dispatch({ type: SET_FETCHING_METADATA, payload: false })
    });
  }

}

export const getCalendarEventsFromTillCurrentStart = (startDate) => {

  return (dispatch, getState) => {
    const endDate = moment(startDate).add(6, 'months').toDate();

    const startTime = startDate.getTime() / 1000
    const endTime = endDate.getTime() / 1000;
  
    dispatch({type: BEGIN_FETCH_EARLIER, payload: {searchStartDate: startDate, searchEndDate: endDate} })

    const auth = getState().auth;
    const url = `/api_v1/site_content/cal_events_rest?start=${startTime}&end=${endTime}`;
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger
      console.log(response)
      dispatch({ type: SUCCESS_FETCH_EARLIER, payload: {calendarEvents: response.data} })
    })
    .catch(error => {
      // debugger;
      console.error("ERROR: " + error);
      dispatch({ type: ERROR_FETCH_EARLIER, payload: error });

      // dispatch({ type: SET_CALENDAR_EVENT_ERROR, payload: error });
      // dispatch({ type: SET_FETCHING_EVENTS, payload: {fetching: false} })
    });

  }
}
export const getCalendarEvents = (startDate, startMonthBias=0, endMonthBias=6) => {
  // startDate is the beginning date to search events for
  // startMonthBias is the number of months before the requested start date to fetch
  // endMonthBias is the number of months ahead to search for events.

  let start = new Date ( startDate.getFullYear(), startDate.getMonth()-startMonthBias, 
                         (startMonthBias === 0 ? startDate.getDate() : 1));
  // alert(`StartDate: ${startDate}  START: ${start}`)
  let temp = new Date(startDate);
  const endDate = new Date();
  let end = new Date(endDate.setMonth(endDate.getMonth()+endMonthBias))

  const startTime = start.getTime() / 1000
  const endTime = end.getTime() / 1000;

  return (dispatch, getState) => {
    dispatch({ type: SET_FETCHING_EVENTS, payload: {searchStartDate: start} })
    const auth = getState().auth;
    const url = `/api_v1/site_content/cal_events_rest?start=${startTime}&end=${endTime}`;
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(response)
      dispatch({ type: SET_CALENDAR_EVENTS, payload: {calendarEvents: response.data} })
    })
    .catch(error => {
      // debugger;
      console.error("ERROR: " + error);
      dispatch({ type: SET_CALENDAR_EVENT_ERROR, payload: error });
      dispatch({ type: SET_FETCHING_EVENTS, payload: {fetching: false} })
    });
  }
}

export const setCurrentEvent = (event) => {
  // debugger;
  return (dispatch, getState) => {
    dispatch({ type: SET_CURRENT_EVENT, payload: {event: event} });
  }
}

export const clearError = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_ASSET_ERROR, payload: true })
  }
}
export const reserveAsset = (assetId, beginTime, duration, title, description) => {
  return (dispatch, getState) => {
    dispatch({ type: RESERVE_ASSET_START, payload: true })
    const auth = getState().auth;
    const url = `/api_v1/reserve_asset`;

    let formJson = {
      "asset": {
        "asset_id": assetId,
        "begin_time": beginTime.toString(),
        "duration": duration,
        "title": title,
        "description": description,
        "site_definition_id": 2,
        "neighborhood_id": 2
      }
    };

    axios.post(url, formJson, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(response)
      dispatch({ type: RESERVE_ASSET_FINISHED, payload: {calendar_event: response.data.calendar_event} })
    })
    .catch(error => {
      // debugger;
      console.error("ERROR: " + error);
      let err = error;
      if (error.response?.data?.error) {
        err = error.response.data.error;
      }
      dispatch({ type: RESERVE_ASSET_ERROR, payload: {error: err} });
    });
  }
}

export const joinEvent = (event, numGuests) => {
  return (dispatch, getState) => {
    dispatch({ type: JOIN_EVENT_START, payload: true })
    const auth = getState().auth;
    const url = `/api_v1/join_event/${event.id}?num_guests=${numGuests}`;
    axios.post(url, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      dispatch({ type: JOIN_EVENT_END, payload: {calendar_event: response.data.calendar_event} })
    })
    .catch(error => {
      // debugger;
      console.error("ERROR: " + error);
      dispatch({ type: SET_CALENDAR_EVENT_ERROR, payload: error });
    });
  }
}

export const leaveEvent = (event) => {
  return (dispatch, getState) => {
    dispatch({ type: LEAVE_EVENT_START, payload: true })
    const auth = getState().auth;
    const url = `/api_v1/leave_event/${event.id}`;
    axios.post(url, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      dispatch({ type: LEAVE_EVENT_END, payload: {calendar_event: response.data.calendar_event} })
    })
    .catch(error => {
      // debugger;
      console.error("ERROR: " + error);
      dispatch({ type: SET_CALENDAR_EVENT_ERROR, payload: error });
    });
  }
}

export const createEvent = (eventValues) => {
  const sendEmail = eventValues.send_email;
  delete eventValues.send_email;
  const groups = eventValues.group_ids;
  delete eventValues.group_ids;
  const formJson = {
    "send_notify": sendEmail.toString(),
    "group_ids": groups,
    "calendar_event": {...eventValues }
  };
  // debugger
//XXX: ADD GROUP IDS....
//XXX: Change send_notify to a string
  return (dispatch, getState) => {
    dispatch({ type: CREATE_EVENT_START })
    const auth = getState().auth;
    const url = `/api_v1/calendar_events.json`;
    axios.post(url, formJson, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      dispatch({ type: CREATE_EVENT_END, payload: {calendar_event: response.data.calendar_event} })
    })
    .catch(error => {
      // debugger;
      console.error("ERROR: " + error);
      // debugger
      dispatch({ type: SET_CALENDAR_EVENT_ERROR, payload: (error.response?.data ? error.response.data:error.response.statusText) });
    });
  }
}

export const editEvent = ( eventId, auth ) => {
  console.log(`calendarEventActions: editEvent: ID: ${eventId}`)
  return (dispatch, getState) => {
    dispatch({ type: EDIT_EVENT_START, payload: {eventId: eventId} })
    axios.get(`/api_v1/calendar_events/${eventId}/edit`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      // dispatch({ type: SET_CURRENT_POST, payload: response.data.post })
      dispatch({ type: EDIT_EVENT_END, payload: response.data })
    })
    .catch(error => {
      console.log("ERROR: " + error);
      dispatch({ type: SET_CALENDAR_EVENT_ERROR, payload: error });
    });
  }
}


export const updateEvent = ( event ) => {
  console.log(`calendarEventActions: updateEvent: ID: ${event.id}`)
  const sendEmail = event.send_email;
  delete event.send_email;
  const groups = event.group_ids;
  delete event.group_ids;
  if (event.groups) {
    delete event.groups;
  }
  const formJson = {
    "send_notify": sendEmail.toString(),
    "group_ids": groups,
    "calendar_event": {...event }
  };

  return (dispatch, getState) => {
    const auth = getState().auth;
    // const formJson = {
    //   "calendar_event": {...event}
    // };
    dispatch({ type: UPDATE_EVENT_START })
    // debugger;
    axios.patch(`/api_v1/calendar_events/${event.id}`, formJson, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      // dispatch({ type: SET_CURRENT_POST, payload: response.data.post })
      dispatch({ type: UPDATE_EVENT_END, payload: response.data })
    })
    .catch(error => {
      console.log("ERROR: " + error);
      dispatch({ type: SET_CALENDAR_EVENT_ERROR, payload: error });
    });
  }
}


export const cancelEvent = (event, sendEmailNotifications) => {
  return (dispatch, getState) => {
    dispatch({ type: CANCEL_EVENT_START })
    const auth = getState().auth;
    const url = `/api_v1/calendar_events/${event.id}?send_notify=${sendEmailNotifications}`;
    axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      dispatch({ type: CANCEL_EVENT_END, payload: {calendar_event: {...event}} })
    })
    .catch(error => {
      // debugger;
      console.error("ERROR: " + error);
      dispatch({ type: SET_CALENDAR_EVENT_ERROR, payload: error });
    });
  }
}

export const resetUpdated = () => {
  return (dispatch) => {
    dispatch({ type: RESET_EVENT_STATE })
  }
}
