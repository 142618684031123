import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { COMMENT_EDITOR } from '../../../utils/constants';
import TinyMceEditor from '../PostEditor/TinyMceEditor';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { addComment } from '../../../redux/actions/postActions';

import Spinner from '../../Spinner/Spinner';
import classes from './Comment.module.css';

const CommentForm = (props) => {
  const auth = useSelector(state => state.auth);
  const posts = useSelector(state => state.posts);
  const dispatch = useDispatch();
  const [addingComment, setAddingComment] = useState(false);

  useEffect(() => {
    if (!posts.working) {
      setAddingComment(false)
      // TODO: Maybe check posts.error???
      // TODO: Do we need to invoke this or
      //    will setting posts in the action/reducer do it?
      //  props.updated(post)

      formik.resetForm();
    }
  }, [addingComment, posts.working]);

  // TODO: Should I bump the limit up per Peter Hussey's email request on 8-29-22?
  const schema = Yup.object({
    body: Yup.string()
      .min(3, 'Must be 3 characters or more')
      .max(1024, 'Must be 1024 characters or less')
      .default('[Enter a comment]')
      .required('Required')
  });
  
  const formik = useFormik({
    initialValues: {
      body: '',
      user_id: auth.uid
    },
    // validateOnChange: true,
    validationSchema: schema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const updateEditorContent = (content) => {
    // console.log(`[CommentForm]: update comment: contentIN: ${content}`);
    formik.setFieldValue("body", content);
  }

  const handleFormSubmit = (values) => {
    dispatch(addComment(props.post, values))
    setAddingComment(true)
  }
  
  const buildForm = () => {
    // console.log('buildForm', props)
    return (
      <div className={classes.CommentBox}>
        <h5 className={classes.CommentTitle}>
          Add A Comment
        </h5>
        <Form
          onSubmit={formik.handleSubmit}
          className={classes.Form}
        >
          <TinyMceEditor
            value={formik.values.body} 
            auth={auth}
            blur={() => {}}
            changed={updateEditorContent}
            editorType={COMMENT_EDITOR}
            placeholder={"Enter your comment"}
          />
          <Form.Control type="hidden" id="user_id" name="user_id" value={auth.uid} />
          <div className={classes.CommentSubmit}>
            <Button type="submit">Post Comment</Button>
          </div>
        </Form>
      </div>
    );
  }

  if (addingComment) {
    return <Spinner />
  } else {
    return buildForm();
  }

}

export default CommentForm;