import React from 'react';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons'; 

import classes from './TocDrawerToggleButton.module.css';

const TocDrawerToggleButton = (props) => {
  const controls = useSelector(state => state.controls);

  const tocButtonClickHandler = () => {
    if (props.clicked) {
      props.clicked()
    }
  }

  let btnClasses = classes.DrawerToggleButton;
  if (props.inverseColor) {
    btnClasses = [classes.DrawerToggleButton, classes.DrawerToggleButtonInverse].join(' ');
  }

  // Return an empty div to even out the layout.
  if (controls.showDrawerButton === false && !props.innerButton) {
    return <div style={{width:'40px'}} />;
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      {/* {renderRightBtn(props)} */}
      <div className={btnClasses} onClick={tocButtonClickHandler}>
        <FontAwesomeIcon icon={faList} size="2x" style={{color: 'white'}} title="Table of Contents" />
      </div>
     </div>
  );
}

export default TocDrawerToggleButton;
