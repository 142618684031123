import React, { Component } from 'react';
import { connect } from 'react-redux';

import Image from 'react-bootstrap/Image';

import placeholderImage from '../../assets/images/Picnic-Table-128.png';
import axios from '../../utils/axios-whe';

import { setTitle } from '../../redux/actions/controlsActions';

import classes from './Groups.module.css';

class Groups extends Component {

  constructor(props) {
    super(props);
    this.state = { groups: [], error: null };
  }

  componentDidMount() {
    this.props.setTitle("Groups");

    axios.get('/api_v1/groups', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${this.props.auth.token}`,
        'X-CSRF-Token': this.props.auth.csrf_token
      }
    })
    .then(response => {
      console.log('=======> Groups: response after axios.get...')
      // console.log(`SiteDefinition: count: ${this.state.count}`)
      if (response !== undefined) {
        this.setState({groups: response.data});
      }
    })
    .catch(error => {
      // dispatch(fetchIngredientsFailed());
      console.log("ERROR fetching Groups...", error)
      // this.setState({error: error});
      this.setState((prevState, prevProps) => ({
        error: error
      }));
  
    });
  }

  renderGroupMembers(group) {
    // debugger;
    return group.members.map((m, ix) => {
      return (
        <div className={classes.GroupMemberName} id={`G${group.id}U${m.id}`} key={`G${group.id}U${m.id}`}>{ix} - {m.name}</div>
      )
    })
  }

  renderGroup(group) {
    return (
      <div key={`G_${group.id}`} className={classes.GroupContainer}>
          <Image className={classes.GroupIcon} src={placeholderImage} thumbnail />
      
        <div className={classes.GroupName}>{group.name}</div>
      </div>
    );
  //   <div className={classes.DocumentContainer}>
  //     <div>1: This will contain links to documents.</div>
  //     <div>People will also be able to add / edit links from here.</div>
  //   </div>
  //   <div className={classes.DocumentContainer}>
  //     <div>2: This will contain links to documents.</div>
  //     <div>People will also be able to add / edit links from here.</div>
  //   </div>
  // </div>

  }
  renderGroups() {
    // debugger;
    return this.state.groups.map((group) => {
      return this.renderGroup(group);

      // return (
        // <div key={`G_${group.id}`} className={classes.Group}>
        //   <div className={classes.GroupName}>{group.name}</div> - <div className={classes.GroupDesc}>{group.description}</div>
        //   {this.renderGroupMembers(group)}
        // </div>
      // );
    });
  }
  render() {
    return (
      <div className={classes.GroupsContainer}>
        <div className={classes.GroupsHeader}>Groups [Coming soon...]</div>
        <div className={classes.Groups}>
          {this.renderGroups()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    // closeDrawer: () => dispatch(closeDrawer()),
    // toggleDrawer: () => dispatch(toggleDrawer()),

    // showButton: () => { dispatch(showDrawerButton()) },
    // hideButton: () => { dispatch(hideDrawerButton()) },
    setTitle: (title) => {dispatch(setTitle(title))}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
