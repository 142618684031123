import React from 'react'
import { connect } from 'react-redux'
import { size, toArray } from 'lodash'
import Spinner from 'react-bootstrap/Spinner'

import { uploadFile, cleanupUploads } from '../../../redux/uploadFile/uploadFile.actions'
import UploadItem from '../UploadItem/UploadItem'
import Styles from './UploadProgress.module.css'

const photoDefaults = {
  // id: null,
  album_id: -1,
  'photo[description]': '',
  'photo[site_definition_id]': 2,
  'photo[neighborhood_id]': 2,
  // 'photo[image]': null,
  'photo[creator_id]': 1 // XXX: Must add in to post.
};

class UploadProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {count: 0};
    this.uploadInProg = false;
  }

  // debugger;

  // useEffect(() => {
    // const fileToUpload = toArray(fileProgress).filter(file => file !== null && file.progress === 0)
    // debugger;

    // XXX: ORIGINAL had this calling uploadFile.  I changed the ImageUpload component
    //      and have it doing this upon "upload pic"
    // uploadFile({}, fileToUpload)

    // Cleanup on unmount
  //   return () => {
  //     console.log('[UploadProgress]: Cleaning up Uploaded Files...')
  //     props.cleanupUploadMetadata()
  //   }
  // }, [uploadedFileAmount])

  componentDidMount() {
    console.log(`[UploadProgress] componentDidMount`)
    // XXX: Needed to force a state change to rerender and kickoff the upload
    if (this.state.count === 0) {
      setTimeout(() => {this.setState({count: this.state.count+1})},50)
    }
  }
  componentDidUpdate() {
    const fileToUpload = toArray(this.props.uploader.fileProgress).filter(file => file.progress === 0)
    // debugger;
    if (fileToUpload.length > 0) {
      console.log(`[UploadProgress] componentDidUpdate.  FilesToUpload: ${fileToUpload.length}`)
      let photoJson = {...photoDefaults, album_id: this.props.album.id};
      if (this.uploadInProg === false) {
        this.uploadInProg = true;
        this.props.uploadFile(photoJson, fileToUpload)
      }
    } else {
      const uploadedFileAmount = size(this.props.uploader.fileProgress)
      console.log(`[UploadProgress] componentDidUpdate.  UploadedFileAmount ${uploadedFileAmount}  Remaining: ${this.props.count}`)
      if (uploadedFileAmount > 0 && this.props.count === 0) {
        // XXX: Needed to be called here instead of the renderProgressHeading
        //    because it could have a side effect invoking setState and that's
        //    a no-no during render.
        this.props.uploadComplete();
      }  
    }
  }

  componentWillUnmount() {
    console.log('[UploadProgress] componentWillUnmount');
    this.props.cleanupUploadMetadata();
  }

  renderProgressHeading = () => {
    const uploadedFileAmount = size(this.props.uploader.fileProgress)
    // const fileToUpload = toArray(this.props.uploader.fileProgress).filter(file => file.progress === 0)
    console.log(`[UploadProgress] renderProgressHeading: uploadedFileAmount: ${uploadedFileAmount} Count: ${this.props.count}`)
    if (uploadedFileAmount > 0 && this.props.count === 0) {
      // this.props.uploadComplete();
      this.uploadInProg = false;
      return <span>Upload complete.</span>;
    } else {
      return (
        <div>
          <span>Uploading File{uploadedFileAmount > 1 ? 's':''}</span>&nbsp;
          <Spinner
            as="span"
            animation="border"
            size="lg"
            role="status"
          />
        </div>
      );
    }
  }

  render () {
    const uploadedFileAmount = size(this.props.uploader.fileProgress)
    // debugger;
    return uploadedFileAmount > 0 ? (
      <div className={Styles.wrapper}>
        <h4>{this.renderProgressHeading()}</h4>
        {size(this.props.uploader.fileProgress) > 0
          ? toArray(this.props.uploader.fileProgress).map(file => <UploadItem key={file.id} file={file} />)
          : null}
      </div>
    ) : null;
  }

}

const mapStateToProps = state => ({
  uploader: state.uploader,
  count: state.uploader.count
})

const mapDispatchToProps = dispatch => ({
  uploadFile: (photoJson, files) => { dispatch(uploadFile(photoJson, files)) },
  cleanupUploadMetadata: () => { dispatch(cleanupUploads()) }
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgress)
