import axios from 'axios';
import store from './store';

import {  WHE_BASE_URL } from './constants';
import { setRedirectPath, reinitAppState } from '../redux/actions/authActions';
import { getCurrentPath } from '../utils/utils';
import { history } from './utils';

const instance = axios.create({
  baseURL: WHE_BASE_URL
});

export const gen_axios = axios.create();

// Add a request interceptor
// instance.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) {
    // Issue a Clear Op to ALL reducers to get all to their INITIAL_STATE
    store.dispatch(reinitAppState());

    // Setup the redirectUrl to go to the current path.
    store.dispatch(setRedirectPath(getCurrentPath()));

    // Redirect to Auth
    history.push('/auth');
  }
  return Promise.reject(error);
});


export default instance;
