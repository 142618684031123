import React, { Component } from 'react';

import SiteMessagesCommon from './SiteMessagesCommon';

class SiteMessages extends Component {
  // 

  render() {
    return (
      <SiteMessagesCommon 
        title="Site Messages"
        allPosted
        // url="/api_v1/site_messages_controller/sent_site_messages.json"
      />
    );
  }
}
export default SiteMessages;
