import React from 'react';
import parse from 'html-react-parser';

const Content = (props) => {
  const TITLE = "title";
  const PARAGRAPH = "paragraph";
  const UNORDERED_LIST = "unordered_list";
  const NUMBERED_LIST = "numbered_list";
  const FOOTER = "footer";

  let keyIx = 0;

  const renderFooter = (jsonEntry) => {
    //TODO: implement
    return null;
  }

  const renderNumberedList = (jsonEntry) => {
    const lineItems = jsonEntry[NUMBERED_LIST];
    return (
      <ol key={`ol-${keyIx}`}>
        {lineItems.map((line) => <li>{line}</li>)}
      </ol>
    )
  }

  const renderUnorderedList = (jsonEntry) => {
    const lineItems = jsonEntry[UNORDERED_LIST];
    return (
      <ul key={`ul-${++keyIx}`}>
        {lineItems.map((line) => <li key={`lic-${++keyIx}`}>{line}</li>)}
      </ul>
    )
  }

  const renderParagraph = (jsonEntry) => {
    const lines = jsonEntry[PARAGRAPH];
    const paragraphs = lines.map((line) => {
      return <p key={`line-${++keyIx}`}>{parse(line)}</p>
    })
    return (
      <div key={`paradiv-${keyIx}`}>
        {paragraphs}
      </div>
    )
  }

  const renderTitle = (jsonEntry) => {
    return <h5 key={`title-${++keyIx}`}>{jsonEntry["title"]}</h5>
  }
  const parseContent = (jsonBody) => {
    return jsonBody.map((jsonEntry) => {
      switch (Object.keys(jsonEntry)[0]) {
        case TITLE:
          return renderTitle(jsonEntry);

        case PARAGRAPH:
          return renderParagraph(jsonEntry);

        case UNORDERED_LIST:
          return renderUnorderedList(jsonEntry);

        case NUMBERED_LIST:
          return renderNumberedList(jsonEntry);
      
        case FOOTER:
          return renderFooter(jsonEntry);

        default:
          return null;
      }
    })
  }

  return parseContent(props.json);
}

export default Content;