import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Posts from './Posts/Posts';

class About extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row>
          <Col>This is Posts page. (remove)</Col>
        </Row>
        <Row>
          <Col sm={9}>
            <Posts />
          </Col>
          <Col sm={3}>
            I'm the post nav
          </Col>
        </Row>
      </Container>
    );
  }
}

export default About;
