import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setEnableEditDocs } from '../../../redux/actions/fileUploadActions';

const EnableDisableEditDocs = (props) => {
  const history = useHistory();
  const fileUpload = useSelector(state => state.fileUpload);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth && auth.app_admin) {
      dispatch(setEnableEditDocs(!fileUpload.editDocs))
      history.replace('/documents');
    }  
  }, [])
  // if (auth && auth.app_admin) {
  //   dispatch(setEnableEditDocs(!fileUpload.editDocs))
  //   history.replace('/documents');
  //   // return <Redirect to='/documents' />;
  //   return null;
  // }
  return null;
}
export default EnableDisableEditDocs;
