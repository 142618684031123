import React, { useEffect, useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckMoving, faUserTimes } from '@fortawesome/free-solid-svg-icons';

import SearchButton from '../SearchButton/SearchButton';
import Spinner from '../Spinner/Spinner';

import { getWheUsers } from '../../redux/actions/propertyActions';
import { cancelUser } from '../../redux/actions/authActions';

import classes from './Properties.module.css';
import PropertyUserForm from './PropertyUserForm';

const WheUsers = (props) => {
  // const [users, setUsers] = useState();
  const [userSearch, setUserSearch] = useState('');
  const [userPropForm, setUserPropForm] = useState(null);
  const [cancelingAccount, setCancelingAccount] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const properties = useSelector(state => state.properties);

  useEffect(() => {
    dispatch(getWheUsers());
  }, [])

  useEffect(() => {
    if (cancelingAccount) {
      window.location.reload();
    }
  }, [cancelingAccount])

  const updateSearchValue = (value) => {
    setUserSearch(value);
  }

  const buildSearchString = (user) => {
    let searchString = (user.full_name ? user.full_name + ' ' : '');
    searchString += (user.wh_street_address ? user.wh_street_address + ' ' : '');
    searchString += (user.owner_name ? user.owner_name + ' ' : '');
    return searchString.toLowerCase();
  }

  const filterAddress = (user) => {
    const searchString = buildSearchString(user);   
    // debugger;
    if (searchString.toLowerCase().includes(userSearch.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  const cancelUserAccount = (user) => {
    let cancelAccount = window.confirm(`Are you sure you want to cancel the account for ${user.full_name}?`);
    if (cancelAccount) {
      dispatch(cancelUser(user.id))
      setCancelingAccount(true);
    }
  }

  const renderPropertyForm = (user) => {
    setUserPropForm(user);
    // alert(`[build form to change property for ${user.full_name}]`)
  }
  const renderUsers = () => {
    if (properties.whe_users.length === 0) {
      return null;
    }

    return properties.whe_users.map((user, ix) => {
      if (filterAddress(user)) {
        return (
          <Row key={`${user.id}-${user.full_name}`} className={classes.Property}>
            <Col xs={6} className={[classes.MobileOnly, classes.Header].join(' ')}>Name</Col>
            <Col xs={6} md={3} className={classes.AddrEdit} onClick={() => {cancelUserAccount(user)}}>
              <FontAwesomeIcon icon={faUserTimes} />
              {user.full_name}
            </Col>
            <Col xs={6} className={[classes.MobileOnly, classes.Header].join(' ')}>Last Updated</Col>
            <Col xs={6} md={2}>
              {user.updated_at}
            </Col>
            <Col xs={6} className={[classes.MobileOnly, classes.Header].join(' ')}>WH Street</Col>
            <Col xs={6} md={4} className={classes.AddrEdit} onClick={() => {renderPropertyForm(user)}}>
              <FontAwesomeIcon icon={faTruckMoving} />
              {user.wh_street_address}
            </Col>
            <Col xs={6} className={[classes.MobileOnly, classes.Header].join(' ')}>Owner</Col>
            <Col xs={6} md={2}>{user.owner_name}</Col>
          </Row>
        );
      } else {
        return null;
      }
    })
  }

  if (properties.whe_users.length === 0 || auth.api_op_inprog) {
    return <Spinner />
  }

  const showUserPropFormModal = () => {
    if (userPropForm === null) {
      return null;
    }

    return (
      <Modal show={userPropForm !== null} onHide={() => setUserPropForm(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Move {userPropForm.full_name} To Property...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PropertyUserForm wheUser={userPropForm} cancel={() => {setUserPropForm(null)}} onSubmit={() => {setUserSearch("");setUserPropForm(null)}} />
        </Modal.Body>
      </Modal>
    )
  }
  return (
    <React.Fragment>
      <Row>
        <Col className={classes.Title} xs={12} md={6}>
          WHE Users and Properties
        </Col>
        <Col xs={0} md={1}>

        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <SearchButton valueChanged={updateSearchValue} placeholder="Search Users / Properties" />
        </Col>
      </Row>
      <div className={classes.PropertiesContainer}>
        <Row className={classes.Header}>
          <Col xs={6} md={3} className={classes.DesktopOnly}>Name</Col>
          <Col xs={6} md={2} className={classes.DesktopOnly}>Last Updated</Col>
          <Col xs={6} md={4} className={classes.DesktopOnly}>WH Street</Col>
          <Col xs={6} md={2} className={classes.DesktopOnly}>Owner</Col>
        </Row>
        <div style={{height: '65vh', overflowY: 'auto'}}>
        {renderUsers()}
        </div>
      </div>
      {showUserPropFormModal()}
    </React.Fragment>
  );

};

export default WheUsers;
