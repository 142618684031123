import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Image from 'react-bootstrap/Image';

import { useParams, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Input from '../../UI/Input';
import Spinner from '../../Spinner/Spinner';

import { updatePhotoAlbum, getAlbum, deletePhoto } from '../../../redux/actions/photoActions';

// import "./NewPost.css";
import classes from './NewAlbum.module.css';
import PhotoUploader from '../PhotoUploader/PhotoUploader';

const schema = Yup.object({
  name: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(80, 'Must be 32 characters or less')
    .required('ALbum name is required')
});

// let disableModalClose = true;

let photoToDelete = null;
let gettingAlbum = false;
const EditAlbum = (props) => {
  const { albumId } = useParams();
  const auth = useSelector(state => state.auth);
  const [, setTimeoutId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMine = props.history?.location?.state?.mine;
  const photos = useSelector(state => state.photos);
  
  let album = useRef({...photos.currentAlbum});

  console.log('[EditAlbum] begin')
  const formik = useFormik({
    initialValues: {
      name: album.current.name,
      description: album.current.description,
      published: album.current.published,
      allow_all_to_contribute: album.current.allow_all_to_contribute,
      neighborhood_id: album.current.neighborhood_id,
      site_definition_id: album.current.site_definition_id,
      creator_id: album.current.creator_id
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  useEffect(() => {
    if (albumId && isNaN(albumId)) {
      history.push(`/myphotos`)
    }
    if (album.current.id === null && albumId && !Number.isNaN(albumId) && !gettingAlbum) {
      gettingAlbum = true;
      dispatch(getAlbum(albumId));
      return;
    }
    formik.setValues({...photos.currentAlbum})
  }, [albumId, album, auth, photos]);

  const startTimer = () => {
    return setTimeout(() => {
      if (photos.working) {
        setTimeoutId(startTimer());
      } else {
        formik.setSubmitting(false)
        setTimeoutId(null);
        // XXX: Show success/fail modal and on close redirect.
        history.push(`/myphotos/${album.current.id}`)
      }
    }, 500)
  };

  const handleFormSubmit = (values) => {
    setTimeoutId(startTimer());
    dispatch(updatePhotoAlbum(album.current.id, values));
  }

  const handleRadioChange = (event) => {
    // alert(`handleRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
      } else {
        formik.setFieldValue(event.target.name, false);
      }
    }
  }
  
  const showSpinner = () => {
    if (formik.isSubmitting) {
      return <Spinner />;
    } else {
      return null;
    }
  }

  const uploadComplete = () => {
    // debugger;
    console.log('[EditAlbum]: uploadComplete... Setup any necessary state like maybe getalbums()')
    dispatch(getAlbum(photos.currentAlbum.id));
  }

  const cantPublishAlbum = () => {
    const currentAlbum = photos.currentAlbum
    // debugger;
    if ( currentAlbum.photos.length > 0 &&
         ((currentAlbum.published && currentAlbum.allow_all_to_contribute) ||
          (currentAlbum.creator_id === auth.uid) ||
           auth.site_admin)) {
      return false;
    } else {
      return true;
    }
  }

  const renderPublishedButtons = () => {
    let warningText = null;
    if (cantPublishAlbum()) {
      warningText = <h6>(No photos.  Must add some before publishing album)</h6>;
    }
    return (
      <React.Fragment>
        <InputGroup>
          <div className={classes.RadioTitle}>Published ? </div>
          <Form.Label className={classes.RadioLabelYes} htmlFor='published_true'>YES</Form.Label>
          <Form.Check
            disabled={formik.isSubmitting || cantPublishAlbum()}
            type='radio'
            as='input'
            id="published_true"
            className={classes.Radio}
            checked={formik.values.published === true}
            value={true}
            name='published'
            onChange={handleRadioChange}
            isInvalid={formik.errors['published']}
            isValid={!formik.errors['published']}
          />
          <Form.Label className={classes.RadioLabelNo} htmlFor='published_false'>NO</Form.Label>
          <Form.Check
            disabled={formik.isSubmitting || cantPublishAlbum()}
            styles={[{marginLeft: 20}]}
            type='radio'
            as='input'
            id="published_false"
            className={classes.Radio}
            checked={formik.values.published === false}
            value={false}
            name='published'
            onChange={handleRadioChange}
            isInvalid={formik.errors['published']}
            isValid={!formik.errors['published']}
          />
        </InputGroup>
        {warningText}
      </React.Fragment>
    );
  }

  const startDeleteTimer = () => {
    return setTimeout(() => {
      if (photos.working) {
        setTimeoutId(startDeleteTimer());
      } else {
        setTimeoutId(null);
        photoToDelete = null;
        // Handle any error by leaving modal up so it can display the error.
        // debugger;
        if (photos.error) {
          // setShowDeleteModal(false);
          return;
        } else {
          setShowDeleteModal(false);
          dispatch(getAlbum(photos.currentAlbum.id));
        }
      }
    }, 500)
  }

  const deleteImage = (albumId, photoId) => {
    startDeleteTimer();
    dispatch(deletePhoto(albumId, photoId));
  }

  const showDeleteModalForm = (photo) => {
    photoToDelete = {...photo};
    setShowDeleteModal(true);
  }

  const renderDeleteError = () => {
    if (photos.error) {
      return <p style={{color: 'red'}}>{photos.error}</p>
    }
  }
  const cleanupModalAfterError = () => {
    setShowDeleteModal(false);
    dispatch(getAlbum(photos.currentAlbum.id));
  }
  const renderDeleteModalButtons = () => {
    if (photos.error) {
      return <Button color="secondary" onClick={() => {cleanupModalAfterError()}}>Cancel</Button>
    } else {
      return (
        <React.Fragment>
          <Button color="secondary" onClick={() => {deleteImage(photos.currentAlbum.id, photoToDelete.id)}}>Yes</Button>
          <Button color="secondary" onClick={() => {setShowDeleteModal(false)}}>No</Button>
        </React.Fragment>
      )
    }
  }

  const renderThumbnailImage = (photoToDelete) => {
    if (photoToDelete) {
      return <Image src={photoToDelete.thumbnail} height='160px' width="160px" thumbnail />
    } else {
      return null;
    }
  }
  const renderDeleteImageModal = () => {
    if (showDeleteModal === false) {
      return null;
    }
    return (
      <Modal show={showDeleteModal} backdrop="static">
        <ModalBody>
          {renderDeleteError()}
          <p>Are you sure you want to delete this image?</p>
          {renderThumbnailImage(photoToDelete)}
        </ModalBody>
        <ModalFooter>
          {renderDeleteModalButtons()}
        </ModalFooter>
      </Modal>
    );
  };


  const textAreaChange = (event) => {
    // debugger;
    formik.handleChange(event)
  }
  const renderThumbnails = () => {
    const thumbnails = photos.currentAlbum.photos.map( photo => {
      return (
        <Col key={photo.id} xs={4} sm={2}>
          <Image src={photo.thumbnail} height='80px' width="80px" thumbnail />
          <FontAwesomeIcon onClick={() => {showDeleteModalForm(photo)}} className={classes.DeleteIcon} icon={faTimesCircle} />
        </Col>
      );
    });

    let thumbnailHeading = "Photo Album Thumbnail Images";
    if (photos.currentAlbum.photos.length === 0) {
      thumbnailHeading = null;
    }
    return (
      <Row style={{marginTop: '8px'}}>
        <Col sm={12}>
          <h5 style={{textAlign: 'left'}}>{thumbnailHeading}</h5>
        </Col>
        {thumbnails}
      </Row>
    )
  }
  // let images = {...photos.currentAlbum.photos, originalClass: classes.ImageViewerAdditional}
  return (
    <Container fluid>
      {showSpinner()}
      <Row>
        <Col sm={10}>
          <div sm={8} className={classes.Title}>
            Update Album
          </div>
          <div sm={4} className={classes.BackButton}>
            <Button size="sm" onClick={() => {history.replace(`/${(isMine ? 'myphotos':'photos')}/${album.current.id}`)}}>
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;Back to Album
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={10} style={{paddingLeft: '0', marginLeft: '15px', marginRight: '0'}}>
          <Form className={classes.Form} onSubmit={formik.handleSubmit}>
            <Input
              style={{fontSize: '18px'}}
              placeholder="Enter Title"
              noPrepend
              disabled={formik.isSubmitting}
              id='name'
              required
              label="Name"
              value={formik.values.name}
              name='name'
              changed={formik.handleChange}
              blur={formik.handleBlur}
              errors={formik.errors.name}
              touched={formik.touched.name ? 1 : 0}
              showValid
            />
            <textarea 
              className='form-control'
              style={{fontSize: '18px', minHeight: '90px', whiteSpace: 'pre-wrap'}}
              placeholder="Enter Description"
              disabled={formik.isSubmitting}
              id='description'
              required
              label="Description"
              value={formik.values.description}
              name='description'
              onChange={textAreaChange}
              onBlur={formik.handleBlur}
              errors={formik.errors.description}
              touched={formik.touched.description ? 1 : 0}
            >
                {formik.values.description}
            </textarea>
            
            <InputGroup>
              <div className={classes.RadioTitle}>Allow all to add photos?</div>
              <Form.Label className={classes.RadioLabelYes} htmlFor='allow_all_to_contribute_true'>YES</Form.Label>
              <Form.Check
                disabled={formik.isSubmitting}
                type='radio'
                as='input'
                id="allow_all_to_contribute_true"
                className={classes.Radio}
                checked={formik.values.allow_all_to_contribute === true}
                value={true}
                name='allow_all_to_contribute'
                onChange={handleRadioChange}
                isInvalid={formik.errors['allow_all_to_contribute']}
                isValid={!formik.errors['allow_all_to_contribute']}
              />
              <Form.Label className={classes.RadioLabelNo} htmlFor='allow_all_to_contribute_false'>NO</Form.Label>
              <Form.Check
                disabled={formik.isSubmitting}
                styles={[{marginLeft: 20}]}
                type='radio'
                as='input'
                id="allow_all_to_contribute_false"
                className={classes.Radio}
                checked={formik.values.allow_all_to_contribute === false}
                value={false}
                name='allow_all_to_contribute'
                onChange={handleRadioChange}
                isInvalid={formik.errors['allow_all_to_contribute']}
                isValid={!formik.errors['allow_all_to_contribute']}
              />
            </InputGroup>
            {renderPublishedButtons()}
            <InputGroup style={{width: '150px'}}>
            {/* {renderAddPhotoButton()} */}
            </InputGroup>
            <input type="hidden" name="neighborhood_id" value={formik.values.neighborhood_id} />
            <input type="hidden" name="site_definition_id" value={formik.values.site_definition_id} />
            <input type="hidden" name="creator_id" value={formik.values.user_id} />
            <input type="hidden" name="user_id" value={formik.values.user_id} />
            <InputGroup className={classes.SubmitButton}>
              <Button size="sm" type="submit" disabled={formik.isSubmitting}>Update Album</Button>
            </InputGroup>
          </Form>
          <div style={{marginTop: '10px'}}>
            {/* <PhotoUploadButton 
              title="Add images to this album."
              uploadImages={(images, uploadComplete) => {uploadImages(images, uploadComplete)}}
            /> */}
            <PhotoUploader
              currentAlbum={photos.currentAlbum}
              title="Add images to this album."
              // uploadImages={(images, uploadComplete) => {uploadImages(images, uploadComplete)}}
              uploadComplete={uploadComplete}
            /> 
          </div>
        </Col>
      </Row>
      {renderThumbnails()}
      {renderDeleteImageModal()}
    </Container>
  );
}

export default EditAlbum;