import store from './store';

import { CURRENT_GUI_VERSION } from './constants';

import { KEEP_ALIVE, TRY_RESTORE_LOGIN } from '../redux/actions/authActions';

import axios from '../utils/axios-whe';

export const reloadAppIfNewerAvailable = (signinData) => {
  if (signinData.gui_version > CURRENT_GUI_VERSION) {
    // alert('Updated user interface is being loaded...')
    window.location.reload(true);
  }
}

export const stopKeepAliveTimer = () => {
  const state = store.getState();

  const timeoutId = state.auth.keepAliveTimer;
  if (timeoutId !== null) {
    clearTimeout(timeoutId);
  }
};

export const startKeepAliveTimer = () => {
  const timeoutId = setTimeout(() => {doKeepAlive()}, 5 * (60 * 1000));
  console.log('startKeepAliveTimer...')
  store.dispatch({ type: KEEP_ALIVE, payload: {timerId: timeoutId}});
  return timeoutId;
};

const doKeepAlive = () => {
  const state = store.getState();

  console.log('.....doKeepAlive.....')
  axios.get(`/api/v1/keep_alive.json`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${state.auth.token}`,
      'X-CSRF-Token': state.auth.csrf_token
    }
  })
  .then(response => {
    console.log('=======> doKeepAlive. response..')
    // console.log(`SiteDefinition: count: ${this.state.count}`)
    if (response !== undefined) {
      console.log("Keep alive data: ", response.data);
      reloadAppIfNewerAvailable(response.data.signin);
      store.dispatch({ type: TRY_RESTORE_LOGIN, payload: response.data.signin });
      // XXX: kick off next timeout and save the timer id
      startKeepAliveTimer();
    }
  })
  .catch(error => {
    // dispatch(fetchIngredientsFailed());
    console.log("ERROR fetching doKeepAlive...", error)
  });
}
