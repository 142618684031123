import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import classes from './WhAddress.module.css';

const whAddressHdr = props => {
  const headerClass = [classes.Header, classes.WhAddressSmScreen].join(' ')
  return (
    <Row className={classes.WhAddressHdr} >
      <Col xs={3} md={1} className={classes.Header} onClick={() => {props.sortByClick("lot_num")}}>
        Lot#
        {props.sortCol === "lot_num" && <FontAwesomeIcon icon={(props.sortAsc ? faSortUp : faSortDown)} />}
      </Col>
      <Col xs={5} md={2} className={classes.Header} onClick={() => {props.sortByClick("last_name")}}>
        Name&nbsp;
        {props.sortCol === "last_name" && <FontAwesomeIcon icon={(props.sortAsc ? faSortUp : faSortDown)} />}
      </Col>
      <Col xs={4} md={2} className={classes.Header} onClick={() => {props.sortByClick("wh_street_address")}}>
        WH Address&nbsp;
        {props.sortCol === "wh_street_address" && <FontAwesomeIcon icon={(props.sortAsc ? faSortUp : faSortDown)} />}
      </Col>
      {/* <Col md={3} className={headerClass} onClick={() => {alert("Can't sort on Primary Address")}}>Primary Address</Col> */}
      <Col md={2} className={headerClass} onClick={() => {alert("Can't sort on Phone")}}>Phone</Col>
      <Col md={2} className={headerClass} onClick={() => {props.sortByClick("updated_at")}}>
        Updated&nbsp;
        {props.sortCol === "updated_at" && <FontAwesomeIcon icon={(props.sortAsc ? faSortUp : faSortDown)} />}
        </Col>
    </Row>
  )
};

export default whAddressHdr;