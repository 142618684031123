import React, {useState, useEffect} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment'

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faClone, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Input from '../UI/Input';
import CancelEventForm from './Edit/CancelEventForm';
import { formattedDate, formattedTime, addNewLineTags } from '../../utils/utils';

import { joinEvent, leaveEvent, cancelEvent, setCurrentEvent } from '../../redux/actions/calendarEventActions';

import AnimatedText from '../AnimatedText/AnimatedText';

import classes from './CalendarEvent/CalendarEvent.module.css';


const CalendarEventDetailView = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const calendarEvents = useSelector(state => state.calendarEvents)
  const auth = useSelector(state => state.auth);
  const [showCancelModal, setShowCancelModal] = useState(null)
  const [showCancelAddGuestInline, setShowCancelAddGuestInline] = useState(null);
  const [numGuests, setNumGuests] = useState(0);
  const [eventDetailToShow, setEventDetailToShow] = useState(null);
  const [showJoinEventModal, setShowJoinEventModal] = useState(null);

  useEffect(() => {
    // debugger
    if (calendarEvents.currentEvent) {
      setEventDetailToShow(calendarEvents.currentEvent)
      if (props.onChange) {
        props.onChange(calendarEvents.currentEvent)
      }
      setShowCancelAddGuestInline(null)
      setShowJoinEventModal(null)
    } else {
      setEventDetailToShow(props.event)
    }
    return (() => {
      // Clear out the current event when changing out / closing the modal.
      // This lets us start fresh with next view and not have a stale currentEvent
      dispatch(setCurrentEvent(null))
      // alert('EXIT')
    })
  }, [props.event, calendarEvents.currentEvent])

  const cancelEventCallback = (values) => {
    const event = showCancelModal;
    dispatch(cancelEvent(event, values.send_notifications));
    setShowCancelModal(null)
    history.replace('/calendar_events')
    //TODO: Need to force the spinner to show and then after canceling the event 
    //      need to redirect to calendar_events...
    //  Initial attenmpt got me back to /calendar_events but the event list wasn't updated so need to force
  }

  const renderCancelEventModal = () => {
    if (showCancelModal !== null) {
      return (
        <Modal show={(showCancelModal !== null)} backdrop="static">
          <Modal.Header>
            <p style={{fontWeight: 'bold'}}>Cancelling the event "{showCancelModal.title}"</p>
          </Modal.Header>
          <Modal.Body>
            {/* {renderDeleteError()} */}
            <CancelEventForm
              onCancel={ () => {setShowCancelModal(null)} }
              onSubmit={cancelEventCallback}
            />
          </Modal.Body>
        </Modal>
      );
    }
    return null;
  }

  const renderCancelEventButton = (event) => {
    if ( (auth.site_admin || auth.uid === event.creator_id) &&
         moment(new Date()).isBefore(event.begin_date)) {
      return (
        <React.Fragment>
          <Button size="sm" onClick={() => {setEventDetailToShow(event); setShowCancelModal(event)}}>
            <FontAwesomeIcon 
              id={"cancelEvent"}
              icon={faTimesCircle}
              style={{marginRight: '4px'}}
            />
            Cancel Event
          </Button>
          <Button size="sm" style={{marginLeft: '40px'}} onClick={() => {history.push(`/editevent/${event.id}`)}}>
            <FontAwesomeIcon 
              id={"editEvent"}
              icon={faEdit}
              style={{marginRight: '4px'}}
            />
            Edit Event
          </Button>
        </React.Fragment>
      );
    }

    if (moment(new Date()).isAfter(event.begin_date)) {
      return (
        <div className={classes.PastEvent}>[Event is in the past]</div>
      );
    }
    return null;
  }

  const renderActivity = (activityId) => {
    const activities = calendarEvents.eventMetadata.activities;
    const activityIx = activities.findIndex(activity => {
      if (activity.id === activityId) {
        return true;
      } else {
        return false;
      }
    })
    if (activityIx >= 0) {
      return (
        <div className={classes.ActivityIcon}>
          <Image src={activities[activityIx].thumbnail} height="40" width="40" thumbnail />
        </div>
      );
    }
    return null;
  }

  const cloneCalendarEvent = (calendarEvent) => {
    // alert(`Would be cloning cal event ${calendarEvent.title}`)
    // debugger
    history.replace(`/cloneevent/${calendarEvent.id}`)
  }

  const renderCloneButton = (calendarEvent) => {
    // if (auth.app_admin) {
      return <FontAwesomeIcon 
              id={"cloneSM"}
              onClick={(e) => {
                e.stopPropagation()
                let cloneEvent = window.confirm(`Do you want to clone this calendar event?`);
                if (cloneEvent) {          
                  cloneCalendarEvent(calendarEvent)
                }
              }}
              size="2x" className={classes.CloneIcon}
              // style={{float: 'right', marginRight: '5px'}}
              title='Clone Event'
              icon={faClone} />
    // }
    // return null;
  }

  const getGuestCount = (event) => {
    let numGuests = 0;
    const ix = event.attendees.findIndex((attendee) => {
      if (attendee.attendee_id === auth.uid) {
        return true;
      } else {
        return false;
      }
    });
    if (ix >= 0) {
      numGuests = event.attendees[ix].num_guests
    }
    return numGuests;
  }

  const leaveEventView = (event) => {
    console.log('LeaveEvent')
    setShowCancelAddGuestInline(event)
    setNumGuests(getGuestCount(event))
  }

  const renderJoinEvent = (event) => {
    let icon = null;
    const ix = event.attendees.findIndex((attendee) => {
      if (attendee.attendee_id === auth.uid) {
        return true;
      } else {
        return false;
      }
    });
    if (ix === -1 && moment(new Date()).isBefore(event.begin_date)) {
      icon = (
        <Row className={classes.JoinEvent}>
          <Col xs={5} sm={4} onClick={() => {setShowJoinEventModal(event)}}><FontAwesomeIcon icon={faPlus} /> Join Event</Col>
          <Col xs={7} sm={8}><AnimatedText text={"Click Join Event!!!"} onClick={() => alert('clicked...')} /></Col>
        </Row>
      );
    }
    return icon;
  }

  const renderGroup = (group) => {    
    return (
      <Col className={classes.Group} sm={6}>
        <div className={classes.Group}>
          {group.group_name}
        </div>
      </Col>
    )
  }
  const renderGroups = (event) => {
    let groups = []
    for (let ix = 0; ix < event.groups.length; ix += 2) {
      groups.push(
        <Row key={event.groups[ix].group_id}>
          {renderGroup(event.groups[ix])}
          { (ix+1 < event.groups.length) ? renderGroup(event.groups[ix+1]) : null }
        </Row>
      )
    }
    return groups;
  }

  const renderAddress = (event) => {
    if (event.street && event.street.length > 0) {
      return (
        <React.Fragment>
          {event.street} <br />
          {event.city}, {event.state} <br />
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
  const renderLocation = (event) => {
    return (
      <Row>
        <Col>
        {event.location_name} <br />
        {renderAddress(event)}
        </Col>
      </Row>
    );
  }

  const renderModalNumberOfGuestsControl = (event) => {
    if (moment(new Date()).isAfter(event.begin_date)) {
      return (
        <div>You RSVPd you were bringing {numGuests} {(numGuests === 1 ? "guest":"guests")} to the event.</div>
      )
    }
    return (
      <div>
        Change number of guests you're bringing?&nbsp;
        <Input 
          type="number"
          min="0" 
          max="20" 
          noPrepend 
          noInputGroup
          value={numGuests} 
          changed={(e) => setNumGuests(e.target.value)}
          style={{width: '70px', display:'inline-block'}}
        />
      </div>
    )
  }

    // Show JOIN modal
  const renderJoinEventModal = () => {
    let event = showJoinEventModal;
    // debugger;
    if (event !== null) {
      return (
        <Modal show={(event !== null)} backdrop="static">
          <Modal.Header className={classes.EventTitle} style={{marginLeft: '0px'}}>
            RSVP to "{event.title}"
          </Modal.Header>
          <Modal.Body>
            <div>
              Number of guests you're bringing with you, if any.&nbsp;
              <Input 
                type="number"
                min="0" 
                max="20" 
                noPrepend 
                noInputGroup
                value={numGuests} 
                changed={(e) => setNumGuests(e.target.value)}
                style={{width: '70px', display:'inline-block'}}
              />
            </div>
            {/* TODO: issue joinEvent */}
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" onClick={() => {dispatch(joinEvent(event, numGuests))}} >Join Event</Button>
            <Button size="sm" onClick={() => {setShowJoinEventModal(null)}}>Don't Join</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  }
  
  const renderModalUpdateButtons = (event) => {
    if (moment(new Date()).isAfter(event.begin_date)) {
      return (
        <Button onClick={() => {setShowCancelAddGuestInline(null)}}>Close</Button>
      )
    }

    return (
      <Row style={{marginTop: '4px'}}>
        <Col>
          <Button size="sm" onClick={() => {dispatch(joinEvent(event, numGuests))}} >Update guests</Button>
        </Col>
        <Col>
          <Button size="sm" onClick={() => {dispatch(leaveEvent(event))}} >Leave Event</Button>
        </Col>
        <Col>
          <Button size="sm" onClick={() => {setShowCancelAddGuestInline(null)}}>Don't Change</Button>
        </Col>
      </Row>      
    )
  }

  // Show Cancel / Add/change event modal modal
  const renderCancelOrGuestEventInline = () => {
    // TODO: Add in classes for styling header, body, and footer
    const event = showCancelAddGuestInline;
    if (event) {
      return (
        <div style={{marginTop: '4px'}}>
          <div>
            {renderModalNumberOfGuestsControl(event)}
          </div>
          <div>
            {renderModalUpdateButtons(event)}
          </div>
        </div>
      )
    }
  }

  const nop = () => {
    // NOP
  }

  const renderAttendee = (event, attendee) => {
    let icon = null;
    if (attendee.attendee_id === auth.uid) {
      icon = <FontAwesomeIcon icon={faEdit} />
    }
    return (
      <Col onClick={() => {(icon === null ? nop() : leaveEventView(event)) }} className={classes.Attendee} sm={6}>
        <div className={(icon !== null ? classes.Icon : null)}>
          {icon} {attendee.attendee_name}{(attendee.num_guests > 0 ? ` + (${attendee.num_guests})` : null)}
        </div>
      </Col>
    )
  }
  const renderAttendees = (event) => {
    let attendees = [];
    let totalAttendees = 0;
    console.log(`[CalendarEvent]: there are ${event.attendees.length} attendees`)
    for (let ix = 0; ix < event.attendees.length; ix += 2) {
      totalAttendees += event.attendees[ix].num_guests + 1;
      if (ix+1 < event.attendees.length) {
        totalAttendees += event.attendees[ix+1].num_guests + 1;
      }
      attendees.push(
        <Row key={event.attendees[ix].attendee_id}>
          {renderAttendee(event, event.attendees[ix])}
          { (ix+1 < event.attendees.length) ? renderAttendee(event, event.attendees[ix+1]) : null }
        </Row>
      )
    }
    if (attendees.length > 0) {
      return (
        <React.Fragment>
          <Row><Col><div className={classes.AttendeeHeader}>Attendees</div><div style={{display: 'inline-block',marginLeft: '10px'}}>(total: {totalAttendees})</div></Col></Row>
          {attendees}
          {renderCancelOrGuestEventInline()}
        </React.Fragment>
      );
    }
    return null;
  }

  const closeModal = () => {
    props.onClose(eventDetailToShow)
    setEventDetailToShow(null)
  }

  const eventDetailModal = () => {
    const event = eventDetailToShow;
    if (event === null) {
      return null;
    }

    return (
      <Modal show={(event !== null)} onHide={() => {closeModal()}}>
        <Modal.Header closeButton>
          {renderActivity(event.activity_id)}
          <span className={classes.EventTitle}>{event.title}</span>
          {renderCloneButton(event)}
        </Modal.Header>
        <Modal.Body>
        <div key={event.id} className={classes.EventDetailContainer}>
          <Col sm={12}>
            {renderJoinEvent(event)}
            <div
              className={classes.EventDetail}
              dangerouslySetInnerHTML={{
                __html:  addNewLineTags(event.description)
              }}
            >
            </div>
            <div className={classes.EventDetail}>
              Scheduled by: {event.creator}
            </div>
            <div className={classes.EventDetail}>
              Date: {formattedDate(event.begin_date)} - {formattedTime(event.end)}
            </div>
            <div className={classes.CancelEvent}>
              {renderCancelEventButton(event)}
            </div>
            {/* <div className={classes.JoinEvent}>
              {renderCancelOrGuestEventModal(event)}
            </div> */}
            <div className={classes.EventDetail}>
                <div className={classes.EventHeader}>Location</div>
                {renderLocation(event)}
            </div>
            <div className={classes.EventDetail}>
                <div className={classes.EventHeader}>{(event.groups.length > 1 ? "Group(s)":"Group")}</div>
                {renderGroups(event)}
            </div>
            {renderAttendees(event)}
            {renderJoinEventModal()}
            {renderCancelEventModal()}
          </Col>
        </div>

        </Modal.Body>
      </Modal>
    )
  }

  return eventDetailModal();
}
export default CalendarEventDetailView;
