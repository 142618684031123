export const SHOW_DRAWER = "show_drawer";
export const HIDE_DRAWER = "hide_drawer";
export const OPEN_DRAWER = "open_drawer";
export const CLOSE_DRAWER = "close_drawer";
export const TOGGLE_DRAWER = "toggle_drawer";
export const SET_TITLE = 'set_title';

export const HIDE_NAV_DRAWER = 'hide_nav_drawer';
export const SHOW_NAV_DRAWER = 'show_nav_drawer';
export const TOGGLE_NAV_DRAWER = 'toggle_nav_drawer';

export const SHOW_MARQUIS = 'show_marquis';

export const setTitle = (title) => {
  return (dispatch, getState) => {
    if (getState().controls.title !== title) {
      dispatch({ type: SET_TITLE, payload: title });
    }
  }
}

export const showDrawerButton = () => {
  return (dispatch) => {
    dispatch({ type: SHOW_DRAWER });
  }
}
export const hideDrawerButton = () => {
  return (dispatch) => {
    dispatch({ type: HIDE_DRAWER });
  }
}
export const openDrawer = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_DRAWER });
  }  
}

export const closeDrawer = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_DRAWER });
  }  
}

export const toggleDrawer = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_DRAWER });
  }  
}


export const showNavDrawer = () => {
  return (dispatch) => {
    dispatch({ type: SHOW_NAV_DRAWER });
  }  
}

export const hideNavDrawer = () => {
  return (dispatch) => {
    dispatch({ type: HIDE_NAV_DRAWER });
  }  
}

export const toggleNavDrawer = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_NAV_DRAWER });
  }  
}

export const showMarquis = (show) => {
  return (dispatch) => {
    dispatch({ type: SHOW_MARQUIS, payload: {showMarquis: show} });
  }  
}
