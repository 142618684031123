import React from 'react';

import Help from '../../Help/Help';

const PostHelp = ({title}) => {

  const helpBody = [
    {
      "name": "Subject",
      "desc": "Short, 1 line description about your post."
    },
    {
      "name": "Publish?",
      "desc": `IF set to YES, the post will be live when saved/updated and email notifications will be sent.  IF set to NO, the post
               will be saved in your drafts so it can be worked on, completed, or deleted at a later date.`
    },
    {
      "name": "Allow Comments?",
      "desc": `If set to YES, people will be able to comment on this post once it's live.  If set to NO, people will not be able to
               comment on this post once it's live.`
    },
    {
      "name": "Content",
      "desc": `This will contain your post.  IF you decide to paste content in from another application like MS Word, Excel, etc, the application 
              will detect this and cleanup the content so that it's formatted properly for the web and email. The application will warn you that 
              this was done and give you a chance to correct / reformat anything that might not look the way you wanted it to before it's posted
              or saved.`
    },
    {
      "name": "Save and Send Test Message",
      "desc": `This will save the curremt post as a draft and send a test email message to you and ONLY you so you can see how this message will
              appear in email`
    },
    {
      "name": "Save / Update...",
      "desc": `If the 'Save Post' or 'Update Post' button is visible the post will be saved to your drafts so you can work on this at a later
               date.  If the button contains 'and Send Post' is visible, the post will be saved, it will be live on the site, and
               notifications will be sent out.  The setting of the 'Publish?' switch (See above) will dictate this behavior.`
    },
    {
      "name": "Cancel",
      "desc": "If you click the Cancel button any changes you made to the form will not be saved."
    }
  ];
  return (
    <Help 
      title={title}
      body={helpBody}
      iconStyle={{float: 'none', display: 'inline-block', marginLeft: '4px', marginTop: '4px', fontSize: '0.4em'}}
    />
  )
}

export default PostHelp;
