import { updateObject } from '../../utils/utils';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import { SET_MAP, SET_SELECTED_ADDR } from '../actions/mapActions';

const INITIAL_STATE = {
  map: null,
  selectedAddr: null
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;
    
    case SET_MAP:
      return updateObject(state, { map: action.payload.map });

    case SET_SELECTED_ADDR:
      let selAddr = null;
      if (action.payload.addr !== null) {
        selAddr = {...action.payload.addr,  lat: action.payload.addr.latitude, lng: action.payload.addr.longitude};
      }
      return updateObject(state, {selectedAddr: selAddr});
      
    default:
      return state;
  }
}

export default reducer;
