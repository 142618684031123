import { updateObject } from '../../utils/utils';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  GET_PROPERTIES_START,
  GET_PROPERTIES_FAILED,
  GET_PROPERTIES_SUCCESS,

  SORT_PROPERTIES,

  CREATE_PROPERTY_CONTACT_START,
  CREATE_PROPERTY_CONTACT_FAILED,
  CREATE_PROPERTY_CONTACT_SUCCESS,

  UPDATE_PROPERTY_CONTACT_START,
  UPDATE_PROPERTY_CONTACT_FAILED,
  UPDATE_PROPERTY_CONTACT_SUCCESS,

  DELETE_PROPERTY_CONTACT_START,
  DELETE_PROPERTY_CONTACT_FAILED,
  DELETE_PROPERTY_CONTACT_SUCCESS,

  UPDATE_PROPERTY_START,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAILED,

  GET_WHE_USERS_START,
  GET_WHE_USERS_SUCCESS,
  GET_WHE_USERS_FAILED,

  MOVE_USER_START,
  MOVE_USER_SUCCESS,
  MOVE_USER_FAILED
} from '../actions/propertyActions';

const INITIAL_STATE = {
  addrs: [],
  whe_users: [],
  sortCol: 'last_name',
  sortAsc: true,
  error: '',
  working: false,
  opComplete: false
};

const replaceAddr = (state, addrIn) => {
  let addrs = [...state.addrs];
  const ix = addrs.findIndex((addr) => {
    if (addr.id === addrIn.id) {
      return true;
    } else {
      return false;
    }
  })
  addrs[ix] = {...addrIn}
  return addrs;
}

const sortByWhAddr = (addrs, sortDir) => {
  // Build Street map first
  let streets = {};
  let streetNames = [];
  addrs.forEach(a => {
    if (streets[a.wh_street] === undefined) {
      streets[a.wh_street] = [];
      streetNames.push(a.wh_street)
    }
    streets[a.wh_street].push(a);
  })
  
  // Sort street names
  streetNames.sort((a, b) => {
    if (sortDir) {
      return (a < b ? -1 : 1) 
    } else {
      return (a < b ? 1 : -1) 
    }
  })
  // Sort houses on a street
  streetNames.forEach(street => {
    streets[street].sort((a, b) => {
      let numA = parseInt(a.wh_house_num);
      let numB = parseInt(b.wh_house_num);
      if (sortDir) {
        if (numA === undefined || numB === undefined) {
          return(numB === undefined ? -1 : 1);
        }
        return (numA < numB ? -1 : 1)
      } else {
        if (numA === undefined || numB === undefined) {
          return(numB === undefined ? 1 : -1);
        }
        return (numA < numB ? 1 : -1)
      }
    })
  })
  // debugger
  let retAddrs = [];
  streetNames.forEach(street => {
    retAddrs = [...retAddrs, ...streets[street]];
  })
  return retAddrs;
}

const sortProperties = (state, sortCol, sortAsc) => {
  let addrs = [...state.addrs];

  // TODO: Make a switch statement if multiple special case sorts
  if (sortCol === 'wh_street_address') {
    addrs = sortByWhAddr(addrs, sortAsc);
  } else {
    addrs.sort((a, b) => {
      if (sortAsc) {
        return (a[sortCol] < b[sortCol] ? -1 : 1);
      } else {
        return (a[sortCol] < b[sortCol] ? 1 : -1);
      }
    });
  }
  return addrs;
}

const reducer = (state = INITIAL_STATE, action) => {
  // console.log(`[postReducers]: ${action.type}`, action.payload);
  switch (action.type) {
    case GET_PROPERTIES_START:
      return updateObject(state, {working: true, opComplete: false, addrs: []});

    case GET_PROPERTIES_SUCCESS:
      return updateObject(state, {addrs: [...action.payload.addrs], working: false, opComplete: true, error: ''});

    case GET_PROPERTIES_FAILED:
      return updateObject(state, {error: action.payload.error, working: false, opComplete: true})

    case SORT_PROPERTIES:
      let sortAsc = state.sortAsc;
      if (state.sortCol === action.payload.sortCol) {
        sortAsc = !sortAsc;
      } else {
        sortAsc = true;
      }
      const sortedAddrs = sortProperties(state, action.payload.sortCol, sortAsc);
      return updateObject(state, {addrs: sortedAddrs, sortCol: action.payload.sortCol, sortAsc: sortAsc});

    case CREATE_PROPERTY_CONTACT_START:
      return updateObject(state, {working: true, opComplete: false});
    case CREATE_PROPERTY_CONTACT_SUCCESS:
      // Add contact to the addr with address_list_id
      const addrs = replaceAddr(state, action.payload);
      return updateObject(state, {working: false, opComplete: true, addrs: addrs})

    case CREATE_PROPERTY_CONTACT_FAILED:
      return updateObject(state, {error: action.payload.error, working: false, opComplete: true})
    
    case UPDATE_PROPERTY_CONTACT_START:
      return updateObject(state, {working: true, opComplete: false});
    case UPDATE_PROPERTY_CONTACT_SUCCESS:
      // Update the contact in the address_list_id
      const updAddrs = replaceAddr(state, action.payload);
      return updateObject(state, {working: false, opComplete: true, addrs: updAddrs})
  
    case UPDATE_PROPERTY_CONTACT_FAILED:
      return updateObject(state, {error: action.payload.error, working: false, opComplete: true})
      
    case DELETE_PROPERTY_CONTACT_START:
      return updateObject(state, {working: true, opComplete: false});

    case DELETE_PROPERTY_CONTACT_SUCCESS:
      return updateObject(state, {working: false, opComplete: true, addrs: replaceAddr(state, action.payload)});
    
    case DELETE_PROPERTY_CONTACT_FAILED:
      return updateObject(state, {working: false, opComplete: true, error: action.payload.error});

    case UPDATE_PROPERTY_START:
      return updateObject(state, {working: true, opComplete: false});
  
    case UPDATE_PROPERTY_SUCCESS:
      return updateObject(state, {working: false, opComplete: true, addrs: replaceAddr(state, action.payload)});

    case UPDATE_PROPERTY_FAILED:
      return updateObject(state, {working: false, opComplete: true, error: action.payload.error});

    case GET_WHE_USERS_START:
      return updateObject(state, {working: true, opComplete: false, whe_users: []});

    case GET_WHE_USERS_SUCCESS:
      return updateObject(state, {working: false, opComplete: true, whe_users: [...action.payload.whe_users]});

    case GET_WHE_USERS_FAILED:
      return updateObject(state, {working: false, opComplete: true, error: action.payload.error});

    case MOVE_USER_START:
      return updateObject(state, {working: true, opComplete: false, whe_users: []});

    case MOVE_USER_SUCCESS:
      return updateObject(state, {working: false, opComplete: true, whe_users: [...action.payload.whe_users]});

    case MOVE_USER_FAILED:
      return updateObject(state, {working: false, opComplete: true, error: action.payload.error});

    default:
      return state;
  }
}

export default reducer;
