import React from 'react';
import { useSelector } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Input from '../UI/Input';

import {  } from '../../redux/actions/authActions';
import Spinner from '../Spinner/Spinner';
import PhotoUploadButton from '../Photos/PhotoUploader/PhotoUploadButton';

import axios from '../../utils/axios-whe';

import classes from './User.module.css';
import placeholderImage from '../../assets/images/missing.png'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object({
  email: Yup.string()
    .email("Valid email address is required")
    .required('Required'),
  first_name: Yup.string()
    .min(1, 'Must be 1 character or more')
    .max(32, 'Must be 32 characters or less')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Must be 2 character or more')
    .max(32, 'Must be 32 characters or less')
    .required('Required'),
  house_number: Yup.number()
    .min(1, "House number must be valid")
    .required('House number required.'),
  // street: Yup.string()
  //   .min(3, 'Must be 1 character or more')
  //   .max(64, 'Must be 32 characters or less')
  //   .required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  street: Yup.string()
    .test("len", "Must select a street.", val => {
      return val !== "0";
    }),
  context_note: Yup.string()
    .test("len", "Must select relationship.", val => {
      return val !== "0";
    })
});

const UserForm = (props) => {
  const auth = useSelector(state => state.auth);
  let updating = false;

  // alert(JSON.stringify(props.user))
  const initialValues = {
    email: props.user.email,
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    house_number: props.user.house_number,
    street: props.user.street,
    phone: props.user.phone,
    context_note: props.user.context_note,
    share_phone: props.user.share_phone,
    share_email: props.user.share_email,
    wants_notifications: props.user.wants_notifications,
    message_notification_frequency_id: props.user.message_notification_frequency_id
  };
    
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    }
  });

  const buildFormData = (formValues) => {
    let formData = new FormData()
    let keys = Object.keys(formValues);
    keys.map(key => formData.append(`user[${key}]`, formValues[key]));

    return formData;
  };

  const handleFormSubmit = (values) => {
    // debugger;
    axios.patch('/api_v1/account', buildFormData(values),
      {
        _method: 'patch',
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      .then(response => {
        // debugger;
        console.log(`UpdatedAddr`, response.data);
        updating = false;
        formik.setSubmitting(false)
        if (props.updated) {
          props.updated(response.data.user);
        }
        // toggle();
      })
      .catch(error => {
        alert(`ERROR updating address info.  ${error}`)
        formik.setSubmitting(false)
      })
  };

  const handleRadioChange = (event) => {
    // alert(`handleRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
      } else {
        formik.setFieldValue(event.target.name, false);
      }
    }
  }

  const handleSelectedFile = (image) => {
    // debugger;
    formik.setFieldValue('avatar', image)
  }
  const avatarPreview = () => {
    let avatarImg = props.user.avatar_thumbnail;

    if (avatarImg.includes('missing.png')) {
      avatarImg = placeholderImage;
    }
    if (formik.values.avatar) {
      avatarImg = URL.createObjectURL(formik.values.avatar);
    }
    return (
      <Image src={avatarImg} width="75" thumbnail />
    );
  }
  
  const buildForm = () => {
    const mnfOptions = props.user.message_notification_frequencies.map(mnf => {
      return (
        <option key={mnf.id} value={mnf.id}>{mnf.description}</option>
      );
    });

    return (
      <Row>
        <Col sm={12} className={classes.AuthCol}>
          <div className={classes.SignupBox}>
            <h4 className={classes.FormTitle}>Edit Profile</h4>
            <h5 style={{color: 'red', textAlign: 'center'}}>{auth.error}</h5>
            {props.location && props.location.state && props.location.state.error ? <p className={classes.FormError}>{props.location.state.error}</p>:null}
            <Form
              onSubmit={formik.handleSubmit}
              className={classes.Form}
            >
              <InputGroup className={classes.InputGroup} style={{height: '75px', marginBottom: '0px'}}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  {avatarPreview()}
                </InputGroup.Prepend>
                <PhotoUploadButton
                  single
                  uploadImages={ (image, uploadComplete) => {handleSelectedFile(image[0])} }
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='email'
                  type="email"
                  placeholder="Your email address"
                  required
                  label="Email"
                  value={formik.values.email}
                  name='email'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.email}
                  touched={formik.touched.email}
                  disabled={formik.isSubmitting}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='first_name'
                  type="text"
                  placeholder="Your first name"
                  required
                  label="First Name"
                  value={formik.values.first_name}
                  name='first_name'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.first_name}
                  touched={formik.touched.first_name}
                  disabled={formik.isSubmitting}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='last_name'
                  type="text"
                  placeholder="Your last name"
                  required
                  label="Last Name"
                  value={formik.values.last_name}
                  name='last_name'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.last_name}
                  touched={formik.touched.last_name}
                  disabled={formik.isSubmitting}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <Input
                  id='phone'
                  type="text"
                  placeholder="Your phone number"
                  required
                  label="Phone"
                  value={formik.values.phone}
                  name='phone'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.phone}
                  touched={formik.touched.phone}
                  showValid
                  disabled={formik.isSubmitting}
                />
              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <Input
                  id='house_number'
                  placeholder="Enter House Number"
                  type="number"
                  // required
                  label="House Number"
                  value={formik.values.house_number}
                  name='house_number'
                  changed={formik.handleChange}
                  blur={formik.handleBlur}
                  errors={formik.errors.house_number}
                  touched={formik.touched.house_number}
                  showValid
                  disabled={formik.isSubmitting}
                />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="street">Street</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="street"
                  name="street"
                  as="select" 
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.street}
                  disabled={formik.isSubmitting}
                >
                  <option value="0">[Select]</option>
                  <option value="Alder Ln">Alder Ln</option>
                  <option value="Aspen Ln">Aspen Ln</option>
                  <option value="Birch Ln">Birch Ln</option>
                  <option value="Buckthorn Ln">Buckthorn Ln</option>
                  <option value="Inkberry Ln">Inkberry Ln</option>
                  <option value="Ironwood Ln">Ironwood Ln</option>
                  <option value="Redbud Ln">Redbud Ln</option>
                  <option value="Sassafras Ln">Sassafras Ln</option>
                </Form.Control>
              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="context_note">Relationship to owner?</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="context_note" name="context_note"
                  as="select" 
                  value={formik.values.context_note}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.context_note}
                >
                  <option value="0">[Select]</option>
                  <option value="Owner">Owner</option>
                  <option value="Co-Owner">Co-Owner</option>
                  <option value="Trustee">Trustee</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Parent">Parent</option>
                  <option value="Child">Child</option>
                  <option value="Friend">Friend</option>
                  <option value="Renter">Renter</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
                  {formik.errors.context_note}
                </Form.Control.Feedback>
              </InputGroup>

              <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="share_phone_">Share Phone?</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Label className={classes.Radio} htmlFor='share_phone_true'>YES</Form.Label>
                <Form.Check
                  disabled={formik.isSubmitting}
                  type='radio'
                  as='input'
                  id="share_phone_true"
                  className={classes.Radio}
                  checked={formik.values.share_phone === true}
                  value={true}
                  name='share_phone'
                  onChange={handleRadioChange}
                  isInvalid={formik.errors['share_phone']}
                  isValid={!formik.errors['share_phone']}
                />
                <Form.Label className={classes.Radio} htmlFor='share_phone_false'>NO</Form.Label>
                <Form.Check
                  disabled={formik.isSubmitting}
                  styles={[{marginLeft: 20}]}
                  type='radio'
                  as='input'
                  id="share_phone_false"
                  className={classes.Radio}
                  checked={formik.values.share_phone === false}
                  value={false}
                  name='share_phone'
                  onChange={handleRadioChange}
                  isInvalid={formik.errors['share_phone']}
                  isValid={!formik.errors['share_phone']}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="share_email_">Share Email?</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Label className={classes.Radio} htmlFor='share_email_true'>YES</Form.Label>
                <Form.Check
                  disabled={formik.isSubmitting}
                  type='radio'
                  as='input'
                  id="share_email_true"
                  className={classes.Radio}
                  checked={formik.values.share_email === true}
                  value={true}
                  name='share_email'
                  onChange={handleRadioChange}
                  isInvalid={formik.errors['share_email']}
                  isValid={!formik.errors['share_email']}
                />
                <Form.Label className={classes.Radio} htmlFor='share_email_false'>NO</Form.Label>
                <Form.Check
                  disabled={formik.isSubmitting}
                  styles={[{marginLeft: 20}]}
                  type='radio'
                  as='input'
                  id="share_email_false"
                  className={classes.Radio}
                  checked={formik.values.share_email === false}
                  value={false}
                  name='share_email'
                  onChange={handleRadioChange}
                  isInvalid={formik.errors['share_email']}
                  isValid={!formik.errors['share_email']}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="wants_notifications_">Receive notifications?</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Label className={classes.Radio} htmlFor='wants_notifications_true'>YES</Form.Label>
                <Form.Check
                  disabled={formik.isSubmitting}
                  type='radio'
                  as='input'
                  id="wants_notifications_true"
                  className={classes.Radio}
                  checked={formik.values.wants_notifications === true}
                  value={true}
                  name='wants_notifications'
                  onChange={handleRadioChange}
                  isInvalid={formik.errors['wants_notifications']}
                  isValid={!formik.errors['wants_notifications']}
                />
                <Form.Label className={classes.Radio} htmlFor='wants_notifications_false'>NO</Form.Label>
                <Form.Check
                  disabled={formik.isSubmitting}
                  styles={[{marginLeft: 20}]}
                  type='radio'
                  as='input'
                  id="wants_notifications_false"
                  className={classes.Radio}
                  checked={formik.values.wants_notifications === false}
                  value={false}
                  name='wants_notifications'
                  onChange={handleRadioChange}
                  isInvalid={formik.errors['wants_notifications']}
                  isValid={!formik.errors['wants_notifications']}
                />
              </InputGroup>

              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend className={classes.InputGroup}>
                  <InputGroup.Text id="context_note">Notification Frequency?</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="message_notification_frequency_id" name="message_notification_frequency_id"
                  as="select" 
                  value={formik.values.message_notification_frequency_id}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.message_notification_frequency_id}
                >
                  {mnfOptions}
                </Form.Control>
                <Form.Control.Feedback className={classes.ErrorMessage} type='invalid'>
                  {formik.errors.message_notification_frequency_id}
                </Form.Control.Feedback>
              </InputGroup>


              <InputGroup>
                <InputGroup.Prepend className={classes.InputGroup} />
                <Button type="submit" disabled={updating}>Update</Button>
                &nbsp;&nbsp;&nbsp;
                <Button type="cancel" onClick={props.cancel} disabled={updating}>Cancel</Button>
              </InputGroup>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }

  if (auth.loading) {
    return <Spinner />;
  } else {
    return buildForm();
  }
}

export default UserForm;