import axios from '../../utils/axios-whe';

export const CREATE_ALBUM_START = 'create_album_start';
export const CREATE_ALBUM_SUCCESS = 'create_album_success';
export const CREATE_ALBUM_FAILED = 'create_album_failed';

export const UPDATE_ALBUM_START = 'update_album_start';
export const UPDATE_ALBUM_SUCCESS = 'update_album_success';
export const UPDATE_ALBUM_FAILED = 'update_album_failed';

export const ADD_PHOTOS_START = 'add_photos_start';
export const ADD_PHOTOS_SUCCESS = 'add_photos_success';
export const ADD_PHOTOS_FAILED = 'add_photos_failed';

export const DELETE_PHOTO_START = 'delete_photo_start';
export const DELETE_PHOTO_SUCCESS = 'delete_photo_success';
export const DELETE_PHOTO_FAILED = 'delete_photo_failed';

export const GET_ALL_PHOTOS_START = 'get_all_photos_start';
export const GET_ALL_PHOTOS_SUCCESS = 'get_all_photos_success';
export const GET_ALL_PHOTOS_FAILED = 'get_all_photos_failed';
export const SET_CURRENT_ALBUM = 'SET_CURRENT_ALBUM';
export const GET_ALBUM_SUCCESS = 'get_album_success';

export const LIKE_ALBUM_START = "like_album_start";
export const LIKE_ALBUM_SUCCESS = "like_album_success";
export const LIKE_ALBUM_FAILED = "liks_album_failed";

export const LIKE_PHOTO_START = "like_photo_start";
export const LIKE_PHOTO_SUCCESS = "like_photo_success";
export const LIKE_PHOTO_FAILED = "like_photo_failed";

const beginGetAllPhotos = () => {
  return {type: GET_ALL_PHOTOS_START};
};

const getAllPhotosError = (errorMessage) => {
  return { type: GET_ALL_PHOTOS_FAILED, payload: {error: errorMessage} };
}

const getAllPhotosSuccess = (albums, defaultAlbumId) => {
  return {type: GET_ALL_PHOTOS_SUCCESS, payload: {albums: albums, defaultAlbumId: defaultAlbumId} }
}

const getAlbumSuccess = (album) => {
  return { type: GET_ALBUM_SUCCESS, payload: {album: album} };
}

export const getAlbum = (id) => {
  const fetchUrl = `/api_v1/albums/${id}`;
  console.log(`photoActions: getAlbum: ${fetchUrl}`)
  // debugger;
  return (dispatch, getState) => {
    const auth = getState().auth;
    axios.get(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log('photoActions: getAlbum: fetched', response)
      dispatch(getAlbumSuccess(response.data.album));
    })
    .catch(error => {
      console.log("ERROR: " + error);
      dispatch(getAllPhotosError(`Error fetching photos.  ${error.message}`))
      // this.setState((prevState, prevProps) => ({
      //   error: error
      // }));
    });
  
  }
}
const getAlbums = (dispatch, getState, fetchUrl, defaultAlbumId) => {
  console.log('photoActions: getAlbums')

  const auth = getState().auth;
  if (!auth.logged_in) {
    console.log('photoActions: getAlbums: not logged in')
    return dispatch(getAllPhotosError("Not logged in.  Can't get photos."));
  }

  const retVal = dispatch(beginGetAllPhotos());
  axios.get(fetchUrl, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${auth.token}`,
      'X-CSRF-Token': auth.csrf_token
    }
  })
  .then(response => {
    console.log('photoActions: getAlbums: fetched', response)
    dispatch(getAllPhotosSuccess(response.data, defaultAlbumId));
  })
  .catch(error => {
    console.log("ERROR: " + error);
    dispatch(getAllPhotosError(`Error fetching photos.  ${error.message}`))
    // this.setState((prevState, prevProps) => ({
    //   error: error
    // }));
  });
  return retVal;
}
/*
 * Fetch all photo albums for this site.
 */
export const getAllPhotoAlbums = (defaultAlbumId) => {
  console.log('photoActions: getAllPhotoAlbums')
  const fetchUrl = '/api_v1/albums.json';
  return (dispatch, getState) => {
    getAlbums(dispatch, getState, fetchUrl, defaultAlbumId);
  }
}

export const getMyPhotoAlbums = (defaultAlbumId) => {
  console.log('photoActions: getMyPhotoAlbums')
  const fetchUrl = '/api_v1/albums.json?m=1';
  return (dispatch, getState) => {
    getAlbums(dispatch, getState, fetchUrl, defaultAlbumId);
  }
}

export const setCurrentAlbum = (album) => {
  return (dispatch) => {
    dispatch({ type : SET_CURRENT_ALBUM, payload: {currentAlbum: album} });
  }
}

export const createPhotoAlbum = (formValues) => {
  // Create the photo album on the host.
  // Once created we can begin adding photos to it
  return (dispatch, getState) => {
    const formJson = {
      "album": {...formValues }
    };

    const auth = getState().auth;
    dispatch({ type: CREATE_ALBUM_START });
    axios.post('/api_v1/albums.json', formJson, {
      _method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log('[NewAlbum]: ', response.data);
      dispatch({ type: CREATE_ALBUM_SUCCESS, payload: {album: response.data.album} })
    })
    .catch(error => {
      dispatch({ type: CREATE_ALBUM_FAILED, payload: {error: error.message} })
    })
  }
};

export const publishAlbum = () => {
  // set the album to the published state IF we have photos in the album.
};

export const updatePhotoAlbum = (id, formValues) => {
  const formJson = {
    "album": {...formValues }
  };

  const url = `/api_v1/albums/${id}`;
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: UPDATE_ALBUM_START });

    // update the album from publish to not, allow all to allow only me, title, etc
    axios.patch(url, formJson, {
      _method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log('postActions: updatePhotoAlbum', response.data);
      dispatch({ type: UPDATE_ALBUM_SUCCESS, payload: {album: response.data.album} })
    })
    .catch(error => {
      dispatch({ type: UPDATE_ALBUM_FAILED, payload: {error: error.message} })
    })
  }
};

export const addPhotos = (pictures) => {
  // Kick off a photo add for each picture in pictures
};

export const deletePhoto = (albumId, photoId) => {
 // delete a photo from the album
  console.log(`postActions: deletePost: ID: ${photoId}`)

  return (dispatch, getState) => {
    dispatch({ type: DELETE_PHOTO_START });
    const auth = getState().auth;
    axios.post(`/api_v1/del_image/${albumId}/${photoId}`, {}, {
      // method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      //console.log(JSON.stringify(response));
      console.log(response);
      dispatch({ type: DELETE_PHOTO_SUCCESS });

      // XXX: Do a REDIRECT to myposts
      // dispatch({ type: SET_REDIRECT_PATH, payload: {path: '/myposts'} });
    })
    .catch(error => {
      // debugger;
      let errorMsg = error.message;
      if (error.response && error.response.data && error.response.data.message) {
        errorMsg = error.response.data.message
      }
      console.error(`ERROR deleting photo.  ERR: ${error}`);
      dispatch({ type: DELETE_PHOTO_FAILED, payload: {error:  errorMsg} });
    });
  }
};

export const likeAlbum = (album) => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: LIKE_ALBUM_START});
    axios.post(`/api_v1/likes/thumbs_up/Album/${album.id}`, {},
    {
      _method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger
      console.log(`Thumbs Up Album.  Status`, response.data);
      dispatch({type: LIKE_ALBUM_SUCCESS, payload: {album: album, likes: response.data.likes}})
      // props.updated(values);
      // XXX: Shuold we have a disable form / reenable toggle methdo invoked here?
    })
    .catch(error => {
      alert(`ERROR updating Album info.  ${error}`)
      dispatch({type: LIKE_ALBUM_FAILED, payload: {error: error}});
    })
  }
}
export const likePhoto = (album, photo) => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({ type: LIKE_PHOTO_START});
    axios.post(`/api_v1/likes/thumbs_up/Photo/${photo.id}`, {},
    {
      _method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger
      console.log(`Thumbs Up photo.  Status`, response.data);
      dispatch({type: LIKE_PHOTO_SUCCESS, payload: {album: album, photo: photo, likes: response.data.likes}})
      // props.updated(values);
      // XXX: Shuold we have a disable form / reenable toggle methdo invoked here?
    })
    .catch(error => {
      alert(`ERROR updating photo info.  ${error}`)
      dispatch({type: LIKE_PHOTO_FAILED, payload: {error: error}});
    })
  }
}
