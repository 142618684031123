import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authReducer from '../redux/reducers/authReducers';
import postReducer from '../redux/reducers/postReducers';
import controlsReducer from '../redux/reducers/controlsReducer';
import uploadReducer from '../redux/uploadFile/uploadFile.reducer';
import photosReducer from '../redux/reducers/photoReducers';
import siteMessagesReducer from '../redux/reducers/siteMessageReducers';
import calendarEventReducer from '../redux/reducers/calendarEventReducer';
import newsReducer from '../redux/reducers/newsReducers';
import searchReducer from '../redux/reducers/searchReducer';
import mapReducer from '../redux/reducers/mapReducers';
import fileUploadReducer from '../redux/reducers/fileUploadReducers';
import emailListsReducer from '../redux/reducers/emailListsReducer';
import propertyReducer from '../redux/reducers/propertyReducers';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
const rootReducer = combineReducers({
  auth: authReducer,
  posts: postReducer,
  controls: controlsReducer,
  uploader: uploadReducer,
  photos: photosReducer,
  siteMessages: siteMessagesReducer,
  calendarEvents: calendarEventReducer,
  news: newsReducer,
  search: searchReducer,
  map: mapReducer,
  fileUpload: fileUploadReducer,
  emailLists: emailListsReducer,
  properties: propertyReducer
});

console.log('***** store **** SETUP *****')
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

export default store;
