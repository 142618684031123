import { updateObject } from '../../utils/utils';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  SET_SEARCHING,
  SET_RESULTS,
  SET_ERROR,
  SHOW_SEARCH_BOX,
  CLEAR_SEARCH_RESULTS,

  SEARCH_SITE_MESSAGES_START,
  SEARCH_SITE_MESSAGES_SUCCESS,
  SEARCH_SITE_MESSAGES_FAILED
} from '../actions/searchActions';

const INITIAL_STATE = {
  posts: [],
  site_messages: [],
  searching: false,
  searched: false,
  lastSearch: '',
  error: '',
  showSearchBox: false
}

const reducer = (state = INITIAL_STATE, action) => {
  // console.log(`[postReducers]: ${action.type}`, action.payload);
  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    case CLEAR_SEARCH_RESULTS:
      return INITIAL_STATE;

    case SET_SEARCHING:
      return updateObject(state, { searching: action.payload.searching, lastSearch: action.payload.searchString });

    case SET_RESULTS:
      // debugger;
      return updateObject(state, { searching: false, posts: action.payload.posts, searched: true })

    case SET_ERROR:
      return updateObject(state, { searching: false, error: action.payload.error} )

    case SHOW_SEARCH_BOX:
      return updateObject(state, { showSearchBox: action.payload.show });

    case SEARCH_SITE_MESSAGES_START:
      return updateObject(state, { searching: true, lastSearch: action.payload.searchString });
      
    case SEARCH_SITE_MESSAGES_SUCCESS:
      return updateObject(state, { searching: false, site_messages: action.payload.site_messages, searched: true });
        
    case SEARCH_SITE_MESSAGES_FAILED:
      return updateObject(state, { searching: false, error: action.payload.error, searched: false });
            
    default:
      return state;
  }
}

export default reducer;
