import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
// import FormControl from 'react-bootstrap/FormControl';

import { withRouter } from "react-router";

import RbNavItem from '../NavigationItems/NavigationItem/RbNavItem';
import classes from './NestedDropdownButton.module.css';
import '../NavigationItems/OverrideStyles.css';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick, isDropdownPath }, ref) => {
  console.log(`CustomToggle:  isDropdownPath: ${isDropdownPath}`)
  return (
    <div style={{padding: '1px'}}>
      <a
        className={(isDropdownPath ? [classes.NestedDropdownButton, classes.NestedDropdownButton_active].join(' '): classes.NestedDropdownButton)}
        href="/#"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children} &#x25bc;
      </a>
    </div>
  );
});

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    // const [value, setValue] = useState('');
    const value = '';

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {/* <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        /> */}
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().includes(value),
          )}
        </ul>
      </div>
    );
  },
);

class NestedDropdownButton extends Component {
  // WDS: See if I can control dropdown "show" after an item in the dropdown is clicked without
  //  affecting the target.
  
  constructor(props) {
    super(props);

    console.log(`[NestedDropdownButton] path: ${props.location.pathname}`)
    this.state = {
      showDropdown: false
    };
    // this.menuSelected = false;
    this.childHrefs = [];
    // React.Children.toArray(props.children).map((child) => {
    //   this.childHrefs.push(child.props.href)
    // })
    props.children.map((child) => {
      if (child) {
        return this.childHrefs.push(child.props.href)
      } else {
        return null;
      }
    })
    // debugger;
  }

  toggleDDState = (link) => {
    console.log(`toggleDDState: was: ${this.state.showDropdown}`)
    // WDS: The following was an attempt to keep the dropdown open if we're in a sidebar.
    //    Problem is that it goes OVER the items beneath it as opposed to pushing them down
    //    so their visible and clickable.
    // if (this.state.showDropdown && this.props.openIfSideSelected && this.props.openIfSideSelected === true) {
    //   return;
    // }
    this.setState((prevState, prevProps) => ({
      showDropdown: !prevState.showDropdown
    }))
  }
  render() {
    return (
      <Dropdown show={this.state.showDropdown} 
        onToggle={() => {this.toggleDDState(this.props.location.path)}}>
        <Dropdown.Toggle
          isDropdownPath={this.childHrefs.includes(this.props.location.pathname)} as={CustomToggle} id="dropdown-custom-components">
          {this.props.title}
        </Dropdown.Toggle>
    
        <Dropdown.Menu as={CustomMenu} show={this.state.showDropdown}>
          { React.Children.toArray(this.props.children).map((child, index) => {
              return (
                <React.Fragment key={`${this.props.title}-${index}`}>
                <Dropdown.Item 
                  key={index}
                  href={child.props.href}
                  eventKey="1"
                  as={RbNavItem}
                  variant="warn"
                  // activeClassName={classes.NestedDropdownButton} 
                  clicked={() => {this.toggleDDState(child.props.href)}}
                  closed={this.props.closed}
                >
                  {child.props.children}
                </Dropdown.Item>
                {child.props.divider ? <Dropdown.Divider /> : null}
                </React.Fragment>
              )
            })
          }
        </Dropdown.Menu>
      </Dropdown>  
    );
  }
}
export default withRouter(NestedDropdownButton);