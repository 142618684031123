import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Spinner from '../Spinner/Spinner';
import Input from '../UI/Input';

import { forgotPassword, clearLoginCredentials } from '../../redux/actions/authActions';

import classes from './Authenticate.module.css';

let emailAddr = '';

const ForgotPassword = (props) => {

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [issuedForgotPassword, setIssuedForgotPassword] = useState(false);

  useEffect(() => {
    return () => {
      // console.log("ForgotPassword: cleaned up");
      dispatch(clearLoginCredentials());
    };
  }, []);

  const schema = Yup.object({
    email: Yup.string()
      .email("Valid email address is required")
      .required('Required')
  });
    
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    // validateOnChange: true,
    validationSchema: schema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  
  const handleFormSubmit = (values) => {
    // const keys = Object.keys(values);
    // console.log(values);
    dispatch(forgotPassword(values.email));
    emailAddr = values.email;
    setIssuedForgotPassword(true);
  }

  const buildForm = () => {
    console.log('buildForm', props)
    return (
      <Row>
        <Col sm={12} className={classes.AuthCol}>
          <div className={classes.SigninBox}>
            <h4 className={classes.FormTitle}>Forgot Password</h4>
            <h5 style={{color: 'red', textAlign: 'center'}}>{auth.error}</h5>
            {props.location && props.location.state && props.location.state.error ? <p className={classes.FormError}>{props.location.state.error}</p>:null}
            <Form
              onSubmit={formik.handleSubmit}
              className={classes.Form}
            >
              <InputGroup className={classes.InputGroup}>
              <Input
                id='email'
                type="email"
                placeholder="Enter Email Address"
                required
                label="Email Address"
                value={formik.values.username}
                name='email'
                changed={formik.handleChange}
                blur={formik.handleBlur}
                errors={formik.errors.email}
                touched={formik.touched.email}
              />
              </InputGroup>
              <InputGroup className={classes.InputGroup}>
                <InputGroup.Prepend>
                </InputGroup.Prepend>
                <Button type="submit">Reset Password</Button>
              </InputGroup>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }

  console.log(`Authenticate: ${auth.logged_in}`)
  // setComponentRenderCount(componentRenderCount + 1)
  if (auth.loading) {
    return <Spinner />;
  } else {
    // if (auth.logged_in) {
    //   // WDS: Change to use a previous URL.  Add reducer/action
    //   console.log(`[Authenticate]: authRedirectPath: ${auth.authRedirectPath}`)
    //   return <Redirect to={auth.authRedirectPath} />;
    // } else {
    //   return buildForm();
    // }
    let msg = `Check your email account "${emailAddr}" for a link to let you set your password.`;
    if (auth.error) {
      msg = auth.error;
    }
    if (issuedForgotPassword) {
      return (
        <Row>
          <Col sm={12} className={classes.AuthCol}>
            <div className="Placeholder">{msg}</div>
          </Col>
        </Row>
      )
    } else {
      return buildForm();
    }
  }
}

export default ForgotPassword;