import React from 'react';

import PhotosCommon from './PhotosCommon';

const Photos = (props) => {
  return (
    <PhotosCommon title="All Album" mine={false} />
  )
}

export default Photos;