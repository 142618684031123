import React from 'react';

import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import classes from './LikesModal.module.css';

const LikesModal = (props) => {
  if (props.likes === null) {
    return null;
  }
  const likes = props.likes.map((like) => {
    return (
      <React.Fragment key={`${like.full_name}${like.user_id}-0`}>
      <li key={`${like.full_name}${like.user_id}-1`}>{like.full_name}</li>
      </React.Fragment>
    )
  })

  return (
    <Modal
    show={props.show}
    onHide={() => {props.onHide()}}
    backdropClassName={classes.LikeBackdrop}
    dialogClassName={classes.LikeContent}
    scrollable={true}
  >
    <Modal.Header closeButton>
      <span className={classes.ThumbsUpCount}>
        <FontAwesomeIcon icon={faThumbsUp} size="1x" />
      </span>
      <span className={classes.ThumbsUpCount}>{props.likes.length} like this {props.likes[0].likable_type.toLowerCase()}</span>
    </Modal.Header>
    <Modal.Body>
      <ol style={{marginTop: '0px', marginBottom: '0px'}}>
        {likes}
      </ol>
    </Modal.Body>
  </Modal>
  );
}

export default LikesModal;
