import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { connect } from 'react-redux';

import axios from '../utils/axios-whe';

import { setRedirectPath } from '../redux/actions/authActions';

import { setTitle } from '../redux/actions/controlsActions';

import ModalError from './Errors/ModalError/ModalError';

class SiteDefinition extends Component {
  constructor(props) {
    super(props);
    this.state = { site_defs: [], count: 1, error: null };
    // debugger;
  }

  componentDidMount() {
    this.props.setTitle("Site Definitions");
    console.log('SiteDefinition: componentDidMount', this.props.location);

    axios.get(`/api_v1/site_definitions.json`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${this.props.auth.token}`,
        'X-CSRF-Token': this.props.auth.csrf_token
      }
    })
    .then(response => {
      console.log('=======> SiteDefinition: response after axios.get...')
      // console.log(`SiteDefinition: count: ${this.state.count}`)
      if (response !== undefined) {
        this.setState({site_defs: response.data, count: this.state.count + 1});
      }
    })
    .catch(error => {
      // dispatch(fetchIngredientsFailed());
      console.log("ERROR fetching SiteDefinitions...", error)
      // this.setState({error: error});
      this.setState((prevState, prevProps) => ({
        error: error
      }));
  
    });
  }

  renderSites() {
    if (this.state.error) {
      console.log("[SiteDefinition]: redirecting to /")
      // WDS: Tried this to see if I could get proper redirect working.  This did in fact
      //    redirect to the /auth path.  Problem is that we were already logged_in as was
      //    seen in the state in the Redux store so it ended up recursing back to here...
      //    NEED to log the error and NOT redirect to /auth.  Need to either:
      //    - just log the error on this page.
      //    - add a reducer / action to perform a logout...
      //  I'm inclined to create a common error logging wrapper and make sure the component
      //  does "the right thing", whatever that is
      //
      // this.props.history.replace('/auth', 
      //                   {state: { from: '/neighbors', error: `***** Unauthorized.  Must sign in to access this feature.` }});

      return (
        // <Redirect
        //   to={{
        //     pathname: '/',
        //     state: { from: this.props.path, error: `Error trying too access SiteDefinitions.  ${this.state.error.message}` }
        //   }}
        // />
        <h5 style={{color: 'red'}}>Error fetching site definitions.  ERR: {this.state.error.message}</h5>
      );
    }
    console.log(`There are ${this.state.site_defs.length}`)
    return this.state.site_defs.map((site_def) => {
      console.log(site_def.name)
      return (
        <Row key={site_def.id + this.state.count}>
          <Col>{`${site_def.id} - ${site_def.name}`}</Col>
        </Row>
      );
    })
  }
  render() {
    console.log("******* rendering sites ***********")
    if (this.state.error) {
      const errMsg = `Error of "${this.state.error.message}" while getting SiteDefinition info.`;
      return <ModalError redirect="/auth" error={errMsg} header="Error fetching Site Definitions." />
    }
    const sites = this.renderSites();
    return (
      <Col xs={12}>
        { sites }
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRedirect: (path) => dispatch(setRedirectPath(path)),
    setTitle: (title) => dispatch(setTitle(title))
  }
};


// export default connect(mapStateToProps)(withErrorHandler(SiteDefinition, axios));
export default connect(mapStateToProps, mapDispatchToProps)(SiteDefinition);
