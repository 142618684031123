import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';

import Spinner from '../../Spinner/Spinner';

import PostForm from './PostForm';
import PostHelp from './PostHelp';

import { setTitle } from '../../../redux/actions/controlsActions';
import { savePost } from '../../../redux/actions/postActions';

import classes from './PostForm.module.css';
import "./NewPost.css";

  let postDefaults = {
    id: null,
    name: '',
    content: '',
    published: false,
    allow_comments: true,
    user_id: null,
    site_definition_id: null,
    neighborhood_id: null,
    post_type_id: 1 // XXX: Replace with something dynamic
    // comments: []
  };

const NewPost = (props) => {
  const auth = useSelector(state => state.auth);
  const postsState = useSelector(state => state.posts);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("New Post"));
  }, [dispatch]);

  // handleFormSubmit receives the values from the form in a JS object
  // the postCallback to let the form know we're done processing.
  const handleFormSubmit = (values, postCallback) => {
    dispatch(savePost(values))
  }

  if (postsState.working) {
    return <Spinner title="Saving post..." />
  }

  if (postsState.postsOpComplete) {
    history.replace("/myposts");
    return null;
  }
  
  return (
    <div>
      <Col sm={10}>
        <div className={classes.Title}>
          Create New Post
        </div>
        <PostHelp title="New Post" />
      </Col>
      <Col sm={10}>
        <PostForm 
          post={postDefaults}
          auth={auth}
          formSubmit={handleFormSubmit}
        />
      </Col>
    </div>
  );
}

export default NewPost;
