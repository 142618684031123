import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import "./AnimatedText.css";

const AnimatedText = ({ text, onClick }) => {
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    // TODO: The following is for additional animation types.  For initial impl
    //    I chose to keep it simple and just do a slide-in reminder text.
    // const intervalId = setInterval(() => setIsAnimating(!isAnimating), 1000);
    // return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{display: 'inline-block', width: '160px', _textAlign: 'center'}}>
      <div style={{_marginLeft: '10px'}} className={`animated-button ${isAnimating ? 'pulse' : ''}`}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <span style={{marginLeft: '4px',fontSize: '1.0em'}}>{text}</span>
        {/* <button
          
          onClick={onClick}
        >,
          {text}
        </button> */}
      </div>
    </div>
  );
};

export default AnimatedText;
