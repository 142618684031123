import React from 'react';

import { NavLink } from 'react-router-dom';

const RbNavItem = (props) => {
  const linkClicked = (event, link) => {
    if (props.clicked) {
      props.clicked(event, link);
    }

    if (props.closed) {
      props.closed(event);
    }
  }
  console.log(`[RbNavItem]  activeClassName: ${props.activeClassName}`)
  return (
    <div onClick={(event) => {linkClicked(event, props.href)}} style={{height: '50px'}}>
      <NavLink 
        className="dropdown-item"
        style={{fontSize: '1.1em'}}
        // activeClassName={props.activeClassName}
        activeStyle={{color: 'white'}}
        to={props.href}>{props.children}</NavLink>
    </div>
  );
}

export default RbNavItem;