import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

const NavigationItem = (props) => {
  let clicked = () => {};
  if (props.clicked) {
    clicked = props.clicked;
  }
  return (
    <li className={classes.NavigationItem} onClick={clicked}>
      <NavLink
        to={props.link}
        exact={props.exact}
        activeClassName={props.activeClassName ? props.activeClassName : classes.active}
      >
        {props.children}
      </NavLink>
    </li>
  );
};

export default NavigationItem;
