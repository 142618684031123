import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from './Posts.module.css';
import Post from './Post';

import PostTocSelect from './PostTocSelect';

import Spinner from '../Spinner/Spinner';

import SideDrawerRightMobile from '../Navigation/SideDrawer/SideDrawerRightMobile';
import Backdrop from '../UI/Backdrop/Backdrop';

import SearchResultsContent from '../Search/SearchResultsContent';

import { clearCurrentPost, getPost, setCurrentPost, getPostTocAndOnePost, getPostsForCurrentUser, setPosts, clearPostError } from '../../redux/actions/postActions';
import { openDrawer, closeDrawer, toggleDrawer, showDrawerButton, hideDrawerButton, setTitle } from '../../redux/actions/controlsActions';
import SearchBox from '../Search/SearchBox';

import { showSearchBox, searchPosts, clearSearch } from '../../redux/actions/searchActions';

const ERROR_TIMER_COUNT = 2;

class PostsCommon extends Component {
  constructor(props) {
    super(props);
    this.postToc = {};
    this.state = {countdownTimer: ERROR_TIMER_COUNT};
  }

  componentDidMount() {
    // console.log('[PostsCommon]: componentDidMount', this.props.auth);
    let defaultPostId = -1;
    if (this.props.match.params.postId) {
      defaultPostId = parseInt(this.props.match.params.postId);
      if (isNaN(defaultPostId) || defaultPostId < 1) {
        defaultPostId = -1;
      }
    }
    if (this.props.mine) {
      this.props.getPostsCommon(defaultPostId);
    } else {
      this.props.getPostTocAndOnePost(defaultPostId);
    }
    this.props.showButton();
    this.props.setTitle(this.props.title);
  }

  componentWillUnmount() {
    this.props.hideButton();
    // this.props.hideSearchBox();
    this.props.clearSearch();
  }

  tocEntryClicked = (post) => {
    console.log('[PostsCommon][tocEntryClicked]')
    let postIx = this.props.posts.postToc.findIndex(p => p.id === post.id);
    if (postIx !== -1 && post.id !== this.props.posts.currentPost.id) {
      console.log(`[PostsCommon][tocEntryClicked]: id: ${post.id}  postIX: ${postIx}`)
      // XXX: Kick off fetch of new post
      // this.props.setCurrentPost(this.props.posts.posts[postIx]);
      this.props.getPost(post.id);
      this.props.closeDrawer();
    }
  }

  toggleDrawer = () => {
    this.props.toggleDrawer();
  }
  closeDrawer = () => {
    console.log('closeDrawer')
    this.props.closeDrawer();
  }

  searchPostsProc = searchValue => {
    // do the search
    if (searchValue.length > 2) {
      this.props.searchPosts(searchValue);
    }
  }

  updatePost = (post) => {
    // Find post in state.
    let postIx = this.props.posts.postToc.findIndex(p => p.id === post.id);
    if (postIx !== -1) {
      // Update the posts.
      let updatedPosts = [...this.props.posts.posts];
      console.log('PostsCommon: updatePost: current and updated', postIx, updatedPosts[postIx], post);
      updatedPosts[postIx] = {...post};
      this.props.setCurrentPost(updatedPosts[postIx]);
      this.props.setPosts(updatedPosts);
    }
  }

  renderPost = () => {
    // console.log(`***** renderPost ***** currentPostID: ${this.props.posts.currentPost.id}`)
    return (
      <div>
        <Post
          key={this.props.posts.currentPost.id}
          post={this.props.posts.currentPost}
          updated={this.updatePost}
          showNewPostButton={!this.props.mine}
        />
      </div>
    );
  }
  renderPostContent = () => {
    let posts = this.props.posts.postToc;

    this.postToc = {};
    if (posts.length === 0) {
      return null;
    }
    let curYear = null;
    let curYearToc = [];
    posts.forEach((post) => {
      if (curYear === null || curYear !== new Date(post.post_date).getFullYear()) {
        curYear = new Date(post.post_date).getFullYear();
        curYearToc = [];
        this.postToc[curYear] = curYearToc;
      }
      curYearToc.push(post);
    });

    // debugger;
    // console.log('PostTOC Object:', this.postToc);
    let postContent = (
      <Row>
        <Col sm={12} md={9} className={classes.Posts}>
          <Row>
            <Col sm={12} md={12}>
              <SearchBox placeholder="Search Posts..." searchProc={this.searchPostsProc} />
            </Col>
          </Row>
          {/* {postContentBody} */}
          <Row>
            <Col sm={12} md={12}>
              {this.props.posts.currentPost === null ? 
                null:
                (this.props.posts.working ? <Spinner /> :
                this.renderPost())
              }
            </Col>
          </Row>
          
        </Col>
        <Col sm={3} className={[classes.Toc, classes.TocDesktop]}>
          {this.props.posts.postToc.length > 0 ? <PostTocSelect title={this.props.title} clicked={this.tocEntryClicked} postToc={this.postToc} currentPost={this.props.posts.currentPost} /> : console.log("**** NOT READY ***")}
        </Col>
      </Row>
    );
    return postContent;
  }

  renderPostToc = () => {
    return (
      <Row>
        <Col className={classes.Toc}>
          {this.props.posts.postToc.length > 0 ? <PostTocSelect title={this.props.title} clicked={this.tocEntryClicked} postToc={this.postToc} currentPost={this.props.posts.currentPost} /> : console.log("**** NOT READY ***")}
        </Col>
      </Row>
    );
  }

  renderBackdrop = () => {
    let backdrop;
    if (this.props.controls.drawerOpen) {
      // console.log('*** MY POSTS *** backdrop SHOW')
      backdrop = <Backdrop show={this.props.controls.drawerOpen} click={this.closeDrawer} />;
    } else {
      // console.log('Backdrop not shown')
    }
    return backdrop;
  }

  renderPostSearchResults = () => {
    return (
      <Row>
        <Col sm={12} md={12} className={classes.Posts}>
          <SearchResultsContent />
        </Col>
      </Row>
    )
  }
  renderPostsCommonContent = () => {
    const postContent = this.renderPostContent();
    return (
      <div className={classes.PostsContainer}>
        <Row>
          <Col sm={12}>
            <SideDrawerRightMobile
              contentPanel={this.renderPostToc()}
              open={this.props.controls.drawerOpen}
              closed={this.toggleDrawer}
            />
            {this.renderBackdrop()}
            <Backdrop />
          </Col>
        </Row>

        { postContent }
      </div>
    );
  }

  renderContent = () => {
    if (this.props.search.searched) {
      return this.renderPostSearchResults();
    }
    return this.renderPostsCommonContent()
  }

  timerError = (count) => {
    setTimeout(() => {
      if (count === 0) {
        this.props.clearPostError();
        // if (this.props.mine) {
        //   this.props.getPostsCommon(-1);
        //   this.props.history.replace("/myposts")
        // } else {
        //   this.props.getPostTocAndOnePost(-1);
        //   this.props.history.replace("/posts")
        // }
        // User most either tried for an invalid post or they have no posts.  Redir to posts
        this.props.getPostTocAndOnePost(-1);
        this.props.history.replace("/posts")
    } else {
        this.setState({countdownTimer: count - 1});  
        this.timerError(count - 1);
      }
    }, 1000)
  }
  render() {
    if (this.props.posts.error) {
      if (this.state.countdownTimer === ERROR_TIMER_COUNT) {
        this.timerError(ERROR_TIMER_COUNT)
      }
      return (
        <div className={classes.ErrorContainer}>
          <Row>
            <Col sm={12}>
              <h4 style={{textAlign: 'center', paddingTop:'40px'}}>{this.props.posts.error} - Reloading in {this.state.countdownTimer+1} seconds...</h4>
            </Col>
          </Row>
        </div>
      )
    }

    if (this.props.posts.working || this.props.search.searching) {
      const spinnerLabel = (this.props.search.searching ? "Searching...": "Fetching posts...")
      return <Spinner title={spinnerLabel} />;
    }
  
    if (this.props.posts.postToc.length === 0) {
      return (
        <h4 style={{textAlign: 'center'}}>[You have not posted to the site yet.]</h4>
      );
    }

    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    posts: state.posts,
    controls: state.controls,
    search: state.search
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPost: (post) => dispatch(setCurrentPost(post)),
    clearCurrentPost: () => dispatch(clearCurrentPost()),
    getPostsCommon: (postId) => dispatch(getPostsForCurrentUser(postId)),
    getPostTocAndOnePost: (postId) => dispatch(getPostTocAndOnePost(postId)),
    setPosts: (posts) => dispatch(setPosts(posts)),
    getPost: (postId) => dispatch(getPost(postId)),
    clearPostError: () => dispatch(clearPostError()),
    clearSearch: () => dispatch(clearSearch()),

    openDrawer: () => dispatch(openDrawer()),
    closeDrawer: () => dispatch(closeDrawer()),
    toggleDrawer: () => dispatch(toggleDrawer()),
    showButton: () => dispatch(showDrawerButton()),
    hideButton: () => dispatch(hideDrawerButton()),
    setTitle: (title) => {dispatch(setTitle(title))},

    // hideSearchBox: () => {dispatch(showSearchBox(false))},
    searchPosts: (searchValue) => {dispatch(searchPosts(searchValue))}
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostsCommon));
