import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

import { useSelector, useDispatch } from 'react-redux';

import { clearSearch } from '../../redux/actions/searchActions';
import classes from './Search.module.css';

const SearchBox = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const search = useSelector(state => state.search);
  const dispatch = useDispatch();

  useEffect(() => {
    setSearchValue(search.lastSearch);
  }, [search.lastSearch])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (searchValue.length > 2) {
        if (props.searchProc) {
          props.searchProc(searchValue);
        } else {
          alert("NO SEARCH METHOD")
        }
      }
    }
  }

  const inputChanged = (e) => {
    setSearchValue(e.target.value);
  }

  const renderSearchBoxControl = () => {
    if (search.searched) {
      return (
        <InputGroup.Text onClick={() => {dispatch(clearSearch())}} title="Clear Search">
          <FontAwesomeIcon size="1x" className={classes.CancelSearchIcon} icon={faTimes} />
        </InputGroup.Text>
      )
    } else {
      return (
        <InputGroup.Text onClick={() => {setSearchValue("")}}>
          <FontAwesomeIcon size="1x" className={classes.CancelSearchIcon} icon={faTimesCircle} />
        </InputGroup.Text>
      )
    }
  }
  // WDS: This initially was set to none and then block to show/hide.  However, I changed the way
  //    I wanted this to work so that it always showed.
  let showSearch = "block";
  if (search.showSearchBox) {
    showSearch = "block";
  }

  let bp = (props.placeholder ? props.placeholder : "NONE");

  // debugger
  return (
    <Row style={{display: showSearch}}>
      <Col sm={12}>
        <InputGroup>
          <InputGroup.Prepend className="search-prepend">
            <InputGroup.Text>
              <FontAwesomeIcon onClick={() => {}} size="1x" className={classes.SearchIcon} icon={faSearch} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl 
            placeholder={bp}
            value={searchValue}
            onChange={inputChanged}
            onKeyDown={handleKeyDown}
            style={{fontSize: '1.2em'}}
            disabled={search.searched}
          />
          {searchValue.length === 0 ? null :
          <InputGroup.Append>
            {renderSearchBoxControl()}
          </InputGroup.Append>}
        </InputGroup>
      </Col>
    </Row>
  )
};

export default SearchBox;
