import { updateObject } from '../../utils/utils';

import { REINIT_ALL_STATE } from '../sitewideReduxConstants';

import {
  BEGIN_NEWS_FETCH,
  END_NEWS_FETCH,
  SET_NEWS_ERROR,
  CLEAR_NEWS
} from '../actions/newsActions';

const INITIAL_STATE = {
  posts: [],
  site_messages: [],
  error: '',
  working: false,
  fetched: false
};

const reducer = (state = INITIAL_STATE, action) => {
  // console.log(`[postReducers]: ${action.type}`, action.payload);
  switch (action.type) {
    case REINIT_ALL_STATE:
      return INITIAL_STATE;

    case BEGIN_NEWS_FETCH:
      return updateObject(state, { working: true, posts: [], site_messages: [] } );

    case END_NEWS_FETCH:
      return updateObject(state, { working: false, fetched: true, posts: action.payload.posts, site_messages: action.payload.site_messages } );
  
    case SET_NEWS_ERROR:
      return updateObject(state, { working: false, posts: [], site_messages: [], error: action.payload});

    case CLEAR_NEWS:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default reducer;
