import axios from '../../utils/axios-whe';

export const GET_SITE_MESSAGES_START = 'GET_SITE_MESSAGES_START';
export const GET_SITE_MESSAGES_SUCCESS = 'GET_SITE_MESSAGES_SUCCESS';
export const GET_SITE_MESSAGES_FAILED = 'GET_SITE_MESSAGES_FAILED';
export const SET_CURRENT_SITE_MESSAGE = 'SET_CURRENT_SITE_MESSAGE';
export const INIT_SITE_MESSAGE_STATE = 'INIT_SITE_MESSAGE_STATE';
export const DELETE_SITE_MESSAGE_START = 'DELETE_SITE_MESSAGE_START';
export const DELETE_SITE_MESSAGE_SUCCESS = 'DELETE_SITE_MESSAGE_SUCCESS';
export const DELETE_SITE_MESSAGE_FAILED = 'DELETE_SITE_MESSAGE_FAILED';

export const CREATE_SITE_MESSAGE_START = 'create_site_message_start';
export const CREATE_SITE_MESSAGE_SUCCESS = 'create_site_message_success';
export const CREATE_SITE_MESSAGE_FAILED = 'create_site_message_failed';
export const UPDATE_SITE_MESSAGE_START = 'update_site_message_start';
export const UPDATE_SITE_MESSAGE_SUCCESS = 'update_site_message_success';
export const UPDATE_SITE_MESSAGE_FAILED = 'update_site_message_failed';

export const CLEAR_OP_STATUS = "clear_op_status";

const getSiteMessages = ( messageId, url, dispatch, getState ) => {
  console.log(`siteMessageActions: getSiteMessages`)
  const auth = getState().auth;
  dispatch({type: GET_SITE_MESSAGES_START});
  axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${auth.token}`,
      'X-CSRF-Token': auth.csrf_token
    }
  })
  .then(response => {
    console.log(response)
    // debugger;
    // Find message to set as current.
    let message = response.data.site_messages[0];
    if (messageId !== -1) {
      const msgIx = response.data.site_messages.findIndex(message => (message.id === messageId))
      if (msgIx >= 0) {
        message = response.data.site_messages[msgIx];
      }
    }
    dispatch({type: GET_SITE_MESSAGES_SUCCESS, 
              payload: {messages: response.data.site_messages,
              currentMessage: message}
              });
  })
  .catch(error => {
    console.log("ERROR: " + error);
    dispatch({type: GET_SITE_MESSAGES_FAILED, payload: {error: error.message}})
  });
}

export const getMySiteMessages = (messageId) => {
  console.log(`siteMessageActions: getMySiteMessages`)
  return (dispatch, getState) => {
    getSiteMessages(messageId, "/api_v1/site_messages_controller/my_site_messages.json", dispatch, getState);
  }
}

export const getPostedSiteMessages = (messageId) => {
  console.log(`siteMessageActions: getMySiteMessages`)
  return (dispatch, getState) => {
    getSiteMessages(messageId, "/api_v1/site_messages_controller/sent_site_messages.json", dispatch, getState);
  }
}

export const deleteSiteMessage = (siteMessage) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    dispatch({ type: DELETE_SITE_MESSAGE_START });
    axios.delete(`/api_v1/site_messages/${siteMessage.id}`, {
      // method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      setTimeout(() => {
        dispatch({ type: DELETE_SITE_MESSAGE_SUCCESS, payload: {message: siteMessage} });
      }, 500)
    })
    .catch(error => {
      console.error(`ERROR logging out of ${error}`);
      dispatch({ type: DELETE_SITE_MESSAGE_FAILED, payload: { error: error.message } });
    });
  }
}
// export const updateSiteMessage = (siteMessage) => {
//   const formJson = {
//     "site_message": {...siteMessage }
//   };
//   // debugger;
//   const url = `/api_v1/site_messages/${siteMessage.id}`;
//   const controllerMethod = 'patch';

//   console.log(`[EditSiteMessage]: Patching POST with URL of ${url}  FORM JSON:`, formJson)
//   // updating = true;
//   this.waitingForPost = true;
//   // debugger;
//   const tid = this.startTimer();
//   this.setState({timeoutId: tid});
//   axios.patch(url, formJson,
//   {
//     _method: controllerMethod,
//     headers: {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//       'Authorization': `Token ${this.props.auth.token}`,
//       'X-CSRF-Token': this.props.auth.csrf_token
//     }
//   })
//   .then(response => {
//     console.log(`Post create or update`, response.data);
//     // updating = false;
//     this.waitingForPost = false;
//     this.props.history.push('/mysitemessages');
//   })
//   .catch(error => {
//     this.waitingForPost = false;
//     clearTimeout(this.state.timeoutId);
//     this.setState({timeoutId: null});
//     alert(`ERROR updating SiteMessage info.  ${error}`)
//   })
// };

export const setCurrentMessage = (siteMessage) => {
  return (dispatch, getState) => {
    dispatch({type: SET_CURRENT_SITE_MESSAGE, payload: {message: siteMessage}});
  }
}

export const initSiteMessageState = () => {
  return (dispatch) => {
    dispatch({type: INIT_SITE_MESSAGE_STATE});
  }
}

export const saveSiteMessage = (values) => {
  console.log('[saveSiteMessage] *********')
  const formJson = {
    "site_message": {...values }
  };
  // debugger;
  const url = `/api_v1/site_messages.json`;
  const controllerMethod = 'POST';

  console.log(`[saveSiteMessage]: Patching POST with URL of ${url}  FORM JSON:`, formJson)

  return (dispatch, getState) => {
    const auth = getState().auth;
    dispatch({type: CREATE_SITE_MESSAGE_START});
    axios.post(url, formJson, {
      _method: controllerMethod,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`Post create or update`, response.data);
      dispatch({type: CREATE_SITE_MESSAGE_SUCCESS, payload: {site_message: response.data.site_message}})
    })
    .catch(error => {
      dispatch({type: CREATE_SITE_MESSAGE_FAILED, payload: {error: error}})
      // alert(`ERROR updating POST info.  ${error}`)
    })
  }
}

export const updateSiteMessage = (values) => {
  return (dispatch, getState) => {
    const auth = getState().auth;

    const url = `/api_v1/site_messages/${values.id}`
    const formJson = {
      "site_message": {...values }
    };
    dispatch({ type: UPDATE_SITE_MESSAGE_START });
    axios.patch(url, formJson,
    {
      _method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      console.log(`siteMessagesActions: updateSiteMessage: updated`, response.data);
      dispatch( {type: UPDATE_SITE_MESSAGE_SUCCESS, payload: {site_message: response.data.site_message}} )
    })
    .catch(error => {
      // alert(`ERROR updating address info.  ${error}`)
      dispatch( {type: UPDATE_SITE_MESSAGE_FAILED, payload: {error: error}});
    })
  }
}

export const clearOpStatus = () => {
  return (dispatch) => {
    dispatch({type: CLEAR_OP_STATUS})
  }
}
