import React, {Component} from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import { setMap, setSelectedAddr } from '../../redux/actions/mapActions';

import { capitalizeString } from '../../utils/utils';

import classes from './NeighborhoodMap.module.css';

const myGoogleApiKey = "AIzaSyAv7i3LzED6tY7l5RDOZO799GZeNxii3fU";

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 10
}
const center = {
  address: 'Center of WHE',
  name: "Wild Harbour Estates",
  lat: 41.63572736965776,
  lng: -70.63787626591079,
}

class NeighborhoodMap extends Component {
  constructor() {
    super();
    this._map = undefined;
    this.duplicateAddrs = {}
    this.uniqueAddrs = {}
  }

  clearSelectedAddress = () => {
    this.props.setSelectedAddr(null);
  }
  onClick = ((e) => {
    this.clearSelectedAddress();
  })

  componentWillUnmount() {
    this.props.setSelectedAddr(null);
  }

  userName = (user) => {
    let name = '';
    let sep = '';
    if (user.fname) {
      name = capitalizeString(user.fname);
      sep = " ";
    }
    if (user.lname) {
      name += sep + capitalizeString(user.lname);
    }
    return name;
  }
  renderPropertyUsers = (al) => {
    const propertyUsers = al.users.map(u => {
      return (
        <Row key={`usa-${u.user_id}`}>
          <Col>{this.userName(u)}</Col>
        </Row>
      )
    })
    if (propertyUsers.length === 0) {
      return (
        <Row key={`usa-${123456}`}>
          <Col>(none)</Col>
        </Row>
      )
    }
    return propertyUsers;
  }

  buildOwnerName = (al) => {
    let firstName = al.first_name1;
    if (al.first_name2 && al.first_name2.length > 0) {
      firstName = `${firstName} / ${al.first_name2}`;
    }
    let fullName = `${al.last_name}, ${firstName}`;

    // debugger
    if (al.property_contacts.length > 0) {
      let newFullName = '';
      let sep = '';
      al.property_contacts.forEach(pc => {
        if (pc.is_owner) {
          newFullName += sep + capitalizeString(pc.first_name) + ' ' + capitalizeString(pc.last_name);
          sep = ", ";
        }
      })
      fullName = newFullName;
    }
    return fullName;
  }
  buildListView = (al) => {
    let fullName = this.buildOwnerName(al);

    return (
      <Container className={classes.MarkerInfoContainer}>
        <Row className={classes.MarkerAddressHeaderInfo}>
          <Col md={12}>{al.wh_house_num} {al.wh_street}</Col>
        </Row>
        <Row className={classes.MarkerAddressInfo}>
          <Col xs={4} md={4} className={classes.MapMarkerInfoDesc}>Lot Number</Col><Col xs={8} md={8}>{al.lot_num}</Col>
        </Row>
        <Row className={classes.MarkerAddressInfo}>
          <Col xs={4} md={4} className={classes.MapMarkerInfoDesc}>Owner(s)</Col><Col xs={8} md={8}>{fullName}</Col>
        </Row>
        <Row className={classes.MarkerAddressInfo}>
          <Col xs={4} md={4} className={classes.MapMarkerInfoDesc}>Pri Address</Col><Col xs={8} md={8}>{al.pri_street_addr}, {al.pri_town} {al.pri_state}</Col>
        </Row>
        <Row className={classes.MarkerAddressInfo}>
          <Col xs={4} md={4} className={classes.MapMarkerInfoDesc}>Phone</Col><Col xs={8} md={8}>{al.phone}</Col>
        </Row>
        <Row style={{marginBottom: '4px'}}>
          <Col xs={4} md={4} className={classes.MapMarkerInfoDesc}>
            <FontAwesomeIcon icon={faUsers} style={{marginRight: '4px'}} />
            People
          </Col>
          <Col xs={8} md={8}>
            {this.renderPropertyUsers(al)}
          </Col>
        </Row>
      </Container>
    );
  };

  markerClicked = ((e, location, ref) => {
    this.props.setSelectedAddr(location.addr);
    if (this.props.mmClicked) {
      this.props.mmClicked(location.addr)
    }
  })

  markerClustererClicked = (e) => {
    // debugger;
  }
  render() {
    console.log("NeighborhoodMap: render")
    // debugger
    return (
      <div>
        <LoadScript
          googleMapsApiKey={myGoogleApiKey}
        >
          <GoogleMap
            mapContainerClassName={classes.MapContainer}
            center={center}
            zoom={16}
            onClick={this.onClick}
            mapTypeId="hybrid"

            onLoad={map => {
              this._map = map;
              this.props.setMap(map);
              // this.geocodeAddress(this.props.addrs[0].wh_street + "%20" + this.props.addrs[0].wh_house_num + "%2002556");
              // debugger;
              // const bounds = new window.google.maps.LatLngBounds();
              // map.fitBounds(bounds);
            }}
          >
            <MarkerClusterer
              onClick={this.markerClustererClicked}
            >
              {(clusterer) =>
                this.props.addrs.map((addr) => {
                  let loc = {
                    id: addr.id,
                    name: addr.full_name,
                    lot_num: addr.lot_num,
                    address: addr.wh_street_address,
                    lat: addr.latitude, lng: addr.longitude,
                    addr: addr
                  };

                  let lotKey = `${loc.lot_num}-${loc.address}`;
                  if (this.uniqueAddrs[lotKey] !== undefined) {  
                    if (this.duplicateAddrs[lotKey] !== undefined) {
                      this.duplicateAddrs[lotKey] = this.duplicateAddrs[lotKey] + `; ${addr.lot_num}`;
                    } else {
                      this.duplicateAddrs[lotKey] = `${addr.lot_num}`
                    }
                  } else {
                    if (addr.lot_num)
                    this.uniqueAddrs[lotKey] = `${addr.lot_num}`;
                  }
                  if (loc.lat !== 0.0 && loc.lng !== 0.0) {
                    const ourRef = React.createRef();
                    return (
                      //anchorPoint: _.O {x: -0.5, y: -43, i: true}
                      //viewIcon.anchor: x: 14, y:43
                      //labelOrigin: x:14,y:15
                      //width: 27
                      //height: 43
                      <Marker
                        key={addr.id}
                        ref={ourRef}
                        icon={{
                          // url: "//maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless2_hdpi.png",
                          url: (this.props.selectedAddrId === loc.id ? 'http://maps.google.com/mapfiles/ms/icons/orange.png': 'http://maps.google.com/mapfiles/ms/icons/red.png'),
                  
                          // anchor: new window.google.maps.Point(17, 50),
                          anchor: {x: 24, y: 43},
                          scaledSize: new window.google.maps.Size(50, 50),
                          labelOrigin: new window.google.maps.Point(25, 18)
                        }}                        
                        // icon={"http://maps.google.com/mapfiles/ms/icons/orange.png"}
                        // icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
                        label={{
                          text: addr.lot_num,
                          fontSize: '1.1em',
                          fontWeight: 'bold'
                        }}
                        position={loc}
                        clusterer={clusterer}
                        onClick={(e) => {this.markerClicked(e, loc, ourRef)}}
                      />
                    )
                  }
                })
              }
            </MarkerClusterer>
            {this.props.map.selectedAddr && (
              <InfoWindow
                position={this.props.map.selectedAddr}
                onCloseClick={() => {this.clearSelectedAddress()}}
                divStyle={{overflow: 'hidden'}}
              >
                {this.buildListView(this.props.map.selectedAddr)}
              </InfoWindow>
        
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    map: state.map
  }
}

const mapDispatchToProps= (dispatch) => {
  return {
    setMap: (map) => {dispatch(setMap(map))},
    setSelectedAddr: (addr) => {dispatch(setSelectedAddr(addr))}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NeighborhoodMap);
