import React, {useState, useEffect, useRef} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Image from 'react-bootstrap/Image';

import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Moment from 'react-moment';

import CalendarEventDetailView from './CalendarEventDetailView';
import CalendarEventControls from './CalendarEventControls';

import { getCalendarEvents, getEventMetadata } from '../../redux/actions/calendarEventActions';
import { setTitle } from '../../redux/actions/controlsActions';

import classes from './CalendarEvent/CalendarEvent.module.css';

import SpinnerV2 from '../Spinner/Spinner';
import moment from 'moment';

const CalendarEventList = (props) => {
  let { eventId } = useParams();
  const dispatch = useDispatch();
  const calendarEvents = useSelector(state => state.calendarEvents);
  const auth = useSelector(state => state.auth);
  const [eventToShow, setEventToShow] = useState(null);
  const [activityTypeFilter, setActivityTypeFilter] = useState(null);
  const [initialMonthsBack, setInitialMonthsBack] = useState(0);
  const initialEvent = useRef(null);
  const listContainer = useRef(null)
  const eventIdRef = useRef(eventId);

  useEffect(() => {
    // console.log(`useEffect:  there are ${calendarEvents.calendarEvents.length} events...`)
    if (calendarEvents.calendarEvents.length > 0 && eventIdRef.current !== null) {
      const evToEdit = findEvent(Number(eventId))
      if (evToEdit !== null) {
        // console.log(`useEffect: setting event for modal view...`)
        setEventToShow(evToEdit);
        eventIdRef.current = null;
      }
    } else {
      // setEventToShow(null);
    }
  }, [calendarEvents.calendarEvents])
  useEffect(() => {
    dispatch(setTitle("Calendar Event List"))
    dispatch(getEventMetadata())
    dispatch(getCalendarEvents(new Date(), initialMonthsBack));
    setEventToShow(null);
  }, [calendarEvents.updatedEvent])

  const findEvent = (eventId) => {
    let event = null;
    if (calendarEvents.calendarEvents.length === 0) {
      return null;
    }
    if (calendarEvents && calendarEvents.calendarEvents.length > 0) {
      const ix = calendarEvents.calendarEvents.findIndex(e => {
        if (e.id === eventId) {
          return true;
        } else {
          return false;
        }
      });
      if (ix >= 0) {
        event = calendarEvents.calendarEvents[ix];
        let duration = 60;
        if (event.end_date != null) {
          let begin = moment(event.start);
          let end = moment(event.end)
          duration = end.diff(begin);
          duration = duration / 1000 / 60
          // debugger
        }
        event = {...calendarEvents.calendarEvents[ix], event_duration: duration}
      }
    }
    return event;
  }

  const renderActivity = (event) => {
    // debugger;
    if (calendarEvents.eventMetadata === null) {
      return null;
    }
    
    const activities = calendarEvents.eventMetadata.activities;
    const activityIx = activities.findIndex(activity => {
      if (activity.id === event.activity_id) {
        return true;
      } else {
        return false;
      }
    })
    if (activityIx >= 0) {
      return (
        <div className={classes.ActivityIcon}>
          <Image src={activities[activityIx].thumbnail} height="64" width="64" thumbnail />
        </div>
      );
    }
    return null;
  }

  const renderEventDate = (event) => {
    return (
      <Row>
        <Col xs={12} sm={12} className={classes.EventListDetailDate} _style={{border: '1px solid black'}}>
          <Row><Col xs={12}><Moment format="ddd MMM DD YYYY">{event.start}</Moment></Col></Row>
          <Row><Col xs={12}><div style={{textAlign: 'center', width: '100%'}}><Moment format="hh:mm a">{event.start}</Moment></div></Col></Row>
        </Col>
      </Row>
    )
  }

  const eventTypeSelected = (activityType) => {
    // alert(`eventTypeSelected: ${activityType.name}`)
    setActivityTypeFilter(activityType)
  }

  const calculateNumAttendees = (event) => {
    let totalAttendees = 0;
    for (let ix = 0; ix < event.attendees.length; ix += 2) {
      totalAttendees += event.attendees[ix].num_guests + 1;
      if (ix+1 < event.attendees.length) {
        totalAttendees += event.attendees[ix+1].num_guests + 1;
      }
    }
    return totalAttendees;
  }

  const renderAttendingInfo = (event) => {
    const myAttendingIx = event.attendees.findIndex((attendee) => {
      if (attendee.attendee_id === auth.uid) {
        return true;
      } else {
        return false;
      }
    });
    
    const totalAttendees = calculateNumAttendees(event);
    if (totalAttendees === 0) {
      return <span><b>Total Attending: 0</b></span>
    }

    let numGuestsPlusMe = "";
    if (myAttendingIx !== -1) {
      const guestCount = event.attendees[myAttendingIx].num_guests;
      if (guestCount > 0) {
        numGuestsPlusMe = ` + ${guestCount} ${guestCount === 1 ? "guest":"guests"}`;
      }
    }
    return (
      <span>
        Total Attending: {totalAttendees}
          {(myAttendingIx >= 0 ? ", including me" : null) }{numGuestsPlusMe}  
      </span>
    )
  }

  const humanReadableDuration = (event) => {
    // XXX: Tried using moment's humanize method but I'm a version below where it was added so used this
    let durationInMin = event.end - event.start;
    durationInMin = durationInMin / 1000 / 60;

    const duration = moment.duration(durationInMin, "minutes")

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes() % 60;

    const hoursString = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
    const minutesString = minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';

    const parts = [hoursString, minutesString].filter(part => part.length > 0);
    return parts.join(' and ');
  }

  const renderEvents = () => {
    if (calendarEvents.calendarEvents.length === 0) {
      return (
        <Row style={{marginTop: '100px'}}>
          <Col style={{color: 'red', fontSize: '1.3em', textAlign: 'center'}}>No events found for selected date range</Col>
        </Row>
      )
    }
    const calEvents = calendarEvents.calendarEvents.map(ce => {
      // debugger
      //TODO: Add layout styling to get Date into a square and the activity icon into an adjacent square

      if (activityTypeFilter === null || (activityTypeFilter && activityTypeFilter.id === ce.activity_id)) {
        return (
          <Row 
            key={`${ce.id}-${ce.start}`}
            className={classes.EventListRow}
            onClick={() => {setEventToShow(ce)}}
            ref={(ref) => {
              if (moment(new Date()).isBefore(ce.begin_date) && initialEvent.current === null) {
                // debugger
                initialEvent.current = ref;
              }
            }}
          >
            <Col xs={3} sm={1} className={classes.EventListActivityIcon}>{renderActivity(ce)}</Col>
            <Col xs={4} sm={2} className={classes.EventListDetailDate}>
              {renderEventDate(ce)}
            </Col>
            <Col xs={5} sm={6} className={classes.EventListDetailTitle}>
              <Row><Col>{ce.title}</Col></Row>
              <Row><Col style={{fontSize: '0.8em'}}>{renderAttendingInfo(ce)}</Col></Row>
              <Row><Col style={{fontSize: '0.8em'}}>Duration: {humanReadableDuration(ce)}</Col></Row>
            </Col>
          </Row>
        )
      } else {
        return null;
      }
    })
    return (                 
      <div ref={(ref) => {listContainer.current = ref}} className={classes.EventListContainer}>
        {calEvents}
      </div>
    )
  }

  const showEventDetail = () => {
    if (eventToShow === null) {
      return null;
    }

    // dispatch(setCurrentEvent(eventToShow))
    return <CalendarEventDetailView event={eventToShow} onClose={() => setEventToShow(null)} />
    // return <CalendarEventDetailView />
  }

  const refetchEvents = (monthsBack) => {
    // debugger
    setInitialMonthsBack(monthsBack)
    setActivityTypeFilter(null);
    dispatch(getCalendarEvents(new Date(), Number(monthsBack)));
  }
  if (calendarEvents.fetchingEvents || calendarEvents.updatingEvents || 
      ((calendarEvents.eventMetadata === null || 
      calendarEvents.calendarEvents.length === 0) && calendarEvents.fetchingEvents)) {
    return <SpinnerV2 title="Fetching event list..." />
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {/* <Row style={{
        position: 'fixed', _left: '0', _right: '0', height: '50px', width: '100%', zIndex: '1000', backgroundColor: 'white'}}>
        <Col xs={12} sm={12}>
          <CalendarEventControls
            onSelect={eventTypeSelected}
            _rowStyle={{position: 'fixed', _left: '0', _right: '0', height: '50px', _width: '100%', zIndex: '1000', backgroundColor: 'white'}}
          />
        </Col>
      </Row> */}
      <CalendarEventControls
        onSelect={eventTypeSelected}
        onRenderBack={(monthsBack) => refetchEvents(monthsBack)}
        calendarViewNext={'calendar_view'}
        beginMonthBias={initialMonthsBack}
        rowStyle={{position: 'fixed', paddingTop: '4px', left: '0px', right: '0px', height: '50px', width: 'inherit', zIndex: '1000', backgroundColor: 'white'}}
      />

      {renderEvents()}
      {showEventDetail()}
    </div>
  );
}

export default CalendarEventList;