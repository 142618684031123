import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/**
 * Show a Yes / No modal
 * 
 * Props
 * show - true === show the modal
 * yesCallback - the callback method to be invoked upon "Yes" selection.
 * callbackArg - the argument that will be passed back to the caller when the callback is invoked
 * noCallback - the callback method to be invoked upon "No" selection or onHide
 * 
 * (Optional)
 * yesLabel - the label specified on the YES button
 * noLabel - the label specified on the NO button
 * 
 * @param {*} props 
 * @returns 
 */
const YesNoModal = (props) => {
  const buildYesLabel = () => {
    if (props.yesLabel) {
      return props.yesLabel;
    }
    return "YES";
  }

  const buildNoLabel = () => {
    if (props.noLabel) {
      return props.noLabel;
    }
    return "NO";
  }

  const cancelModal = () => {
    if (props.cancel) {
      props.cancel()
    }
  }
  
  const renderAreYouSureModal = () => {
    return (
      <Modal
        show={props.show}
        onHide={() => {cancelModal()}}
      >
        <Modal.Body>
          <div>
            <Row>
              <Col>
                {props.children}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button size="sm"
                  onClick={() => {props.yesCallback(props.callbackArg)}}>{buildYesLabel()}
                </Button>&nbsp;&nbsp;
                <Button size="sm" onClick={() => {props.noCallback()}}>{buildNoLabel()}</Button>
              </Col>
            </Row>
          </div>
          
        </Modal.Body>
      </Modal>
    )
  }

  if (props.show) {
    return renderAreYouSureModal();
  }
  return null;

};

export default YesNoModal;