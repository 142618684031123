import React, {useState, useMemo, useEffect} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { closeDrawer } from '../../redux/actions/controlsActions';
import { formattedDate } from '../../utils/utils';
import SiteMessage from '../SiteMessages/SiteMessage/SiteMessage';
import SideDrawerRightMobile from '../Navigation/SideDrawer/SideDrawerRightMobile';
import Backdrop from '../UI/Backdrop/Backdrop';

import SearchBox from './SearchBox';

import classes from './Search.module.css';


const siteMessageListRefs = new Map();

const SiteMessageSearchResultsContent = (props) => {
  const [selectedSiteMessage, setSelectedSiteMessage] = useState(null);
  const search = useSelector(state => state.search);
  const controls = useSelector(state => state.controls);
  const dispatch = useDispatch();

  const memoSelSiteMessage = useMemo( () => {
    return selectedSiteMessage;
  }, [selectedSiteMessage]);

  useEffect(() => {
    if (memoSelSiteMessage === null && search.site_messages.length > 0) {
      // debugger;
      setSelectedSiteMessage(search.site_messages[0])
    }
  }, [memoSelSiteMessage, search.site_messages])

  const closeNavDrawer = () => {
    dispatch(closeDrawer())
  }

  const updateSelectedSiteMessage = (siteMessage) => {
    setSelectedSiteMessage(siteMessage);
    closeNavDrawer();
  }

  const renderSiteMessage = (siteMessage) => {
    if (siteMessage === null) {
      return <p style={{textAlign: 'center'}}>{search.searched ? `[No search results for ${search.lastSearch}]`:null}</p>;
    }
    return (
      <React.Fragment>
        <SiteMessage
          key={`SM${siteMessage.id}`}
          siteMessage={siteMessage}
          okToEdit={false}
        />
      </React.Fragment>
    );
  }

  const isActive = (siteMessageId) => {
    return (siteMessageId === selectedSiteMessage?.id ? classes.TocEntryActive : '');
  }

  const renderResultsList = () => {
    let resultList = search.site_messages.map((siteMessage) => {
      return (
        <div
          key={siteMessage.id}
          ref={(element) => {siteMessageListRefs.set(siteMessage.id, element)}}
          className={[classes.TocEntry, isActive(siteMessage.id)].join(' ')} 
          onClick={() => {updateSelectedSiteMessage(siteMessage)}}>
            <p>{siteMessage.name}</p>
            <p className={classes.TocEntryMeta}>{formattedDate(siteMessage.post_date)}</p>
        </div>
      );
    })
    if (resultList.length === 0) {
      return null;
    }
    return (
      <div className={classes.Toc} style={{backgroundColor: 'white'}}>
        <div>
          <p className={classes.SearchListTitle}>Site Message Search Results</p>
        </div>
        {resultList}
      </div>
    );
  }

  let backdrop;
  if (controls.drawerOpen) {
    backdrop = <Backdrop show={controls.drawerOpen} click={closeNavDrawer} />;
  }

  // Render the content
  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          <SideDrawerRightMobile
            contentPanel={renderResultsList()}
            open={controls.drawerOpen}
            closed={closeNavDrawer}
          />
          {backdrop}
          <Backdrop />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
        <Row>
            <Col sm={12} md={12}>
              <SearchBox placeholder="Search Site Messages..." searchProc={() => {}} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} className={classes.SearchContainer}>
              {renderSiteMessage(selectedSiteMessage)}
            </Col>
          </Row>
        </Col>
        <Col sm={4} className={[classes.SearchContainer, classes.TocDesktop].join(' ')}>
          {renderResultsList()}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SiteMessageSearchResultsContent;