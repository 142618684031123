import React, {Component} from 'react';
import { connect } from 'react-redux';

import { Calendar, momentLocalizer, Views  } from 'react-big-calendar' 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { setTitle } from '../../redux/actions/controlsActions';
import { reserveAsset, getEventMetadata, getCalendarEvents, clearError, resetUpdated, cancelEvent } from '../../redux/actions/calendarEventActions';

import { formattedDate } from '../../utils/utils';

import Help from '../Help/Help';

import Spinner from '../Spinner/Spinner';

import './Forms/AssetForm.css';

const localizer = momentLocalizer(moment)

let _duration = 60;
let _activityId = 0; // TODO: Set to an actual default id.
let eventToCancel = null;

let EARLIEST_HOUR = 8;
let LATEST_HOUR = 20;

const tennisTypeKeys = [
  {"15 min": 15},
  {"30 min": 30},
  {"60 min (Singles)": 60},
  {"90 min (Doubles)": 90},
  {"2 hours (Round Robin)": 120},
  {"3 hours": 180},
  {"4 hours": 240}
]

const reservationDurations = [
  {"30 min": 30},
  {"60 min": 60},
  {"90 min": 90},
  {"2 hours": 120},
  {"3 hours": 180},
  {"4 hours": 240}
]

const assetSelectorHelp = [
  {
    "name": "Choose asset type",
    "desc": "Select the type of asset you'd like to reserve. A Tennis Court, the Picnic Area, or the Bocce Pit."
  }
];

const assetCalendarOverview = [
  {
    "paragraph": `The Asset Calendar is used for reserving a neighborhood asset for your use.  The Tennis Courts, 
    Bocce Court, and the Picnic Area are currently available to all residents.  If you want to reserve an area for a
    block of time, select the asset type, choose a date / time by clicking in the view for that day, and select the
    amount of time you'd like to reserve it for.  The Bocce Court and Picnic area are schedulable for blocks of time
    from 30 minutes to multiple hours.
    `
  },
  {
    "paragraph": "The tennis courts can be scheduled from 15 minutes to multiple hours. 3 of the durations are also labeled as singles, doubles, and round robin."
  },
  {
    "paragraph": `You may only schedule a single asset type at any given time.  For example, you could schedule a tennis court
    at 9am on Monday but could not schedule the second court at the same time or any overlapping time.
    `
  },
  {
    "paragraph": `You can only view one asset type at a time.  The selector lets you choose which type to view.  You must
    select the asset type you want to schedule in order to reserve the correct type.`
  },
  {
    "paragraph": `The calendar is used both for reserving an asset as well as seeing who has reserved an asset at a given
    date/time.  Entries in the time slots in orange represent reserved slots.  If you click on one of the orange events you'll
    see who's reserved it as well as the time / duration.  If you want to reserve a time slot, click in the white area of a
    time slot and you'll be presented with a popup that will let you do that.
    `
  },
  {
    "note": `If your view is showing an entire Month and you click on the white area within a given day, the calendar will
    change to Week view.  This lets you select a specific time you want to get a reservation.
    `
  }
]
let defaultAssetTypeName = "Tennis Court";
class AssetCalendarComponent extends Component {

  constructor(props) {
      super(props)
      this.state = {curStartDate: new Date(), currentCalendaView: Views.MONTH, assetTypeId: -1, cancelingEvent: false, currentEvent: null, curEventList: [], resources: [], modalScheduleEvent: null}
  }

  getAssetTypeFromName = (assetTypeName) => {
    let assetType = null;
    if (this.props.calendarEvents.eventMetadata) {
      this.props.calendarEvents.eventMetadata.asset_types.forEach((at) => {
        if (at.name === assetTypeName) {
          assetType = at;
        }
      })
    }
    return assetType;
  }
  getAssetTypeFromId = (assetTypeId) => {
    let assetType = null;
    if (this.props.calendarEvents.eventMetadata) {
      this.props.calendarEvents.eventMetadata.asset_types.forEach((at) => {
        if (at.id === assetTypeId) {
          assetType = at;
        }
      })
    }
    return assetType;
  }
  setupCalEventList = (assetTypeId) => {
    // Get a list of the events of the specified type.
    if (this.props.calendarEvents.calendarEvents.length === 0) {
      return [];
    }

    const calEvents = this.props.calendarEvents.calendarEvents.filter((ce) => {
      if (ce.asset_type_id === assetTypeId) {
        return true;
      } else {
        return false;
      }
    })
    // this.setState( {curEventList: calEvents} );
    return calEvents;
  }

  setupResources = (assetTypeId) => {
    let assets = [];
    this.props.calendarEvents.eventMetadata.assets.forEach((asset) => {
      if (asset.asset_type_id === parseInt(assetTypeId)) {
        assets.push({ resourceId: asset.id, resourceTitle: asset.name })
      }
    })
    const assetType = this.getAssetTypeFromId(parseInt(assetTypeId))
    EARLIEST_HOUR = assetType.begin_hour;
    LATEST_HOUR = assetType.end_hour;
    // TODO: Go back to assetType after debug
    this.setState({resources: assets, assetTypeId: assetType.id});
  }

  setupDefaults = () => {
    console.log('setupDefaults')
    let tcId = -1;
    this.props.calendarEvents.eventMetadata.asset_types.forEach((at) => {
      if (at.name === defaultAssetTypeName) {
        tcId = at.id;
      }
    })
    if (tcId !== -1) {
      // debugger
      this.setupResources(tcId);
      // this.setupCalEventList(tcId);
    }
    return tcId;
  }

  setupAssets = (assetTypeId) => {
    let assets = [];
    // set the resources to represent only THIS type of asset.
    // based on the asset_type, get the list of matching scheduled
    // asset events.
    this.props.calendarEvents.eventMetadata.assets.forEach((asset) => {
      if (asset.asset_type_id === parseInt(assetTypeId)) {
        assets.push({ resourceId: asset.id, resourceTitle: asset.name })
      }
    })
    const curEventList = this.setupCalEventList(parseInt(assetTypeId));
    let at = this.getAssetTypeFromId(parseInt(assetTypeId));
    EARLIEST_HOUR = at.begin_hour;
    LATEST_HOUR = at.end_hour;
    this.setState({resources: assets, assetTypeId: parseInt(assetTypeId), curEventList: curEventList});

  }
  selectAssetType = (event) => {
    // TODO: get the list of events for the selected asset type.
    // alert(event.target.value)

    const assetTypeId = event.target.value;
    this.setupAssets(assetTypeId);
    return;
  }

  componentDidMount() {
    this.props.getEventMetadata();
    // Start off by trying to get events for current date.
    this.props.getCalendarEvents(new Date());
    this.props.setTitle("Asset Calendar");
  }

  componentDidUpdate(prevProps, prevState) {
    // debugger
    let stateUpdate = {};

    if (eventToCancel && this.props.calendarEvents.updatedEvent && this.state.cancelingEvent) {
      console.log('componentDidUpdate: clearing curEventList cuz event canceled')
      // Clear curEventList so it forces a reload of curEventList upon fetch events completion
      stateUpdate['cancelingEvent'] = false;
      stateUpdate['curEventList'] = [];
      // this.setState({cancelingEvent: false, curEventList: []});
      eventToCancel = null;
      // XXX: set updatingEvents to false in SET_FETCHING_EVENTS negating resetUpdated.
      // this.props.resetUpdated();
      // this.props.history.push('/calendar');
      console.log('componentDidUpdate: fetching new event list cuz event canceled')
      this.props.getCalendarEvents(new Date());
    }

    if (prevProps.calendarEvents.eventMetadata === null && this.props.calendarEvents.eventMetadata) {
      /*||
        this.state.resources.length === 0 && this.props.calendarEvents.eventMetadata) {*/
        // debugger
        this.setupDefaults();
    }
    if (this.props.calendarEvents.calendarEvents.length !== prevProps.calendarEvents.calendarEvents.length) {
      /*||
        this.state.curEventList.length === 0) {*/
      // TODO: Account for a delta to handle newly scheduled events as well as canceled...
      // if (this.state.assetType !== null) {
      // debugger
      const eventList = this.setupCalEventList(this.state.assetTypeId);
      stateUpdate['curEventList'] = eventList;
    }

    // Do the state update if needed
    if (Object.keys(stateUpdate).length > 0) {
      this.setState(stateUpdate);
    } else {
      if (this.props.calendarEvents.eventMetadata) {
        // debugger
        // this.setupAssets(this.state.assetTypeId);
      }
    }
  }

  cancelEvent = (calEvent) => {
    eventToCancel = calEvent;
    // const sendEmailNotifications = window.confirm(`Do you want to send email notifications about the cancellation or just silently cancel this event?`);
    // this.props.cancelEvent(calEvent, sendEmailNotifications);
    this.props.cancelEvent(calEvent, false);
    this.setState({cancelingEvent: true});
  }

  renderCancelEventButton = (calEvent) => {
    if ((this.props.auth.site_admin || this.props.auth.uid === calEvent.creator_id) &&
        moment(new Date()).isBefore(calEvent.begin_date)) {
      return (
        <React.Fragment>
          <Button onClick={() => { this.setState({currentEvent: null}); this.cancelEvent(calEvent) }}>Cancel Event</Button>
        </React.Fragment>
      );
    }
    return null;
  }

  renderBody = () => {
    // debugger
    return (
      <div>
        {/* <div>All Day: {(this.state.currentEvent.allDay ? "ALL DAY": "NOPE")}</div> */}
        <div>Event Type: {this.state.currentEvent.title}</div>
        <div>Location: {this.assetFromId(this.state.currentEvent.schedulable_asset_id).name}</div>
        <div>Start: {formattedDate(this.state.currentEvent.start)}</div>
        <div>End: {formattedDate(this.state.currentEvent.end)}</div>
        <div>Scheduled By: {this.state.currentEvent.scheduler}</div>
        {this.renderCancelEventButton(this.state.currentEvent)}
      </div>
    );
  }

  assetFromId = (assetId) => {
    // debugger;
    if (this.props.calendarEvents.eventMetadata === null) {
      return null;
    }
    const assetIx = this.props.calendarEvents.eventMetadata.assets.findIndex((asset) => {
      if (asset.id === assetId) {
        return true;
      } else {
        return false;
      }
    })
    if (assetIx >= 0) {
      return this.props.calendarEvents.eventMetadata.assets[assetIx];
    }
    return null;
  }

  renderActivities = () => {
    if (this.props.calendarEvents && !this.props.calendarEvents.eventMetadata) {
      return null;
    }

    const activities = this.props.calendarEvents.eventMetadata.activities.map(activity => {
      return (
        <option key={activity.id} value={activity.id}>
          {/* <div className={classes.ActivityIcon}>
            <Image src={activity.thumbnail} height="30" width="30" thumbnail />
          </div> */}
          {activity.name}
        </option>
      );
    });
    activities.unshift(<option key={0} value={0}>[Choose an activity]</option>)

    return (
      <Form.Control id="activitySelect" as="select" onChange={(event) => {_activityId = event.target.value}} defaultValue={_activityId}>
        {activities}
      </Form.Control>
    )
    // return activities;
  }

  // Show Cancel / Add/change event modal modal
  renderScheduleEventModal = (event) => {
    if (this.state.modalScheduleEvent === null) {
      return null;
    }
    // debugger
    const asset = this.assetFromId(event.resourceId);
    // debugger;
    const assetName = (asset === null ? "UNKNOWN ASSET": asset.name);
    return (
      <Modal show={(this.state.modalScheduleEvent !== null)} onHide={() => {this.setState({modalScheduleEvent: null})}} backdrop={true}>
        <Modal.Header>
        <div>Reserve {assetName} on {formattedDate(event.start)}</div>
        </Modal.Header>            
        <ModalBody>
          <div>
            {this.buildReservationDurations()}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(event) => {this.reserveAssetForTime(event, asset.activity_description)}} >Reserve {assetName}</Button>
          <Button onClick={() => {this.setState({modalScheduleEvent: null})}}>Don't Reserve</Button>
        </ModalFooter>
      </Modal>
    );
  }
    
  hideModal = () => {
    this.setState({currentEvent: null})
  }
  showModalView = () => {
    if (!this.state.currentEvent) {
      return null;
    }
    // debugger
    return (
      <Modal show={(this.state.currentEvent !== null)} backdrop={true} onHide={this.hideModal.bind(this)}>
        <Modal.Header style={{fontSize: '1.2em'}} closeButton>
          {this.state.currentEvent.title}
        </Modal.Header>
        <ModalBody>
        {this.renderBody()}
        </ModalBody>
        <ModalFooter>
        {/* {renderCancelModalButtons()} */}
        </ModalFooter>
      </Modal>
    );
  }

  handleEventClicked = (event) => {
    this.setState({currentEvent: event});
  }  

  buildReservationDurations = () => {
    let durationTypes = reservationDurations;
    // let showActivityTypes = true;
    if (this.state.assetTypeId !== -1) {
      const assetType = this.getAssetTypeFromId(this.state.assetTypeId)
      if (assetType !== null && assetType.name === defaultAssetTypeName) {
        durationTypes = tennisTypeKeys;
        // showActivityTypes = false;
      }
    }

    // let activities = null;
    // if (showActivityTypes) {
    //   activities = this.renderActivities();
    // }
    return (
      <React.Fragment>
        <Form.Control id="durationSelect" as="select" onChange={(event) => {_duration = event.target.value}} defaultValue={_duration}>
          {durationTypes.map((ttype) => {
            let typeKey = Object.keys(ttype)[0];
            let typeValue = ttype[typeKey];
            return <option key={typeKey} value={typeValue}>{typeKey}</option>
          })}
        </Form.Control>
        {/* {activities} */}
      </React.Fragment>
    )
  }

  buildAssetType = () => {
    console.log("buildAssetType")
    if (!this.props.calendarEvents.eventMetadata) {
      return null;
    }
    const assetTypes = this.props.calendarEvents.eventMetadata.asset_types.map((at, ix) => {
      return <option key={`${at.name}-${ix}`} value={at.id}>{at.name}</option>
    })
    let defaultAssetType = this.getAssetTypeFromName(defaultAssetTypeName)
    if (this.state.assetTypeId !== -1) {
      defaultAssetType = this.getAssetTypeFromId(this.state.assetTypeId);
    }
    return (
      <Form.Control id="assetTypeSelect" as="select" onChange={(event) => {this.selectAssetType(event)}} defaultValue={defaultAssetType.id}>
          {assetTypes}
      </Form.Control>
    )
  }

  reserveAssetForTime = (event) => {
    const selEvent = this.state.modalScheduleEvent;
    // TODO: Compute Asset Type based on current selected type...
    const asset = this.assetFromId(selEvent.resourceId);

    const assetType = this.getAssetTypeFromId(this.state.assetTypeId);
    // alert(`AssetType: begin: ${assetType.begin_hour} / END: ${assetType.end_hour}`)
    // Make sure the end time of the reservation isn't pase the LATEST HOUR
    const durationMinutes = parseInt(_duration);
    if ((selEvent.start.getHours() + (durationMinutes/60)) > (LATEST_HOUR+1) ) {
      alert(`Can't schedule a ${assetType.name} at this time for the chosen duration as the end time is after hours`);
      return;
    }

    // TODO: Change the description to be passed in from form value
    this.props.reserveAsset(selEvent.resourceId, selEvent.start, parseInt(_duration), asset.activity_description, "[Activity desc like doubles/singles]");
    this.setState({modalScheduleEvent: null});
  }

  viewChanged = (currentView) => {
    this.setState({currentCalendaView: currentView});
  }

  timeSlotBlocked = (date) => {
    if ((this.state.currentCalendaView === Views.DAY || this.state.currentCalendaView === Views.WEEK) &&
        (date.getHours() < EARLIEST_HOUR || date.getHours() > LATEST_HOUR) ) {
      return true;
    } else {
      return false;
    }
  }
  showReserveAssetModal = (event) => {
    if (this.timeSlotBlocked(event.start)) {
      alert("Can't schedule an event during this time period.");
      return;
    }
    if (moment(event.start).isBefore(new Date())) {
      alert("Sorry, but you can't schedule an event for a date that is in the past.");
      return;
    }

    if (this.state.currentCalendaView === Views.MONTH) {
      this.setState({currentCalendaView: Views.WEEK, curStartDate: event.start});
      return;
    }

    // IF asset.max_days_ahead !== -1 and it's beyond the allowed date, don't allow
    const asset = this.assetFromId(event.resourceId);
    if (asset.max_days_ahead > 0) {
      var a = moment(event.start);
      var b = moment(new Date());
      const diffDays = a.diff(b, 'days') + 1;
      // alert(`Date is ${diffDays} in the future`)
      if (diffDays > asset.max_days_ahead) {
        alert(`Can only schedule ${asset.name} a max of ${asset.max_days_ahead} days ahead.  Please try again later.`)
        return;
      }
    }

    this.setState({modalScheduleEvent: {...event}})
  }

  dateRangeChanged = (dates) => {
    // debugger
    console.log(`dateRangeChanged: Start: ${dates.start}  End: ${dates.end}`)
    let newMonth;
    let newYear = this.state.curStartDate.getFullYear();

    // See if the date is today...
    let today = new Date();

    // If dates is an array then use the first value.
    if (Array.isArray(dates)) {
      this.setState({curStartDate: dates[0]});
      return;
    }
    if (moment(dates.start).isBefore(today) && moment(dates.end).isAfter(today)) {
      this.setState({curStartDate: today});
      return;
    }
    if (moment(dates.start).isBefore(this.state.curStartDate)) {
      // TODO: Handle year roll
      const nextDate = new Date(this.state.curStartDate.getTime());
      newMonth = this.state.curStartDate.getMonth()-1;
      if (newMonth < 0) {
        newMonth = 11;
        newYear -= 1;
      }
      nextDate.setFullYear(newYear)
      nextDate.setMonth(newMonth);
      nextDate.setDate(1);
      this.setState({curStartDate: nextDate});
    } else {
      const prevDate = new Date(this.state.curStartDate.getTime());
      newMonth = this.state.curStartDate.getMonth()+1;
      if (newMonth > 11) {
        newMonth = 0;
        newYear += 1;
      }
      prevDate.setFullYear(newYear)
      prevDate.setMonth(newMonth);
      prevDate.setDate(1);
      this.setState({curStartDate: prevDate});
    }
  }
  // TODO: Need to set a height on the container to get full calendar to show
  // TODO: onDrillDown will fire on truncated event links clicked like when popup is NOT defined.
  render() {
    // debugger
    if (this.props.calendarEvents.updatingEvents || this.props.calendarEvents.fetchingEvents ||
       (this.state.cancelingEvent && !this.props.calendarEvents.updatingEvents)) {
      return <Spinner title="Updating calendar events..." />;
    }
    if (this.props.calendarEvents.error) {
        alert(`Error: ${this.props.calendarEvents.error}`)
        this.props.clearError();
    }
    return (
        <div style={{height: '70vh'}}>
        <div style={{width: '200px', margin: '4px', display:'inline-block'}}>
            {this.buildAssetType()}
            {/* <Button onClick={() => {selectAssetType()}}>Select Asset Type</Button> */}
        </div>
        <div style={{display:'inline-block'}}>
          <Help title="Select an Asset Type" body={assetSelectorHelp} />
        </div>
        <div style={{float: 'right'}}>
          <Help title="Asset Calendar Overview" body={assetCalendarOverview} />
        </div>
        <Calendar
          events={this.state.curEventList}
          localizer={localizer}
          defaultView={this.state.currentCalendaView}
          view={this.state.currentCalendaView}
          views={[Views.DAY, Views.WEEK, Views.MONTH]}
          step={30}
          defaultDate={new Date()}
          date={this.state.curStartDate}
          onNavigate={()=> {}}
          resources={this.state.resources}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          onSelectEvent={(event) => {this.handleEventClicked(event)}}
          onSelectSlot={(event) => {this.showReserveAssetModal(event)}}
          // onDrillDown={this.drillDownClickHandler}
          onView={(viewType) => {this.viewChanged(viewType)}}
          onRangeChange={(dates) => this.dateRangeChanged(dates)}
          popup
          selectable
          longPressThreshold={200}
          slotPropGetter={(date) => {
            if (this.timeSlotBlocked(date)) {
              return {className: "rbc-off-range-bg"};
            } else {
              return {};
            }
          } }
          min={new Date(2017, 10, 0, EARLIEST_HOUR, 0, 0)}
          max={new Date(2017, 10, 0, LATEST_HOUR+1, 0, 0)}
        />
        {this.showModalView()}
        {this.renderScheduleEventModal(this.state.modalScheduleEvent)}
        </div>
    )
  }
};

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      controls: state.controls,
      calendarEvents: state.calendarEvents
    }
  }
  
  const mapDispatchToProps= (dispatch) => {
    return {
      getCalendarEvents: (startDate) => {dispatch(getCalendarEvents(startDate))},
      getEventMetadata: () => {dispatch(getEventMetadata())},
      reserveAsset: (resourceId, start, duration, title, description) => {dispatch(reserveAsset(resourceId, start, duration, title, description))},
      cancelEvent: (event, sendEmailNotifications) => {dispatch(cancelEvent(event, sendEmailNotifications))},
      resetUpdated: () => {dispatch(resetUpdated())},
      clearError: () => {dispatch(clearError())},

      setTitle: (title) => {dispatch(setTitle(title))}
    }
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(AssetCalendarComponent);