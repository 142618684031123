import React, {useRef, useEffect, useState} from 'react';
import { Editor } from '@tinymce/tinymce-react';

import heic2any from 'heic2any';

import { resizeFile } from '../../../utils/utils';
import { TinyMceEditorConfig } from './TinyMceEditorConfig';
import { WHE_BASE_URL } from '../../../utils/constants';

import axios from '../../../utils/axios-whe';
// import axios from 'axios';

const TinyMceEditor = ({ auth, value, blur, changed, editorType, placeholder, testCallback }) => {
  const [editorValue, setEditorValue] = useState(value ?? '');
  const editorRef = useRef(null);
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };
  
  useEffect(() => {
    // console.log('useEffect: setting editor value')
    setEditorValue(value)
  }, [value])

  const toBase64 = async (file) => {
    const retBase64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
    return retBase64;
  }

  const resizeFileAndProcess = async (fileToConvert, callback, value, meta) => {
    const resizeJPGStart = Date.now()
    const smallerFile = await resizeFile(fileToConvert, "file");
    console.log(`resizeFile: resizeJPGStart FILE runtime: ${Date.now() - resizeJPGStart} ms`)

    const toB64Start = Date.now()
    const image = await toBase64(smallerFile)
    console.log(`toBase64 runtime: ${Date.now() - toB64Start} ms`)

    /*
      Note: Now we need to register the blob in TinyMCEs image blob
      registry. In the next release this part hopefully won't be
      necessary, as we are looking to handle it internally.
    */
    // const id = 'blobid' + (new Date()).getTime();
    const id = smallerFile.name.substring(0, smallerFile.name.lastIndexOf("."));
    const blobCache =  editorRef.current.editorUpload.blobCache;
    const base64 = image.split(',')[1];
    const blobInfo = blobCache.create(id, smallerFile, base64);
    blobCache.add(blobInfo);

    if (testCallback) { 
      testCallback(image)
    }

    /* call the callback and populate the Title field with the file name */
    const fileUrl = image; // URL representing the file's data
    //TODO: Can add width/height strings
    callback(fileUrl, { title: smallerFile.name, alt: `${smallerFile.name}` });
  }

  const handleFilePickerInput = async (callback, value, meta) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/png, image/jpeg, image/jpg, image/gif, image/heic');

    input.addEventListener('change', (e) => {
      const file = e.target.files[0];

      const reader = new FileReader();
      const blobReader = new FileReader();

      blobReader.addEventListener('load', async () => {
        // const base64 = reader.result.split(',')[1];
        const startConv = Date.now();
        const blob = new Blob([blobReader.result])
        // Convert the HRIC file to jpeg
        const converted = await heic2any({
          blob: blob,
          toType: "image/jpeg",
          quality: 1.0, // cuts the quality and size by half
        })
        const endConv = Date.now()
        console.log(`Conversion to JPEG from blob took: ${endConv - startConv} ms`)
        converted.name = file.name.substring(0, file.name.lastIndexOf(".")) + ".jpg";
        converted.lastModified = Date.now();

        resizeFileAndProcess(converted, callback, value, meta)
      })

      reader.addEventListener('load', async () => {
        resizeFileAndProcess(file, callback, value, meta)
      });
      if (file.type.toLowerCase() === "image/heic") {
        alert("Resizing the image so it fits in your post.  The original file will not change. Please wait.")
        blobReader.readAsArrayBuffer(file);
      } else {
        reader.readAsDataURL(file);
      }
    });

    input.click();

  }

  const image_upload_handler = (blobInfo, progress) => {
    // debugger

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      // TODO: Add in auth
      axios.post(`${WHE_BASE_URL}/api_v1/tinymce_controller/tinymce_upload`, formData, {
        _method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      .then(response => {
        // debugger
        resolve(response.data.location)
      })
      .catch(error => {
        console.error(`ERROR posting file.  EXC: ${error.message}`)
        reject({ message: 'HTTP Error: ' + error.message, remove: true });
      })
    })
  }

  const handleBlur = (value, editor) => {
    // TODO: Placeholder
    // blur(value)
  }
  const handleUpdate = (value, editor) => {
    // debugger
    setEditorValue(value)
    changed(value)
  };

  return (
    <>
      <Editor
        apiKey='le2ave7utsac3vbzmpah50hhxkctaajl60b3ficswnpg8wid'
        onInit={(evt, editor) => {
          // console.log('TinyMceEditor: onInit');
          editorRef.current = editor;
        }}
        value={editorValue}
        init={TinyMceEditorConfig(auth, editorType, placeholder, handleFilePickerInput, image_upload_handler)}
        // onBlur={handleBlur}
        onEditorChange={handleUpdate}
        placeholder={placeholder}
      />
      {/* <button onClick={(e) => {e.preventDefault();log()}}>Log editor content</button> */}
    </>
  );

}

export default TinyMceEditor;