import React, { Component } from 'react';

import classes from './Modal.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {
 
  shouldComponentUpdate(nextProps, nextState) {
    console.log('[Modal]: shouldComponentUpdate')
    return nextProps.show !== this.props.show;// || nextProps.children !== this.props.children;
  }

  render() {
    console.log(`[Modal]: show: ${this.props.show}`)
    return (
      <Aux>
        <Backdrop show={this.props.show} click={this.props.modalClosed} />
        <div 
          className={classes.Modal}
          style={{
            transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: this.props.show ? '1' : '0'
          }}
        >
          {this.props.children}
        </div>
      </Aux>
    );
  }
}
export default Modal;
