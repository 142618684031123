import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import classes from './Help.module.css';

let keyCounter = 1;
const Help = (props) => {
  const [showHelpModal, setShowHelpModal] = useState(false);

  const renderCancelModalButtons = () => {
    return (
      <React.Fragment>
        <Button color="secondary" onClick={() => {setShowHelpModal(false)}}>Close</Button>
      </React.Fragment>
    )
  }

  const toggleModal = () => {
    if (showHelpModal) {
      setShowHelpModal(false);
    } else {
      setShowHelpModal(true);
    }
  }

  const renderFieldDesc = (helpEntry) => {
    return (
      <div key={helpEntry.name} className={classes.HelpEntryContainer}>
        <div className={classes.HelpName}>{helpEntry.name}</div>
        <div className={classes.HelpBody}>{helpEntry.desc}</div>
      </div>
    )
  };

  const renderParagraph = (helpEntry) => {
    if (helpEntry.paragraph) {
      keyCounter += 1;
      return (
        <div key={`par${keyCounter}`} className={classes.HelpEntryContainer}>
          <div className={classes.HelpParagraph}>{helpEntry.paragraph}</div>
        </div>
      )
    }
  }

  const renderNote = (helpEntry) => {
    if (helpEntry.note) {
      keyCounter += 1;
      return (
        <div key={`note${keyCounter}`} className={classes.HelpEntryContainer}>
          <div className={classes.HelpNote}>{helpEntry.note}</div>
        </div>
      )
    }
  }

  const renderNumberedList = (helpEntry) => {
    const listItems = helpEntry.numbered_list;
    return (
      <ol key={`numList${keyCounter}`}>
        {listItems.map((item) => {
          keyCounter += 1;
          return <li key={`listitem-${keyCounter}`}>{item}</li>;
        })}
      </ol>
    );
  }
  const renderBody = (jsonBody) => {
    return jsonBody.map((helpEntry, ix) => {
      switch (Object.keys(helpEntry)[0]) {
        case "name":
          return renderFieldDesc(helpEntry);

        case "numbered_list":
          return renderNumberedList(helpEntry);

        case "paragraph":
          return renderParagraph(helpEntry);

        case "note":
          return renderNote(helpEntry);

        default:
          return null;
      }
    })
  }

  const showModal = (props) => {
    return (
      <Modal show={showHelpModal} onHide={toggleModal}>
          <Modal.Header style={{fontSize: '1.2em'}} closeButton>
            {props.title} - Help
          </Modal.Header>
        <ModalBody>
        {renderBody(props.body)}
        {/* <p>[Help... I need somebody...]</p>
        <p>[Help... Not just anybody...]</p>
        <p>[Help... you know I need someone...]</p>
        <p>[Help...]</p> */}
        </ModalBody>
        <ModalFooter>
          {renderCancelModalButtons()}
        </ModalFooter>
      </Modal>
    );
  }

  let iconStyle = {};
  if (props.iconStyle) {
    iconStyle = props.iconStyle;
  }
  return (
    <div className={classes.HelpIcon} style={iconStyle}>
      <FontAwesomeIcon
        onClick={ (e) => { toggleModal() } }
        icon={faQuestionCircle}
        size="3x"
        className={classes.Icon}
      />
      {showModal(props)}
    </div>
  )
}

export default Help;
