import axios, { gen_axios } from '../../utils/axios-whe';
export const UPLOAD_FILE_START = 'upload_file_start';
export const UPLOAD_FILE_SUCCESS = 'upload_file_success';
export const UPLOAD_FILE_FAILED = 'upload_file_failed';

export const UPLOAD_RESET_INITIAL_STATE = 'upload_reset_initial_state';

export const UPLOAD_GET_DOCS_START = 'upload_get_docs_start';
export const UPLOAD_GET_DOCS_SUCCESS = 'upload_get_docs_success';
export const UPLOAD_GET_DOCS_FAILED = 'upload_get_docs_failed';

export const UPLOAD_SET_ENABLE_EDIT = 'upload_set_enable_edit';

export const UPLOAD_FILE_EDIT_START = 'upload_file_edit_start';
export const UPLOAD_FILE_EDIT_SUCCESS = 'upload_file_edit_success';
export const UPLOAD_FILE_EDIT_FAILED = 'upload_file_edit_failed';

export const UPLOAD_FILE_UPDATE_START = 'upload_file_update_start';
export const UPLOAD_FILE_UPDATE_SUCCESS = 'upload_file_update_success';
export const UPLOAD_FILE_UPDATE_FAILED = 'upload_file_update_failed';

export const UPLOAD_FILE_DELETE_START = 'upload_file_delete_start';
export const UPLOAD_FILE_DELETE_SUCCESS = 'upload_file_delete_success';
export const UPLOAD_FILE_DELETE_FAILED = 'upload_file_delete_failed';

export const UPLOAD_FILE_CLEAR_ERROR = 'UPLOAD_FILE_CLEAR_ERROR';

const validKeys = [
  'name',
  'description',
  'site_document_type_id',
  'creator_id',
  'active',
  'external_url',
  'doc'
];

const errorText = (error) => {
  let errText = error.message;
  if (error.response?.status === 404 && error.response?.data?.error) {
    errText = error.response.data.error;
  }
  return errText;
}

export const resetUpload = () => {
  return (dispatch, getState) => {
    dispatch({ type: UPLOAD_RESET_INITIAL_STATE, payload: {} })
  }
}

export const setEnableEditDocs = (enableEdit) => {
  return (dispatch) => {
    dispatch({ type: UPLOAD_SET_ENABLE_EDIT, payload: {enable_edit: enableEdit} });
  }
}

export const clearErrors = () => {
  return (dispatch) => {
    dispatch({ type: UPLOAD_FILE_CLEAR_ERROR, payload: {} })
  }
}

export const editDoc = (docId) => {
  return async (dispatch, getState) => {
    const auth = getState().auth;
    let url = `/api_v1/site_documents/${docId}`;

    dispatch({ type: UPLOAD_FILE_EDIT_START, payload: {} })
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${auth.token}`,
        'X-CSRF-Token': auth.csrf_token
      }
    })
    .then(response => {
      // debugger;
      console.log(response)
      dispatch({ type: UPLOAD_FILE_EDIT_SUCCESS, payload: {site_doc: response.data} })
    })
    .catch(error => {
      console.log("ERROR: " + error);
      dispatch({ type: UPLOAD_FILE_EDIT_FAILED, payload: {error: errorText(error)} });
    });

  }
}

const getTrusteesUrl = (results) => {
  let url = null;
  results.forEach((f) => {
    if (f.doc_type === 'JSON') {
      url = f.url;
    }
  })
  return url;
}
export const getDocs = (active) => {

  return async (dispatch, getState) => {
    const auth = getState().auth;
    let url = "/api_v1/site_documents.json";
    if (active) {
      // url = "/api_v1/site_documents/get_docs_v2.json";
      url = "/api_v1/site_documents/get_docs_v2.json?active=true";
    } else {
      // leave active param off to retrieve ALL
      url = "/api_v1/site_documents/get_docs_v2.json";
    }
    dispatch({ type: UPLOAD_GET_DOCS_START, payload: {} })
    try {
      let response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      console.log(response)
      // debugger;
      // Get the trustees.json
      let trusteesUrl = getTrusteesUrl(response.data.site_documents.documents);
      let trustees = [];
      if (trusteesUrl) {
        let trusteeDoc = await gen_axios.get(trusteesUrl, { crossdomain: true }, { active: true })
        if (trusteeDoc) {
          trustees = trusteeDoc.data;
        }
      }
      dispatch({ type: UPLOAD_GET_DOCS_SUCCESS, payload: {site_docs: response.data.site_documents, trustees: trustees} })
      // dispatch({ type: UPLOAD_GET_DOCS_SUCCESS, payload: {site_docs: response.data, trustees: trustees} })
    } catch (error) {
      console.log("ERROR: " + error);
      dispatch({ type: UPLOAD_GET_DOCS_FAILED, payload: {error: errorText(error)} });
    }
  }
}

export const uploadFile = (fileJson) => {
  let formData = new FormData();

  let keys = Object.keys(fileJson);

  keys.forEach((key) => {
    if (validKeys.includes(key)) {
      formData.append(`site_document[${key}]`, fileJson[key])
    }
  });

  return async (dispatch, getState) => {
    const auth = getState().auth;
    // debugger;
    dispatch({type: UPLOAD_FILE_START, payload: {doc: fileJson}})
    try {

      let response = await axios.post('/api_v1/site_documents.json', formData, {
        _method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      // debugger;
      if (response.status === 201) {
        // debugger;
        dispatch({type: UPLOAD_FILE_SUCCESS, payload: { doc_id: response.data.id } })
      } else {
        dispatch({type: UPLOAD_FILE_FAILED, payload: {error: `Failed to upload file`}})
      }
    } catch (error) {
      let uploadErrMsg = 'Failed to update file.';
      if ( error.request.response && JSON.parse(error.request.response)['doc'] ) {
        uploadErrMsg = JSON.parse(error.request.response)['doc'];
      }
      dispatch({type: UPLOAD_FILE_FAILED, payload: {error: `Update error: ${uploadErrMsg}`}})
    }  
  }
}

export const updateFile = (fileJson) => {
  let formData = new FormData();

  let keys = Object.keys(fileJson);

  keys.forEach((key) => {
    if (validKeys.includes(key)) {
      formData.append(`site_document[${key}]`, fileJson[key])
    }
  });

  return async (dispatch, getState) => {
    const auth = getState().auth;
    // debugger;
    dispatch({type: UPLOAD_FILE_UPDATE_START, payload: {doc: fileJson}})
    try {
      let response = await axios.patch(`/api_v1/site_documents/${fileJson.id}`, formData, {
        _method: 'PATCH',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      // debugger;
      if (response.status === 200) {
        // debugger;
        dispatch({type: UPLOAD_FILE_UPDATE_SUCCESS, payload: { doc: response.data } })
      } else {
        dispatch({type: UPLOAD_FILE_UPDATE_FAILED, payload: {error: `Failed to update file`}})
      }
    } catch (error) {
      // debugger;
      let updErrMsg = 'Failed to update file.';
      if ( error.request.response && JSON.parse(error.request.response)['doc'] ) {
        updErrMsg = JSON.parse(error.request.response)['doc'];
      }
      dispatch({type: UPLOAD_FILE_UPDATE_FAILED, payload: {error: `Update error: ${updErrMsg}`}})
    }  
  }
}

export const destroyFile = (docId) => {

  return async (dispatch, getState) => {
    const auth = getState().auth;
    // debugger;
    dispatch({type: UPLOAD_FILE_DELETE_START, payload: {doc_id: docId}})
    try {
      let response = await axios.delete(`/api_v1/site_documents/${docId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${auth.token}`,
          'X-CSRF-Token': auth.csrf_token
        }
      })
      // debugger;
      if (response.status === 204) {
        // debugger;
        dispatch({type: UPLOAD_FILE_DELETE_SUCCESS, payload: { doc_id: docId } })
      } else {
        dispatch({type: UPLOAD_FILE_DELETE_FAILED, payload: {error: `Failed to delete file`}})
      }
    } catch (error) {
      dispatch({type: UPLOAD_FILE_DELETE_FAILED, payload: {error: `Failed to delete file`}})
    }  
  }
}