import { POST_EDITOR } from '../../../utils/constants';

export const TinyMceEditorConfig = (auth, editorType, placeholder, filePickerCallback, imageUploadHandler) => {

  let height;
  let toolbar;

  if (editorType === POST_EDITOR) {
    height = 400;
    toolbar = 'image link bullist numlist bold italic | bullist numlist | ' +
              'undo redo outdent indent forecolor blocks ' +
              `alignleft aligncenter alignright alignjustify|table fullscreen `;
    if (auth.site_admin) {
      toolbar += 'code';
    }
  } else {
    height = 150;
    toolbar = 'bullist numlist bold italic | bullist numlist | ' +
              'undo redo ';
  }
  const config = {
    placeholder: placeholder,
    height: height,
    menubar: false,
    // mobile: { theme: 'mobile' },
    plugins: [
      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
      'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
      'insertdatetime', 'media', 'table', 'help', 'wordcount'
    ],
    toolbar: toolbar,
    // menubar: true,
    mobile: {
      // menubar: true,
      plugins: `advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table help wordcount`,
      toolbar: toolbar,
      toolbar_mode: 'scrolling',
    },
    contextmenu: false,  // WDS: Remove the contextmenu when right-clicking inside the editor so you can paste.
    content_style: "body { font-size: 18px }",
    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    // images_upload_url: `http://192.168.1.19:3004/api_v1/tinymce_controller/tinymce_upload`,
    // automatic_uploads: true,
    // automatic_uploads: false,
    images_file_types: 'jpeg,jpg,jpe',
    images_reuse_filename: true,  // USE the original name of the file
    // file_picker_callback: handleFilePicker, // WORKS but not on Safari due to window.openFile API
    file_picker_callback: filePickerCallback,
    image_title: true,
    file_picker_types: 'image',
    image_caption: true,
    // image_advtab: true,
    // a11y_advanced_options: true,
    // images_upload_handler: example_image_upload_handler
    images_upload_handler: imageUploadHandler,
    // image_description: false,  // Disable image description field
    // image_dimensions: false,   // Disable image dimensions fields
    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
    // paste_preprocess: (editor, args) => {
    //   console.log(args.content);
    //   const cleanContent = args.content.replace(/<\/?(mso:|o:|v:|w:|xml:|xmlns:|st1:|st2:|st3:|st4:)[^>]*>/gi, '');
    //   console.log(`Cleaned Content: ${cleanContent}`)
    //   args.content += cleanContent;
    // },
    paste_remove_styles_if_webkit: false,
    paste_as_text: true,
    statusbar: false,

    paste_block_drop: true,
    // paste_preprocess: (editor, args) => {
    //   alert("Dragging content in is not allowed.")
    //   args.content = '';
    // }
    
  };
  return config;
}
