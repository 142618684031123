import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import moment from 'moment'

import Spinner from '../../Spinner/Spinner';

import { setTitle } from '../../../redux/actions/controlsActions';
import { createEvent } from '../../../redux/actions/calendarEventActions';

import classes from './EventForm.module.css';
import "./EventForm.css";
import EventForm from './EventForm';

const CloneEvent = (props) => {
  let { eventId } = useParams();
  const [clonedEvent, setClonedEvent] = useState(null);
  const auth = useSelector(state => state.auth);
  const calendarEvents = useSelector(state => state.calendarEvents);
  const history = useHistory();
  const dispatch = useDispatch();

  const findEvent = (eventId) => {
    let event = null;
    if (calendarEvents && calendarEvents.calendarEvents.length > 0) {
      const ix = calendarEvents.calendarEvents.findIndex(e => {
        if (e.id === eventId) {
          return true;
        } else {
          return false;
        }
      });
      if (ix >= 0) {
        event = calendarEvents.calendarEvents[ix];
        let duration = 60;
        if (event.end_date != null) {
          let begin = moment(event.start);
          let end = moment(event.end)
          duration = end.diff(begin);
          duration = duration / 1000 / 60
          // debugger
        }
        event = {...calendarEvents.calendarEvents[ix], event_duration: duration}
        // Clear out the event date and force the user to choose.
        event.begin_date = null;
        event.start = null;
        event.end_date = null;
        event.end = null;
      }
    }
    return event;
  }

  useEffect(() => {
    dispatch(setTitle("Replicate Event"));
    let evId = eventId;
    if (evId) {
      evId = parseInt(evId);
    }
  
    if (evId === undefined || isNaN(evId)) {
      alert("Invalid event selected to clone.  Try again");
      history.replace('/calendar_events')
      return;
    }
  
    console.log(`[CloneEvent]: eventId: ${evId} calUpdEvent: ${calendarEvents.updatedEvent}`)
    let e2e = findEvent(evId)
    if (e2e !== null) {
      setClonedEvent(e2e);
    }
  }, [calendarEvents.updatedEvent]);

  if (calendarEvents && !calendarEvents.eventMetadata) {
    history.replace('/calendar_events');
    return null;
  }

  if (calendarEvents.updatedEvent) {
    history.replace('/calendar_events')
    return null;
  }

  // handleFormSubmit receives the values from the form in a JS object
  // the postCallback to let the form know we're done processing.
  const handleFormSubmit = (values, postCallback) => {
    const event = {...values};
    dispatch(createEvent(event));
  }

  const renderContent = () => {
    if (!clonedEvent || calendarEvents.updatingEvent) {
      return <Spinner />;
    } else {
      // debugger;
      return (
        <EventForm 
          event={{...clonedEvent, groups: auth.user.groups, group_ids: [clonedEvent.groups[0].group_id]}}
          auth={auth}
          edit={false}
          sendNotifications={false}
          onSubmit={handleFormSubmit}
          title="Clone Event"
          cancel={() => {history.replace('/calendar_events')}}
        />
      );
    }
  }

  return (
    <div>
      <div className={classes.Title}>Cloning Event "{(clonedEvent ? clonedEvent.title : null)}"</div>
      <Col sm={10} style={{paddingLeft: '0', marginLeft: '20px', marginRight: '0'}}>
        {renderContent()}
      </Col>
    </div>
  );
}

export default CloneEvent;
