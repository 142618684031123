import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Input from '../../UI/Input';
import Spinner from '../../Spinner/Spinner';

import { createPhotoAlbum } from '../../../redux/actions/photoActions';
import { setTitle } from '../../../redux/actions/controlsActions';

// import "./NewPost.css";
import classes from './NewAlbum.module.css';

const schema = Yup.object({
  name: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(80, 'Must be 32 characters or less')
    .required('ALbum name is required')
});

const NewAlbum = (props) => {
  const auth = useSelector(state => state.auth);
  let [, setTimeoutId] = useState(null);
  let [redirectFlag, setRedirectFlag] = useState(false);
  const dispatch = useDispatch();
  const photos = useSelector(state => state.photos);
  
  const albumDefaults = {
    id: null,
    name: '',
    description: '',
    allow_all_to_contribute: false,
    published: false,
    neighborhood_id: auth.neighborhood_id,
    site_definition_id: auth.site_definition_id,
    creator_id: auth.uid
  };
  
  let album = {...albumDefaults};
  useEffect(() => {
    dispatch(setTitle("New Album"))
    // Sammy's new Album 6 - 6-19
    if (redirectFlag) {
      props.history.push(`/editalbum/${photos.currentAlbum.id}`)
    }  
  })

  const formik = useFormik({
    initialValues: {
      name: album.name,
      description: album.description,
      published: album.published,
      allow_all_to_contribute: album.allow_all_to_contribute,
      neighborhood_id: auth.neighborhood_id,
      site_definition_id: auth.site_definition_id,
      creator_id: auth.uid
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const startTimer = () => {
    return setTimeout(() => {
      if (photos.working) {
        setTimeoutId(startTimer());
      } else {
        formik.setSubmitting(false)
        setTimeoutId(null);
        // Redirect to editAlbum
        setRedirectFlag(true);
      }
    }, 500)
  };

  const handleFormSubmit = (values) => {
    setTimeoutId(startTimer());
    dispatch(createPhotoAlbum(values));
  }

  const handleRadioChange = (event) => {
    // alert(`handleRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
    if (event.target.type === 'radio') {
      if (event.target.value === 'true' || event.target.value === true) {
        formik.setFieldValue(event.target.name, true);
      } else {
        formik.setFieldValue(event.target.name, false);
      }
    }
  }
  
  const showSpinner = () => {
    if (formik.isSubmitting) {
      return <Spinner />;
    } else {
      return null;
    }
  }

  return (
    <Container fluid>
      {showSpinner()}
      <Row>
        <Col sm={12}>
          <div className={classes.Title}>Create New Album</div>
        </Col>
      </Row>
      <Row>
        <Col sm={10} style={{paddingLeft: '0', marginLeft: '15px', marginRight: '0'}}>
          <Form onSubmit={formik.handleSubmit}>
            <Input
              style={{fontSize: '18px'}}
              placeholder="Enter Title"
              noPrepend
              disabled={formik.isSubmitting}
              id='name'
              required
              label="Name"
              value={formik.values.name}
              name='name'
              changed={formik.handleChange}
              blur={formik.handleBlur}
              errors={formik.errors.name}
              touched={formik.touched.name}
              showValid
            />
            <textarea 
              className='form-control'
              style={{fontSize: '18px', minHeight: '90px', whiteSpace: 'pre-wrap'}}
              placeholder="Enter Description"
              disabled={formik.isSubmitting}
              id='description'
              required
              label="Description"
              value={formik.values.description}
              name='description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.errors.description}
              touched={formik.touched.description}
            >
                {formik.values.description}
            </textarea>
            
            <InputGroup>
              <div className={classes.RadioTitle}>Allow anyone to add photos?</div>
              <Form.Label className={classes.Radio} htmlFor='allow_all_to_contribute_true'>YES</Form.Label>
              <Form.Check
                disabled={formik.isSubmitting}
                type='radio'
                as='input'
                id="allow_all_to_contribute_true"
                className={classes.Radio}
                checked={formik.values.allow_all_to_contribute === true}
                value={true}
                name='allow_all_to_contribute'
                onChange={handleRadioChange}
                isInvalid={formik.errors['allow_all_to_contribute']}
                isValid={!formik.errors['allow_all_to_contribute']}
              />
              <Form.Label className={classes.Radio} htmlFor='allow_all_to_contribute_false'>NO</Form.Label>
              <Form.Check
                disabled={formik.isSubmitting}
                styles={[{marginLeft: 20}]}
                type='radio'
                as='input'
                id="allow_all_to_contribute_false"
                className={classes.Radio}
                checked={formik.values.allow_all_to_contribute === false}
                value={false}
                name='allow_all_to_contribute'
                onChange={handleRadioChange}
                isInvalid={formik.errors['allow_all_to_contribute']}
                isValid={!formik.errors['allow_all_to_contribute']}
              />
            </InputGroup>
            <input type="hidden" name="neighborhood_id" value={formik.values.neighborhood_id} />
            <input type="hidden" name="site_definition_id" value={formik.values.site_definition_id} />
            <input type="hidden" name="creator_id" value={formik.values.user_id} />
            <input type="hidden" name="user_id" value={formik.values.user_id} />
            <InputGroup className={classes.SubmitButton}>
              <Button type="submit" disabled={formik.isSubmitting}>Create Album</Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewAlbum;