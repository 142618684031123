import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Collapse from 'react-bootstrap/Collapse';

import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faInfoCircle, faUser, faUserSlash, faEye } from '@fortawesome/free-solid-svg-icons';

// import CopyToClipboard from 'react-copy-to-clipboard';

import Input from '../UI/Input';
import Spinner from '../Spinner/Spinner';

import { createPropertyContact, updatePropertyContact, deletePropertyContact, updateProperty } from '../../redux/actions/propertyActions';

import { formattedDateSm, capitalizeString } from '../../utils/utils';

import PropertyContactForm from './PropertyContactForm';

import classes from './WhAddress.module.css';
import { noop } from 'lodash';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object({
  last_name: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(32, 'Must be 32 characters or less')
    .required('Required'),
  first_name1: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(32, 'Must be 32 characters or less')
    .required('Required'),
  first_name2: Yup.string()
    .max(32, 'First name must be 32 characters or less'),
  pri_street_addr: Yup.string()
    .max(64, 'Invalid street address')
    .required('Required'),
  pri_town: Yup.string()
    .max(32, 'Invalid length.  Must be 32 characters or less')
    .required('Required'),
  pri_state: Yup.string()
    .max(32, 'Invalid length.  Must be 32 characters or less')
    .required('Required'),
  pri_zipcode: Yup.string()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be 5 or 9 digits')
      .required('Required'),
  wh_house_num: Yup.string()
    .required('Required'),
  wh_street: Yup.string()
    .max(32, 'Invalid length.  Must be 32 characters or less')
    .required("Required"),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
});

const WhAddressFunc = React.forwardRef((props, ref) => {

  const auth = useSelector(state => state.auth);
  const properties = useSelector(state => state.properties);
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState(false);
  const [editAddr, setEditAddr] = useState(false);
  const [renderLongEmail, setRenderLongEmail] = useState(false);

  /**
   * type: CONTACT_LIST, SPECIFIC_CONTACT, EDIT_CONTACT, NEW_CONTACT
   * payload: AddressList for CONTACT_LIST OR PropertyContact for a SPECIFIC_CONTACT or EDIT_CONTACT
   */
  const CONTACT_LIST = 1;
  const SPECIFIC_CONTACT = 2;
  const EDIT_CONTACT = 3;
  const NEW_CONTACT = 4;

  const [showContactsModal, setShowContactsModal] = useState(null);

  const { addr } = props;

  const formik = useFormik({
    initialValues: {
      last_name: addr.last_name,
      first_name1: addr.first_name1,
      first_name2: addr.first_name2,
      pri_street_addr: addr.pri_street_addr,
      pri_town: addr.pri_town,
      pri_state: addr.pri_state,
      pri_zipcode: addr.pri_zipcode,
      wh_house_num: addr.wh_house_num,
      wh_street: addr.wh_street,
      phone: addr.phone,
      built: addr.built,
      id: addr.id
    },
    // validateOnChange: true,
    validationSchema: schema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
    
    const handleFormSubmit = (values) => {
      // console.log('***** handleFormSubmit *********')
      dispatch(updateProperty(props.addr, values));  
    }
    const showModalForm = (e) => {
      // e.preventDefault()
      console.log(`ShowModalForm: ${editAddr}`)
      setModalState(true);
    }

    const editModalForm = (e, addr) => {
      // e.preventDefault()
      console.log(`EditModalForm: true`)
      // Update the form values so the currently selected address is shown.
      formik.setValues(addr)
      setEditAddr(true);
      showModalForm(e);
    }
  
    const toggle = () => {
      setModalState(!modalState);
      if (modalState) {
        setEditAddr(false);
      }
      formik.resetForm();
      // setAddrState(origAddrState);
    };
  
    const addrClicked = (e, al) => {
      props.clicked(al);
      showModalForm(e);
    }

    const getPrimaryContact = (al) => {
      const ix = al.property_contacts.findIndex((pc) => {
        if (pc.is_primary) {
          return true;
        } else {
          return false;
        }
      })
      if (ix !== -1) {
        return al.property_contacts[ix];
      } else {
        return null;
      }
    }
    const updatePropertyContactInfo = (pc, values) => {
      /*
       * Only admins or primary contacts can update contact info.  IF a contact is changing
       * the primary to someone else then they will not be able to edit once this is done
       * and it will take an ADMIN or the designated property contact to update back.
       */
      
      // Checkl that the user updating this contact actually wants to make
      //  this one primary and let them know that they'll be toast and unable
      //  to edit any longer once they designate another as primary.
      //  IF this is being updated by an app_admin not this user, handle
      //
      // An app_admin can make any change and is assumed they know what they're doing
      if ( !auth.app_admin &&
           (pc.is_admin && !values.is_admin) &&
           (pc.user_id === auth.uid) ) {
        const reassignPrimary = window.confirm(`Changing yourself to not be an admin will mean you can no longer edit contacts.  Only an administrators or other listed admins of this property will be able to edit.  Are you sure you want to do this?`);
        if (!reassignPrimary) {
          // alert("OK, not updating the primary contact.")
          return false;
        }
      }
      dispatch(updatePropertyContact(values))
      return true;
    }
    const savePropertyContact = (values) => {
      dispatch(createPropertyContact(values))
    }

    const renderPropertyContactForm = (al, cloneFirst) => {
      const pcData = {
        id: "",
        address_list_id: al.id,
        first_name: (cloneFirst ? al.first_name1 : ""),
        last_name: (cloneFirst ? al.last_name : ""),
        note: '',
        is_primary: (cloneFirst ? true : false),
        is_billing: (cloneFirst ? true : false),
        is_owner: false,
        whe_is_primary: false,
        email: '',
        share_email: false,
        phone: (cloneFirst ? al.phone : ""),
        share_phone: false,
        address_1: al.pri_street_addr,
        address_2: '',
        town: al.pri_town,
        state: al.pri_state,
        zipcode: al.pri_zipcode,
        user_id: null,
        is_admin: true
      }
      // Render this in a Modal
      return (
        <PropertyContactForm edit={false} property_contact={pcData} onSubmit={savePropertyContact} cancel={() => setShowContactsModal({type: CONTACT_LIST, payload: al})} al={al} />
      )
    }

    const renderEditPropertyContactForm = (pc) => {
      return (
        <PropertyContactForm edit={true} property_contact={pc} onSubmit={(values) => updatePropertyContactInfo(pc, values)} cancel={() => setShowContactsModal({type: CONTACT_LIST, payload: al})} al={al} />
      )
    }

    const buildCurrentUser = (pc) => {
      if (pc.user_id === null) {
        return <div className={classes.SiteUserMessage}>[No website user currently associated with this contact]</div>
      }
      const ix = auth.site_users.findIndex(user => {
        if (pc.user_id === user.id) {
          return true;
        } else {
          return false;
        }
      });
      if (ix === -1) {
        return <div className={classes.SiteUserMessage}>[No website user associated with this contact]</div>
      }
      return <div className={classes.SiteUserMessage}>{capitalizeString(auth.site_users[ix].first_name)} {capitalizeString(auth.site_users[ix].last_name)} website account is associated with this site contact</div>;
    }
  
    const buildContactAttributes = (pc) => {
      let attrs = []
      if (pc.is_primary) {
        attrs.push("Primary Contact");
      }
      if (pc.is_billing) {
        attrs.push("Billing Contact");
      }
      if (pc.is_owner) {
        attrs.push("Is Owner")
      }
      if (pc.is_admin) {
        attrs.push("Is Property Admin")
      }
      return attrs.join(', ');
    }

    const renderEmailAddress = (pc) => {
      if (pc.email === null || pc.email.trim().length === 0) {
        return "(None Specified)"
      }
      let emailInfo = renderEmailLink(pc);
      if (pc.share_email) {
        emailInfo += " (Is public)"
      } else {
        emailInfo += " (Is private)";
      }
      return emailInfo;
    }

    const renderPhoneNumber = (pc) => {
      if (pc.phone === null || pc.phone.trim().length === 0) {
        return "(None Specified)"
      }
      let phoneInfo = pc.phone;
      if (pc.share_phone) {
        phoneInfo += " (Is public)"
      } else {
        phoneInfo += " (Is private)";
      }
      return phoneInfo;
    }
    const renderAddressInfo = (pc) => {
      let address = []
      if (pc.address_1 || pc.address_1.trim().length > 0) {
        address.push(pc.address_1.trim());
      }
      if (pc.address_2 || pc.address_2.trim().length > 0) {
        address.push(pc.address_2.trim());
      }
      let cityState = pc.town.trim() + ", " + pc.state.trim() + " " + pc.zipcode.trim();
      return capitalizeString(address.join(' ') + ", " + cityState);
    }

    const renderPropertyContactNote = (pc) => {
      if (auth.app_admin) {
        return (
          <Row>
            <Col>
            <b>Note: </b> {(pc.note && pc.note.trim().length > 0 ? pc.note.trim() : "N/A")}
            </Col>
          </Row>
        )
      }
      return null;
    }
    const renderPropertyContactInfo = (pc) => {
      return (
        <div>
          <Row>
            <Col>
            {buildCurrentUser(pc)}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Contact Attributes:</b> {buildContactAttributes(pc)}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Email:</b> {renderEmailAddress(pc)}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Phone:</b> {renderPhoneNumber(pc)}
            </Col>
          </Row>
          <Row>
            <Col>
            <b>Address: </b> {renderAddressInfo(pc)}
            </Col>
          </Row>
          {renderPropertyContactNote(pc)}
        </div>
      )
    }

    const deletePropContactConfirm = (pc) => {
      const deleteContact = window.confirm(`Are you sure you want to delete the contact for ${pc.first_name} ${pc.last_name} ?`);
      if (deleteContact) {
        dispatch(deletePropertyContact(pc));
      }
    }
    const renderEditIcons = (pc) => {
      return (
        <React.Fragment>
          <FontAwesomeIcon className={classes.WhAddrEdit} onClick={() => { setShowContactsModal({type: SPECIFIC_CONTACT, payload: pc}) }} icon={faEye} />
          <FontAwesomeIcon className={classes.WhAddrDelete} onClick={() => {deletePropContactConfirm(pc)}} icon={faTrash} />
        </React.Fragment>
      )
    }

    const emailAddrClickHandler = (e, pc) => {
      e.preventDefault();
      if ( (pc.share_email || auth.app_admin) && !renderLongEmail ) {
        setRenderLongEmail(true)
        return;
      }
      if (renderLongEmail) {
        setRenderLongEmail(false)
      }
    }

    const renderEmailAddrInfo = (pc) => {
      if (renderLongEmail) {
        return (
          <Col xs={12} md={5} className={classes.EmailOverflowWide} onClick={(e) => {emailAddrClickHandler(e, pc)}}>
              {(renderEmailLink(pc))}
          </Col>
        )
      }
      return (
        <React.Fragment>
          <Col xs={4} md={3} className={[classes.MobileOnly, classes.Header].join(' ')}>Email</Col>
          {/* <CopyToClipboard text={pc.email} onCopy={() => alert(`Copied email address '${pc.email} to the clipboard so you can paste it elsewhere.`)}> */}
            <Col xs={6} md={3} className={classes.EmailOverflow} onClick={(e) => {emailAddrClickHandler(e, pc)}}>
              {(pc.share_email || auth.app_admin ? pc.email : "private")}
            </Col>
          {/* </CopyToClipboard> */}
        </React.Fragment>
      )
    }

    const renderPrimaryBilling = (pc) => {
      if (renderLongEmail) {
        return null;
      }
      return (
        <React.Fragment>
          <Col xs={4} md={2} className={[classes.MobileOnly, classes.Header].join(' ')}>Pri / Billing</Col>
          <Col xs={6} md={2}>{pc.is_primary ? "YES":"NO"} / {pc.is_billing ? "YES":"NO"}</Col>
        </React.Fragment>
      )
    }

    const renderDesktopModalHeader = (contacts) => {
      // renderLongEmail
      if (userCanModify(al)) {
        if (renderLongEmail) {
          return (
            <React.Fragment>
              <Row>
                <Col xs={6} md={4} className={classes.DesktopOnly}>Name</Col>
                <Col xs={6} md={3} className={classes.DesktopOnly}>Phone</Col>
                <Col xs={6} md={5} className={classes.DesktopOnly}>Email</Col>
              </Row>
              {contacts}
            </React.Fragment>
          )
        }
        return (
          <React.Fragment>
            <Row>
              <Col xs={6} md={4} className={classes.DesktopOnly}>Name</Col>
              <Col xs={6} md={3} className={classes.DesktopOnly}>Phone</Col>
              <Col xs={6} md={3} className={classes.DesktopOnly}>Email</Col>
              <Col xs={6} md={2} className={classes.DesktopOnly}>Pri / Billing</Col>
            </Row>
            {contacts}
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <Row>
              <Col className={classes.DesktopOnly}>Name</Col>
              <Col className={classes.DesktopOnly}>Phone</Col>
              <Col className={classes.DesktopOnly}>Email</Col>
            </Row>
            {contacts}
          </React.Fragment>
        )
      }

    }

    const renderEmailLink = (pc) => {
      if (pc.email) {
        return (
          <a href={`mailto:${pc.email}?subject=[${auth.first_name} ${auth.last_name} from WHE]`}
            onClick={(e) => {e.stopPropagation()}}
          >
            {pc.email}
          </a>
        )
      }
      return "NA";
    }
    const renderPhoneLink = (pc) => {
      if (pc.phone) {
        return <a href={`tel:${pc.phone}`}>{pc.phone}</a>
      }
      return "NONE";
    }
    const renderPropertyContacts = (al) => {
      if (auth.app_admin && al.property_contacts.length === 0) {
        return (
          <Row>
            <Col>[No Contacts.  Please add one...]</Col>
          </Row>
        )
      }

      const contacts = al.property_contacts.map(pc => {
        if (userCanModify(al)) {
          return (
            <Row className={classes.ContactInfo} key={`prop_contact${pc.id}`}>
              <Col xs={4} md={4} className={[classes.MobileOnly, classes.Header].join(' ')}>Name</Col>
              <Col xs={6} md={4}>
                {renderEditIcons(pc)}
                {capitalizeString(pc.last_name)}, {capitalizeString(pc.first_name)}
                {(pc.user_id === null ? <FontAwesomeIcon className={classes.WhUserIcon} icon={faUserSlash} /> : <FontAwesomeIcon className={classes.WhUserIcon} icon={faUser} />)}
              </Col>
              <Col xs={4} md={3} className={[classes.MobileOnly, classes.Header].join(' ')}>Phone</Col>
              <Col xs={6} md={3}>{(pc.share_phone || auth.app_admin ? renderPhoneLink(pc) : "private")}</Col>
              {renderEmailAddrInfo(pc)}
              {renderPrimaryBilling(pc)}
              {/* <Col xs={4} className={[classes.MobileOnly, classes.Header].join(' ')}>Billing</Col>
              <Col xs={6} md={2}>{pc.is_billing ? "YES":"NO"}</Col> */}
            </Row>
          )
        } else {
          return (
            <Row className={classes.ContactInfo} key={`prop_contact${pc.id}`}>
              <Col xs={4} className={[classes.MobileOnly, classes.Header].join(' ')}>Name</Col>
              <Col xs={6} md={4}>
                {pc.last_name}, {pc.first_name} <span style={{color: 'red', fontSize: '1.1em'}}>{(pc.is_primary ? " *":"")}</span>
              </Col>
              <Col xs={4} className={[classes.MobileOnly, classes.Header].join(' ')}>Phone</Col>
              <Col xs={6} md={4}>{(pc.share_phone || auth.app_admin ? renderPhoneLink(pc) : (pc.phone ? "private" : "None") )}</Col>

              <Col xs={4} className={[classes.MobileOnly, classes.Header].join(' ')}>Email</Col>
              <Col xs={6} md={4}>{(pc.share_email || auth.app_admin ? renderEmailLink(pc) : "private")}</Col>
            </Row>
          )
        }
      })
      // debugger;
      return renderDesktopModalHeader(contacts);
    }

    const renderContacts = (al) => {
      if (!props.isSelected) {
        return null;
      }
      const contacts = [];
      // debugger
      al.property_contacts.forEach((pc, ix) => {
        // Only add the non-primary contacts as the primary is listed by default.
        if (pc.is_primary === false) {
          contacts.push(
            <Row key={`prop-contact-${pc.id}`}>
              <Col>{pc.last_name}, {pc.first_name}</Col>
            </Row>
          )
        }
      })
      return contacts;
    }
    const renderPropertyContactName = (al) => {
      // Render the primary contact name first
      let firstName = al.first_name1;
      if (al.first_name2 && al.first_name2.length > 0) {
        firstName = `${firstName} / ${al.first_name2}`;
      }
      let fullName = `${al.last_name}, ${firstName}`;

      let contactName = "";
      if (al.property_contacts.length > 0) {
        const pIx = al.property_contacts.findIndex(pc => {
          if (pc.is_primary) {
            return true;
          } else {
            return false
          }
        })
        let contact = al.property_contacts[0];
        if (pIx >= 0) {
          contact = al.property_contacts[pIx]
        }

        fullName = `${contact.last_name}, ${contact.first_name}`;
        contactName = (
          <span className={classes.WhAddrEdit}>
            <FontAwesomeIcon 
                style={{marginRight: '2px'}} icon={faInfoCircle}
            />
            {fullName}
          </span>
        )
      } else {
        contactName = fullName;
      }
      return contactName;
    }
    const hasAdminUser = (al) => {
      const ix = al.property_contacts.findIndex(pc => {
        if ((pc.is_admin || pc.is_owner) && pc.user_id !== null) {
          return true;
        } else {
          return false;
        }
      })
      return ix !== -1;
    }

    const renderNoAdminUser = (al) => {
      if (hasAdminUser(al)) {
        return null;
      }
      // debugger
      if (auth.app_admin) {
        return (
          <span style={{fontSize: '1.2em', color: 'red'}}>**</span>
        )
      } else {
        return null;
      }
    }
    const renderAddressDiv = (al) => {
      let addrClass = [classes.WhAddress];
      if (props.isSelected) {
        addrClass.push(classes.WhAddressSelected);
      }
      addrClass = addrClass.join(' ');

      let editClassName = "";
      if (userCanModify(al)) {
        editClassName = classes.WhAddrEdit;
      }
      const pc = getPrimaryContact(al);
      let phone = "private";
      if (pc && (pc.share_phone || auth.app_admin)) {
        phone = (pc.phone ? pc.phone : "none");
      }
      return (
        <Row className={addrClass} onClick={(e) => {addrClicked(e, al)}} >
          <Col className={editClassName} xs={3} md={1} onClick={(e) => {userCanModify(al) && editModalForm(e, al)}}>
            {userCanModify(al) && <FontAwesomeIcon icon={faEdit} />}
            {al.lot_num}
            {renderNoAdminUser(al)}
          </Col>
          <Col xs={5} md={2}>
            {/* TODO: Need a click that renders existing property contact info and IF none exist it should at least
                render a view with a button letting the user create one
             */}
            <Row onClick={(e) => {(auth.app_admin || al.property_contacts.length > 0 ? setShowContactsModal({type: CONTACT_LIST, payload: al}) : noop())}}>
              <Col>
                {renderPropertyContactName(al)}
              </Col>
            </Row>
            <Collapse in={props.isSelected}>
              <div style={{marginLeft: '16px'}} onClick={(e) => {(auth.app_admin || al.property_contacts.length > 0 ? setShowContactsModal({type: CONTACT_LIST, payload: al}) : noop())}}>
              {renderContacts(al)}
              </div>
            </Collapse>
          </Col>
          {/* <Col >{firstName}</Col> */}
          <Col xs={4} md={2}>{al.wh_house_num} {al.wh_street}</Col>
          {/* <Col md={3} className={classes.WhAddressSmScreen}>{al.pri_street_addr}, {al.pri_town} {al.pri_state}</Col> */}
          <Col md={2} className={classes.WhAddressSmScreen}>{phone}</Col>
          <Col md={2} className={classes.WhAddressSmScreen}>{formattedDateSm(al.updated_at)}</Col>
        </Row>
      );
    }

    const handleRadioChange = (event) => {
      // alert(`handleRadioChange: fieldName: ${event.target.name}  event.target.value: ${event.target.value}`)
      if (event.target.type === 'radio') {
        if (event.target.value === 'true' || event.target.value === true) {
          formik.setFieldValue(event.target.name, true);
        } else {
          formik.setFieldValue(event.target.name, false);
        }
      }
    }
    
    const allowWhAddrChange = () => {
      if (auth.site_admin && properties.working === false) {
        return false;
      }
      return true;
    }

    const userIsAdmin = (al) => {
      const ix = al.property_contacts.findIndex(pc => {
        if (pc.user_id === auth.uid && (pc.is_admin || pc.is_owner)) {
          return true;
        } else {
          return false;
        }
      });
      if (ix !== -1) {
        return true;
      } else {
        return false;
      }
    }
    const userCanModify = (al) => {
      if (auth.app_admin) {
        return true
      }

      // ORIG: user to require it be a primary contact.  Now it's owner.
      // const primaryContact = getPrimaryContact(props.addr);
      // // debugger
      // if (primaryContact && primaryContact.user_id && primaryContact.user_id === auth.uid) {
      //   return true;
      // }
      if (userIsAdmin(al)) {
        return true;
      }

      return false;
    }
    const cannotModifyAddress = (al) => {
      // if (auth.app_admin || ((al.users && al.users[0] && al.users[0].user_id) === auth.uid)) {
      //   return false;
      // }
      return !userCanModify(al);
    }

    const fixCaseStringChanged = (e) => {
      // const s = capitalizeString(e.target.value);
      // formik.setFieldValue(e.target.name, s);
      formik.setFieldValue(e.target.name, e.target.value);
    }
    const buildModalForm = (al) => {
      // if not mobile and not edit then show the list
      // if (!editAddr) {
      //   return buildListView(al);
      // }
      if (cannotModifyAddress(al) || (userCanModify(al) && !editAddr)) {
        return null;
      }
      return (
        <Form onSubmit={formik.handleSubmit}>
          <Input
            disabled={properties.working}
            id='last_name'
            required
            label="Last Name"
            value={formik.values.last_name}
            name='last_name'
            changed={fixCaseStringChanged}
            blur={formik.handleBlur}
            errors={formik.errors.last_name}
            touched={formik.touched.last_name}
            showValid
          />
          <Input
            disabled={properties.working}
            id='first_name1'
            required
            label="First Name"
            value={formik.values.first_name1}
            name='first_name1'
            changed={fixCaseStringChanged}
            blur={formik.handleBlur}
            errors={formik.errors.first_name1}
            touched={formik.touched.first_name1}
            showValid
          />
          <Input
            disabled={properties.working}
            id='first_name2'
            required={false}
            label="First Name 2"
            value={formik.values.first_name2}
            name='first_name2'
            changed={fixCaseStringChanged}
            blur={formik.handleBlur}
            errors={formik.errors.first_name2}
            touched={formik.touched.first_name2}
            showValid
          />
          <Input
            disabled={properties.working}
            id='pri_street_addr'
            required
            label="Primary Street"
            value={formik.values.pri_street_addr}
            name='pri_street_addr'
            changed={formik.handleChange}
            blur={formik.handleBlur}
            errors={formik.errors.pri_street_addr}
            touched={formik.touched.pri_street_addr}
            showValid
          />
          <Input
            disabled={properties.working}
            id='pri_town'
            required
            label="Primary Town"
            value={formik.values.pri_town}
            name='pri_town'
            changed={formik.handleChange}
            blur={formik.handleBlur}
            errors={formik.errors.pri_town}
            touched={formik.touched.pri_town}
            showValid
          />
          <Input
            disabled={properties.working}
            id='pri_state'
            required
            label="Primary State"
            value={formik.values.pri_state}
            name='pri_state'
            changed={formik.handleChange}
            blur={formik.handleBlur}
            errors={formik.errors.pri_state}
            touched={formik.touched.pri_state}
            showValid
          />
          <Input
            disabled={properties.working}
            id='pri_zipcode'
            required
            label="Primary Zipcode"
            value={formik.values.pri_zipcode}
            name='pri_zipcode'
            changed={formik.handleChange}
            blur={formik.handleBlur}
            errors={formik.errors.pri_zipcode}
            touched={formik.touched.pri_zipcode}
            showValid
          />
          <hr />
          <p>Wild Harbour Address</p>
          <hr />
          <Input
            disabled={allowWhAddrChange()}
            id='wh_house_num'
            required
            label="House Number"
            value={formik.values.wh_house_num}
            name='wh_house_num'
            changed={formik.handleChange}
            blur={formik.handleBlur}
            errors={formik.errors.wh_house_num}
            touched={formik.touched.wh_house_num}
            showValid
          />
          <Input
            disabled={allowWhAddrChange()}
            id='wh_street'
            required
            label="Street"
            value={formik.values.wh_street}
            name='wh_street'
            changed={formik.handleChange}
            blur={formik.handleBlur}
            errors={formik.errors.wh_street}
            touched={formik.touched.wh_street}
            showValid
          />
          <Input
            disabled={properties.working}
            id='phone'
            label="Phone"
            value={formik.values.phone}
            name='phone'
            changed={formik.handleChange}
            blur={formik.handleBlur}
            errors={formik.errors.phone}
            touched={formik.touched.phone}
            showValid
          />
          <InputGroup>
            <InputGroup.Prepend className={classes.InputGroup}>
              <InputGroup.Text  id='built'>Built ?</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Label className={classes.Radio} htmlFor='built_true'>YES</Form.Label>
            <Form.Check
              disabled={properties.working}
              type='radio'
              as='input'
              id="built_true"
              className={classes.Radio}
              checked={formik.values.built === true}
              value={true}
              name='built'
              onChange={handleRadioChange}
              isInvalid={formik.errors['built']}
              isValid={!formik.errors['built']}
            />
            <Form.Label className={classes.Radio} htmlFor='built_false'>NO</Form.Label>
            <Form.Check
              disabled={properties.working}
              styles={[{marginLeft: 20}]}
              type='radio'
              as='input'
              id="built_false"
              className={classes.Radio}
              checked={formik.values.built === false}
              value={false}
              name='built'
              onChange={handleRadioChange}
              isInvalid={formik.errors['built']}
              isValid={!formik.errors['built']}
            />
          </InputGroup>
          {/* <input type='hidden' name='id' value={formik.values.id} /> */}
          <InputGroup>
            <InputGroup.Prepend className={classes.InputGroup}>
            </InputGroup.Prepend>
            <Button type="submit" disabled={properties.working}>Update</Button>
          </InputGroup>

        </Form>
      );
    }
  
    const showModal = (al) => {
      // XXX: Change this to allow showing modal IF admin or owner of the property
      // if (this.state.modal && al.users && al.users[0] && al.users[0].user_id !== 121) {
      if (modalState) {
          return true;
      }
      console.log(`You are not the owner or an admin...state.modal ${modalState}`, al);
      return false;
    }
  
    const hideModal = () => {
      setModalState(false);
      setEditAddr(false);
      formik.resetForm();
    }
  
    const showSpinner = () => {
      if (properties.working) {
        return <Spinner />;
      } else {
        return null;
      }
    }

    const renderPropertyContactModal = (al, pc) => {
      return (
        <Modal dialogClassName={classes.ModalDialog} show={showContactsModal !== null} onHide={() => setShowContactsModal({type: CONTACT_LIST, payload: al})}>
          <ModalHeader className={classes.ModalHeader} closeButton>Property Contact Info for - {capitalizeString(pc.first_name)} {capitalizeString(pc.last_name)}</ModalHeader>
          <ModalBody>{renderPropertyContactInfo(pc)}</ModalBody>
          <Modal.Footer>
            <Button color="secondary" size="sm" onClick={(e) => {setShowContactsModal({type: EDIT_CONTACT, payload: pc})}}>EDIT</Button>
          </Modal.Footer>
        </Modal>
      )
    }
    const renderEditPropertyContactModal = (al, pc) => {
      return (
        <Modal dialogClassName={classes.ModalDialog} show={showContactsModal !== null} onHide={() => setShowContactsModal({type: CONTACT_LIST, payload: al})}>
          <ModalHeader className={classes.ModalHeader} closeButton>Property Contact Info for - {capitalizeString(pc.first_name)} {capitalizeString(pc.last_name)}</ModalHeader>
          <ModalBody>{renderEditPropertyContactForm(pc)}</ModalBody>
        </Modal>
      )
    }
    const renderNewPropertyContactModal = (al) => {
      return (
        <Modal dialogClassName={classes.ModalDialog} show={showContactsModal !== null} onHide={() => setShowContactsModal({type: CONTACT_LIST, payload: al})}>
          <ModalHeader className={classes.ModalHeader} closeButton>New Property Contact Info</ModalHeader>
          <ModalBody>{renderPropertyContactForm(al, al.property_contacts.length === 0)}</ModalBody>
        </Modal>
      )
    }

    const renderAddContactButton = (al) => {
      if (userCanModify(al)) {
        return (
          <Modal.Footer>
            <Button color="secondary" size="sm" onClick={(e) => {setShowContactsModal({type: NEW_CONTACT, payload: al})}}>Add Contact</Button>
          </Modal.Footer>
        )
      }
      return null;
    }
    const renderPropertyContactsModal = (al) => {
      return (
        <Modal dialogClassName={classes.ModalDialog} show={showContactsModal !== null} onHide={() => {setRenderLongEmail(false); setShowContactsModal(null)}}>
          <Modal.Header className={classes.ModalHeader} closeButton>Contact(s) for '{al.wh_house_num} {al.wh_street} - Lot # {al.lot_num}'</Modal.Header>
          <Modal.Body>
            {renderPropertyContacts(al)}
          </Modal.Body>
          {/* TODO: Change PropContact form to not fill in first/last name by default for this case... */}
          {renderAddContactButton(al)}
        </Modal>
      )
    }

    const renderPropertyContactTypeModal = al => {
      if (showContactsModal === null) {
        return null;
      }

      switch(showContactsModal.type) {
        case CONTACT_LIST:
          return renderPropertyContactsModal({...showContactsModal.payload});

        case SPECIFIC_CONTACT:
          //renderPropertyContactInfo(pc)
          //return 
          return renderPropertyContactModal(al, showContactsModal.payload)

        case EDIT_CONTACT:
          return renderEditPropertyContactModal(al, showContactsModal.payload)

        case NEW_CONTACT:
          return renderNewPropertyContactModal(al)

        default:
          return null;
      }
    }

    const renderModal = (al) => {
      if (modalState === false) {
        return null;
      }
      let theForm = buildModalForm(al)
      if (theForm === null) {
        return null;
      }

      let modalHdrText = `Update entry for '${al.wh_house_num} ${al.wh_street}' - Lot # ${al.lot_num}`;
      if (cannotModifyAddress(al)) {
        modalHdrText = `${al.wh_house_num} ${al.wh_street} - Lot # ${al.lot_num}`;
      }
      return (
        <Modal show={showModal(al)} onHide={hideModal}>
          <ModalHeader>{modalHdrText}</ModalHeader>
          <ModalBody>
            {showSpinner()}
            {/* <Row>
              <Col>{al.lot_num}</Col>
            </Row>
            <Row>
              <Col>{al.lot_num}</Col>
            </Row>
            <Row>
              <Col>{al.last_name}</Col>
            </Row>
            <Row>
              <Col>{firstName}</Col>
            </Row> */}
            {theForm}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      );
    };

    // const al = addrState;
    const al = props.addr;
    return (
      <div ref={ref}>
        { renderAddressDiv(al) }

        { modalState ? renderModal(al) : null }

        { renderPropertyContactTypeModal(al) }
      </div>
    );
  });
    
  export default WhAddressFunc;